import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  id: "",
  isAdmin: false,
  picture: "",
  companyName: "",
  companyMarketField: "",
  companyNumberOfEmployees: "",
  position: "",
  city: "",
  state: "",
  country: "",
  intendedUse: "",
  howCameToKnow: "",
  levelOfMLExperience: "",
  needRefetch: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
      state.id = action.payload.id;
      state.isAdmin = action.payload.isAdmin;
      state.token = action.payload.token;
      state.picture = action.payload.picture;
      state.companyName = action.payload.companyName;
      state.companyMarketField = action.payload.companyMarketField;
      state.companyNumberOfEmployees = action.payload.companyNumberOfEmployees;
      state.position = action.payload.position;
      state.city = action.payload.city;
      state.state = action.payload.state;
      state.country = action.payload.country;
      state.intendedUse = action.payload.intendedUse;
      state.howCameToKnow = action.payload.howCameToKnow;
      state.levelOfMLExperience = action.payload.levelOfMLExperience;
    },
    setFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    setLastName: (state, action) => {
      state.lastName = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setId: (state, action) => {
      state.id = action.payload;
    },
    setIsAdmin: (state, action) => {
      state.isAdmin = action.payload;
    },
    setPicture: (state, action) => {
      state.picture = action.payload;
    },
    setTutorialDone: (state, action) => {
      state.tutorialDone = action.payload;
    },
    setNeedRefetchUser: (state, action) => {
      state.needRefetch = action.payload;
    }
  }
});

export const {
  setUser,
  setFirstName,
  setLastName,
  setEmail,
  setId,
  setIsAdmin,
  setPicture,
  setNeedRefetchUser
} = userSlice.actions;
export default userSlice.reducer;
export const selectUserFirstName = (state) => state.user.firstName;
export const selectUserLastName = (state) => state.user.lastName;
export const selectUserEmail = (state) => state.user.email;
export const selectUserId = (state) => state.user.id;
export const selectUserIsAdmin = (state) => state.user.isAdmin;
export const selectUserPicture = (state) => state.user.picture;
export const selectUser = (state) => state.user;
export const selectNeedRefetchUser = (state) => state.user.needRefetch;
