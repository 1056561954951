import "./../../../assets/css/views/popups/actions/apikeypopup.css";

import AddicionIcon from "./../../../assets/icons/jsxIcons/AdditionIcon";
import TrashIcon from "./../../../assets/icons/jsxIcons/TrashIcon";
import PencilIcon from "../../../assets/icons/jsxIcons/PencilIcon";
import CodeIcon from "./../../../assets/icons/jsxIcons/CodeIcon";

import externalApi from "../../../services/ExternalApi";

import { useEffect } from "react"
import DataParse from "../../../helpers/DataParse";

import { useTranslation } from "react-i18next"
import "./../../../translation/i18n";

function ApiKeyPopup({
    setTrigger, 
    setNewKeyApiPopup, 
    setImplementCodePopup,
    modelID,
    token,
    setApiKey,
    setEditApiKeyPopup,
    listKeys,
    setListKeys,
}){

    const {t} = useTranslation();

    function deleteKey(index){
        if(listKeys[index]){
            externalApi.deletekeyapi(token, listKeys[index].id)
            .then(() => {
                let auxKeys = listKeys;
                auxKeys.splice(index, 1);
                setListKeys(auxKeys);
                setApiKey(null);
            })
        }
    }

    const date = (time) => {
        if(typeof time === "number"){
            let aux = DataParse.parseDate(time);
            if(aux){
                return aux.day;
            }
            return "error";
        }
        return "error";
    }
    
    useEffect(() => {
        if(modelID && token){
            let id = modelID;
            setApiKey("");
            let array = [];
            externalApi.getbymodel(token, id)
            .then(data => {
                if(data){
                    let auxKeys = [];
                    data.forEach(element => {
                        let auxKey = {
                            ...element,
                            selected: false,
                            authorizedIPAddresses: element.authorizedIPAddresses ? element.authorizedIPAddresses : array,
                            key: "",
                        }
                        auxKeys.unshift(auxKey);
                    })
                    setListKeys([]);
                    setTimeout(() => {
                        setListKeys(auxKeys);
                    }, 0);
                }
            })
        }
        //eslint-disable-next-line
    }, [modelID], []);

    return (
        <div className="containerApiKey">
            <div className="containerHeader">
                <p>{t("popup.action.apikey.title")}</p>
                <span>{t("popup.action.apikey.description_1")}</span>
                {listKeys.length === 0 && <span>{t("popup.action.apikey.description_2")}</span>}
            </div>
            {listKeys.length > 0 ? (
                <div className="containerContent-ApiKeyPopup">
                    <header className="headerContent">
                        <p id="name">{t("popup.action.apikey.table.nav.name_1")}</p>
                        <p id="use">{t("popup.action.apikey.table.nav.name_2")}</p>
                        <p id="status">{t("popup.action.apikey.table.nav.name_3")}</p>
                        <p id="creation">{t("popup.action.apikey.table.nav.name_4")}</p>
                        <p id="validity">{t("popup.action.apikey.table.nav.name_5")}</p>
                    </header>
                    <div className="containerKey-ApiKeyPopup">
                        {listKeys.map((data, index) => {
                            return (
                                <div key={index} className={data.selected ? "containerKeySelected" : ""} onClick={() => {
                                    let auxKeys = [];
                                    let i = 0;
                                    while(i < listKeys.length){
                                        let card = listKeys[i];
                                        if(i === index){
                                            card.selected = !card.selected;
                                            setApiKey(card);
                                        } else {
                                            card.selected = false;
                                        }
                                        auxKeys.push(card);
                                        i++;
                                    }
                                    setListKeys(auxKeys);
                                }}>
                                    <p id="name">{data.alias}</p>
                                    <p id="use">{data.count}</p>
                                    <p id="status">{!data.apiExpired ? (<span id="active">{t("popup.action.apikey.table.status.active")}</span>) : (<span id="inactive">{t("popup.action.apikey.table.status.inactive")}</span>)}</p>
                                    <p id="creation">{date(data.date)}</p>
                                    <p id="validity">{data.dateLimit ? date(data.dateLimit) : "indefinido"}</p>
                                    {data.selected && (
                                        <div id="edit">
                                            <div onClick={() => {
                                                setTrigger(false);
                                                setImplementCodePopup(true);
                                            }}>
                                                <CodeIcon />
                                            </div>
                                            <div onClick={() => {
                                                setTrigger(false);
                                                setEditApiKeyPopup(true);
                                            }}>
                                                <PencilIcon style={{size: 16}}/>
                                            </div>
                                            <div onClick={() => deleteKey(index)}>
                                                <TrashIcon style={{size: 16}}/>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                </div>
            ) : (
                <div>
                </div>
            )}
            <div className="buttonNewKeyApi" onClick={() => {
                    setTrigger(false);
                    setNewKeyApiPopup(true);
            }}>
                <AddicionIcon />
                <p>{t("popup.action.apikey.button")}</p>
            </div>
        </div>
    )
}

export default ApiKeyPopup;