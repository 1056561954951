import styles from "./../../../assets/css/monitoring/views/screens/MongoDB.module.css"

import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, Filler, Title, Tooltip, Legend, PointElement, LineElement } from "chart.js"
import logoMongoDB from "./../../../assets/images/mongodb.png";

import { useState, useEffect } from "react";
import { t } from "i18next";
import handleErrorResponse from "../../../helpers/handleErrorResponse";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Popup } from "../../../components/Popup";

import LoadingWheel from "../../../components/LoadingWheel";

import monitoringService from "../../../services/MonitoringService";

// cache:
import { useDispatch, useSelector } from "react-redux";
import {
    selectMongoDBLogs,
    selectMongoDBLogsUpdated,
    selectMemoriesMongoDB,
    setMongoDBLogs,
    setMongoDBLogsUpdated
} from "../../../store/slices/monitoring/monitoringSlice";

function MongoDB() {
    const [isLoading, setIsLoading] = useState(true);
    const [triggerPopup, setTriggerPopup] = useState();
    const [graphic, setGraphic] = useState(null);
    const link = "monitoring.dashboard.service.mongodb";
    const history = useHistory();
    const dispatch = useDispatch();
    const mongoDBLogsUpdated = useSelector(selectMongoDBLogsUpdated);
    const mongoDBLogs = useSelector(selectMongoDBLogs);
    const memoriesMongoDB = useSelector(selectMemoriesMongoDB);

    function initiGraphic() {
        if (memoriesMongoDB) {
            try {
                ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Filler, Title, Tooltip, Legend);
                setGraphic({
                    data: {
                        labels: memoriesMongoDB.map(memorie => { return memorie.Time }).reverse(),
                        datasets: [{
                            label: t(`${link}.graphic.buttons.virtual`),
                            data: memoriesMongoDB.map(memorie => { return memorie.VirtualMemUsed }).reverse(),
                            backgroundColor: 'rgba(255, 0, 0, 0.2)',
                            borderColor: 'rgba(255, 0, 0, 0.6)',
                            fill: true,
                            pointRadius: 0,
                            lineTension: 0.2
                        }, {
                            label: t(`${link}.graphic.buttons.resident`),
                            data: memoriesMongoDB.map(memorie => { return memorie.ResidentMemUsed }).reverse(),
                            backgroundColor: 'rgba(0, 0, 255, 0.2)',
                            borderColor: 'rgba(0, 0, 255, 0.6)',
                            fill: true,
                            pointRadius: 0,
                            lineTension: 0.2
                        }]
                    },
                    options: {
                        maintainAspectRatio: false,
                        responsive: true,
                        plugins: {
                            title: {
                                display: true,
                                text: t(`${link}.graphic.title`)
                            },
                            legend: {
                                position: "top"
                            },
                        },
                        scales: {
                            x: {
                                type: 'category',
                                labels: memoriesMongoDB.map(memorie => memorie.Time).reverse(),
                                ticks: {
                                    font: {
                                        size: 8
                                    }
                                },
                                grid: {
                                    display: true,
                                    drawBorder: true,
                                }
                            },
                        }
                    }
                })
            } catch (error) {

            }
        }
    }

    useEffect(() => {
        if (!mongoDBLogsUpdated) {
            monitoringService.getmongolog()
                .then(data => {
                    if (data.length) {
                        data = data.map(element => {
                            return {
                                ...element,
                                t: { "$date": new Date(element.t["$date"] * 1000).toLocaleString('pt-BR') }
                            }
                        });
                        dispatch(setMongoDBLogs(data));
                        dispatch(setMongoDBLogsUpdated(true));
                    }
                })
                .catch(error => handleErrorResponse(history, error, t))
                .finally(() => setIsLoading(false));
        } else {
            setIsLoading(false);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        initiGraphic();
        // eslint-disable-next-line
    }, [mongoDBLogsUpdated]);

    if (isLoading) {
        return (
            <main className={styles.isloading}>
                <LoadingWheel loadingSize="large" />
            </main>
        )
    } else {
        return (
            <main className={styles.containermongodb}>
                <section className={styles.containergraphic}>
                    <div className={styles.graphic}>
                        {graphic && <Line data={graphic.data} options={graphic.options} />}
                    </div>
                    <div className={styles.logo}>
                        <img alt={logoMongoDB} src={logoMongoDB} />
                    </div>
                </section>
                <section className={styles.containerlogs}>
                    <h2>{t(`${link}.content.header.title_1`)}</h2>
                    <div className={styles.tablelogs}>
                        <header>
                            <h2 className={styles.date}>{t(`${link}.content.header.date`)}</h2>
                            <span></span>
                            <h2 className={styles.log}>{t(`${link}.content.header.log`)}</h2>
                        </header>
                        <div className={styles.logs}>
                            {mongoDBLogs.map(log => {
                                return (
                                    <button onClick={() => { setTriggerPopup(log) }} key={log.id}>
                                        <div className={styles.date}><p>{log.t["$date"]}</p></div>
                                        <div className={styles.log}><p>{log.msg}</p></div>
                                    </button>
                                )
                            })}
                        </div>
                    </div>
                </section>
                <Popup trigger={triggerPopup} setTrigger={setTriggerPopup}>
                    <pre className={styles.containerjson}>{JSON.stringify(triggerPopup, null, 2)}</pre>
                </Popup>
            </main>
        )
    }
}

export default MongoDB;