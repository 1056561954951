import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

export const RocCurveChartD3 = ({ datas, selectedCategory }) => {
  const rocCurveChartRef = useRef();

  function drawRocCurveChart() {
    // Limpa o SVG existente para criar um novo gráfico
    d3.select(rocCurveChartRef.current).selectAll("*").remove();

    const data = datas[selectedCategory];
    const xData = data.FPR;
    const yData = data.TPR;

    const margin = { top: 20, right: 20, bottom: 20, left: 40 };
    const width = 450;
    const height = 230;

    const svg = d3
      .select(rocCurveChartRef.current)
      .attr("viewBox", `0 0 ${width} ${height}`) // Define a caixa de visualização
      .attr("preserveAspectRatio", "xMidYMid meet") // Mantém a proporção do gráfico
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const xScale = d3
      .scaleLinear()
      .domain([0, 1])
      .range([0, width - margin.left - margin.right]);

    const yScale = d3
      .scaleLinear()
      .domain([0, 1])
      .range([height - margin.top - margin.bottom, 0]);

    // Eixos
    svg.append("g").attr("transform", `translate(0,${height - margin.top - margin.bottom})`).call(d3.axisBottom(xScale));
    svg.append("g").call(d3.axisLeft(yScale));

    // Rótulos e linhas
    svg.append("text").attr("x", width - 130).attr("y", height - 50).text(`AUC: ${data.AUC.toFixed(4)}`);
    svg.append("path").datum([[0, 0], [1, 1]]).attr("stroke", "grey").attr("stroke-dasharray", "5,5").attr("d", d3.line().x(d => xScale(d[0])).y(d => yScale(d[1])));

    // Linha do ROC Curve
    const jointDataArray = xData.map((x, i) => ({ x, y: yData[i] }));
    svg.append("path").datum(jointDataArray).attr("stroke", "blue").attr("fill", "none").attr("d", d3.line().x(d => xScale(d.x)).y(d => yScale(d.y)));
  }

  useEffect(() => {
    drawRocCurveChart();
  }, [datas, selectedCategory]);

  return <svg ref={rocCurveChartRef} />;
};
