import * as d3 from "d3";
import React, { useState, useEffect, useRef, useCallback } from "react";
import "../../assets/css/graphicsComponents/modelsRepresentations/kmeansd3.css";

export const KmeansD3 = ({ result, fieldList, setSelectedCluster }) => {
  const [colorDefault, setColorDefault] = useState("");

  /* PREVINE ZOOM NA PÁGINA */
  // https://stackoverflow.com/a/56546458
  // https://stackoverflow.com/a/55566585
  // https://stackoverflow.com/a/33083910
  // https://stackoverflow.com/q/27116221
  // Adicionar em modelos que possuam zoom próprio
  // (pra poder dar zoom com Ctrl + e Ctrl -)
  /* -------------- COMEÇO ---------------- */
  const handleUserZoom = useCallback((event) => {
    if (event.ctrlKey) {
      event.preventDefault();
    }
  }, []);

  useEffect(() => {
    // listener de comando de mouse
    document.getElementById("root").addEventListener("wheel", handleUserZoom);

    return () => {
      document
        .getElementById("root")
        .removeEventListener("wheel", handleUserZoom);
    };
  }, [handleUserZoom]);
  /* -------------- FIM ------------------- */

  // vetor com as paletas de cores indicadas no figma
  const colorScheme = [
    // 'rgba(57, 40, 104, 1)',
    // 'rgba(30, 22, 99, 1)',
    // 'rgba(3, 4, 94, 1)',
    "rgba(73, 47, 117, 1)",
    "rgba(38, 55, 128, 1)",
    "rgba(2, 62, 138, 1)",
    // 'rgba(89, 54, 130, 1)',
    // 'rgba(45, 87, 156, 1)',
    // 'rgba(0, 119, 182, 1)',
    "rgba(105, 61, 143, 1)",
    "rgba(53, 106, 171, 1)",
    "rgba(0, 150, 199, 1)",
    // 'rgba(120, 67, 155, 1)',
    // 'rgba(60, 124, 186, 1)',
    // 'rgba(0, 180, 216, 1)',
    "rgba(149, 101, 178, 1)",
    "rgba(111, 152, 203, 1)",
    "rgba(72, 202, 228, 1)",
    //'rgba(177, 134, 201, 1)',
    //'rgba(161, 179, 220, 1)',
    //'rgba(144, 224, 239, 1)',
    "rgba(205, 167, 224, 1)",
    "rgba(189, 200, 234, 1)",
    "rgba(173, 232, 244, 1)",
    //'rgba(233, 200, 247, 1)',
    //'rgba(218, 220, 248, 1)',
    //'rgba(202, 240, 248, 1)',
  ];

  // inicializa um Ref
  const d3Chart = useRef();

  //Key Perfomance Indicator
  function KmeansKPI(d, i) {
    if (i > colorScheme.length) {
      return colorScheme[colorScheme.length - (i % colorScheme.length)];
    } else {
      return colorScheme[i];
    }
  }

  function desenharKmeans() {
    // adicionado pra remover um bug que, ao alterar entre
    // algoritmos muito rápido, fazia o frontend crashar porque
    // o resultado era null ou não possuia uma propriedade de
    // 'centers', que (eu acho) que são os centroides do kmeans
    if (result === null || !result.hasOwnProperty("centers")) {
      return;
    }

    // remove a linha e os eixos pra desenhar novamente, evitando que fique se sobrepondo
    d3.select("#kmeansd3-circle").remove();

    let isGrabbing = false;
    let isZooming = false;
    let lastTransform = null;

    const margin = {top: 0, right: 0, bottom: 0, left: 0};
    const width = parseInt(d3.select(".model-result-1").style("width"), 10) - margin.left - margin.right;
    const height = parseInt(d3.select(".model-result-1").style("height"), 10) - margin.top - margin.bottom;

    const svg = d3
      .select(d3Chart.current)
      .attr("viewBox", `0 0 ${width} ${height}`) // Define a caixa de visualização
      .attr("preserveAspectRatio", "xMidYMid meet") // Mantém a proporção do gráfico
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // d3.select("#kmeansd3-result")
    //   .on("mousedown", function () {
    //     // Botão do mouse esquerdo pressionado
    //     isGrabbing = true;
    //     d3.select("body").style("cursor", "grab");
    //   })
    //   .on("mouseup", function () {
    //     // Botão do mouse esquerdo liberado
    //     isGrabbing = false;
    //     d3.select("body").style("cursor", "default");
    //   })
    //   .on("wheel", function (event) {
    //     isZooming = true;
    //     isGrabbing = true;
    //     d3.select("body").style("cursor", "default");
    //   });

    var forceProperties = {
      center: {
        x: 0.5,
        y: 0.5,
      },
      collide: {
        strenghth: 0.7,
        iterations: 1,
        radius: 10,
      },
      forceX: {
        enabled: false,
        strenghth: 0.1,
        x: 0.5,
      },
      forceY: {
        enabled: false,
        strenghth: 0.1,
        y: 0.5,
      },
    };

    //Cria simulação de forças
    var simulation = d3.forceSimulation();

    //Cria escala de tamanho
    var escala = d3
      .scaleLinear()
      .domain([
        0,
        Math.max.apply(
          null,
          result.centers.map((x) => x.count)
        ),
      ])
      .range([10, width / 10]);

    // Pedro: criando os circulos, aplicando
    const node = svg
      .append("g")
      .attr("id", "kmeansd3-circle")
      .attr("class", "nodes")
      .selectAll("circle")
      .data(result.centers)
      .join("circle")
      .attr("cx", ({ x }) => x)
      .attr("cy", ({ y }) => y)
      .attr("r", (d, i) => {
        return escala(d.count);
      })
      .attr("fill", function (d, i) {
        return KmeansKPI(d, i);
      })
      .call(d3.drag())
      .on("mouseover", function (d, i) {
        var current = d3.select(this);
        setSelectedCluster(i);
        current
          .style("stroke", "lightgray")
          .style("fill", "black")
          .style("stroke-width", "4px");
      })
      .on("mouseout", function (d, i) {
        // Remove the info text on mouse out.
        d3.select(this)
          .style("stroke", "lightgray")
          .style("fill", KmeansKPI(d, i))
          .style("stroke-width", "4px");
      });
    // d3.select("#kmeansd3-result").call(
    //   d3
    //     .zoom()
    //     .extent([
    //       [0, 0],
    //       [width, height],
    //     ])
    //     .scaleExtent([-8, 10])
    //     .on("zoom", zoomed)
    // );

    function zoomed({ transform }) {
      if (lastTransform !== null) {
        if (transform.k - lastTransform.k !== 0 && isZooming) {
          svg.attr("transform", transform);

          let direction = transform.k - lastTransform.k < 0 ? "down" : "up";

          if (direction === "down") {
            d3.select("body").style("cursor", "vertical-text");
          } else {
            d3.select("body").style("cursor", "crosshair");
          }
        } else if (
          (transform.x - lastTransform.x !== 0 ||
            transform.y - lastTransform.y !== 0) &&
          isGrabbing
        ) {
          svg.attr("transform", transform);
        }
      } else {
        svg.attr("transform", transform);
      }
      lastTransform = transform;
    }

    simulation.nodes(result.centers);
    simulation
      .force("collide", d3.forceCollide())
      .force("center", d3.forceCenter());

    simulation
      .force("center")
      .x(width * forceProperties.center.x)
      .y(height * forceProperties.center.y);
    simulation
      .force("collide")
      .strength(0.7)
      .radius((d) => {
        return escala(d.count);
      })
      .iterations(3);

    // Reinicia a simulação para aplicar mudanças
    simulation.alpha(1).restart();
    simulation.on("tick", () => {
      node
        .attr("cx", function (d) {
          return d.x;
        })
        .attr("cy", function (d) {
          return d.y;
        });
    });

    return svg.node();
  }

  useEffect(() => {
    desenharKmeans();
    // eslint-disable-next-line
  }, [fieldList]);

  return (
    <svg ref={d3Chart}></svg>
  );
};
