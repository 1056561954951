function RefreshIcon({ style }) {
  if (style == null) {
    style = [];
  }

  return (
    <svg
      width={style.hasOwnProperty("size") ? style.size : 24}
      height={style.hasOwnProperty("size") ? style.size : 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_408_1588)">
        <path
          d="M22.9509 12C22.7063 11.9969 22.4694 12.0852 22.2865 12.2476C22.1036 12.4099 21.9878 12.6348 21.9619 12.878C21.7655 15.1518 20.7978 17.2903 19.2194 18.9387C17.6409 20.5872 15.5465 21.6467 13.2833 21.9416C11.0202 22.2365 8.72416 21.7491 6.77599 20.5601C4.82783 19.3712 3.34451 17.5521 2.57195 15.4045C1.79939 13.257 1.78399 10.9098 2.5283 8.75231C3.27262 6.59478 4.73195 4.75643 6.66434 3.54202C8.59674 2.32762 10.8861 1.8101 13.153 2.07528C15.4198 2.34045 17.528 3.37239 19.1279 5H16.3759C16.1107 5 15.8564 5.10536 15.6688 5.29289C15.4813 5.48043 15.3759 5.73478 15.3759 6C15.3759 6.26522 15.4813 6.51957 15.6688 6.70711C15.8564 6.89464 16.1107 7 16.3759 7H20.3759C20.9064 7 21.4151 6.78929 21.7901 6.41421C22.1652 6.03914 22.3759 5.53043 22.3759 5V1C22.3759 0.734784 22.2706 0.48043 22.083 0.292893C21.8955 0.105357 21.6412 0 21.3759 0C21.1107 0 20.8564 0.105357 20.6688 0.292893C20.4813 0.48043 20.3759 0.734784 20.3759 1V3.415C18.4269 1.51604 15.8901 0.336284 13.182 0.0694657C10.4739 -0.197353 7.75565 0.464628 5.47349 1.9467C3.19134 3.42877 1.48099 5.64283 0.623306 8.22531C-0.234374 10.8078 -0.1884 13.6051 0.753679 16.158C1.69576 18.7109 3.47794 20.8676 5.80757 22.2739C8.13719 23.6801 10.8758 24.2524 13.5736 23.8968C16.2714 23.5411 18.7681 22.2786 20.6537 20.3167C22.5393 18.3547 23.7017 15.8098 23.9499 13.1C23.9628 12.9608 23.9466 12.8205 23.9024 12.6879C23.8581 12.5554 23.7867 12.4335 23.6927 12.33C23.5987 12.2265 23.4842 12.1437 23.3565 12.0869C23.2288 12.0301 23.0907 12.0005 22.9509 12V12Z"
          fill={style.hasOwnProperty("color") ? style.color : "#282828"}
        />
      </g>
      <defs>
        <clipPath id="clip0_408_1588">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default RefreshIcon;
