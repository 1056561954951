import React, { useState } from "react";
import {
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   Button,
   Typography,
   makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import StopIcon from "@material-ui/icons/Stop";
import wiselib from "../../../services/wiselib";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { setTutorialDone } from "../../../store/slices/wizard/wizardSlice";
import handleErrorResponse from "../../../helpers/handleErrorResponse";
import { Info } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
   dialogContent: {
      textAlign: "center",
      padding: theme.spacing(3),
   },
   dialogActions: {
      justifyContent: "center",
      padding: theme.spacing(2),
   },
   button: {
      margin: theme.spacing(1),
   },
}));

const CloseTourDialog = ({ open, onCloseDialog, onCloseTour }) => {
   const classes = useStyles();

   const [isLoading, setIsLoading] = useState(false);
   const dispatch = useDispatch();
   const history = useHistory();
   const { t } = useTranslation();

   const handleButtonCloseTour = () => {
      onCloseDialog();
      onCloseTour();
   };

   const onStopTutorial = () => {
      setIsLoading(true);
      wiselib
         .updatetutorialdone(true)
         .then(() => {
            dispatch(setTutorialDone(true));
         })
         .catch((error) => {
            handleErrorResponse(history, error, t);
         })
         .finally(() => {
            onCloseDialog();
            setIsLoading(false);
         });
   };

   return (
      <Dialog open={open} onClose={onCloseDialog} style={{ zIndex: 9999999 }}>
         <DialogTitle>{t("wizard.closeTourDialog.title")}</DialogTitle>
         <DialogContent
            className={classes.dialogContent}
            style={{ display: "flex", flexDirection: "column", gap: "2em" }}
         >
            <Typography variant="body1">
               <strong>{t("wizard.closeTourDialog.description_1")}</strong>
            </Typography>
            <Typography
               variant="body2"
               style={{ display: "flex", alignItems: "start" }}
            >
               <Info />
               {t("wizard.closeTourDialog.description_2")}
            </Typography>
         </DialogContent>
         <DialogActions className={classes.dialogActions}>
            <Button
               onClick={handleButtonCloseTour}
               color="primary"
               variant="contained"
               startIcon={<CloseIcon />}
               disabled={isLoading}
               className={classes.button}
            >
               {t("wizard.closeTourDialog.button_step")}{" "}
            </Button>
            <Button
               onClick={onStopTutorial}
               color="secondary"
               variant="contained"
               startIcon={<StopIcon />}
               disabled={isLoading}
               className={classes.button}
            >
               {t("wizard.closeTourDialog.button_stop")}
            </Button>
         </DialogActions>
      </Dialog>
   );
};

export default CloseTourDialog;
