function DecisionTreeIcon({style}) {
  if(style == null){
    style = [];
  }
  return (
    <svg
      width={style.hasOwnProperty('size') ? style.size : 58}
      height={style.hasOwnProperty('size') ? style.size : 58}
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="35.0417"
        cy="8.45832"
        rx="6.04167"
        ry="6.04167"
        fill={style.hasOwnProperty('color') ? style.color : "#57CC99"}
      />
      <ellipse
        cx="47.125"
        cy="27.7917"
        rx="6.04167"
        ry="6.04167"
        fill={style.hasOwnProperty('color') ? style.color : "#57CC99"}
      />
      <circle 
        cx="35.0417" 
        cy="49.5417" 
        r="6.04167" 
        fill={style.hasOwnProperty('color') ? style.color : "#57CC99"} 
      />
      <ellipse
        cx="22.9584"
        cy="27.7917"
        rx="6.04167"
        ry="6.04167"
        fill={style.hasOwnProperty('color') ? style.color : "#57CC99"}
      />
      <circle 
        cx="10.875" 
        cy="49.5417" 
        r="6.04167" 
        fill={style.hasOwnProperty('color') ? style.color : "#57CC99"} 
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.2503 7.25001L47.058 25.9694L44.9651 27.1778L34.1574 8.45834L36.2503 7.25001Z"
        fill={style.hasOwnProperty('color') ? style.color : "#57CC99"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.1669 29L34.9746 47.7194L32.8817 48.9278L22.0741 30.2083L24.1669 29Z"
        fill={style.hasOwnProperty('color') ? style.color : "#57CC99"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.1666 25.9694L34.9742 7.24998L37.0671 8.45831L26.2595 27.1777L24.1666 25.9694Z"
        fill={style.hasOwnProperty('color') ? style.color : "#57CC99"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0833 47.7194L22.891 29L24.9839 30.2083L14.1762 48.9277L12.0833 47.7194Z"
        fill={style.hasOwnProperty('color') ? style.color : "#57CC99"}
      />
    </svg>
  );
}

export default DecisionTreeIcon;