function XGBoostIcon({ style }) {
  if (style == null) {
    style = [];
  }
  return (
    <svg
      class="svg-icon"
      // style="vertical-align: middle;fill: currentColor;overflow: hidden;"
      viewBox="0 0 1024 1024"
      version="1.1"
      width={style.hasOwnProperty("size") ? style.size : 24}
      height={style.hasOwnProperty("size") ? style.size : 24}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={style.hasOwnProperty("color") ? style.color : "#282828"}
        d="M624.4 780.1c-9.1 0-17.8-3.6-24.3-10.1L299.6 469.5c-13.4-13.4-13.4-35.1 0-48.5l300.6-300.6c13.4-13.4 35.1-13.4 48.5 0s13.4 35.1 0 48.5L372.4 445.3l252 252 252-252-113-113c-13.4-13.4-13.4-35.1 0-48.5s35.1-13.4 48.5 0L949.3 421c6.4 6.4 10.1 15.2 10.1 24.3 0 9.1-3.6 17.8-10.1 24.3L648.7 770.1c-6.4 6.4-15.2 10-24.3 10zM235.7 379.2c-8.8 0-17.6-3.4-24.3-10.1l-136-136C62 219.7 62 198 75.4 184.6s35.1-13.4 48.5 0l136.1 136c13.4 13.4 13.4 35.1 0 48.5-6.7 6.8-15.5 10.1-24.3 10.1zM89.6 727.1c-8.8 0-17.6-3.4-24.3-10.1-13.4-13.4-13.4-35.1 0-48.5l136.1-136.1c13.4-13.4 35.1-13.4 48.5 0s13.4 35.1 0 48.5L113.8 717c-6.7 6.7-15.4 10.1-24.2 10.1z"
      />
    </svg>
  );
}

export default XGBoostIcon;
