import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    modified: false,
};

export const cardSlice = createSlice({
    name: "card",
    initialState,
    reducers: {
        setCardModified: (state, action) => {
            state.modified = action.payload;
        },
    },
});

export const {setCardModified} = cardSlice.actions
export default cardSlice.reducer
export const selectCardModified = (state) => state.card.modified;
