import React from "react";

export function DecisionTreeIcon({ style }) {
  if (style == null) {
    style = [];
  }

  return (
    <svg
      width={style.hasOwnProperty("size") ? style.size : 24}
      height={style.hasOwnProperty("size") ? style.size : 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="14.5"
        cy="3.5"
        r="2.5"
        fill={style.hasOwnProperty("color") ? style.color : "#282828"}
      />
      <circle
        cx="19.5"
        cy="11.5"
        r="2.5"
        fill={style.hasOwnProperty("color") ? style.color : "#282828"}
      />
      <circle
        cx="14.5"
        cy="20.5"
        r="2.5"
        fill={style.hasOwnProperty("color") ? style.color : "#282828"}
      />
      <circle
        cx="9.5"
        cy="11.5"
        r="2.5"
        fill={style.hasOwnProperty("color") ? style.color : "#282828"}
      />
      <circle
        cx="4.5"
        cy="20.5"
        r="2.5"
        fill={style.hasOwnProperty("color") ? style.color : "#282828"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0001 3.00001L19.4722 10.746L18.6062 11.246L14.1341 3.50001L15.0001 3.00001Z"
        fill={style.hasOwnProperty("color") ? style.color : "#282828"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0001 12L14.4722 19.746L13.6062 20.246L9.13407 12.5L10.0001 12Z"
        fill={style.hasOwnProperty("color") ? style.color : "#282828"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99997 10.746L14.4721 3L15.3381 3.5L10.866 11.246L9.99997 10.746Z"
        fill={style.hasOwnProperty("color") ? style.color : "#282828"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.99997 19.746L9.47211 12L10.3381 12.5L5.866 20.246L4.99997 19.746Z"
        fill={style.hasOwnProperty("color") ? style.color : "#282828"}
      />
    </svg>
  );
}

export default DecisionTreeIcon;
