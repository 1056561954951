import "../../../assets/css/views/models/fpgrowth/fpgrowth.css";

import AssociationDiscoveryIcon from "../../../assets/icons/jsxIcons/AssociationDiscoveryIcon";
import FileIcon from "../../../assets/icons/jsxIcons/FileIcon";
import InputSlider from "../../../components/Slider";

import { Popup } from "../../../components/Popup";
import { MetricsPopup } from "../../popups/informations/MetricsPopup";

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setRunningTask } from "../../../store/slices/tasks/tasksSlice";
import { setNewNotification } from "../../../store/slices/notification/notificationSlice";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import LoadingWheel from "../../../components/LoadingWheel";

import modelService from "../../../services/ModelService";
import ModelResult from "./ModelResult";
import SaveStatus from "../../../components/SaveStatus";
import { setNeedUpdateProjectList } from "../../../store/slices/projects/projectsSlice";
import ModeTabs from "../../../components/ModeTabs";
import Dropdown from "../../../components/Dropdown";

import { useTranslation } from "react-i18next";
import "./../../../translation/i18n";
import handleErrorResponse from "../../../helpers/handleErrorResponse";
import CustomTooltipWrapper from "../../../components/CustomTooltip";
import { MdOutlineFileOpen } from "react-icons/md";

function FPGrowth({
   model,
   setModel,
   setIsCreated,
   isCreated,
   setGeneratedPredictionsPopup,
   dataSetExists,
   setDataSetExists,
}) {
   const [readOnly, setReadOnly] = useState(isCreated);
   const { parameters } = model;

   const { t } = useTranslation();

   const [minSupport, setMinSupport] = useState(10);
   const [minConfidence, setMinConfidence] = useState(10);
   const [metricsPopup, setMetricsPopup] = useState(false);
   const [taskIsLoading, setTaskIsLoading] = useState(false);
   const [progress, setProgress] = useState(0);
   const [taskId, setTaskId] = useState("");
   const dispatch = useDispatch();
   const history = useHistory();

   const taskList = useSelector((state) => state.tasks.taskList);

   useEffect(() => {
      if (taskId) {
         let task = taskList.filter((task) => task.task === taskId)[0];
         if (task) {
            setProgress(task.progress);
         } else if (progress <= 100) {
            setProgress(100);
            setTaskIsLoading(false);
            modelService
               .getmodel(taskId)
               .then((data) => {
                  setTaskId("");
                  setModel(data);
                  dispatch(setNeedUpdateProjectList(true));
                  dispatch(setNewNotification(true));
                  history.push(`/model/${data.id}`);
               })
               .catch((error) => {
                  handleErrorResponse(history, error, t);
               });
         }
      }
      // eslint-disable-next-line
   }, [taskList]);

   useEffect(() => {
      setIsCreated(!!model?.id);
      setReadOnly(!!model?.id);
      // eslint-disable-next-line
   }, [model]);

   function createFPGrowth() {
      if (!taskIsLoading) {
         setIsCreated(false);
         setTaskIsLoading(true);
         setProgress(0);
         setModel({
            ...model,
            id: "",
            name: "",
            saved: false,
            sharedWith: null,
            result: null,
            metrics: "",
            significantFields: null,
            hasPrediction: false,
         });
         modelService
            .fpgrowth({
               datasetid: model?.datasetId,
               name: model?.name,
               minSupport: minSupport,
               minConfidence: minConfidence,
            })
            .then((id) => {
               dispatch(setRunningTask(true));
               setTaskId(id);
            })
            .catch((error) => {
               setTaskIsLoading(false);
               handleErrorResponse(history, error, t);
            });
      }
   }

   const isShared = model?.sharedWith && model?.sharedWith.length > 0;

   return (
      <div className="model-algorithm">
         <div className="main-algorithm">
            <div className="left">
               <header className="header-algorithm">
                  <div className="name">
                     <AssociationDiscoveryIcon style={{ size: 24, color: "#282828" }} />
                     <p id="algorithm-name">FPGrowth</p>
                  </div>
                  <div className="name">
                     {isShared || !dataSetExists ? <FileIcon /> : <MdOutlineFileOpen size={24} />}
                     {!isShared && dataSetExists ? (
                        <button
                           onClick={() => {
                              history.push(`/dataset/${model?.datasetId}`);
                           }}
                        >
                           <p id="model-dataset">{model?.datasetName}</p>
                        </button>
                     ) : (
                        <p id="model-dataset">{model?.datasetName}</p>
                     )}
                  </div>
                  {isCreated && <ModeTabs readOnly={readOnly} setReadOnly={setReadOnly} owner={model?.owner} />}
               </header>
               {/* Hiperparâmetros */}
               <div className="model-hyperparameters">
                  <div className="model-slider">
                     <CustomTooltipWrapper title={t("model.fpgrowth.slider_1.info")}>
                        <p>{t("model.fpgrowth.slider_1")}</p>
                     </CustomTooltipWrapper>
                     <InputSlider
                        currentValue={readOnly ? parameters?.minSupport * 100 : minSupport}
                        disabled={readOnly}
                        setCurrentValue={setMinSupport}
                        defaultValue={10}
                        isPercentage={true}
                        min={0}
                        max={100}
                        id="minSupport"
                     />
                  </div>
                  <div className="model-slider">
                     <CustomTooltipWrapper title={t("model.fpgrowth.slider_2.info")}>
                        <p>{t("model.fpgrowth.slider_2")}</p>
                     </CustomTooltipWrapper>
                     <InputSlider
                        currentValue={readOnly ? parameters?.minConfidence * 100 : minConfidence}
                        disabled={readOnly}
                        setCurrentValue={setMinConfidence}
                        defaultValue={10}
                        isPercentage={true}
                        min={0}
                        max={100}
                        id="minConfidence"
                     />
                  </div>
                  <div className="model-dropdown">
                     <p>{t("model.backend")}</p>
                     <Dropdown
                        defaultValue={0}
                        disabled={readOnly}
                        onChange={() => {}}
                        options={[{ label: "Spark", value: "spark" }]}
                     />
                  </div>
               </div>
               {!readOnly && (
                  <div className="model-execute">
                     <button
                        onClick={createFPGrowth}
                        className={dataSetExists ? "" : "btn--disabled--solid"}
                        disabled={!dataSetExists}
                     >
                        {t("model.execute")}
                     </button>
                  </div>
               )}
            </div>
            <div className="model-result-2" id="container">
               {taskIsLoading && <LoadingWheel progress={progress} loadingSize="large" />}
               {model?.sharedWith === null && isCreated && !model?.saved && <SaveStatus />}
               {model?.result && <ModelResult result={model?.result} />}
            </div>
         </div>

         {model?.id && (
            <div className="buttons-bottom-algorithm">
               {model?.metrics && (
                  <button
                     disabled={!model}
                     onClick={() => {
                        setMetricsPopup(!metricsPopup);
                     }}
                  >
                     <p>{t("model.footer.button_1")}</p>
                  </button>
               )}
               {model?.hasPrediction && (
                  <button
                     disabled={!model?.hasPrediction}
                     onClick={() => {
                        setGeneratedPredictionsPopup(true);
                     }}
                  >
                     <p>{t("model.footer.button_3")}</p>
                  </button>
               )}
            </div>
         )}
         {model?.metrics && (
            <Popup trigger={metricsPopup} setTrigger={setMetricsPopup}>
               <MetricsPopup
                  data={{
                     metrics: model?.metrics,
                     framework: model?.framework,
                     type: model?.type,
                  }}
               />
            </Popup>
         )}
         {/* <Popup trigger={predictionPopup} setTrigger={setPredictionPopup}>
         <Prediction 
            targetName={model?.target}
            fields={model?.fields}
            modelId={model?.id}
            algorithmType={model?.type}
         />
         </Popup> */}
      </div>
   );
}

export default FPGrowth;
