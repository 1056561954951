function KmeansIcon({ style }) {
  if (style == null) {
    style = [];
  }

  return (
    <svg
      width={style.hasOwnProperty("size") ? style.size : 24}
      height={style.hasOwnProperty("size") ? style.size : 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="6.5"
        cy="16.5"
        r="2.5"
        fill={style.hasOwnProperty("color") ? style.color : "#282828"}
      />
      <circle
        cx="6.5"
        cy="7.5"
        r="3.5"
        fill={style.hasOwnProperty("color") ? style.color : "#282828"}
      />
      <circle
        cx="15.5"
        cy="16.5"
        r="4.5"
        fill={style.hasOwnProperty("color") ? style.color : "#282828"}
      />
      <circle
        cx="16.5"
        cy="6.5"
        r="3.5"
        fill={style.hasOwnProperty("color") ? style.color : "#282828"}
      />
    </svg>
  );
}

export default KmeansIcon;
