import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Input, Loader, Modal } from "semantic-ui-react";

import ws from "../../../services/wiselib";
import handleErrorResponse from "../../../helpers/handleErrorResponse";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/Button";
import { PasswordRecoverPopup } from "../../popups/actions/PasswordRecoverPopup";
import EmailConfirmationPopup from "../../popups/actions/EmailConfirmationPopup";

const LoginCard = () => {
   const [userEmail, setUserEmail] = useState("");
   const [userPassword, setUserPassword] = useState("");
   const [needCaptchaVerification, setNeedCaptchaVerification] = useState(false);
   const [showCaptcha, setShowCaptcha] = useState(0);
   const [emailConfirmation, setEmailConfirmation] = useState(false);
   const [resetPassword, setResetPassword] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [canLogin, setCanLogin] = useState(false);
   const history = useHistory();
   const captcha = useRef(null);
   const recaptchaRef = useRef();

   const { t } = useTranslation();

   const inputRef = useRef(null);

   // Verifica a quantidade de tentativas do usuario, caso seja maior do que 1, CAPTCHA é ativado
   const handleAttemptLimit = () => {
      ws.verifyattemptlimitcounter()
         .then((res) => {
            if (res.data) {
               setNeedCaptchaVerification(true);
               setCanLogin(false);
               if (captcha.current) {
                  captcha.current.reset();
               }
            } else {
               setNeedCaptchaVerification(false);
               setCanLogin(true);
            }
            setShowCaptcha(res.data);
         })
         .catch((error) => {
            handleErrorResponse(history, error, t);
            setCanLogin(false);
         });
   };

   useEffect(() => {
      handleAttemptLimit();
      inputRef.current.focus();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const handleLogin = (e) => {
      e?.preventDefault();
      if (!userEmail || !userPassword) {
         toast.error(t("popup.creations.newproject.error"));
         return;
      }
      handleAttemptLimit();

      if (needCaptchaVerification === false) {
         setIsLoading(true);
         ws.login(userEmail.trim(), userPassword.trim())
            .then((res) => {
               setIsLoading(false);
               if (res.status === 200) {
                  localStorage.setItem("user_token", res.data);
                  history.push("/myprojects");
               } else {
                  toast.error(t("home.startpage.error.userandpassword"));
               }
            })
            .catch((error) => {
               setIsLoading(false);
               if (captcha.current) {
                  captcha.current.reset();
               }
               handleErrorResponse(history, error, t);
               // Se o erro for de email não confirmado, abre o modal de confirmação
               if (error?.response) {
                  if (error.response.data === "_ALERT_3") {
                     setEmailConfirmation(true);
                  }
               }
            });
      }
   };
   return (
      <form onSubmit={handleLogin} className="auth-login">
         <label className="input-title">{t("home.startpage.card.content.title.email")}</label>
         <Input
            className="text-input"
            placeholder="exemplo@email.com"
            size="small"
            ref={inputRef}
            onChange={(e) => {
               setUserEmail(e.target.value);
            }}
         />
         <label className="input-title">{t("home.startpage.card.content.title.password")}</label>
         <Input
            className="password-input"
            placeholder="* * * * * *"
            size="small"
            type="password"
            onChange={(e) => {
               setUserPassword(e.target.value);
            }}
            ref={recaptchaRef}
         />
         {showCaptcha > 0 && (
            <ReCAPTCHA
               ref={captcha}
               sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
               onChange={(e) => {
                  setNeedCaptchaVerification(e === null);
                  setCanLogin(e !== null);
               }}
               style={{ marginLeft: "2rem" }}
            />
         )}
         <div className="login-buttons">
            <Button
               buttonStyle={needCaptchaVerification === false ? "btn--primary--solid" : "btn--disabled--solid"}
               buttonSize="btn--auto"
               type={"submit"}
               disabled={(needCaptchaVerification && showCaptcha) || isLoading || !canLogin}
            >
               {t("home.startpage.card.footer.button")} {isLoading && <Loader inline active size="tiny" />}
            </Button>

            <div id="password-recover">
               <Button
                  buttonStyle="btn--primary--transparent"
                  buttonSize="btn--small"
                  onClick={() => {
                     setResetPassword(true);
                  }}
                  type="button"
               >
                  <span id="recover-text">{t("home.startpage.card.footer.password")}</span>
               </Button>
            </div>
         </div>
         <Modal
            open={resetPassword}
            size="mini"
            closeIcon
            onClose={() => setResetPassword(false)}
            onOpen={() => setResetPassword(true)}
         >
            <PasswordRecoverPopup setTrigger={setResetPassword} />
         </Modal>
         <Modal
            open={emailConfirmation}
            size="mini"
            closeIcon
            onClose={() => setEmailConfirmation(false)}
            onOpen={() => setEmailConfirmation(true)}
         >
            <EmailConfirmationPopup setTrigger={setEmailConfirmation} />
         </Modal>
      </form>
   );
};

export default LoginCard;
