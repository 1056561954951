import i18n from "i18next";
import { initReactI18next } from "react-i18next"    
import enUSTranslation from "./locales/en-us.json";
import ptBRTranslation from "./locales/pt-br.json";
import ptPTTranslation from "./locales/pt-pt.json";
import esESTranslation from "./locales/es-es.json";
import frFRTranslation from "./locales/fr-fr.json";

i18n.use(initReactI18next)
    .init({
        lng: "pt_br", // Idioma inicial
        fallbacklng: "pt_br", // Idioma default
        resources: {
            en_us: {
                translation: enUSTranslation,
            },
            pt_pt: {
                translation: ptPTTranslation,
            },
            es_es: {
                translation: esESTranslation,
            },
            fr_fr: {
                translation: frFRTranslation,
            },
            pt_br: {
                translation: ptBRTranslation,
            }
        },
        interpolation: {
            escapeValue: false // Evitar a necessidade de escapar essa sequência em traduções
        },
    });

export default i18n;