import React from "react";

import "../../assets/css/graphicsComponents/examples/collaborativefilteringtableexample.css";

import example_data from "./collaborative-filtering-table-example.json";


let data = example_data;

const CollaborativeFilteringTableExample = () => {
  var listao = data.Recommendations.map((value, key) => {
    var userId = 0;
    //let data = {};

    userId = value.UserId;
    let data = value.recommendations.map(valor => {
      return {
        productId: valor.ProductId,
        rating: Math.round(valor.rating * 100) / 100
      }
    })
    // value.recommendations.forEach((valor) => {
    //   let object = {};
    //   object.productId = valor.ProductId;
    //   object.rating = Math.round(valor.rating * 100) / 100;
    //   data.push(object);
    // });
    return ({ userId, data });
  });


  return (
    <div className="table-content">
      {listao.map((value, index1) => {
        return (
            <table key={index1}>
              <thead>
                <tr id="user">
                  <th>Usuário:</th>
                  <th>{value.userId}</th>
                </tr>
                <tr>
                  <th>Produto</th>
                  <th>Avaliação</th>
                </tr>
              </thead>
              <tbody>
                {value.data.map((data, index2) => {
                    return (
                      <tr key={index2}>
                        <th>{data.productId}</th>
                        <th>{data.rating}</th>
                      </tr>
                    );
                })}
              </tbody>
            </table>
        );
      })}
    </div>
  );
};

export default CollaborativeFilteringTableExample;
