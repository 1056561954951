import { Link } from "react-router-dom";
import React, { useState, useEffect, useCallback } from "react";

import "../../assets/css/views/screens/project.css";

import ModelsIcon from "../../assets/icons/jsxIcons/ModelsIcon";
import DatasetAddIcon from "../../assets/icons/jsxIcons/DatasetAddIcon";

import { Card } from "../../components/Card";
import SearchBar from "../../components/SearchBar";
import { Popup } from "../../components/Popup";

import dp from "../../helpers/data_parse";

import NewDatasetPopup from "../popups/creations/NewDataset/NewDatasetPopup";
import { useSelector } from "react-redux";
import TrashIcon from "../../assets/icons/jsxIcons/TrashIcon";
import CheckboxLabeled from "../../components/CheckboxLabeled";
import { DeletePopup } from "../popups/actions/DeletePopup";
import { selectProjectList } from "../../store/slices/projects/projectsSlice";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import { useTranslation } from "react-i18next";
import "./../../translation/i18n";
import ProjectTour from "../../components/Wizard/ProjectTour";
import { selectTutorialDone } from "../../store/slices/wizard/wizardSlice";

export const Project = () => {
   const { t } = useTranslation();

   const { pageId } = useParams();
   // Encontra o projeto a partir dos projetos armazenados no cache com redux
   const projectData = useSelector(selectProjectList).find(
      (project) => project.id === pageId
   );
   /** Cards de dataset */
   const [datasetCards, setDatasetCards] = useState([]);
   /** Cards de modelo */
   const [modelCards, setModelCards] = useState([]);
   /** Pop-up de adicionar dataset */
   const [addDatasetPopup, setAddDatasetPopup] = useState(false);

   const [option, setOption] = useState("Todos");

   const [optionSort, setOptionSort] = useState("Mais recente");

   const [searchedTerm, setSearchedTerm] = useState("");

   // State com os ids dos datasets selecionados
   const [selectedDatasets, setSelectedDatasets] = useState([]);
   // Stata com os ids dos models selecionados
   const [selectedModels, setSelectedModels] = useState([]);
   //flag que define se select all está ativo dos datasets
   const [isSelectedActiveDataSet, setIsSelectedActiveDataSet] =
      useState(false);
   //flag que define se select all está ativo dos models
   const [isSelectedActiveModels, setIsSelectedActiveModels] = useState(false);
   // Trigger para abrir o pop-up de deletar
   const [deleteDatasetPopup, setDeleteDatasetPopup] = useState(false);
   // Trigger para abrir o pop-up de deletar models
   const [deleteModelsPopup, setDeleteModelsPopup] = useState(false);

   const tutorialDone = useSelector(selectTutorialDone);

   // Função que lida com a seleção de modelos para deletar, etc.
   const handleModelsSelected = useCallback(
      (e, modelId) => {
         e?.preventDefault();
         // Verifico se o modelo já está selecionado
         const isSelected = selectedModels.includes(modelId);

         if (isSelected) {
            // Se estiver selecionado, removo da lista de modelos selecionados
            setSelectedModels(selectedModels.filter((id) => id !== modelId));
         } else {
            // Se não estiver selecionado, adiciono à lista de modelos selecionados
            setSelectedModels([...selectedModels, modelId]);
         }
      },
      [selectedModels]
   );

   // // Função que lida com a seleção de datasets para deletar, etc.
   const handleDatasetsSelected = useCallback(
      (e, datasetId) => {
         e?.preventDefault();
         // Verifico se o dataset já está selecionado
         const isSelected = selectedDatasets.includes(datasetId);

         if (isSelected) {
            // Se estiver selecionado, removo da lista de datasets selecionados
            setSelectedDatasets(
               selectedDatasets.filter((id) => id !== datasetId)
            );
         } else {
            // Se não estiver selecionado, adiciono à lista de datasets selecionados
            setSelectedDatasets([...selectedDatasets, datasetId]);
         }
      },
      [selectedDatasets]
   );

   // Função que seta e lida com os cards de Datasets
   const handleSetDatasetCards = useCallback(
      (data) => {
         const { datasets } = data;
         const renderCard = (dataset) => (
            <Link
               to={!isSelectedActiveDataSet && "/dataset/" + dataset.id}
               key={dataset.id}
               onClick={(e) => {
                  if (e.ctrlKey || e.metaKey || isSelectedActiveDataSet) {
                     handleDatasetsSelected(e, dataset.id);
                     !isSelectedActiveDataSet &&
                        setIsSelectedActiveDataSet(true);
                  }
               }}
            >
               <Card
                  type="dataset"
                  name={dataset.name}
                  date={dataset.date}
                  elementId={dataset.id}
               />
            </Link>
         );

         if (datasets.length === 0) {
            return setDatasetCards(
               <span id="no-data">{t("project.info.no_data")}</span>
            );
         }

         let copyDatasets = datasets;
         if (searchedTerm && (option === "Todos" || option === "DataSets")) {
            copyDatasets = datasets.filter((value) =>
               value.name
                  .toLowerCase()
                  .trim()
                  .includes(searchedTerm.toLowerCase().trim())
            );
            if (copyDatasets.length === 0) {
               return setDatasetCards(
                  <span id="no-data">
                     {t("project.info.none_dataset") +
                        " " +
                        searchedTerm.trim() +
                        " " +
                        t("project.info.found")}
                  </span>
               );
            }
         }

         copyDatasets = dp.searchSort(optionSort, copyDatasets);
         setDatasetCards(copyDatasets.map(renderCard));
      },
      [
         handleDatasetsSelected,
         isSelectedActiveDataSet,
         option,
         optionSort,
         searchedTerm,
         t,
      ]
   );

   // Função que seta e lida com os cards de modelos
   const handleSetModelCards = useCallback(
      (data) => {
         const { models } = data;
         const renderCard = (model) => (
            <Link
               to={!isSelectedActiveModels && "/model/" + model.id}
               key={model.id}
               onClick={(e) => {
                  if (e.ctrlKey || e.metaKey || isSelectedActiveModels) {
                     handleModelsSelected(e, model.id);
                     !isSelectedActiveModels && setIsSelectedActiveModels(true);
                  }
               }}
            >
               <Card
                  type={"model"}
                  algorithm={model.algorithm}
                  name={model.name}
                  date={model.date}
                  elementId={model.id}
                  sharedWith={model.sharedWith}
               />
            </Link>
         );

         if (models.length === 0) {
            return setModelCards(
               <span id="no-data">{t("project.info.no_data")}</span>
            );
         }

         let copyModels = models;
         if (searchedTerm && (option === "Todos" || option === "Models")) {
            copyModels = models.filter((value) =>
               value.name
                  .toLowerCase()
                  .trim()
                  .includes(searchedTerm.toLowerCase().trim())
            );
            if (copyModels.length === 0) {
               return setModelCards(
                  <span id="no-data">
                     {t("project.info.none_model") +
                        " " +
                        searchedTerm.trim() +
                        " " +
                        t("project.info.found")}
                  </span>
               );
            }
         }

         copyModels = dp.searchSort(optionSort, copyModels);
         setModelCards(copyModels.map(renderCard));
      },
      [
         handleModelsSelected,
         isSelectedActiveModels,
         option,
         optionSort,
         searchedTerm,
         t,
      ]
   );

   // Função que literalmente renderiza os cards de modelos pré-preparados na função handleSetModelCards
   const renderModelsCards = () => {
      if (isSelectedActiveModels && modelCards.length > 0) {
         return modelCards.map((model) => (
            <div
               key={model.key}
               className={`project-card background ${
                  selectedModels.includes(model.key) ? "selected" : ""
               }`}
            >
               <input
                  type="checkbox"
                  className="checkbox"
                  checked={selectedModels.includes(model.key)}
                  onChange={() => handleModelsSelected(undefined, model.key)}
               />
               {model}
            </div>
         ));
      } else {
         return modelCards;
      }
   };

   // Função que literalmente renderiza os cards de datasets pré-preparados na função handleSetDatasetCards
   const renderDatasetsCard = () => {
      if (isSelectedActiveDataSet && datasetCards.length > 0) {
         return datasetCards.map((dataset) => (
            <div
               key={dataset.key}
               className={`project-card background ${
                  selectedDatasets.includes(dataset.key) ? "selected" : ""
               }`}
            >
               <input
                  type="checkbox"
                  className="checkbox"
                  checked={selectedDatasets.includes(dataset.key)}
                  onChange={() =>
                     handleDatasetsSelected(undefined, dataset.key)
                  }
               />
               {dataset}
            </div>
         ));
      } else {
         return datasetCards;
      }
   };

   useEffect(() => {
      if (projectData !== undefined) {
         handleSetDatasetCards(projectData);
         handleSetModelCards(projectData);
      }
   }, [handleSetDatasetCards, handleSetModelCards, projectData]);

   return (
      <>
         <div className="container-project">
            <div className="header">
               <div className="title">
                  <p>
                     {projectData?.shared
                        ? t("project.title.name")
                        : projectData?.name}
                  </p>
               </div>
               <SearchBar
                  enableOptions={true}
                  enableSort={true}
                  onChange={(e) => setSearchedTerm(e.target.value)}
                  onChangeType={setOption}
                  onChangeOption={setOptionSort}
                  isShared={projectData?.shared}
               />
            </div>
            <main className="container-cards">
               <section className="container-section">
                  {!projectData?.shared && (
                     <>
                        <div className="section-title">
                           <div className="new-dataset">
                              {t("project.title.datasets")}
                              <button
                                 id="icon"
                                 onClick={() => {
                                    setAddDatasetPopup(true);
                                 }}
                              >
                                 <DatasetAddIcon />
                              </button>
                           </div>
                           <div className="checkbox-labeled">
                              {isSelectedActiveDataSet &&
                                 selectedDatasets.length > 0 && (
                                    <button
                                       className="trash-icon"
                                       onClick={() =>
                                          setDeleteDatasetPopup(true)
                                       }
                                    >
                                       <TrashIcon />
                                    </button>
                                 )}
                              {datasetCards.length > 0 && (
                                 <CheckboxLabeled
                                    isSelectedActive={isSelectedActiveDataSet}
                                    setIsSelectedActive={
                                       setIsSelectedActiveDataSet
                                    }
                                    setSelectedIds={setSelectedDatasets}
                                    cards={datasetCards}
                                 />
                              )}
                           </div>
                        </div>

                        <div className="dataSetsContent">
                           {renderDatasetsCard()}
                        </div>
                     </>
                  )}
               </section>
               <section className="container-section">
                  <div className="section-title">
                     <div className="models-section">
                        {t("project.title.models")}
                        <span id="icon">
                           <ModelsIcon />
                        </span>
                     </div>
                     <div className="checkbox-labeled">
                        {isSelectedActiveModels &&
                           selectedModels.length > 0 && (
                              <button
                                 className="trash-icon"
                                 onClick={() => setDeleteModelsPopup(true)}
                              >
                                 <TrashIcon />
                              </button>
                           )}
                        {modelCards.length > 0 && (
                           <CheckboxLabeled
                              isSelectedActive={isSelectedActiveModels}
                              setIsSelectedActive={setIsSelectedActiveModels}
                              setSelectedIds={setSelectedModels}
                              cards={modelCards}
                           />
                        )}
                     </div>
                  </div>

                  <div className="modelsContent">{renderModelsCards()}</div>
               </section>
            </main>
         </div>
         <Popup trigger={addDatasetPopup} setTrigger={setAddDatasetPopup}>
            <NewDatasetPopup
               setPopupTrigger={setAddDatasetPopup}
               isProjectPage={pageId}
            />
         </Popup>
         <Popup trigger={deleteDatasetPopup} setTrigger={setDeleteDatasetPopup}>
            <DeletePopup
               cardtype={"dataset"}
               modelResultId={selectedDatasets}
               setSelectedArray={setSelectedDatasets}
               setTrigger={setDeleteDatasetPopup}
               setIsSelectedActive={setIsSelectedActiveDataSet}
            />
         </Popup>
         <Popup trigger={deleteModelsPopup} setTrigger={setDeleteModelsPopup}>
            <DeletePopup
               cardtype={"model"}
               modelResultId={selectedModels}
               setSelectedArray={setSelectedModels}
               setIsSelectedActive={setIsSelectedActiveModels}
               setTrigger={setDeleteModelsPopup}
            />
         </Popup>
         {!tutorialDone && <ProjectTour datasets={datasetCards} />}
      </>
   );
};
