import { Button } from "./../../../components/Button";
import "./../../../assets/css/views/popups/actions/deletegenericpopup.css"

import { useTranslation } from "react-i18next"
import "./../../../translation/i18n";

function DeleteGenericPopup({
    onClickDelete, 
    setTrigger
}){

    const {t} = useTranslation();

    return (
        <div className="containerDeleteGenericPopup">
            <p>{t("popup.action.deletegeneric.title")}</p>
            <div className="containerButton">
                <span>
                    <Button
                        buttonStyle="btn--primary--solid"
                        buttonSize="btn--auto"
                        type="button"
                        disable={true}
                        onClick={() => {
                            onClickDelete();
                            setTrigger(false);   
                        }}
                    >
                        {t("popup.action.deletegeneric.yes")}
                    </Button>
                </span>
                <span>
                    <Button
                        buttonStyle="btn--primary--solid"
                        buttonSize="btn--auto"
                        type="button"
                        disable={true}
                        onClick={() => {
                            setTrigger(false);
                        }}
                    >
                        {t("popup.action.deletegeneric.no")}
                    </Button>
                </span>
            </div>
        </div>
    )
}

export default DeleteGenericPopup;