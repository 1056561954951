import styles from "./../assets/css/monitoring/Monitoring.module.css";

import { Route, Switch, useHistory } from "react-router-dom";
import DashBoard from "./views/screens/DashBoard";
import SystemUsers from "./views/screens/SystemUsers"
import AttempLimit from "./views/screens/AttempLimit";
import MongoDB from "./views/screens/MongoDB";
import Java from "./views/screens/Java";
import Server from "./views/screens/Server";

import { useEffect } from "react";
import { useSelector } from "react-redux";
import { t } from "i18next";
import { toast } from "react-toastify";
import SelectLanguage from "../components/SelectLanguage";

function Monitoring() {
  const isAdmin = useSelector((state) => state.user.isAdmin);
  const history = useHistory();

  useEffect(() => {
    if (!isAdmin) {
      toast.warn(t("monitoring.unauthorizedacess"));
      history.push("/myprojects");
    }
    // eslint-disable-next-line
  }, []);

  const link = "monitoring.header";

  if (isAdmin) {
    return (
      <div className={styles.monitoring}>
        <header>
          <div className={styles.headertitle}>
            <div className={styles.title}>
              <img
                src={require("./../assets/images/logo-sidebar.svg").default}
                alt="logo"
              />
              <p>{t(`${link}.title`)}</p>
            </div>
            <div className={styles.buttons}>
              <button onClick={() => { history.push("/myprojects") }}><p>{t(`${link}.buttons.back`)}</p></button>
              <SelectLanguage 
                horizontal="left" 
                vertical="bottom"
                horizontalFill={true}
              />
            </div>
          </div>
          <nav></nav>
          <nav>
            <ul>
              <li>
                <button onClick={() => history.push("/monitoring")}>
                  <p>{t(`${link}.buttons.dashboard`)}</p>
                </button>
              </li>
              <li>
                <button onClick={() => history.push("/monitoring/systemusers")}>
                  <p>{t(`${link}.buttons.systemusers`)}</p>
                </button>
              </li>
              <li>
                <button onClick={() => history.push("/monitoring/attemplimit")}>
                  <p>{t(`${link}.buttons.attemplimits`)}</p>
                </button>
              </li>
            </ul>
          </nav>
        </header>
        <Switch>
          <Route path="/monitoring" exact component={() => <DashBoard />} />
          <Route path="/monitoring/mongodb" component={() => <MongoDB />} />
          <Route path="/monitoring/java" component={() => <Java />} />
          <Route path="/monitoring/server" component={() => <Server />} />
          <Route path="/monitoring/systemusers" component={() => <SystemUsers />} />
          <Route path="/monitoring/attemplimit" component={() => <AttempLimit />} />
        </Switch>
      </div>
    )
  } else {
    return "";
  }
}

export default Monitoring;
