import React, { useEffect, useRef, useState } from "react";
import { Input, Loader } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import ws from "../../../services/wiselib";

import "../../../assets/css/views/popups/actions/emailconfirmation.css";
import { Button } from "../../../components/Button";
import { useDispatch } from "react-redux";
import { setNeedUpdateProjectList } from "../../../store/slices/projects/projectsSlice";

import { useTranslation } from "react-i18next";
import "./../../../translation/i18n";
import handleErrorResponse from "../../../helpers/handleErrorResponse";
import { toast } from "react-toastify";
import { setNeedRefetchUser } from "../../../store/slices/user/userSlice";

export const EmailConfirmationPopup = ({ setLoginTab, setTrigger }) => {
   const { t } = useTranslation();
   const [pin, setPin] = useState("");
   const [isLoading, setIsLoading] = useState(false);
   const [errorMsg, setErrorMsg] = useState("");
   const dispatch = useDispatch();

   const inputRef = useRef(null);
   const history = useHistory();

   const validPin = pin.length === 12;

   useEffect(() => {
      inputRef.current.focus();
   }, []);

   const handleVerify = (e) => {
      e?.preventDefault();
      if (validPin) {
         setIsLoading(true);
         ws.emailvalidation("", pin.trim())
            .then((res) => {
               setIsLoading(false);
               toast.success(t(`backend.SUCCESS.${res.data}`));
               dispatch(setNeedUpdateProjectList(true));
               setLoginTab?.(true);
               setTrigger(false);
               dispatch(setNeedRefetchUser(true));
            })
            .catch((error) => {
               setIsLoading(false);
               handleErrorResponse(history, error, t);
            });
      } else {
         setErrorMsg(t("popup.action.emailconfirm.error"));
      }
   };
   const handlePin = (e) => {
      errorMsg && setErrorMsg("");
      setPin(e.target.value);
   };

   return (
      <form className="email-confirmation" onSubmit={handleVerify}>
         {isLoading && <Loader active />}
         <div style={{ color: "black" }}>
            {t("popup.action.emailconfirm.title")}
         </div>
         <Input
            onChange={handlePin}
            ref={inputRef}
            placeholder={"PIN"}
            error={errorMsg}
         />
         {errorMsg && (
            <div>
               <small className="error">{errorMsg}</small>
            </div>
         )}
         <div className="buttons-pin">
            <Button
               type={"button"}
               buttonStyle="btn--primary--outline"
               buttonSize="btn--auto"
               onClick={handleVerify}
            >
               {t("popup.action.emailconfirm.button.verify")}
            </Button>

            <Button
               type="button"
               buttonStyle="btn--primary--solid"
               buttonSize="btn--auto"
               onClick={() => {
                  setTrigger(false);
                  setIsLoading(false);
               }}
            >
               {t("popup.action.emailconfirm.button.cancel")}
            </Button>
         </div>
      </form>
   );
};

export default EmailConfirmationPopup;
