import React, { useEffect, useState } from "react";
import styles from "../../assets/css/components/Dataset/DatasetData.module.css";
import { useTranslation } from "react-i18next";
import ModelCards from "./Models/ModelCards";
import ModelScores from "./Models/ModelScores";
import ModelComparisons from "./Models/ModelComparisons";
import ModelRocs from "./Models/ModelROCs";
import modelService from "../../services/ModelService";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
const TAB_NAMES = ["button_1", "button_2", "button_4"];

const TabButton = ({ tabName, isActive, onClick }) => {
   const { t } = useTranslation();

   return (
      <button
         className={isActive ? styles.activeButton : ""}
         onClick={() => onClick(tabName)}
      >
         {t(`dataset.models.nav.${tabName}`)}{" "}
      </button>
   );
};

const DatasetModels = () => {
   const [activeTab, setActiveTab] = useState("button_1");
   const datasetId = useLocation().pathname.split("/")[2];
   const toggleTab = (tab) => {
      setActiveTab(tab);
   };

   const [metrics, setMetrics] = useState(null);

   useEffect(() => {
      modelService.getAllModelsMetricsFromDataset(datasetId).then((data) => {
         if (data?.metrics) {
            setMetrics(JSON.parse(data.metrics));
         }
      });
   }, [datasetId]);

   const renderPageSelected = () => {
      switch (activeTab) {
         case "button_1":
            return <ModelCards />;
         case "button_2":
            return <ModelScores metrics={metrics} />;
         case "button_3":
            return <ModelComparisons />;
         case "button_4":
            return <ModelRocs metrics={metrics} />;

         default:
            break;
      }
   };

   return (
      <>
         <nav className={styles.navButtons}>
            {TAB_NAMES.map((tabName) => (
               <TabButton
                  key={tabName}
                  tabName={tabName}
                  isActive={activeTab === tabName}
                  onClick={toggleTab}
               />
            ))}
         </nav>
         {renderPageSelected()}
      </>
   );
};

export default DatasetModels;
