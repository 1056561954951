import http from "../http-common";

//Endpoint para coletar código em python de predição individual para a API
function getpythonpredictioncode(token){
    return http.post("/api/getpythonpredictioncode", {token: token})
    .then(response => {return response.data})

}

//Endpoint para coletar código em python de predição em lote para a API
function getpythonbatchpredictioncode(token){
    return http.post("/api/getpythonbatchpredictioncode", {token: token})
    .then(response => {return response.data})

}

//Endpoint para coletar código em java de presição em lote para a API
function getjavabatchpredictioncode(token){
    return http.post("/api/getjavabatchpredictioncode", {token: token})
    .then(response => {return response.data})

}

//Endpoint para coletar código em java de presição em individual para a API
function getjavapredictioncode(token){
    return http.post("/api/getjavapredictioncode", {token: token})
    .then(response => {return response.data})

}

//Enpoint para criar chave api
function create(
    token, 
    modelid, 
    alias, 
    datelimit, 
    authorizedipaddresses, 
    apirestricted
){
    return http.post("/key/create", {
        token,
        modelid,
        alias,
        datelimit,
        authorizedipaddresses,
        apirestricted,
    })
    .then(response => {return response.data})

}

function getbymodel(
    token, 
    id,
) {
    return http.post("/key/getbymodel", {
        token,
        id,
    })
    .then(response => {return response.data})


}

function deletekeyapi(
    token, 
    id,
){
    return http.post("/key/revoke", {
        token, 
        id,
    })
    .then(response => {return response.data})

}

function updatenamekey(
    token,
    id,
    option,
){
    return http.post("/key/updatealias", {
        token,
        id,
        option,
    })
    .then(response => {return response.data})

}

function updatealias(
    token,
    id,
    option,
) {
    return http.post("/key/updatealias", {
        token, 
        id,
        option,
    })
    .then(response => {return response.data})

}

function updatedatelimit(
    token,
    id,
    option,
) {
    return http.post("/key/updatedatelimit", {
        token,
        id,
        option,
    })
    .then(response => {return response.data})

}

function updaterestricted(
    token,
    id,
    option,
){
    return http.post("/key/updaterestricted", {
        token,
        id, 
        option,
    })
    .then(response => {return response.data})

}

function updateauthorizedipaddresses(
    token, 
    id,
    options,
){
    return http.post("/key/updateauthorizedipaddresses", {
        token,
        id,
        options,
    })
    .then(response => {return response.data})

}

const externalApi = {
    getpythonpredictioncode,
    getpythonbatchpredictioncode,
    getjavabatchpredictioncode,
    getjavapredictioncode,
    create,
    getbymodel,
    deletekeyapi,
    updatenamekey,
    updatealias,
    updatedatelimit,
    updaterestricted,
    updateauthorizedipaddresses,
}

export default externalApi;