import "../../../assets/css/views/popups/informations/metricspopup.css";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next"
import "./../../../translation/i18n";
import ConfusionMatrixD3 from "../../../graphicsComponents/modelsRepresentations/ConfusionMatrixD3";
import { RocCurveChartD3 } from "../../../graphicsComponents/others/RocCurveChartD3";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

import CheckIcon from "../../../assets/icons/jsxIcons/CheckIcon";

export const MetricsPopup = ({ data }) => {

  const [isResult, setIsResult] = useState(true);
  const [comparationIndex, setComparationIndex] = useState(0);
  const [rocCurveIndex, setRocCurveIndex] = useState(0);
  const [categorie, setCategorie] = useState(0);

  const {t} = useTranslation();
  const link = "popup.informations.metrics"
  const metrics = JSON.parse(data.metrics);
  const options = metrics.scores !== null ? Object.keys(metrics?.scores) : [];
  const [scores, setScores] = useState(options !== null ? metrics.scores[options[0]] : null);
  const [typeScore, setTypeScore] = useState(options !== null ? options[0] : null);

  const getListScore = () => {
    let objs = scores.data;
    if(scores){
      return objs.map(obj => {
        const aux = t(`${link}.metrics.${obj.name}`);
        obj["name"] = aux.includes(`metrics.${obj.name}`) ? obj.name : aux;
        return obj;
      });
    } else {
      return [];
    }
  }

  const scoreList = getListScore();

  useEffect(() => {
    const elementsLi = document.querySelectorAll(".containerNav li");
    if(isResult){
      elementsLi[0].style.backgroundColor = "#38A3A51F";
      elementsLi[1].style.backgroundColor = "transparent";
    } else {
      elementsLi[0].style.backgroundColor = "transparent";
      elementsLi[1].style.backgroundColor = "#38A3A51F";
      document.querySelectorAll("#comparations ul li button")[comparationIndex].style.borderBottom = "2px solid #38A3A5"
    }
  }, [isResult]);

  useEffect(() => {
    if(!isResult){
      const elementsLi = document.querySelectorAll("#comparations ul li button");
      elementsLi.forEach(element => {element.style.border = "none"})
      elementsLi[comparationIndex].style.borderBottom = "2px solid #38A3A5"
    }
  }, [comparationIndex]);

  const resultsComponent = () => {
    return (
      <>
        <section className="resultScores">
          <div className="scores">
            {(scoreList !== null) && scoreList.map((object, index) => {
              return (
                <div key={index} className="score">
                  <p>{object.name}</p>
                  <span>{object.value}%</span>
                </div>
              )
            })}
          </div>
          <div className="containerDropdown">
            <p>{t(`${link}.textdropdown`)}</p>
            <select onChange={(e) => {
              setScores(metrics.scores[e.target.value]);
            }}>{options.map((option, index) => {
              return (
                <option key={index} value={option}>{option === "weighted" ? t(`${link}.metrics.weighted`) : option}</option>
              )
            })}</select>
          </div>
        </section>
        <section className="confusionMatrix">
            <header><p>{t(`${link}.matrixname`)}</p></header>
            {scores !== null && <ConfusionMatrixD3 matrix={scores.matrix}/>}
        </section>
      </>
    )
  }

  const StyledTableCell = withStyles(() => ({
    head: {
      backgroundColor: "#F4F4F4",
      color: "#000000",
      height: 44
    },
    body: {
      fontFamily: "Montserrat",
      fontSize: 12,
      fontWeight: 500,
      letterSpacing: 0.2,
      borderBottom: "1px solid #E0E0E0"
    },
  }))(TableCell);

  const StyledTableRow = withStyles(() => ({
    root: {
      backgroundColor: "#FFFFFF",
      color: "#000000",
      height: 64
    },
    body: {
      fontFamily: "Montserrat",
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: 0.2,
      borderBottom: "1px solid #E0E0E0"
    }
  }))(TableRow);
  

  const scoreComponent = () => {
    let models = metrics.models.scoreList.map(model => {return model[typeScore]});
    let header = [];
    let table = [];
    if(typeScore === "weighted"){
      header = [t(`${link}.metrics.model`), "F-Score", t(`${link}.metrics.acurácia`), "F1", t(`${link}.metrics.precisão`), "Recall", "Hammingloss"];
      table = models.map((model) => {return (
        <StyledTableRow key={model["name"]}>
          <StyledTableCell align="center">{model["name"] ? model["name"] : "-"}</StyledTableCell>
          <StyledTableCell align="center">{model["f-score"] ? model["f-score"] : "0"}%</StyledTableCell>
          <StyledTableCell align="center">{model["acurácia"] ? model["acurácia"] : "0"}%</StyledTableCell>
          <StyledTableCell align="center">{model["f1"] ? model["f1"] : "0"}%</StyledTableCell>
          <StyledTableCell align="center">{model["precisão"] ? model["precisão"] : "0"}%</StyledTableCell>
          <StyledTableCell align="center">{model["recall"] ? model["recall"] : "0"}%</StyledTableCell>
          <StyledTableCell align="center">{model["hammingLoss"] ? model["hammingLoss"] : "0"}%</StyledTableCell>
        </StyledTableRow>
      )});
    } else {
      header = [t(`${link}.metrics.model`), t(`${link}.metrics.precisão`), "Recall", "F1"];
      table = models.map((model) => {return (
        <StyledTableRow key={model["name"]}>
          <StyledTableCell align="center">{model["name"] ? model["name"] : "-"}</StyledTableCell>
          <StyledTableCell align="center">{model["precisão"] ? model["precisão"] : "0"}%</StyledTableCell>
          <StyledTableCell align="center">{model["recall"] ? model["recall"] : "0"}%</StyledTableCell>
          <StyledTableCell align="center">{model["f1"] ? model["f1"] : "0"}%</StyledTableCell>
        </StyledTableRow>
      )});
    }

    return (
      <TableContainer component={Paper} style={{width: "100%", height: "80%", border: "1px solid #BDBDBD"}}>
        <Table aria-label="customized table">
          <TableHead style={{position: "sticky", top: 0}}>
            <TableRow>
              {header.map((name) => {
                return <StyledTableCell align="center">{name}</StyledTableCell>
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {table}
          </TableBody>
      </Table>
    </TableContainer>
    )
  }

  const comparisonComponent = () => {
    return (
      <>
        <section className="containerComparation">
            <nav id="comparations">
              <ul>
                <li><button onClick={() => {setComparationIndex(0)}}>Score</button></li>
                {/* <li><button onClick={() => {setComparationIndex(1)}}>Comparações</button></li> */}
                <li><button onClick={() => {setComparationIndex(1)}}>{t(`${link}.curvename`)}</button></li>
              </ul>
            </nav>
            {!comparationIndex ? (
              <div className="containerTable">
                <header>
                  <select onChange={(e) => {setTypeScore(e.target.value)}}>
                    {options.map((label, index) => {
                      return (
                        <option key={index} value={label}>{label === "weighted" ? t(`${link}.metrics.weighted`) : label}</option>
                      )
                    })}
                  </select>
              </header>
              {typeScore !== null && scoreComponent()}
            </div>
            ) : (
              <div className="containerRocCurve">
                <div className="containerInfoRocCurve">
                  <div className="containerSelect">  }
}
                    <span><p>{t(`${link}.category`)}</p>:</span>
                    <select onChange={(e) => {setCategorie(e.target.value)}}>
                      {metrics.rocCurve.categories.map((classe, index) => {
                        return (
                          <option key={index} value={index}>{classe}</option>
                        )
                      })}
                    </select>
                  </div>
                  <div className="containerModels">
                    <header>
                      <p>{t(`${link}.metrics.model`)}</p>
                    </header>
                    {metrics.models.scoreList.map((model, index) => {
                      return (
                        <div 
                          key={index} 
                          className="containerModel" 
                          style={metrics.models.length -1 === index ? {border: "none"} : {}}
                        >
                          <div className="name-model">
                            <div id="circle" style={{backgroundColor: `#282828`}}></div>
                            <div id="text"><p>{model["weighted"].name}</p></div>
                          </div>
                          <button 
                            id="checkbox" 
                            style={rocCurveIndex === index ? {backgroundColor: "#38A3A5"} : {backgroundColor: "transparent"}} 
                            className={rocCurveIndex === index ? "selected" : "noSelected"} onClick={() => {setRocCurveIndex(index)}}>
                            {rocCurveIndex === index && (
                              <CheckIcon style={{color: "white"}}/>
                            )}
                          </button>
                        </div>
                    )})}
                  </div>
                </div>
                <div className="containerGraphic">
                  <RocCurveChartD3
                    datas={metrics.models.rocCurveList[rocCurveIndex].curves}
                    selectedCategory={categorie}
                  />
                </div>
              </div>
            )}
        </section>
      </>
    )
  }

  return (
    <div className="containerMetrics">
      <header className="containerHeader">
        <p>{t(`${link}.header.title`)}</p>
        <nav className="containerNav">
          <ul>
            <li id="result" onClick={() => {setIsResult(!isResult)}}><button>{t(`${link}.header.nav.result`)}</button></li>
            <li id="comparison" onClick={() => {setIsResult(false)}}><button>{t(`${link}.header.nav.comparison`)}</button></li>
          </ul>
      </nav>
      </header>
      <main id="main">
        {isResult ? resultsComponent() : comparisonComponent()}
      </main>
    </div>
  )
}
