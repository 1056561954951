import styles from "./../../../assets/css/monitoring/views/popups/InfoUser.module.css";

import { useState, useEffect } from "react";
import { t } from "i18next";
import UserInfoTable from "../../tables/UserInfoTable";

import LoadingWheel from "../../../components/LoadingWheel";
import monitoringService from "../../../services/MonitoringService";
import handleErrorResponse from "../../../helpers/handleErrorResponse";
import getSelectArray from "../../helpers/getSelectArray";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { toast } from "react-toastify";
import { Popup } from "../../../components/Popup";
import ConfirmationPopup from "../../../views/popups/actions/ConfirmationPopup";

function InfoUserPopUp({
    userId,
    update,
    setTrigger
}) {

    const link = "monitoring.systemuser.popup";
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [newUser, setNewUser] = useState({});
    const [isAdmin, setIsAdmin] = useState(false);
    const [editUser, setEditUser] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [type, setType] = useState("Log");
    const [result, setResult] = useState({});
    const [confirmationTrigger, setConfirmationTrigger] = useState(false);
    const [newProject, setNewProject] = useState({isSharedModelsProject: false})

    useEffect(() => {
        updateInfoUser();
        // eslint-disable-next-line
    }, []);

    function updateInfoUser() {
        monitoringService.getalluserinfo(userId)
            .then(data => {
                data.User = data.User[0];
                data.Log = data.Log.reverse().map(log => {
                    return {
                        ...log,
                        date: new Date(log.date * 1000).toLocaleString('pt-BR'),
                        finishTime: new Date(log.finishTime * 1000).toLocaleString('pt-BR')
                    }
                })

                data.Project = data.Project.reverse().map(project => {
                    return {
                        ...project,
                        date: new Date(project.date * 1000).toLocaleString('pt-BR'),
                        isSharedModelsProject: t(`${link}.table.answer.${project.isSharedModelsProject ? "yes" : "no"}`)
                    }
                })

                data.Dataset = data.Dataset.reverse().map(dataset => {
                    return {
                        ...dataset,
                        date: new Date(dataset.date * 1000).toLocaleString('pt-BR'),
                        isSource: t(`${link}.table.answer.${dataset.isSource ? "yes" : "no"}`)
                    }
                })

                data.Model = data.Model.reverse().map(model => {
                    return {
                        ...model,
                        date: new Date(model.date * 1000).toLocaleString('pt-BR'),
                    }
                })

                data.Prediction = data.Prediction.reverse().map(prediction => {
                    return {
                        ...prediction,
                        date: new Date(prediction.date * 1000).toLocaleString('pt-BR'),
                        isBatch: t(`${link}.table.answer.${prediction.isBatch ? "batch" : "single"}`)
                    }
                })
                setIsAdmin(data.User.isAdmin);
                setResult(data);
            })
            .catch(error => handleErrorResponse(history, error, t))
            .finally(() => {
                setEditUser(false);
                setNewUser({});
                setNewProject({isSharedModelsProject: false});
                setIsLoading(false)
            });
    }

    const getSelect = (field, value, selectArray) => {
        return (
            <select
                onChange={(e) => setNewUser({ ...newUser, [field]: e.target.value })}
                className={newUser[field] ? styles.modifiedfield : ""}
            >
                {selectArray.options.map((option, index) => {
                    return (
                        <option
                            key={selectArray.values[index]}
                            value={selectArray.values[index]}
                            selected={selectArray.values[index] === value}
                        >
                            {option}
                        </option>
                    )
                })}
            </select>
        )
    }

    const getInput = (field, value) => {
        return (
            <input
                onChange={(e) => { setNewUser({ ...newUser, [field]: e.target.value }) }
                }
                defaultValue={newUser[field] ? newUser[field] : value}
                className={newUser[field] ? styles.modifiedfield : ""}
                type={field === "birthDate" ? "date" : "text"}
            />
        )
    }

    const getSwitchButton = (isShared = false) => {
        if (isShared) {
            return (
                <button
                    className={`${newProject.isSharedModelsProject ? styles.active : styles.inactive} ${styles.buttonswitchshared}`}
                    onClick={() => setNewProject({...newProject, isSharedModelsProject: !newProject.isSharedModelsProject})}
                    disabled={type !== "Project"}
                >
                    <div className={styles.buttonswitchball}></div>
                </button>
            )
        }
        return (
            <button
                className={`${isAdmin ? styles.active : styles.inactive} ${styles.buttonswitch} ${!editUser ? styles.disabled : ""}`}
                onClick={() => setIsAdmin(!isAdmin)}
                disabled={!editUser}
            >
                <div className={styles.buttonswitchball}></div>
            </button>
        )
    }

    const getPassword = () => {
        if (editUser) {
            return (
                <input
                    onChange={(e) => { setNewUser({ ...newUser, password: e.target.value }) }}
                    //defaultValue={password ? password : value}
                    className={newUser.password ? styles.modifiedfield : ""}
                    type="password"
                    autocomplete="new-password"
                />
            )
        }
        return <p>********</p>
    }

    const getInfoUser = (field, value) => {
        if (editUser) {
            const selectArray = getSelectArray(field, t);
            if (selectArray) {
                return getSelect(field, value, selectArray);
            } else {
                return getInput(field, value);
            }
        } else {
            return (
                <p>{value}</p>
            )
        }
    }

    const renderPicture = () => {
        if (result?.User?.picture) {
            return <img src={`${result.User.picture}`} alt="img" />
        }
        return <div className={styles.picture}><AccountCircleIcon /></div>
    }

    const deleteUserInfo = () => {
        setIsLoading(true);
        monitoringService.deleteanythingbyid(selectedRows, type)
            .then(data => {
                toast.success(t(`backend.SUCCESS.${data}`));
                updateInfoUser();
            }).catch(error => handleErrorResponse(history, error, t))
            .finally(() => {
                setEditUser(false);
                setNewUser({});
                setNewProject({isSharedModelsProject: false});
                setIsLoading(false);
            });
    }

    const modifyuser = () => {
        setIsLoading(true);
        let user = {
            ...newUser,
            id: result.User.id,
            isAdmin: isAdmin
        };
        if (user.hasOwnProperty("password")) {
            if (user.password === "")
                delete user.password;
        }
        monitoringService.modifyuser(user)
            .then(data => {
                toast.success(t(`backend.SUCCESS.${data}`));
                update();
            })
            .catch(error => handleErrorResponse(history, error, t))
            .finally(() => {
                setNewUser({});
                setNewProject({isSharedModelsProject: false});
                setEditUser(false);
                setIsLoading(false);
            });
    }

    const userdelete = () => {
        setConfirmationTrigger(false);
        setIsLoading(true);
        monitoringService.deleteanythingbyid([userId], "User")
            .then(data => {
                setTrigger(false);
                if (data.includes("_SUCCESS_")) {
                    toast.success(t(`backend.SUCCESS.${data}`));
                }
                update();
            })
            .catch(error => handleErrorResponse(history, error, t))
            .finally(() => {
                setNewUser({});
                setNewProject({isSharedModelsProject: false});
                setEditUser(false);
                setIsLoading(false);
            })
    }

    const createProject = () => {
        setIsLoading(true);
        monitoringService.createproject({...newProject, userId: userId})
        .then(data => {
            updateInfoUser();
        })
        .catch(error => {
            handleErrorResponse(history, error, t)
            setIsLoading(false);
        })
        .finally(() => {
            setNewUser({});
            setEditUser(false);
            setNewProject({isSharedModelsProject: false});
        })
    }

    const addProject = () => {
        const disabled = !(newProject.hasOwnProperty("name") && newProject.hasOwnProperty("description"));
        return (
            <>
                <div className={styles.projectfield}>
                    <label>Nome:</label>
                    <input 
                        type="text" 
                        onChange={(e) => {setNewProject({...newProject, name: e.target.value})}} 
                    />
                </div>
                <div className={styles.projectfield}>
                    <label>Compartilhado:</label>
                    {getSwitchButton(true)}
                </div>
                <div className={styles.projectfield}>
                    <label>Descrição:</label>
                    <input 
                        type="text" 
                        onChange={(e) => {setNewProject({...newProject, description: e.target.value})}}
                    />
                </div>
                <button 
                    className={
                        `${disabled ? 
                            styles.buttondisabled : ""} ${styles.buttonadd}`
                    } 
                    disabled={disabled}
                    onClick={createProject}
                >
                    <p>ADICIONAR</p>
                </button>
            </>
        )
    }

    function converterStorage(mb) {
        let bytes = mb * 1024 * 1024;  // Convertendo MB para bytes

        // Convertendo para KB, MB, GB e TB
        let kb = bytes / 1024;
        let gb = bytes / (1024 ** 3);

        if (gb < 1024) {
            return `${kb.toFixed(2)} KB`;
        }
        return `${gb.toFixed(2)} GB`;

    }

    if (isLoading) {
        return (
            <main className={styles.isloading}>
                <LoadingWheel loadingSize="large" />
            </main>
        )
    } else {
        return (
            <main className={styles.containerinfouserpopup}>
                <section className={styles.containerinfouser}>
                    <div className={styles.infouser}>
                        <div className={styles.username}>
                            {renderPicture()}
                            <div>
                                {getInfoUser("firstName", result.User.firstName)}
                                {getInfoUser("surname", result.User.surname)}
                            </div>
                        </div>
                        <div className={styles.useremail}>{getInfoUser("email", result.User.email)}</div>
                        <ul className={styles.info}>
                            <li className={styles.infotitle}><p>{t(`${link}.info.person`)}</p></li>
                            <li>
                                <ul>
                                    <li>
                                        <p>{t(`${link}.info.birthdate`)}:</p>
                                        {getInfoUser("birthDate", result.User.birthDate)}
                                    </li>
                                    <li>
                                        <p>{t(`${link}.info.mongodiskusage`)}:</p>
                                        <p>{converterStorage(result.User.mongoDiskUsage / 1024 / 1024)}</p>
                                    </li>
                                    <li>
                                        <p>{t(`${link}.info.isadmin`)}:</p>
                                        {getSwitchButton()}
                                    </li>
                                    <li>
                                        <p>{t(`${link}.info.newpassword`)}:</p>
                                        {getPassword()}
                                    </li>
                                </ul>
                            </li>
                            <li className={styles.infotitle}><p>{t(`${link}.info.location`)}</p></li>
                            <li>
                                <ul>
                                    <li>
                                        <p>{t(`${link}.info.city`)}:</p>
                                        {getInfoUser("city", result.User.city)}
                                    </li>
                                    <li>
                                        <p>{t(`${link}.info.state`)}:</p>
                                        {getInfoUser("state", result.User.state)}
                                    </li>
                                    <li>
                                        <p>{t(`${link}.info.country`)}:</p>
                                        {getInfoUser("country", result.User.country)}
                                    </li>
                                </ul>
                            </li>
                            <li className={styles.infotitle}><p>{t(`${link}.info.company`)}</p></li>
                            <li>
                                <ul>
                                    <li>
                                        <p>{t(`${link}.info.companyname`)}:</p>
                                        {getInfoUser("companyName", result.User.companyName)}
                                    </li>
                                    <li>
                                        <p>{t(`${link}.info.market`)}:</p>
                                        {getInfoUser("companyMarketField", result.User.companyMarketField)}
                                    </li>
                                    <li>
                                        <p>{t(`${link}.info.position`)}:</p>
                                        {getInfoUser("position", result.User.position)}
                                    </li>
                                    <li>
                                        <p>{t(`${link}.info.employees`)}:</p>
                                        {getInfoUser("companyNumberOfEmployees", result.User.companyNumberOfEmployees)}
                                    </li>
                                </ul>
                            </li>
                            <li className={styles.infotitle}><p>{t(`${link}.info.client`)}</p></li>
                            <li>
                                <ul>
                                    <li>
                                        <p>{t(`${link}.info.experience`)}:</p>
                                        {getInfoUser("levelOfMLExperience", result.User.levelOfMLExperience)}
                                    </li>
                                    <li>
                                        <p>{t(`${link}.info.know`)}:</p>
                                        {getInfoUser("howCameToKnow", result.User.howCameToKnow)}
                                    </li>
                                    <li>
                                        <p>{t(`${link}.info.intendeduse`)}:</p>
                                        {getInfoUser("intendedUse", result.User.intendedUse)}
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div className={styles.buttons}>
                        <button
                            onClick={() => {
                                if (editUser) {
                                    setNewUser({});
                                    setIsAdmin(result.User.isAdmin);
                                }
                                setEditUser(!editUser);
                            }}
                            className={editUser ? styles.canceledit : ""}
                        >
                            <p>{editUser ? t(`${link}.buttons.cancel`) : t(`${link}.buttons.edit`)}</p>
                        </button>
                        <button
                            onClick={modifyuser}
                            className={(Object.keys(newUser).length === 0 && result.User.isAdmin === isAdmin) ? styles.disabledsave : ""}
                            disabled={(Object.keys(newUser).length === 0 && result.User.isAdmin === isAdmin)}
                        >
                            <p>{t(`${link}.buttons.save`)}</p>
                        </button>
                        <button onClick={() => setConfirmationTrigger(true)}>
                            <p>{t(`${link}.buttons.deleteuser`)}</p>
                        </button>
                    </div>
                </section>
                <section className={styles.containerlogsuser}>
                    <header>
                        <select onChange={(e) => {
                            setNewProject({isSharedModelsProject: false});
                            setType(e.target.value)
                        }}>
                            <option value="Log">{t(`${link}.select.log`)}</option>
                            <option value="Project">{t(`${link}.select.project`)}</option>
                            <option value="Dataset">{t(`${link}.select.dataset`)}</option>
                            <option value="Model">{t(`${link}.select.model`)}</option>
                            <option value="Prediction">{t(`${link}.select.prediction`)}</option>
                        </select>
                        <button
                            className={!selectedRows.length ? styles.buttondisabled : ""}
                            disabled={!selectedRows.length}
                            onClick={deleteUserInfo}
                        >
                            <p>{t(`${link}.buttons.delete`)}</p>
                        </button>
                        <div><p>Qtd: {result[type]?.length}</p></div>
                    </header>
                    <div className={styles.infotable}>
                        {type === "Project" && (
                            <div className={`${styles.creatinginfo} ${styles.maxwidthinfo}`}>
                                {addProject()}
                            </div>
                        )}
                        <div className={`${styles.table} ${type !== "Project" ? styles.maxwidthtable : ""}`}>
                            <UserInfoTable
                                rows={result[type]}
                                isLoading={isLoading}
                                type={type}
                                setSelectedRows={setSelectedRows}
                            />
                        </div>
                    </div>
                </section>
                <Popup trigger={confirmationTrigger} setTrigger={setConfirmationTrigger}>
                    <ConfirmationPopup
                        title={t(`monitoring.popup.areyousure`)}
                        onConfirm={userdelete}
                        onDeny={() => setConfirmationTrigger(false)}
                    />
                </Popup>
            </main>
        )
    }
}

export default InfoUserPopUp;