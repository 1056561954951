import React, { useEffect, useRef, useCallback } from "react";
import * as d3 from "d3";

function FPGrowth({ result }) {

  const d3Chart = useRef(null);

  const createChart = useCallback(() => {

    // Remove previous nodes and links
    d3.select("#fpgrowth-links").remove();
    d3.select("#fpgrowth-nodes").remove();

    const margin = { top: 0, right: 0, bottom: 0, left: 0 };
    const width = parseInt(d3.select(".fpgrowth-result").style("width"), 10) - margin.left - margin.right;
    const height = parseInt(d3.select(".fpgrowth-result").style("height"), 10) - margin.top - margin.bottom;

    const svg = d3
      .select(d3Chart.current)
      .attr("viewBox", `0 0 ${width} ${height}`)
      .attr("preserveAspectRatio", "xMidYMid meet")
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    function ticked() {
      node.attr("cx", (d) => d.x).attr("cy", (d) => d.y);
      link
        .attr("x1", (d) => d.source.x)
        .attr("y1", (d) => d.source.y)
        .attr("x2", (d) => d.target.x)
        .attr("y2", (d) => d.target.y);
    }

    const simulation = d3
      .forceSimulation()
      .force("link", d3.forceLink().id((d) => d.id).distance(100))
      .force("charge", d3.forceManyBody().strength(-100))
      .force("center", d3.forceCenter(width / 2, height / 2))
      .on("tick", ticked);

    let link = svg
      .append("g")
      .attr("id", "fpgrowth-links")
      .attr("stroke", "#999")
      .attr("stroke-opacity", 0.6)
      .selectAll("line");

    let node = svg
      .append("g")
      .attr("id", "fpgrowth-nodes")
      .attr("fill", "#22577A")
      .attr("stroke", "#fff")
      .attr("stroke-width", 1.5)
      .selectAll("circle");

    link = link.data(result?.links, (d) => `${d.source.id}-${d.target.id}`);
    link.exit().remove();
    link = link.enter().append("line").merge(link);

    node = node.data(result?.nodes, (d) => d.id);
    node.exit().remove();
    node = node
      .enter()
      .append("circle")
      .attr("r", 12)
      .call(
        d3
          .drag()
          .on("start", dragstarted)
          .on("drag", dragged)
          .on("end", dragended)
      )
      .merge(node)
      .on("mouseover", (event, d) => {
        node.append("title").text(d.id);
      });

    simulation.nodes(result?.nodes).on("tick", ticked);
    simulation.force("link").links(result?.links);
    simulation.alpha(1).restart();

    function dragstarted(event, d) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
    }

    function dragged(event, d) {
      d.fx = event.x;
      d.fy = event.y;
    }

    function dragended(event, d) {
      if (!event.active) simulation.alphaTarget(0);
      d.fx = null;
      d.fy = null;
    }

  }, [result]);

  useEffect(() => {
    createChart();

    // Handle window resize
    const handleResize = () => {
      d3.select(d3Chart.current).selectAll("*").remove();
      createChart();
    };

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };

  }, [createChart]);

  return <svg ref={d3Chart}></svg>;
}

export default FPGrowth;
