import { DataGrid, ptBR, esES, frFR } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

function SystemUsersTable({
    rows,
    columns,
    isLoading,
    onCellDoubleClick,
    setSelectedRows
}) {
    const { i18n } = useTranslation();
    const [tableColumns, setTableColumns] = useState([]);
    const language = i18n.language;

    let localeText;

    switch (language) {
        case "pt_br":
            localeText = ptBR.props.MuiDataGrid.localeText;
            break;
        case "pt_pt":
            localeText = ptBR.props.MuiDataGrid.localeText;
            break;
        case "es_es":
            localeText = esES.props.MuiDataGrid.localeText;
            break;
        case "fr_fr":
            localeText = frFR.props.MuiDataGrid.localeText;
            break;
        default:
            localeText = ptBR.props.MuiDataGrid.localeText;
            break;
    }

    const useStyles = makeStyles({
        root: {
            fontFamily: "Montserrat, sans-serif",
            fontSize: "0.8em",
            borderRadius: 15,
        },
        row: {
            cursor: "pointer"
        },
        customCell: {
            fontSize: 11,
        },
    });

    const classes = useStyles();

    // TODO: ver uma forma para deixar responsivo
    useEffect(() => {
        let auxColumns = columns;
        if (window.innerWidth < 750) {
            auxColumns.forEach(columns => {
                delete columns.flex;
            })
        }
        setTableColumns(auxColumns);

        // eslint-disable-next-line
    }, []);

    return (
        <DataGrid
            localeText={localeText}
            rows={rows}
            columns={tableColumns}
            pageSize={rows.length}
            classes={{
                root: classes.root,
                cell: classes.customCell
            }}
            loading={isLoading}
            getRowClassName={() => { return `${classes.row}` }}
            rowHeight={50}
            checkboxSelection
            onCellDoubleClick={onCellDoubleClick}
            onSelectionModelChange={(newSelection) => setSelectedRows(newSelection)}
        />
    )

}

export default SystemUsersTable;