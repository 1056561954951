import * as d3 from "d3";
import { useEffect, useRef } from "react";

function ConfusionMatrixD3({ matrix }) {
    const refD3 = useRef();

    useEffect(() => {
        const handleResize = () => {
            const containerWidth = refD3.current.parentElement.clientWidth;
            createConfusionMatrix(containerWidth * 0.75);
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Inicializa a criação do gráfico
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [matrix]);

    const createConfusionMatrix = (width) => {
        const data = matrix.data;
        const labels = matrix.labels;

        const height = width; // Manter um gráfico quadrado
        const svg = d3
            .select(refD3.current)
            .attr('viewBox', `0 0 ${width} ${height}`) // Adiciona o viewBox
            .attr('preserveAspectRatio', 'xMidYMid meet'); // Mantém a proporção

        svg.selectAll('*').remove(); // Limpa o SVG antes de recriar

        const plot = svg.append('g')
            .attr('transform', 'translate(50,50)'); // Margem para eixos

        const number_of_classes = data.length;
        const x_scale = d3.scaleBand().domain(d3.range(number_of_classes)).range([0, width - 100]);
        const y_scale = d3.scaleBand().domain(d3.range(number_of_classes)).range([0, height - 100]);

        const max_value = d3.max(data.flat());

        const rows = plot.selectAll('.row')
            .data(data).enter().append('g')
            .attr('class', 'row')
            .attr('transform', (d, i) => `translate(0,${y_scale(i)})`);

        const cells = rows.selectAll('.cell')
            .data((d) => d)
            .enter().append('g')
            .attr('class', 'cell')
            .attr('transform', (d, i) => `translate(${x_scale(i)}, 0)`);

        cells.append('rect')
            .attr('width', x_scale.bandwidth())
            .attr('height', y_scale.bandwidth())
            .attr('fill', (d) => d3.interpolatePurples(d / max_value))
            .attr('stroke', 'black');

        cells.append('text') // Adiciona o texto nos retângulos
            .attr('x', x_scale.bandwidth() / 2)
            .attr('y', y_scale.bandwidth() / 2)
            .attr('dy', '0.35em') // Alinha verticalmente
            .attr('text-anchor', 'middle')
            .attr("font-size", `${window.innerWidth > 800 ? 15 : 10}px`)
            .attr('fill', (d) => d > max_value / 2 ? 'white' : 'black') // Contraste
            .text((d) => Math.round(d * 100) / 100); // Arredonda para duas casas decimais

        plot.append('g')
            .attr('class', 'x-axis')
            .attr('transform', `translate(0,${height - 100})`)
            .call(d3.axisBottom(x_scale).tickFormat((d) => labels[d]));

        plot.append('g')
            .attr('class', 'y-axis')
            .call(d3.axisLeft(y_scale).tickFormat((d) => labels[d]));
    };

    return <svg ref={refD3} />;
}

export default ConfusionMatrixD3;

