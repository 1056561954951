import "../../../assets/css/views/models/fpgrowth/modelresult.css";

import { useState, useEffect, useRef } from "react";

import FPGrowthD3 from "../../../graphicsComponents/modelsRepresentations/FPGRowthD3";
import SearchIcon from "../../../assets/icons/jsxIcons/SearchIcon";

import { useTranslation } from "react-i18next"
import "./../../../translation/i18n";

function ModelResult({
    result,
}) {

    const { t } = useTranslation();

    const [showGraphic, setShowGraphic] = useState(false);
    const [tableInfo, setTableInfo] = useState([]);
    const [canChangeTable, setCanChangeTable] = useState(true);

    const antecedent = useRef("");

    useEffect(() => {
        if (result?.table.length) {
            //updateTable(result.table);
            setTableInfo(result?.table);
            setShowGraphic(true);
        }
        // eslint-disable-next-line
    }, []);


    return (
        <div className="container-fpgrowth-modelresult">
            <div className="container-fpgrowth-box-external">
                <div className="container-fpgrowth-box-internal">
                    <div className="container-fpgrowth-input">
                        <SearchIcon style={{ size: 20, color: "#BDBDBD" }} />
                        <input
                            type="text"
                            placeholder={t("model.fpgrowth.modelresult.header.placeholder")}
                            onChange={(e) => {
                                antecedent.current = e.target.value;
                                if (canChangeTable) {
                                    setCanChangeTable(false);
                                    const timeout = setTimeout(() => {
                                        setTableInfo(antecedent.current !== "" ? result?.table.filter(data => data?.antecedent.includes(antecedent.current)) : result?.table);
                                        setCanChangeTable(true);
                                        antecedent.current = "";
                                    }, 1500);
                                    return () => { clearTimeout(timeout) }
                                }
                            }}
                        ></input>
                    </div>
                    <div className="container-fpgrowth-buttons">
                        <button
                            style={{ borderRadius: "100px 0px 0px 100px" }}
                            onClick={() => { setShowGraphic(false) }}
                        >
                            <p>{t("model.fpgrowth.modelresult.header.button_1")}</p>
                        </button>
                        <button
                            style={{ borderRadius: "0px 100px 100px 0px" }}
                            onClick={() => { setShowGraphic(true) }}
                        >
                            <p>{t("model.fpgrowth.modelresult.header.button_2")}</p>
                        </button>
                    </div>
                </div>
            </div>
            {showGraphic ? (
                <div className="fpgrowth-result">{result && (<FPGrowthD3 result={result} />)}</div>
            ) : (
                <table>
                    <thead>
                        <tr>
                            <th><p>{t("model.fpgrowth.modelresult.graphic.name_1")}</p></th>
                            <th><p>{t("model.fpgrowth.modelresult.graphic.name_2")}</p></th>
                            <th><p>{t("model.fpgrowth.modelresult.graphic.name_3")}</p></th>
                            <th><p>{t("model.fpgrowth.modelresult.graphic.name_4")}</p></th>
                            <th><p>{t("model.fpgrowth.modelresult.graphic.name_5")}</p></th>
                            <th><p>{t("model.fpgrowth.modelresult.graphic.name_6")}</p></th>
                            <th><p>{t("model.fpgrowth.modelresult.graphic.name_7")}</p></th>
                        </tr>
                    </thead>
                    {/* <tbody>{tableInfo}</tbody> */}
                    <tbody>
                        {tableInfo.map((data, index) => {
                            return (<tr key={index} style={index % 2 === 0 ? { background: "transparent" } : { background: "#F2F2F2" }}>
                                <th><p>{data.antecedent}</p></th>
                                <th><p>{data.consequent}</p></th>
                                <th><p>{data.coverage}%</p></th>
                                <th><p>{data.support}%</p></th>
                                <th><p>{data.confidence}%</p></th>
                                <th><p>{data.leverage}%</p></th>
                                <th><p>{data.lift}</p></th>
                            </tr>)
                        })}
                        {tableInfo.map((data, index) => {
                            return (<tr key={index} style={index % 2 === 0 ? { background: "transparent" } : { background: "#F2F2F2" }}>
                                <th><p>{data.antecedent}</p></th>
                                <th><p>{data.consequent}</p></th>
                                <th><p>{data.coverage}%</p></th>
                                <th><p>{data.support}%</p></th>
                                <th><p>{data.confidence}%</p></th>
                                <th><p>{data.leverage}%</p></th>
                                <th><p>{data.lift}</p></th>
                            </tr>)
                        })}
                    </tbody>
                </table>
            )}
        </div>
    )
}

export default ModelResult;