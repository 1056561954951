import { useEffect, useState } from "react";
import datasetService from "../../../../services/DatasetService";
import { Card } from "./../../../../components/Card";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "./../../../../assets/css/views/popups/creations/NewDataset/availablewiseml.css";
import { useTranslation } from "react-i18next";
import "./../../../../translation/i18n";
import handleErrorResponse from "../../../../helpers/handleErrorResponse";

function AvailableWiseml() {
   const { t } = useTranslation();
   //state utilizado para renderizar o card de dataSources disponibilizados pelo wiseml.
   const [cards, setCards] = useState([]);

   //token que é passado para o endpoint uma única vez.
   const token = localStorage.getItem("user_token");

   const history = useHistory();

   useEffect(() => {
      datasetService
         .getsourcelistwiseml(token)
         .then((data) => {
            let arrayDataSource = [];
            for (let i = 0; i < data.length; i++) {
               let element = data[i];
               arrayDataSource.push({
                  date: element.date,
                  name: element.name,
                  id: element.id,
               });
            }
            setCards(arrayDataSource);
         })
         .catch((error) => {
            handleErrorResponse(history, error, t);
         });
      // eslint-disable-next-line
   }, [token]);

   return (
      <div className="containerAvailableWiseml">
         {cards.length > 0 ? (
            cards?.map((data, index) => {
               return (
                  <Link to={`/datasource/${data.id}`} key={data.id}>
                     <Card type="datasourcewiseml" date={data.date} name={data.name} elementId={data.id} />
                  </Link>
               );
            })
         ) : (
            <span id="no-data">{t("project.info.no_data")}</span>
         )}
      </div>
   );
}

export default AvailableWiseml;
