import React from 'react'
import { Dimmer, Loader, Segment } from 'semantic-ui-react'

import '../assets/css/components/loadingwheel.css'


const LoadingWheel = ({ progress, loadingSize }) => {
    return (
    <div className="loadingwheel">
        <Segment vertical size={loadingSize}>
            <Dimmer active inverted >
                <Loader indeterminate size={loadingSize}>
                    {progress}{progress != null ? '%' : ""}
                    </Loader>
            </Dimmer>
        </Segment>
    </div>)
}

export default LoadingWheel