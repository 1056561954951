import "../../../assets/css/views/popups/informations/histogrampopup.css";
import FileIcon from "../../../assets/icons/jsxIcons/FileIcon";
import BarChart from "../../../graphicsComponents/others/BarChart";
import { useTranslation } from "react-i18next"
import "./../../../translation/i18n"

export const HistogramPopup = ({ selectedDataset, tableData }) => {
  const {t} = useTranslation();

  return (
    <div className="histogram-popup">
      <header><FileIcon style={{ size: 20 }} /><p>{selectedDataset}</p></header>
      <table>
        <thead>
          <tr>
            <th><p>{t("popup.creations.choosealgorithm.info.select.columnname")}</p></th>
            <th><p>{t("popup.creations.choosealgorithm.info.select.histogram")}</p></th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((element, index) => {
            return (
              <tr key={index}>
                <th><p>{element.Nome}</p></th>
                <th id="bar-histogram"><BarChart barChartData={element.Histogram}/></th>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  );
};
