import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import "../../../assets/css/views/popups/actions/deletepopup.css";

import { Button } from "../../../components/Button";

import modelService from "../../../services/ModelService";
import datasetService from "../../../services/DatasetService";
import projectService from "../../../services/ProjectService";

import { setNeedUpdateMyDatasources } from "../../../store/slices/cache/cacheSlice";
import { setNeedUpdateProjectList } from "../../../store/slices/projects/projectsSlice";
import { Loader } from "semantic-ui-react";

import { useTranslation } from "react-i18next";
import "./../../../translation/i18n";
import { toast } from "react-toastify";
import handleErrorResponse from "../../../helpers/handleErrorResponse";

export const DeletePopup = ({
   setTrigger,
   modelResultId,
   cardtype,
   setSelectedArray,
   setIsSelectedActive,
   needBack = false, // flag para saber se precisa voltar para a pagina anterior
}) => {
   const { t } = useTranslation();
   const dispatch = useDispatch();
   const history = useHistory();
   const [isLoading, setIsLoading] = useState(false);

   const handleDelete = () => {
      let token = localStorage.getItem("user_token");
      setIsLoading(true);
      if (token != null) {
         let deleteFunction;

         if (cardtype === "model") {
            deleteFunction = deleteModelResult;
         } else if (cardtype === "dataset") {
            deleteFunction = deleteDataSet;
         } else if (
            cardtype === "datasource" ||
            cardtype === "datasourcewiseml"
         ) {
            deleteFunction = deleteDataSource;
         } else {
            deleteFunction = deleteProject;
         }

         deleteFunction(modelResultId);
      }
   };

   async function deleteModelResult(modelResultId) {
      // Necessario pois o deletemodel aceita só array
      if (typeof modelResultId == "string") {
         modelResultId = [modelResultId];
      }
      modelService
         .deletemodel(modelResultId)
         .then(() => {
            setSelectedArray?.([]);
            setIsSelectedActive?.(false);
            setTrigger(false);
            toast.success(t("popup.action.delete.success"));
         })
         .catch((error) => {
            handleErrorResponse(history, error, t);
         })
         .finally(() => {
            dispatch(setNeedUpdateProjectList(true));
            setIsLoading(false);
         });
   }

   async function deleteDataSource(dataSourceId) {
      let endpoint = null;
      // Necessario pois o deletemanydatasources aceita só array e deletedatasource só string...
      if (typeof dataSourceId == "string") {
         endpoint = datasetService.deletedatasource(dataSourceId);
      } else {
         endpoint = datasetService.deletemanydatasources(dataSourceId);
      }

      endpoint
         .then(() => {
            setSelectedArray?.([]);
            setIsSelectedActive?.(false);
            setTrigger(false);
            needBack && history.goBack();
            toast.success(t("popup.action.delete.success"));
         })
         .catch((error) => {
            handleErrorResponse(history, error, t);
         })
         .finally(() => {
            dispatch(setNeedUpdateMyDatasources(true));
         });
   }

   async function deleteDataSet(datasetId) {
      let endpoint = null;
      // Necessario pois o deletemanydatasets aceita só array e deletedataset só string...
      if (typeof datasetId == "string") {
         endpoint = datasetService.deletedataset(datasetId);
      } else {
         endpoint = datasetService.deletemanydatasets(datasetId);
      }

      endpoint
         .then(() => {
            setSelectedArray?.([]);
            setIsSelectedActive?.(false);
            setTrigger(false);
            toast.success(t("popup.action.delete.success"));
            needBack && history.goBack();
         })
         .catch((error) => {
            handleErrorResponse(history, error, t);
         })
         .finally(() => {
            dispatch(setNeedUpdateProjectList(true));
            setIsLoading(false);
         });
   }

   async function deleteProject(projectId) {
      let endpoint = null;
      // Necessario pois o deletemanyprojects aceita só array e deleteproject só string...
      if (typeof projectId == "string") {
         endpoint = projectService.deleteproject(projectId);
      } else {
         endpoint = projectService.deletemanyprojects(projectId);
      }

      endpoint
         .then(() => {
            setSelectedArray?.([]);
            setIsSelectedActive?.(false);
            toast.success(t("popup.action.delete.success"));
         })
         .catch((error) => {
            handleErrorResponse(history, error, t);
         })
         .finally(() => {
            setTrigger(false);
            dispatch(setNeedUpdateProjectList(true));
            dispatch(setNeedUpdateMyDatasources(true));
            setIsLoading(false);
         });
   }

   let typeOfCard;

   if (cardtype === "model") {
      typeOfCard = "components.card.model";
   } else if (cardtype === "dataset" || cardtype === "datasource") {
      typeOfCard = "components.card.dataset";
   } else {
      typeOfCard = "components.card.project";
   }

   return (
      <div id="delete-model-popup">
         <div id="delete-title">
            {typeof modelResultId === "string"
               ? " " + t("popup.action.delete.title_1") + " " + t(typeOfCard)
               : " " +
                 t("popup.action.delete.title_2") +
                 " " +
                 t(typeOfCard) +
                 t("popup.action.delete.title_2.plural")}
            ?
         </div>
         <div id="description"></div>
         {isLoading && <Loader active />}
         <div id="buttons">
            <span id="btn">
               <Button
                  type="button"
                  buttonStyle="btn--primary--outline"
                  buttonSize="btn--auto"
                  disabled={isLoading}
                  onClick={() => {
                     handleDelete();
                  }}
               >
                  {t("popup.action.delete.yes")}
               </Button>
            </span>

            <span id="btn">
               <Button
                  type="button"
                  buttonStyle="btn--primary--solid"
                  buttonSize="btn--auto"
                  onClick={(_) => {
                     setTrigger(false);
                  }}
               >
                  {t("popup.action.delete.no")}
               </Button>
            </span>
         </div>
      </div>
   );
};
