import Highcharts from 'highcharts'
import highcharts3d from "highcharts/highcharts-3d";
highcharts3d(Highcharts); //init module

Highcharts.setOptions({
    colors: Highcharts.getOptions().colors.map(function (color) {
        return {
            radialGradient: {
                cx: 0.4,
                cy: 0.3,
                r: 0.5
            },
            stops: [
                [0, color],
                [1, Highcharts.color(color).brighten(-0.2).get('rgb')]
            ]
        };
    })
});

function getAxis(feature, min, max){
    let data = {
        title: {
            text: feature.field
        }
    }
    if(feature.encoding.length){
        return {
            ...data,
            categories: feature.encoding,
            min: min,
            max: max
        }
    }
    return {
        ...data,
        min: min,
        max: max,
    }
}

function calculateResponsiveMargin(container) {
    const width = container.offsetWidth;
    const height = container.offsetHeight;
    if(window.innerWidth <= 400){
        return [height * 0.1, width * 0.2, height * 0.3, width * 0.2];
    }
    else if(window.innerWidth < 1000){
        return [height * 0.15, width * 0.15, height * 0.15, width * 0.15]; 
    }
    return [height * 0.2, width * 0.2, height * 0.2, width * 0.2];
}

function create(graphic, name_series){

    const container = document.getElementById('container');
    let margin = calculateResponsiveMargin(container);

    // Set up the chart
    const chart = new Highcharts.Chart({
        chart: {
            renderTo: 'container',
            margin: margin,
            type: 'scatter3d',
            animation: false,
            options3d: {
                enabled: true,
                alpha: 10,
                beta: 30,
                depth: 500,
                viewDistance: 10,
                fitToPlot: false,
                frame: {
                    bottom: { size: 1, color: 'rgba(0,0,0,0.02)' },
                    back: { size: 1, color: 'rgba(0,0,0,0.04)' },
                    side: { size: 1, color: 'rgba(0,0,0,0.06)' }
                }
            }
        },
        title: {
            text: ''
        },
        plotOptions: {
            scatter: {
                width: 10,
                height: 10,
                depth: 10,
            }, series: {
                cursor: 'pointer',
            }
        },
        xAxis: getAxis(graphic.features[0], graphic.min[0], graphic.max[0]),
        yAxis: getAxis(graphic.features[1], graphic.min[1], graphic.max[1]),
        zAxis: getAxis(graphic.features[2], graphic.min[2], graphic.max[2]),
        legend: {
            enabled: true
        },
        tooltip: {
            formatter: function() {
                const xLabel = chart.xAxis[0].categories !== undefined ? chart.xAxis[0].categories[this.point.x] : this.point.x;
                const yLabel = chart.yAxis[0].categories !== undefined ? chart.yAxis[0].categories[this.point.y] : this.point.y;
                const zLabel = chart.zAxis[0].categories !== undefined ? chart.zAxis[0].categories[this.point.z] : this.point.z;
                
                const seriesNameWithColor = `<span style="color:${this.series.color};">&#11044;</span> <b style="textTransform: uppercase">${this.series.name}</b>`;
                return `${seriesNameWithColor}<br/><br/>${graphic.features[0].field}: <b>${xLabel}</b><br/>${graphic.features[1].field}: <b>${yLabel}</b><br/>${graphic.features[2].field}: <b>${zLabel}</b>`;
            }
        },
        accessibility: {
            enabled: false
        },
        series: [{
            name: name_series.name_1,
            colorByPoint: false,
            color: "#EB5757",
            accessibility: {
                exposeAsGroupOnly: true
            },
            marker: {
                symbol: 'circle'
            },
            data: [graphic.instance],
        }, {
            name: name_series.name_2,
            colorByPoint: false,
            color: "#22577A",
            accessibility: {
                exposeAsGroupOnly: true
            },
            marker: {
                symbol: 'circle'
            },
            data: graphic.anomaly_instances,
        }, {
            name: name_series.name_3,
            colorByPoint: false,
            color: "#57CC99",
            accessibility: {
                exposeAsGroupOnly: true
            },
            marker: {
                symbol: 'circle'
            },
            data: graphic.all_instances,
        }],
    });

    // Add mouse and touch events for rotation
    (function (H) {
        function dragStart(eStart) {
            eStart = chart.pointer.normalize(eStart);

            const posX = eStart.chartX,
                posY = eStart.chartY,
                alpha = chart.options.chart.options3d.alpha,
                beta = chart.options.chart.options3d.beta,
                sensitivity = 5,  // lower is more sensitive
                handlers = [];

            function drag(e) {
                // Get e.chartX and e.chartY
                e = chart.pointer.normalize(e);

                chart.update({
                    chart: {
                        options3d: {
                            alpha: alpha + (e.chartY - posY) / sensitivity,
                            beta: beta + (posX - e.chartX) / sensitivity
                        }
                    }
                }, undefined, undefined, false);
            }

            function unbindAll() {
                handlers.forEach(function (unbind) {
                    if (unbind) {
                        unbind();
                    }
                });
                handlers.length = 0;
            }

            handlers.push(H.addEvent(document, 'mousemove', drag));
            handlers.push(H.addEvent(document, 'touchmove', drag));


            handlers.push(H.addEvent(document, 'mouseup', unbindAll));
            handlers.push(H.addEvent(document, 'touchend', unbindAll));
        }
        H.addEvent(chart.container, 'mousedown', dragStart);
        H.addEvent(chart.container, 'touchstart', dragStart);
    }(Highcharts));
}

const IsolationForestHighchart = {
    create,
}

export default IsolationForestHighchart;

