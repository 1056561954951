import React, { useEffect, useRef, useState } from "react";

import Tour from "reactour";
import CloseTourDialog from "./components/CloseTourDialog";
import { useTranslation } from "react-i18next";
import StyledParagraph from "./components/StyledParagraph";
import { Info } from "@material-ui/icons";
import { GiClick } from "react-icons/gi";

const ChooseAlgTour = () => {
   const [isTourOpen, setIsTourOpen] = useState(true);
   const [currentStep, setCurrentStep] = useState(0);
   const [disableInteraction, setDisableInteraction] = useState(true);
   const tourRef = useRef();
   const [isDialogOpen, setIsDialogOpen] = useState(false);
   const { t } = useTranslation();

   const steps = [
      {
         selector:
            "#root > span > div.dataset_dataset__Sa3uE > div.popup > div > div",
         content: (
            <div>
               <h4>{t("wizard.chooseAlgTour.step_1.title")}</h4>
               <StyledParagraph>
                  {t("wizard.chooseAlgTour.step_1.description_1")}
               </StyledParagraph>
               <StyledParagraph icon={Info} small={true}>
                  {t("wizard.chooseAlgTour.step_1.description_2")}
               </StyledParagraph>
            </div>
         ),
         style: {
            borderRadius: 10,
         },
      },
      {
         selector:
            "#root > span > div.dataset_dataset__Sa3uE > div.popup > div > div > main > div.choose-algorithm",
         content: (
            <div>
               <h4>{t("wizard.chooseAlgTour.step_2.title")}</h4>
               <StyledParagraph>
                  {t("wizard.chooseAlgTour.step_2.description_1")}
               </StyledParagraph>
               <ul>
                  <li>
                     <StyledParagraph>
                        {t("wizard.chooseAlgTour.step_2.description_2")}
                     </StyledParagraph>
                  </li>
                  <li>
                     <StyledParagraph>
                        {t("wizard.chooseAlgTour.step_2.description_3")}
                     </StyledParagraph>
                  </li>
               </ul>
            </div>
         ),
         style: {
            borderRadius: 10,
         },
      },
      {
         selector:
            "#root > span > div.dataset_dataset__Sa3uE > div.popup > div > div > main > div.container-info-execute > article",
         content: (
            <div>
               <h4>{t("wizard.chooseAlgTour.step_3.title")}</h4>
               <StyledParagraph>
                  {t("wizard.chooseAlgTour.step_3.description")}
               </StyledParagraph>
            </div>
         ),
         style: {
            borderRadius: 10,
         },
      },
      {
         selector:
            "#root > span > div.dataset_dataset__Sa3uE > div.popup > div > div > main > div.container-info-execute > article > button",
         content: (
            <div>
               <h4>{t("wizard.chooseAlgTour.step_4.title")}</h4>
               <StyledParagraph>
                  {t("wizard.chooseAlgTour.step_4.description_1")}
               </StyledParagraph>
               <ul>
                  <li>
                     <StyledParagraph>
                        {t("wizard.chooseAlgTour.step_4.description_2")}
                     </StyledParagraph>
                  </li>
                  <li>
                     <StyledParagraph>
                        {t("wizard.chooseAlgTour.step_4.description_3")}
                     </StyledParagraph>
                  </li>
                  <li>
                     <StyledParagraph>
                        {t("wizard.chooseAlgTour.step_4.description_4")}
                     </StyledParagraph>
                  </li>
                  <li>
                     <StyledParagraph>
                        {t("wizard.chooseAlgTour.step_4.description_5")}
                     </StyledParagraph>
                  </li>
               </ul>
            </div>
         ),
         style: {
            borderRadius: 10,
         },
      },
      {
         selector:
            "#root > span > div.dataset_dataset__Sa3uE > div.popup > div > div > main > div.container-info-execute > div > select",
         content: (
            <div>
               <h4>{t("wizard.chooseAlgTour.step_5.title")}</h4>
               <StyledParagraph>
                  {t("wizard.chooseAlgTour.step_5.description")}
               </StyledParagraph>
            </div>
         ),
         style: {
            borderRadius: 10,
         },
      },
      {
         selector:
            "#root > span > div.dataset_dataset__Sa3uE > div.popup > div > div > main > div.choose-attributes",
         content: (
            <div>
               <h4>{t("wizard.chooseAlgTour.step_6.title")}</h4>
               <StyledParagraph>
                  {t("wizard.chooseAlgTour.step_6.description")}
               </StyledParagraph>
            </div>
         ),
         style: {
            borderRadius: 10,
         },
      },
      {
         selector:
            "#root > span > div.dataset_dataset__Sa3uE > div.popup > div > div > main > div.container-info-execute > div > button",
         content: (
            <div>
               <h4>{t("wizard.chooseAlgTour.step_7.title")}</h4>
               <StyledParagraph icon={GiClick}>
                  {t("wizard.chooseAlgTour.step_7.description")}
               </StyledParagraph>
            </div>
         ),
         style: {
            borderRadius: 10,
         },
      },
   ];

   useEffect(() => {
      if (currentStep === 6) {
         setDisableInteraction(false);
      } else {
         setDisableInteraction(true);
      }
   }, [currentStep]);

   return (
      <>
         <Tour
            ref={tourRef}
            steps={steps}
            isOpen={isTourOpen}
            onRequestClose={() => setIsDialogOpen(true)}
            getCurrentStep={(step) => setCurrentStep(step)}
            goToStep={currentStep}
            disableDotsNavigation
            disableInteraction={disableInteraction}
            disableFocusLock={isDialogOpen}
            rounded={10}
         />
         <CloseTourDialog
            open={isDialogOpen}
            onCloseDialog={() => setIsDialogOpen(false)}
            onCloseTour={() => setIsTourOpen(false)}
         />
      </>
   );
};

export default ChooseAlgTour;
