import { Check, Help, Info, Warning } from "@material-ui/icons";
import React, {
   useEffect,
   useMemo,
   useRef,
   useState,
   useCallback,
} from "react";

import Tour from "reactour";
import CloseTourDialog from "./components/CloseTourDialog";
import { Button } from "semantic-ui-react";
import wiselib from "../../services/wiselib";
import { toast } from "react-toastify";
import handleErrorResponse from "../../helpers/handleErrorResponse";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setTutorialDone } from "../../store/slices/wizard/wizardSlice";
import StyledParagraph from "./components/StyledParagraph";
import { GiClick } from "react-icons/gi";

const CreateModelTour = ({ modelIsCreated }) => {
   const [isTourOpen, setIsTourOpen] = useState(true);
   const [currentStep, setCurrentStep] = useState(0);
   const [isDialogOpen, setIsDialogOpen] = useState(false);
   const tourRef = useRef();
   const history = useHistory();
   const dispatch = useDispatch();
   const { t } = useTranslation();
   const [isLoading, setIsLoading] = useState(false);
   const [isButtonDisabled, setIsButtonDisabled] = useState(false);

   const steps = useMemo(
      () => [
         {
            selector: "#root > span > div.model > div > div > div > div.left",
            content: (
               <div>
                  <h4>{t("wizard.createModelTour.step_1.title")}</h4>
                  <StyledParagraph icon={Warning}>
                     {t("wizard.createModelTour.step_1.description_1")}
                  </StyledParagraph>
                  <StyledParagraph icon={Info}>
                     {t("wizard.createModelTour.step_1.description_2")}
                  </StyledParagraph>
               </div>
            ),
            style: {
               borderRadius: 10,
            },
         },
         {
            selector:
               "#root > span > div.model > div > div > div > div.left > header > div.target-model",
            content: (
               <div>
                  <h4>{t("wizard.createModelTour.step_2.title")}</h4>
                  <StyledParagraph icon={Warning}>
                     {t("wizard.createModelTour.step_2.description")}
                  </StyledParagraph>
               </div>
            ),
            style: {
               borderRadius: 10,
            },
         },

         {
            selector:
               "#root > span > div.model > div > div > div > div.left > div.model-hyperparameters > div:nth-child(1)",
            content: (
               <div>
                  <h4>{t("wizard.createModelTour.step_3.title")}</h4>
                  <StyledParagraph>
                     {t("wizard.createModelTour.step_3.description")}
                  </StyledParagraph>
               </div>
            ),
            style: {
               borderRadius: 10,
            },
         },
         {
            selector:
               "#root > span > div.model > div > div > div > div.left > div.model-hyperparameters > div:nth-child(2)",
            content: (
               <div>
                  <h4>{t("wizard.createModelTour.step_4.title")}</h4>
                  <StyledParagraph>
                     {t("wizard.createModelTour.step_4.description")}
                  </StyledParagraph>
               </div>
            ),
            style: {
               borderRadius: 10,
            },
         },
         {
            selector:
               "#root > span > div.model > div > div > div > div.left > div.model-hyperparameters > div:nth-child(3)",
            content: (
               <div>
                  <h4>{t("wizard.createModelTour.step_5.title")}</h4>
                  <StyledParagraph>
                     {t("wizard.createModelTour.step_5.description")}
                  </StyledParagraph>
               </div>
            ),
            style: {
               borderRadius: 10,
            },
         },
         {
            selector:
               "#root > span > div.model > div > div > div > div.left > div.model-hyperparameters > div:nth-child(4)",
            content: (
               <div>
                  <h4>{t("wizard.createModelTour.step_6.title")}</h4>
                  <StyledParagraph>
                     {t("wizard.createModelTour.step_6.description")}
                  </StyledParagraph>
               </div>
            ),
            style: {
               borderRadius: 10,
            },
         },
         {
            selector:
               "#root > span > div.model > div > div > div > div.left > div.model-hyperparameters > div:nth-child(5)",
            content: (
               <div>
                  <h4>{t("wizard.createModelTour.step_7.title")}</h4>
                  <StyledParagraph>
                     {t("wizard.createModelTour.step_7.description")}
                  </StyledParagraph>
               </div>
            ),
            style: {
               borderRadius: 10,
            },
         },
         {
            selector:
               "#root > span > div.model > div > div > div > div.left > div.model-hyperparameters > div:nth-child(6)",
            content: (
               <div>
                  <h4>{t("wizard.createModelTour.step_8.title")}</h4>
                  <StyledParagraph>
                     {t("wizard.createModelTour.step_8.description")}
                  </StyledParagraph>
               </div>
            ),
            style: {
               borderRadius: 10,
            },
         },
         {
            selector:
               "#root > span > div.model > div > div > div > div.left > div.model-hyperparameters > div.model-dropdown",
            content: (
               <div>
                  <h4>{t("wizard.createModelTour.step_9.title")}</h4>
                  <StyledParagraph icon={Warning}>
                     {t("wizard.createModelTour.step_9.description")}
                  </StyledParagraph>
               </div>
            ),
            style: {
               borderRadius: 10,
            },
         },
         {
            selector:
               "#root > span > div.model > div > div > div > div.left > div.model-execute",
            content: (
               <div>
                  <h4>{t("wizard.createModelTour.step_10.title")}</h4>
                  <StyledParagraph icon={GiClick}>
                     {t("wizard.createModelTour.step_10.description")}
                  </StyledParagraph>
               </div>
            ),
            style: {
               borderRadius: 10,
            },
         },
      ],
      [t]
   );

   useEffect(() => {
      if (currentStep === steps.length - 1) {
         const createModelButton = document.querySelector(
            "#root > span > div.model > div > div > div > div.left > div.model-execute"
         );

         if (createModelButton) {
            createModelButton.addEventListener("click", () => {
               setIsTourOpen(false);
            });
         }

         return () => {
            if (createModelButton) {
               createModelButton.removeEventListener("click", () => {
                  setIsTourOpen(false);
               });
            }
         };
      }
   }, [currentStep, steps.length]);

   const handleFinishTour = useCallback(async () => {
      if (isLoading) return;

      setIsLoading(true);
      setIsButtonDisabled(true);
      try {
         await wiselib.updatetutorialdone(true);
         setIsTourOpen(false);
         dispatch(setTutorialDone(true));
         toast.success(t("wizard.toast.done"));
      } catch (error) {
         handleErrorResponse(history, error, t);
      } finally {
         setIsLoading(false);
      }
   }, [isLoading, dispatch, t, history]);

   useEffect(() => {
      if (modelIsCreated) {
         steps.push({
            selector: "",
            content: (
               <div>
                  <h4
                     style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                     }}
                  >
                     <Check />
                     {t("wizard.createModelTour.step_11.title")}
                  </h4>

                  <p
                     style={{
                        textAlign: "justify",
                        display: "flex",
                        alignItems: "center",
                     }}
                  >
                     <Info style={{ marginRight: 8 }} />{" "}
                     {t("wizard.createModelTour.step_11.description_1")}
                  </p>
                  <p
                     style={{
                        textAlign: "justify",
                        display: "flex",
                        alignItems: "center",
                        gap: 8,
                     }}
                  >
                     <Help style={{ marginRight: 8 }} />{" "}
                     {t("wizard.createModelTour.step_11.description_2")}
                  </p>
                  <Button
                     color="facebook"
                     onClick={handleFinishTour}
                     disabled={isLoading || isButtonDisabled}
                  >
                     OK
                  </Button>
               </div>
            ),
            style: {
               borderRadius: 10,
            },
         });
         setIsTourOpen(true);
         setCurrentStep(steps.length - 1);
      }
   }, [
      modelIsCreated,
      steps,
      isLoading,
      t,
      handleFinishTour,
      isButtonDisabled,
   ]);

   return (
      <>
         <Tour
            ref={tourRef}
            steps={steps}
            isOpen={isTourOpen}
            onRequestClose={() => setIsDialogOpen(true)}
            getCurrentStep={(step) => setCurrentStep(step)}
            goToStep={currentStep}
            disableDotsNavigation
            showButtons={currentStep !== 10}
            showNavigation={currentStep !== 10}
            showNumber={currentStep !== 10}
            disableKeyboardNavigation={currentStep === 10}
            disableFocusLock={isDialogOpen}
            rounded={10}
         />
         <CloseTourDialog
            open={isDialogOpen}
            onCloseDialog={() => setIsDialogOpen(false)}
            onCloseTour={() => setIsTourOpen(false)}
         />
      </>
   );
};

export default CreateModelTour;
