import React, { useEffect, useRef, useState } from "react";

import ws from "../../../services/wiselib";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Dropdown, Form, FormField, Input, Loader, Modal } from "semantic-ui-react";
import CheckIconGreen from "../../../assets/icons/jsxIcons/CheckIconGreen";
import AlertIcon from "../../../assets/icons/jsxIcons/AlertIcon";
import ReCAPTCHA from "react-google-recaptcha";
import EmailConfirmationPopup from "../../popups/actions/EmailConfirmationPopup";
import { Button } from "../../../components/Button";
import { FormLabel } from "@material-ui/core";
import handleErrorResponse from "../../../helpers/handleErrorResponse";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const SignupCard = ({ setLoginTab }) => {
   const [fullName, setFullName] = useState("");
   const [userEmail, setUserEmail] = useState("");
   const [userPassword, setUserPassword] = useState("");
   const [confirmPassword, setConfirmPassword] = useState("");
   const [validPassword, setValidPassword] = useState({
      has8chars: false,
      hasNumber: false,
      hasLetter: false,
   });
   const [passwordMatch, setPasswordMatch] = useState(false);
   const [age, setAge] = useState(0);
   /** Empresa */
   const [company, setCompany] = useState("");
   /** Área de atuação da empresa */
   const [companyMarketField, setCompanyMarketField] = useState("");
   /** Número de colaboradores da empresa */
   const [companyNumberOfEmployees, setCompanyNumberOfEmployees] = useState(0);
   /** Cargo */
   const [position, setPosition] = useState("");
   /** Cidade */
   const [city, setCity] = useState("");
   /** Estado */
   const [state, setState] = useState("");
   /** Pais */
   const [country, setCountry] = useState("");
   /** Como pretende utilizar a ferramenta */
   const [intendedUse, setIntendedUse] = useState(0);
   /** Como veio a conhecer a aplicação */
   const [howCameToKnow, setHowCameToKnow] = useState(0);
   /** Nível de contato com Machine Learning */
   const [levelOfMLExperience, setLevelOfMLExperience] = useState(0);

   const [isLoading, setIsLoading] = useState(false);

   const [needCaptchaVerification, setNeedCaptchaVerification] = useState(false);
   const [showCaptcha, setShowCaptcha] = useState(0);
   const [emailConfirmation, setEmailConfirmation] = useState(false);

   const { t } = useTranslation();

   const history = useHistory();
   const captcha = useRef(null);
   const inputRef = useRef(null);

   // Verifica a quantidade de tentativas do usuario, caso seja maior do que 1, CAPTCHA é ativado
   const handleAttemptLimit = () => {
      ws.verifyattemptlimitcounter()
         .then((res) => {
            if (res.data) {
               setNeedCaptchaVerification(true);
               if (captcha.current) {
                  captcha.current.reset();
               }
            } else {
               setNeedCaptchaVerification(false);
            }
            setShowCaptcha(res.data);
         })
         .catch((error) => {
            handleErrorResponse(history, error, t);
         });
   };

   useEffect(() => {
      handleAttemptLimit();
      inputRef.current.focus();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const handlePasswordChange = (value) => {
      setUserPassword(value);

      // Verifica força da senha
      const newPassword = value;
      const has8chars = newPassword.length >= 8;
      const hasNumber = /\d/.test(newPassword);
      const hasLetter = /[a-zA-Z]/.test(newPassword);

      setValidPassword({
         has8chars,
         hasNumber,
         hasLetter,
      });
   };

   const handleConfirmPassword = (value) => {
      setConfirmPassword(value);

      // Verifica força da senha
      const newPassword = value;
      const has8chars = newPassword.length >= 8;
      const hasNumber = /\d/.test(newPassword);
      const hasLetter = /[a-zA-Z]/.test(newPassword);

      setValidPassword({
         has8chars,
         hasNumber,
         hasLetter,
      });
   };

   useEffect(() => {
      // Verifica se as senhas coincidem
      if (userPassword === confirmPassword && userPassword.length > 0) {
         setPasswordMatch(true);
      } else {
         setPasswordMatch(false);
      }
   }, [userPassword, confirmPassword]);

   const handleSignUp = (e) => {
      e?.preventDefault();
      if (needCaptchaVerification) {
         return;
      }
      handleAttemptLimit();
      const arr = fullName.split(/ (.*)/);
      let primeiroNome = arr[0];
      let sobrenome = arr[1];
      setIsLoading(true);
      if (sobrenome !== undefined && primeiroNome !== undefined) {
         if (userEmail !== "") {
            if (userPassword === confirmPassword && userPassword !== "") {
               const newUser = {
                  FirstName: primeiroNome.trim(),
                  Surname: sobrenome.trim(),
                  Email: userEmail.trim(),
                  Age: age,
                  BirthDate: age,
                  CompanyName: company,
                  CompanyMarketField: companyMarketField,
                  CompanyNumberOfEmployees: numberOfEmployeesValues[companyNumberOfEmployees],
                  Position: position,
                  City: city,
                  State: state,
                  Country: country,
                  IntendedUse: intendedUseValues[intendedUse],
                  HowCameToKnow: howCameToKnowValues[howCameToKnow],
                  LevelOfMLExperience: levelOfMLexperienceValues[levelOfMLExperience],
                  Password: userPassword,
               };
               ws.createaccount(newUser)
                  .then((res) => {
                     if (res.status === 200) {
                        setIsLoading(false);
                        toast.success(t(`backend.SUCCESS.${res.data}`));
                        setEmailConfirmation(true);
                     } else {
                        toast.error(t("home.startpage.error.userandpassword"));
                     }
                  })
                  .catch((error) => {
                     setIsLoading(false);
                     if (captcha.current) {
                        captcha.current.reset();
                     }
                     handleErrorResponse(history, error, t);
                  });
            } else {
               setIsLoading(false);
               toast.error(t("home.startpage.error.passwords"));
            }
         } else {
            setIsLoading(false);
            toast.error(t("home.startpage.error.email"));
         }
      } else {
         setIsLoading(false);
         toast.error(t("home.startpage.error.name"));
      }
   };
   // const numberOfEmployeesOptionsIndustry = ["Até 19","20 a 99","100 a 499","Mais de 500"]
   const numberOfEmployeesOptions = [
      t("home.startpage.card.content.select.select_employees.employees_1"),
      t("home.startpage.card.content.select.select_employees.employees_2"),
      t("home.startpage.card.content.select.select_employees.employees_3"),
      t("home.startpage.card.content.select.select_employees.employees_4"),
   ];

   const numberOfEmployeesValues = ["Up to 9", "10 to 49", "50 to 99", "More than 100"];

   const intendedUseOptions = [
      t("home.startpage.card.content.select.select_intention.intention_1"),
      t("home.startpage.card.content.select.select_intention.intention_2"),
      t("home.startpage.card.content.select.select_intention.intention_3"),
      t("home.startpage.card.content.select.select_intention.intention_4"),
      t("home.startpage.card.content.select.select_intention.intention_5"),
      t("home.startpage.card.content.select.select_intention.intention_6"),
   ];

   const intendedUseValues = ["Work", "Study", "Personal project", "Research", "Test", "Other"];

   const howCameToKnowOptions = [
      "E-mail",
      t("home.startpage.card.content.select.select_know_about.know_about_1"),
      t("home.startpage.card.content.select.select_know_about.know_about_2"),
      t("home.startpage.card.content.select.select_know_about.know_about_3"),
   ];

   const howCameToKnowValues = ["E-mail", "Lecture", "Friends", "Other"];

   const levelOfMLexperienceOptions = [
      t("home.startpage.card.content.select.select_experience.experience_1"),
      t("home.startpage.card.content.select.select_experience.experience_2"),
      t("home.startpage.card.content.select.select_experience.experience_3"),
   ];

   const levelOfMLexperienceValues = [
      "Never had contact",
      "Little experience/personal projects",
      "Professional/advanced experience",
   ];

   const registerFields = [
      {
         title: t("home.startpage.card.content.title.fullname"),
         placeholder: t("home.startpage.card.content.title.fullname.placeholder"),
         type: "text",
         field: fullName,
         setter: setFullName,
      },
      {
         title: t("home.startpage.card.content.title.email"),
         placeholder: t("home.startpage.card.content.title.email.placeholder"),
         type: "text",
         field: userEmail,
         setter: setUserEmail,
      },
      {
         title: t("home.startpage.card.content.title.age"),
         placeholder: "",
         type: "date",
         field: age,
         setter: setAge,
      },
      {
         title: t("home.startpage.card.content.title.company"),
         placeholder: "Wise",
         type: "text",
         field: company,
         setter: setCompany,
      },
      {
         title: t("home.startpage.card.content.title.occupation"),
         placeholder: "T.I.",
         type: "text",
         field: companyMarketField,
         setter: setCompanyMarketField,
      },
      {
         title: t("home.startpage.card.content.title.collaborators"),
         placeholder: t("home.startpage.card.content.title.collaborators.placeholder"),
         type: "list",
         field: companyNumberOfEmployees,
         values: numberOfEmployeesOptions,
         setter: setCompanyNumberOfEmployees,
         options: numberOfEmployeesOptions,
      },
      {
         title: t("home.startpage.card.content.title.position"),
         placeholder: t("home.startpage.card.content.title.position.placeholder"),
         type: "text",
         field: position,
         setter: setPosition,
      },
      {
         title: t("home.startpage.card.content.title.city"),
         placeholder: "Curitiba",
         type: "text",
         field: city,
         setter: setCity,
      },
      {
         title: t("home.startpage.card.content.title.state"),
         placeholder: "Paraná",
         type: "text",
         field: state,
         setter: setState,
      },
      {
         title: t("home.startpage.card.content.title.country"),
         placeholder: "Brasil",
         type: "text",
         field: country,
         setter: setCountry,
      },
      {
         title: t("home.startpage.card.content.title.intention"),
         placeholder: t("home.startpage.card.content.title.intention.placeholder"),
         type: "list",
         field: intendedUse,
         values: intendedUseValues,
         setter: setIntendedUse,
         options: intendedUseOptions,
      },
      {
         title: t("home.startpage.card.content.title.know_about"),
         placeholder: t("home.startpage.card.content.title.know_about.placeholder"),
         type: "list",
         field: howCameToKnow,
         values: howCameToKnowValues,
         setter: setHowCameToKnow,
         options: howCameToKnowOptions,
      },
      {
         title: t("home.startpage.card.content.title.experience"),
         placeholder: t("home.startpage.card.content.title.experience.placeholder"),
         type: "list",
         field: levelOfMLExperience,
         values: levelOfMLexperienceValues,
         setter: setLevelOfMLExperience,
         options: levelOfMLexperienceOptions,
      },
      {
         title: t("home.startpage.card.content.title.password"),
         placeholder: "* * * * * *",
         type: "password",
         field: userPassword,
         setter: handlePasswordChange,
      },
      {
         title: t("home.startpage.card.content.title.confirm"),
         placeholder: "* * * * * *",
         type: "password",
         field: confirmPassword,
         setter: handleConfirmPassword,
      },
   ];

   /**
    * Renderiza as caixas de input dos campos de cadastro
    * @returns
    */
   function renderRegisterFieldsInputs() {
      let registerFieldsInputs = [];

      registerFields.forEach((item, index) => {
         let inputClassName = item.type + "-input";
         if (item.type === "list") {
            registerFieldsInputs.push(
               <FormField>
                  <FormLabel className="input-title">{item.title}</FormLabel>
                  <div className={inputClassName}>
                     <Dropdown
                        className="dropdown-signup"
                        fluid
                        placeholder={item.placeholder}
                        selection
                        options={item.options.map((item, index) => ({
                           key: index,
                           text: item,
                           value: index,
                        }))}
                        onChange={(e, { value }) => {
                           item.setter(value);
                        }}
                     />
                  </div>
               </FormField>,
            );
         } else {
            registerFieldsInputs.push(
               <FormField>
                  <FormLabel className="input-title">{item.title}</FormLabel>
                  <Input
                     className={inputClassName}
                     placeholder={item.placeholder}
                     size="small"
                     type={item.type}
                     ref={index === 0 ? inputRef : null}
                     onChange={(e, { value }) => {
                        item.setter(value);
                     }}
                  />
               </FormField>,
            );
         }
      });

      return registerFieldsInputs;
   }

   return (
      <div className="auth-signup">
         <Form onSubmit={handleSignUp}>
            {renderRegisterFieldsInputs()}

            <small className="input-title">
               {t("home.startpage.info.caracteres")}:{" "}
               {validPassword.has8chars ? (
                  <CheckIconGreen style={{ size: 16 }} />
               ) : (
                  <AlertIcon style={{ color: "red", size: 12 }} />
               )}
            </small>
            <small className="input-title">
               {t("home.startpage.info.word_and_password")}:{" "}
               {validPassword.hasNumber && validPassword.hasLetter ? (
                  <CheckIconGreen style={{ size: 16 }} />
               ) : (
                  <AlertIcon style={{ color: "red", size: 12 }} />
               )}
            </small>
            <small className="input-title">
               {t("home.startpage.info.same_passwords")}:{" "}
               {passwordMatch ? (
                  <CheckIconGreen style={{ size: 16 }} />
               ) : (
                  <AlertIcon style={{ color: "red", size: 12 }} />
               )}
            </small>
            {showCaptcha > 0 && (
               <ReCAPTCHA
                  ref={captcha}
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  onChange={(e) => {
                     setNeedCaptchaVerification(e === null);
                  }}
                  style={{ marginLeft: "2rem" }}
               />
            )}
            <div className="login-buttons">
               <Button
                  buttonStyle={
                     needCaptchaVerification === false && !isLoading ? "btn--primary--solid" : "btn--disabled--solid"
                  }
                  buttonSize="btn--auto"
                  type="submit"
                  disabled={(needCaptchaVerification && showCaptcha) || isLoading}
               >
                  {t("home.startpage.card.header.register")}
                  {isLoading && <Loader active inline="centered" size="small" />}
               </Button>
            </div>
         </Form>

         <Modal
            open={emailConfirmation}
            closeIcon
            size="mini"
            onClose={() => setEmailConfirmation(false)}
            onOpen={() => setEmailConfirmation(true)}
            closeOnDimmerClick={false}
            closeOnDocumentClick={false}
         >
            <EmailConfirmationPopup setLoginTab={setLoginTab} setTrigger={setEmailConfirmation} />
         </Modal>
      </div>
   );
};

export default SignupCard;
