import http from "../http-common";

function allrunningusertask(){
    return http.get(`/allrunningusertask/${localStorage.getItem("user_token")}`)
        .then(response => {return response.data});
}

function gettaskcompleted(id){
    return http.get(`/gettaskcompleted/${localStorage.getItem("user_token")}/${id}`)
        .then(response => {return response.data});
}

function killtask(id){
    return http.post("/killtask", {
        token: localStorage.getItem("user_token"),
        taskid: id
    })
    .then(response => {return response.data});
}

function deleteusertask(id){
    let token = localStorage.getItem("user_token"); 
    return http.delete(`/deleteusertask/${token}/${id}`)
    .then(response => {return response.data});
}

function deleteallusertasks(){
    let token = localStorage.getItem("user_token"); 
    return http.delete(`/deleteallusertasks/${token}`)
    .then(response => {return response.data});
}

function getusertasks() {
    return http.get(`/getusertasks/${localStorage.getItem("user_token")}`)
        .then((response) => {
            return response.data;
        })
}

function restartusertask(id){
    return http.post("/restartusertask", {
        token: localStorage.getItem("user_token"),
        taskstring: id
    })
    .then(response => {return response.data});
}

const taskService = {
    allrunningusertask,
    gettaskcompleted,
    killtask,
    deleteusertask,
    deleteallusertasks,
    getusertasks,
    restartusertask
}

export default taskService;