import React, { useState } from "react";
import { Dropdown } from "semantic-ui-react";
import { useHistory } from "react-router-dom";

import "../assets/css/components/moreoptions.css";

import MenuDotsVerticalIcon from "../assets/icons/jsxIcons/MenuDotsVerticalIcon";

import { Popup } from "../components/Popup";

import ds from "../services/DatasetService";

import { DownloadModelPopup } from "../views/popups/actions/DownloadModelPopup";
import { DownloadDatasetPopup } from "../views/popups/actions/DownloadDatasetPopup";
import { RenameCardsPopup } from "../views/popups/actions/RenameCardsPopup";
import ConfirmationPopup from "../views/popups/actions/ConfirmationPopup";
import { DeletePopup } from "../views/popups/actions/DeletePopup";
import { useDispatch } from "react-redux";
import LinkProject from "../views/popups/actions/LinkProject";
import modelService from "../services/ModelService";
import { setNeedUpdateProjectList } from "../store/slices/projects/projectsSlice";
import { useTranslation } from "react-i18next";
import "./../translation/i18n";
import { toast } from "react-toastify";
import handleErrorResponse from "../helpers/handleErrorResponse";
import { setOpenModalTask, setRunningTask } from "../store/slices/tasks/tasksSlice";
import { Button } from "./Button";

const trigger = (
   <span>
      <MenuDotsVerticalIcon style={{ size: 18 }} />
   </span>
);

export const MoreOptions = ({ cardtype, elementId, direction, projects }) => {
   const { t } = useTranslation();
   /** Pop-up de download do modelo */
   const [donwloadModelPopup, setDonwloadModelPopup] = useState(false);
   /** Pop-up de download de dataset */
   const [downloadDatasetPopup, setDownloadDatasetPopup] = useState(false);
   /** Pop-up para renomear */
   const [renamePopup, setRenamePopup] = useState(false);
   /** Pop-up de deletar*/
   const [deletePopup, setDeletePopup] = useState(false);
   /** Pop-up de deletar o compartilhamento */
   const [deleteSharingPopup, setDeleteSharingPopup] = useState(false);

   const [linkProjectPopup, setLinkProjectPopup] = useState(false);

   const [copyDatasetPopup, setCopyDatasetPopup] = useState(false);

   const [cloneDatasetPopup, setCloneDatasetPopup] = useState(false);

   const [isLoading, setIsLoading] = useState(false);

   const token = localStorage.getItem("user_token");

   const history = useHistory();

   const dispatch = useDispatch();

   const link = "components.moreoptions";

   const [renameType, setRenameType] = useState("");

   // array de opções contendo texto, key e ação ao clicar
   // pra executar uma ação, basta alterar o conteúdo de onClick
   const modelOptions = [
      {
         key: "renomear",
         text: t(`${link}.rename`),
         onClick: (e) => {
            e.preventDefault();
            setRenameType("rename");
            setRenamePopup(true);
         },
      },
      {
         key: "baixar",
         text: t(`${link}.download`),
         onClick: (e) => {
            e.preventDefault();
            setDonwloadModelPopup(true);
         },
      },
      {
         key: "excluir",
         text: t(`${link}.delete`),
         onClick: (e) => {
            e.preventDefault();
            setDeletePopup(true);
         },
      },
   ];

   const sharedModelOptions = [
      {
         key: "baixar",
         text: t(`${link}.download`),
         onClick: (e) => {
            e.preventDefault();
            setDonwloadModelPopup(true);
         },
      },
      {
         key: "excluir",
         text: t(`${link}.deleteshare`),
         onClick: (e) => {
            e.preventDefault();
            setDeleteSharingPopup(true);
         },
      },
   ];

   let datasetOptions = [
      {
         key: "vincular",
         text: t(`${link}.linkproject`),
         onClick: (e) => {
            e.preventDefault();
            setLinkProjectPopup(true);
         },
      },
      {
         key: "renomear",
         text: t(`${link}.rename`),
         onClick: (e) => {
            e.preventDefault();
            setRenameType("rename");
            setRenamePopup(true);
         },
      },
      {
         key: "baixar",
         text: t(`${link}.download`),
         onClick: (e) => {
            e.preventDefault();
            setDownloadDatasetPopup(true);
         },
      },
      {
         key: "copia",
         text: t(`${link}.copy`),
         onClick: (e) => {
            e.preventDefault();
            setCopyDatasetPopup(true);
         },
      },
      {
         key: "excluir",
         text: t(`${link}.delete`),
         onClick: (e) => {
            e.preventDefault();
            setDeletePopup(true);
         },
      },
   ];

   let datasetWisemlOptions = [
      {
         key: "clonar",
         text: t(`${link}.clone`),
         onClick: (e) => {
            e.preventDefault();
            setCloneDatasetPopup(true);
         },
      },
      {
         key: "baixar",
         text: t(`${link}.download`),
         onClick: (e) => {
            e.preventDefault();
            setDownloadDatasetPopup(true);
         },
      },
      {
         key: "excluir",
         text: t(`${link}.delete`),
         onClick: (e) => {
            e.preventDefault();
            setDeletePopup(true);
         },
      },
   ];

   const options = [
      {
         key: "description",
         text: t(`${link}.description`),
         onClick: (e) => {
            e.preventDefault();
            setRenameType("description");
            setRenamePopup(true);
         },
      },
      {
         key: "renomear",
         text: t(`${link}.rename`),
         onClick: (e) => {
            e.preventDefault();
            setRenameType("rename");
            setRenamePopup(true);
         },
      },
      {
         key: "excluir",
         text: t(`${link}.delete`),
         onClick: (e) => {
            e.preventDefault();
            setDeletePopup(true);
         },
      },
   ];

   function deleteSharing() {
      setIsLoading(true);
      modelService
         .deletemodel([elementId])
         .then(() => {
            dispatch(setNeedUpdateProjectList(true));
            setDeleteSharingPopup(false);
         })
         .catch((error) => {
            handleErrorResponse(history, error, t);
         })
         .finally(() => setIsLoading(false));
   }

   function cloneDataset() {
      setIsLoading(true);
      ds.copydatasource(elementId, t("popup.action.copydataproxy.copy"))
         .then(() => {
            toast.success(t("popup.action.copy_in_progress.message"));
            dispatch(setRunningTask(true));
         })
         .catch((error) => {
            handleErrorResponse(history, error, t);
         })
         .finally(() => {
            setCloneDatasetPopup(false);
            setIsLoading(false);
         });
   }

   const handleButtonClick = () => {
      dispatch(setOpenModalTask(true));
   };

   const copyData = () => {
      setIsLoading(true);
      if (cardtype === "dataset") {
         ds.copydataproxy(token, elementId, t("popup.action.copydataproxy.copy"))
            .then(() => {
               toast.info(
                  <div>
                     {t("popup.action.copy_in_progress.message")}
                     <Button className="btn--primary--solid" onClick={handleButtonClick}>
                        {t("history.type_button.type_1")}
                     </Button>
                  </div>,
               );
               dispatch(setRunningTask(true));
            })
            .catch((error) => {
               handleErrorResponse(history, error, t);
            })
            .finally(() => {
               setCopyDatasetPopup(false);
               setIsLoading(false);
            });
      } else {
         ds.copydatasource(elementId, t("popup.action.copydataproxy.copy"))
            .then(() => {
               toast.info(
                  <div>
                     {t("popup.action.copy_in_progress.message")}
                     <Button className="btn--primary--solid" onClick={handleButtonClick}>
                        {t("history.type_button.type_1")}
                     </Button>
                  </div>,
               );
               dispatch(setRunningTask(true));
            })
            .catch((error) => {
               handleErrorResponse(history, error, t);
            })
            .finally(() => {
               setCopyDatasetPopup(false);
               setIsLoading(false);
            });
      }
   };

   //caso seja dataset removera a opção de vincular projetos
   if (cardtype === "dataset") {
      datasetOptions.shift();
   }

   let dropdownOptions;

   switch (cardtype) {
      case "project":
         dropdownOptions = options;
         break;
      case "shared-model":
         dropdownOptions = sharedModelOptions;
         break;
      case "dataset":
      case "datasource":
         dropdownOptions = datasetOptions;
         break;
      case "datasourcewiseml":
         dropdownOptions = datasetWisemlOptions;
         break;
      default:
         dropdownOptions = modelOptions;
         break;
   }

   return (
      <div className="more-options">
         <Dropdown
            trigger={trigger}
            options={dropdownOptions}
            pointing={direction || "left"}
            icon={null}
            onClick={(e) => {
               // preventDefault faz com que clicar no componente filho não execute
               // a ação de clicar no componente pai, por isso que, ao clicar nos três
               // pontinhos de opções, não é executada a mesma ação de clicar em um card
               e.preventDefault();
            }}
         />
         <span
            onClick={(e) => {
               e.preventDefault();
            }}
         >
            <Popup trigger={donwloadModelPopup} setTrigger={setDonwloadModelPopup} enableCloseIcon={false}>
               <DownloadModelPopup setTrigger={setDonwloadModelPopup} modelResultId={elementId} />
            </Popup>

            <Popup trigger={downloadDatasetPopup} setTrigger={setDownloadDatasetPopup} enableCloseIcon={false}>
               <DownloadDatasetPopup
                  setTrigger={setDownloadDatasetPopup}
                  modelResultId={elementId}
                  cardType={cardtype}
               />
            </Popup>

            <Popup trigger={copyDatasetPopup} setTrigger={setCopyDatasetPopup} enableCloseIcon={false}>
               <ConfirmationPopup
                  title={t(`${link}.confirm.copydataset`)}
                  description={""}
                  confirmText={t(`${link}.confirm.yes`)}
                  denyText={t(`${link}.confirm.no`)}
                  isLoading={isLoading}
                  onConfirm={() => {
                     copyData();
                  }}
                  onDeny={() => setDeleteSharingPopup(false)}
               />
            </Popup>

            <Popup trigger={cloneDatasetPopup} setTrigger={setCloneDatasetPopup} enableCloseIcon={false}>
               <ConfirmationPopup
                  title={t(`${link}.confirm.clonedataset`)}
                  description={""}
                  confirmText={t(`${link}.confirm.yes`)}
                  denyText={t(`${link}.confirm.no`)}
                  isLoading={isLoading}
                  onConfirm={() => {
                     cloneDataset();
                  }}
                  onDeny={() => setCloneDatasetPopup(false)}
               />
            </Popup>

            <Popup trigger={renamePopup} setTrigger={setRenamePopup} enableCloseIcon={false}>
               <RenameCardsPopup
                  setTrigger={setRenamePopup}
                  modelResultId={elementId}
                  cardtype={cardtype}
                  renameType={renameType}
               />
            </Popup>

            <Popup trigger={deletePopup} setTrigger={setDeletePopup} enableCloseIcon={false}>
               <DeletePopup setTrigger={setDeletePopup} modelResultId={elementId} cardtype={cardtype} />
            </Popup>
            <Popup trigger={deleteSharingPopup} setTrigger={setDeleteSharingPopup} enableCloseIcon={false}>
               <ConfirmationPopup
                  title={t(`${link}.confirm.removeshare`)}
                  description={""}
                  confirmText={t(`${link}.confirm.yes`)}
                  denyText={t(`${link}.confirm.no`)}
                  onConfirm={() => {
                     deleteSharing();
                  }}
                  isLoading={isLoading}
                  onDeny={() => setDeleteSharingPopup(false)}
               />
            </Popup>
            <Popup trigger={linkProjectPopup} setTrigger={setLinkProjectPopup} enableCloseIcon={true}>
               <LinkProject dataID={elementId} projects={projects} setTrigger={setLinkProjectPopup} />
            </Popup>
         </span>
      </div>
   );
};
