import { useState, useEffect } from "react";

import FileIcon from "../../assets/icons/jsxIcons/FileIcon";
import DecisionTreeIcon from "../../assets/icons/jsxIcons/DecisionTreeIcon";
import WandIcon from "../../assets/icons/jsxIcons/WandIcon";
import ArrowDownFilledIcon from "../../assets/icons/jsxIcons/ArrowDownFilledIcon";
import ArrowRightFilledIcon from "../../assets/icons/jsxIcons/ArrowRightFilledIcon";
import { Popup } from "../../components/Popup";
import InputSlider from "../../components/Slider";
import LoadingWheel from "../../components/LoadingWheel";
import { Checkbox } from "../../components/Checkbox";
import { setNewNotification } from "../../store/slices/notification/notificationSlice";

import { DecisionTreeD3 } from "../../graphicsComponents/modelsRepresentations/DecisionTreeD3";

import ws from "../../services/wiselib";
import modelService from "../../services/ModelService";

import dp from "../../helpers/data_parse";

import { TreeImportancePopup } from "../popups/informations/TreeImportancePopup";
import Prediction from "../popups/predictions/Prediction";
import { MetricsPopup } from "../popups/informations/MetricsPopup";
import Dropdown from "../../components/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { setRunningTask } from "../../store/slices/tasks/tasksSlice";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { selectUrl } from "../../store/slices/url/urlSlice";
import TargetModel from "../../components/TargetModel";
import SaveStatus from "../../components/SaveStatus";
import { setModelsResult } from "../../store/slices/cache/cacheSlice";
import { setNeedUpdateProjectList } from "../../store/slices/projects/projectsSlice";
import ModeTabs from "../../components/ModeTabs";
import { selectMainAttribute } from "../../store/slices/mainAttribute/mainAttributeSlice";

import { useTranslation } from "react-i18next";
import "./../../translation/i18n";
import handleErrorResponse from "../../helpers/handleErrorResponse";
import CustomTooltipWrapper from "../../components/CustomTooltip";
import { MdOutlineFileOpen } from "react-icons/md";

export const DecisionTree = ({
   pageId,
   setGeneratedPredictionsPopup,
   optimizeAlg,
   model,
   setModel,
   setIsCreated,
   isCreated,
   dataSetExists,
   setDataSetExists,
}) => {
   const mainAttribute = useSelector(selectMainAttribute);
   const [readOnly, setReadOnly] = useState(isCreated);

   const { t } = useTranslation();

   /** Percentual de treinamento do algoritmo */
   const [percentualTreinamento, setPercentualTreinamento] = useState(75);
   /** Quantidade de caminhos da árvore */
   const [qtdCaminhos, setQtdCaminhos] = useState(32);
   /** Quantidade de níveis da árvore */
   const [qtdNiveis, setQtdNiveis] = useState(5);
   /** Bool que define se a task está carregando */
   const [taskIsLoading, setTaskIsLoading] = useState(false);
   /** Progresso atual do resultado */
   const [progress, setProgress] = useState(null);
   /** Caminho atual selecionado na árvore */
   const [caminhoAtual, setCaminhoAtual] = useState([]);
   /** Nó atual selecinado na árvore */
   const [selectedNode, setSelectedNode] = useState(null);
   /** Pop-up de importância dos campos */
   const [importancePopup, setImportancePopup] = useState(false);
   /** Pop-up de predições */
   const [predictionPopup, setPredictionPopup] = useState(false);
   /** Opção de otmização */
   const [optimizeAlgorithm, setOptimizeAlgorithm] = useState(optimizeAlg);
   // Estado para rastrear a expansão de cada node
   const [expandStates, setExpandStates] = useState(Array(caminhoAtual.length).fill(false));
   /** Opção de arvore de classificação */
   const [isRegressionTree, setIsRegressionTree] = useState(false);
   /** Opção de crossValidation */
   const [crossValidation, setCrossValidation] = useState(false);

   /** Pop-up de metricas */
   const [metricsPopup, setMetricsPopup] = useState(false);

   const [selectedMLFramework, setSelectedMLFramework] = useState("spark");

   const frameworkOptions = [
      { label: "Spark", value: "spark" },
      { label: "Scikit-learn", value: "sklearn" },
   ];

   const { parameters } = model;

   const [treeContent, setTreeContent] = useState([]);

   const dispatch = useDispatch();
   const history = useHistory();

   const url = useSelector(selectUrl);

   const taskList = useSelector((state) => state.tasks.taskList);
   const [taskId, setTaskId] = useState("");

   useEffect(() => {
      if (taskId) {
         let task = taskList.filter((task) => task.task === taskId)[0];
         if (task) {
            setProgress(task.progress);
         } else if (progress <= 100) {
            setProgress(100);
            setTaskIsLoading(false);
            modelService
               .getmodel(taskId)
               .then((data) => {
                  setTaskId("");
                  dispatch(setModelsResult(data));
                  dispatch(setNeedUpdateProjectList(true));
                  dispatch(setNewNotification(true));
                  history.push(`/model/${data.id}`);
               })
               .catch((error) => {
                  handleErrorResponse(history, error, t);
               });
         }
      }
      // eslint-disable-next-line
   }, [taskList]);

   useEffect(() => {
      if (model?.id) {
         setIsCreated(true);
         setReadOnly(true);

         if (url.includes("/model/create")) {
            history.push(`/model/${model?.id}`);
         }
         setTreeContent(
            <DecisionTreeD3
               result={model?.result}
               caminhoAtual={caminhoAtual}
               setCaminhoAtual={setCaminhoAtual}
               selectedNode={selectedNode}
               setSelectedNode={setSelectedNode}
               fieldList={model?.fields?.usedFields}
            />,
         );
      } else {
         setIsCreated(false);
      }

      // eslint-disable-next-line
   }, [model]);

   // Função para alternar o estado de expansão de um node específico
   const onHandleExpand = (index) => {
      const newExpandStates = [...expandStates];
      newExpandStates[index] = !newExpandStates[index]; // Alterna o estado do item clicado
      setExpandStates(newExpandStates);
   };

   const executeAlgorithm = () => {
      setTreeContent([]);
      if (!taskIsLoading) {
         setIsCreated(false);
         setTaskIsLoading(true);
         setProgress(0);
         setModel({
            ...model,
            id: "",
            name: "",
            saved: false,
            sharedWith: [],
            result: null,
            metrics: "",
            significantFields: null,
            hasPrediction: false,
         });
         ws.decisiontree(
            model?.name,
            model?.datasetId,
            percentualTreinamento,
            qtdCaminhos,
            qtdNiveis,
            mainAttribute,
            model?.fields?.usedFields.toString(),
            optimizeAlgorithm,
            crossValidation,
            isRegressionTree,
            selectedMLFramework,
         )
            .then((id) => {
               //localhost:3000/history
               dispatch(setRunningTask(true));
               setTaskId(id);
            })
            .catch((error) => {
               setTaskIsLoading(false);
               handleErrorResponse(history, error, t);
            });
      }
   };

   // Função para renderizar os thresholds
   const renderThreshold = (node, index) => {
      if (Array.isArray(node.Threshold)) {
         return node.Threshold.map((value, idx) =>
            idx === 0 ? (
               <div id="info-array" key={`threshold-${value}`}>
                  {dp.removeDecimals(parseFloat(value))}
                  {node.Threshold.length > 1 && (
                     <span onClick={() => onHandleExpand(index)} tabIndex={0} onKeyPress={() => onHandleExpand(index)}>
                        {!expandStates[index] ? <ArrowRightFilledIcon /> : <ArrowDownFilledIcon />}
                     </span>
                  )}
               </div>
            ) : (
               expandStates[index] && (
                  <div id="info-array" key={`threshold-${value}`}>
                     {dp.removeDecimals(parseFloat(value))}
                  </div>
               )
            ),
         );
      } else {
         return isNaN(parseFloat(node.Threshold)) ? node.Threshold : dp.removeDecimals(parseFloat(node.Threshold));
      }
   };

   const isShared = model?.sharedWith && model?.sharedWith.length > 0;

   return (
      <div className="model-algorithm">
         <div className="main-algorithm">
            <div className="left">
               <header className="header-algorithm">
                  <div className="name">
                     <DecisionTreeIcon />
                     <p id="algorithm-name">{t("model.decisiontree.name")}</p>
                  </div>
                  <div className="name">
                     {isShared || !dataSetExists ? (
                        <FileIcon />
                     ) : (
                        <MdOutlineFileOpen size={24} />
                     )}
                     {!isShared && dataSetExists ? (
                        <button
                           onClick={() => {
                              history.push(`/dataset/${model?.datasetId}`);
                           }}
                           id="model-dataset"
                        >
                           {model?.datasetName}
                        </button>
                     ) : (
                        <p id="model-dataset">{model?.datasetName}</p>
                     )}
                  </div>
                  {isCreated && <ModeTabs readOnly={readOnly} setReadOnly={setReadOnly} owner={model?.owner} />}
                  <TargetModel
                     readOnly={readOnly}
                     model={model}
                     dataSetExists={dataSetExists}
                     setDataSetExists={setDataSetExists}
                     nameAlgorithm={"Decision Tree"}
                  />
               </header>
               <div className="model-hyperparameters">
                  <div className="model-slider">
                     <CustomTooltipWrapper title={t("model.decisiontree.slider_1.info")}>
                        <p id="training-split-heading">{t("model.decisiontree.slider_1")}</p>
                     </CustomTooltipWrapper>
                     <InputSlider
                        min={0}
                        max={100}
                        currentValue={readOnly ? parameters?.TrainFraction * 100 : percentualTreinamento}
                        setCurrentValue={setPercentualTreinamento}
                        isPercentage={true}
                        disabled={readOnly}
                        id="training"
                     />
                  </div>
                  <div className="model-slider">
                     <CustomTooltipWrapper title={t("model.decisiontree.slider_2.info")}>
                        <p id="max-bins-heading">{t("model.decisiontree.slider_2")}</p>
                     </CustomTooltipWrapper>
                     <InputSlider
                        min={4}
                        max={100}
                        currentValue={readOnly ? parameters?.MaxBins : qtdCaminhos}
                        setCurrentValue={setQtdCaminhos}
                        disabled={readOnly}
                        id="max-bins"
                     />
                  </div>
                  <div className="model-slider">
                     <CustomTooltipWrapper title={t("model.decisiontree.slider_3.info")}>
                        <p id="max-depth-heading">{t("model.decisiontree.slider_3")}</p>
                     </CustomTooltipWrapper>

                     <InputSlider
                        min={4}
                        max={16}
                        currentValue={readOnly ? parameters?.MaxDepth : qtdNiveis}
                        setCurrentValue={setQtdNiveis}
                        disabled={readOnly}
                        id="max-depth"
                     />
                  </div>
                  <div className="model-checkbox">
                     <Checkbox
                        defaultValue={false}
                        disabled={taskIsLoading || crossValidation || readOnly}
                        onChange={() => {
                           if (crossValidation === false) setOptimizeAlgorithm(!optimizeAlgorithm);
                        }}
                     />
                     <p>{t("model.decisiontree.checkbox_1")}</p>
                  </div>
                  <div className="model-checkbox">
                     <Checkbox
                        defaultValue={false}
                        disabled={taskIsLoading || optimizeAlgorithm || readOnly}
                        onChange={() => {
                           if (optimizeAlgorithm === false) setCrossValidation(!crossValidation);
                        }}
                     />
                     <p>{t("model.decisiontree.checkbox_2")}</p>
                  </div>
                  <div className="model-checkbox">
                     <Checkbox
                        defaultValue={false}
                        disabled={taskIsLoading || readOnly}
                        onChange={() => {
                           setIsRegressionTree(!isRegressionTree);
                        }}
                     />
                     <p>{t("model.decisiontree.checkbox_3")}</p>
                  </div>
                  <div className="model-dropdown">
                     <p>{t("model.backend")}</p>
                     <Dropdown
                        defaultValue={0}
                        disabled={readOnly}
                        onChange={setSelectedMLFramework}
                        options={frameworkOptions}
                     />
                  </div>
               </div>

               {!readOnly && (
                  <div className="model-execute">
                     <button
                        className={dataSetExists ? "" : "btn--disabled--solid"}
                        disabled={taskIsLoading || !dataSetExists}
                        onClick={executeAlgorithm}
                     >
                        {t("model.execute")}
                     </button>
                  </div>
               )}
            </div>

            <div className="model-result-1">
               {taskIsLoading && <LoadingWheel progress={progress} loadingSize="large" />}
               {isCreated && !model?.saved && <SaveStatus />}
               {treeContent}
            </div>
            <div className="model-info">
               {caminhoAtual.map((node, index) => {
                  let next = caminhoAtual[index + 1];
                  return (
                     <div id="info" key={node.feature}>
                        <span id="nameField" title={node.Feature}>
                           {node.Feature}
                        </span>
                        {next?.Side !== undefined &&
                           caminhoAtual.length > 1 &&
                           (next?.Side === "left" ? (
                              <span id="operation" title={node.Operation[0]}>
                                 {node.Operation[0]}
                              </span>
                           ) : (
                              <span id="operation" title={node.Operation[1]}>
                                 {node.Operation[1]}
                              </span>
                           ))}
                        <span id="value" title={node.Threshold}>
                           {renderThreshold(node, index)}
                        </span>
                     </div>
                  );
               })}
            </div>
         </div>
         {model?.id && (
            <div className="buttons-bottom-algorithm">
               {model?.isCategorical && (
                  <button
                     onClick={() => {
                        setMetricsPopup(true);
                     }}
                  >
                     <p>{t("model.footer.button_1")}</p>
                  </button>
               )}
               <button
                  onClick={() => {
                     setImportancePopup(true);
                  }}
               >
                  <p>{t("model.footer.button_2")}</p>
               </button>
               {model?.hasPrediction && (
                  <button
                     onClick={() => {
                        setGeneratedPredictionsPopup(true);
                     }}
                  >
                     <p>{t("model.footer.button_3")}</p>
                  </button>
               )}
               <button
                  id="fill"
                  onClick={() => {
                     if (model?.id) setPredictionPopup(true);
                  }}
               >
                  <WandIcon />
                  <p>{t("model.footer.button_4")}</p>
               </button>
            </div>
         )}

         <Popup trigger={importancePopup} setTrigger={setImportancePopup}>
            <TreeImportancePopup
               setTrigger={setImportancePopup}
               modelResult={model?.result}
               modelName={model?.name}
               fieldList={model?.fields?.usedFields}
               significances={model?.significantFields}
            />
         </Popup>
         <Popup trigger={metricsPopup} setTrigger={setMetricsPopup}>
            <MetricsPopup
               data={{
                  metrics: model?.metrics,
                  framework: model?.framework,
                  type: model?.type,
               }}
            />
         </Popup>

         <Popup trigger={predictionPopup} setTrigger={setPredictionPopup}>
            <Prediction
               targetName={model?.target}
               fields={model?.fields}
               modelId={model?.id}
               algorithmType={model?.type}
            />
         </Popup>
      </div>
   );
};
