import React from "react";

const StyledParagraph = ({ children, icon: Icon, small = false }) => {
   if (small) {
      return (
         <small
            style={{
               textAlign: "justify",
               display: "flex",
               alignItems: "center",
            }}
         >
            {Icon && <Icon style={{ marginRight: 8, fontSize: "1.4em" }} />}
            {children}
         </small>
      );
   } else {
      return (
         <p
            style={{
               textAlign: "justify",
               display: "flex",
               alignItems: "center",
            }}
         >
            {Icon && <Icon style={{ marginRight: 8 }} />}
            {children}
         </p>
      );
   }
};

export default StyledParagraph;
