import React from "react";
import { Tooltip, withStyles } from "@material-ui/core";

const CustomTooltip = withStyles({
   tooltip: {
      backgroundColor: "#333",
      color: "white",
      boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.3)",
      fontSize: 14,
      fontWeight: "bold",
      fontFamily: "Montserrat, sans-serif",
      padding: "8px 16px",
      maxWidth: 300,
      textAlign: "justify",
   },
   arrow: {
      color: "#333",
   },
})(Tooltip);

const CustomTooltipWrapper = ({ title, children, placement = "top" }) => {
   return (
      <CustomTooltip title={title} placement={placement} arrow>
         {children}
      </CustomTooltip>
   );
};

export default CustomTooltipWrapper;
