import JSZip from "jszip";
import http from "../http-common";

/*
    Endpoint de upload de DATASOURCE (que é chamado na página de Meus datasets)
    e retorna o progresso da task.  
*/
function uploaddatasource(file, ID_List) {
   let zip = new JSZip();
   zip.file(file.name, file);
   // zipa o arquivo usando o nome dele (file.name)
   return zip.generateAsync({ type: "blob" }).then(function (content) {
      // cria a estrutura da requisição em formData
      let formData = new FormData();
      var zipfile = new File([content], "data.zip");
      // adiciona o arquivo, o token e o project id ao formData
      formData.append("file", zipfile);
      formData.append("token", localStorage.getItem("user_token"));
      formData.append("prjid", ID_List);

      /*
            faz a requisição de upload de datasetSource com a função onUploadProgress
            como parâmetro, enviada na requisição e atualizada de acordo com
            os retornos de progresso
        */
      return http.post("/uploaddatasource", formData).then((response) => {
         let task = response?.data;
         return task;
      });
   });
}

/*
    Endpoint de upload de DATASET do WISEML(que é chamado na página de Meus datasets)
    e retorna o progresso da task.  
*/
function uploaddatasourcewiseml(file) {
   let zip = new JSZip();
   zip.file(file.name, file);
   // zipa o arquivo usando o nome dele (file.name)
   return zip.generateAsync({ type: "blob" }).then(function (content) {
      // cria a estrutura da requisição em formData
      let formData = new FormData();
      var zipfile = new File([content], "data.zip");
      // adiciona o arquivo, o token e o project id ao formData
      formData.append("file", zipfile);
      formData.append("token", localStorage.getItem("user_token"));

      /*
            faz a requisição de upload de datasetSource com a função onUploadProgress
            como parâmetro, enviada na requisição e atualizada de acordo com
            os retornos de progresso
        */
      return http.post("/uploaddatasourcewiseml", formData).then((response) => {
         return response.data;
      });
   });
}

/*
    Enpoint que vincula o DataSource a um ou mais projetos do usuário 
*/
function linkonedatasource(ID_DataSource, ID_ListProjects) {
   return http
      .post("/linkonedatasource", {
         token: localStorage.getItem("user_token"),
         id: ID_DataSource,
         options: ID_ListProjects,
      })
      .then((response) => {
         return response.data;
      });
}

/*
    Endpoint que vincula o projeto a um ou mais DataSources (na verdade, 
    é feito uma cópia do DataSource, isto é, de um DataSource para um DataProxy)
*/
function linkmanydatasources(ID_Project, ID_DataSources) {
   return http
      .post("/linkmanydatasources", {
         token: localStorage.getItem("user_token"),
         id: ID_Project,
         options: ID_DataSources,
      })
      .then((response) => {
         return response.data;
      });
}

/*
    Faz uma cópia do DataProxy
*/
function copydataproxy(token, ID_DataProxy, copy) {
   return http
      .post("/copydataproxy", {
         token: token,
         id: ID_DataProxy,
         option: copy
      })
      .then((response) => {
         return response;
      });
}
/*
    Faz uma cópia do DataSource
*/
function copydatasource(id, copy) {
   return http
      .post("/copydatasource", {
         token: localStorage.getItem("user_token"),
         id: id,
         option: copy
      })
      .then((response) => {
         return response;
      });
}

function getdataproxy(id) {
   return http
      .get(`/getdataset/${localStorage.getItem("user_token")}/${id}`)
      .then((response) => {
         return response?.data;
      });
}

/*
    Dado um token válido e o id do projeto do usuário, obtém
    uma lista de DataProxy do projeto específico do usuário
*/
function getproxylist(token, ID_Project) {
   return http
      .post("/getproxylist", {
         token: token,
         id: ID_Project,
      })
      .then((response) => {
         return response?.data;
      });
}

/*
    Dado um token válido e o id do usuário, obtém todos os
    DataSoucers do usuário
*/
function getsourcelist() {
   return http
      .get(`/getsourcelist/${localStorage.getItem("user_token")}`)
      .then((response) => {
         return response.data;
      });
}

/*
    Dado um token válido, obtém todos os
    DataSoucers disponibilizados pelo wiseml
*/
function getsourcelistwiseml(token) {
   return http
      .post("/getsourcelistwiseml", {
         token: token,
      })
      .then((response) => {
         return response?.data;
      });
}

function importdatasourcesql(
   datasource //object
) {
   return http
      .post("/importdatasourcesql", {
         token: localStorage.getItem("user_token"),
         prjids: datasource?.projectID,
         connectionString: datasource?.connectionString,
         databaseHost: datasource?.databaseHost,
         databasePort: datasource?.databasePort,
         databaseUserName: datasource?.databaseUserName,
         databaseUserPassword: datasource?.databaseUserPassword,
         databaseTargetSchema: datasource?.databaseTargetSchema,
         databaseTargetTable: datasource?.databaseTargetTable,
         databaseType: datasource?.databaseType,
      })
      .then((response) => {
         let task = response?.data;
         return task;
      });
}

function importdatasourcemongo(
   datasource //object
) {
   return http
      .post("/importdatasourcemongo", {
         token: localStorage.getItem("user_token"),
         prjid: datasource?.projectID,
         connectionString: datasource?.connectionString,
         targetDatabase: datasource?.targetDatabase,
         targetCollection: datasource?.targetCollection,
      })
      .then((response) => {
         return response.data;
      });
}

function getdatasource(id) {
   return http
      .get(`/getdatasource/${localStorage.getItem("user_token")}/${id}`)
      .then((response) => {
         return response.data;
      });
}

function getimagesource(imageid, datasetid) {
   return http
      .get(
         `/imagesource/${imageid}/${localStorage.getItem(
            "user_token"
         )}/${datasetid}`
      )
      .then((response) => {
         return response.data;
      });
}

function getimageproxy(imageid, datasetid) {
   return http
      .get(
         `/imageproxy/${imageid}/${localStorage.getItem(
            "user_token"
         )}/${datasetid}`
      )
      .then((response) => {
         return response.data;
      });
}

// endpoint de excluimento de dataset
function deletedataset(id) {
   const token = localStorage.getItem("user_token");
   return http
      .request({
         method: "delete",
         url: "/deletedataset",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}}`,
         },
         data: { token: token, id: id },
      })
      .then((response) => response.data);
}

function deletedatasource(id) {
   const token = localStorage.getItem("user_token");
   return http
      .request({
         method: "delete",
         url: "/deletedatasource",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}}`,
         },
         data: { token: token, id: id },
      })
      .then((response) => response.data);
}

function deletemanydatasources(id) {
   const token = localStorage.getItem("user_token");
   return http
      .post("/deletemanydatasource", {
         token: token,
         options: id,
      })
      .then((response) => {
         return response;
      });
}
function deletemanydatasets(id) {
   const token = localStorage.getItem("user_token");
   return http
      .post("/deletemanydataset", {
         token: token,
         options: id,
      })
      .then((response) => {
         return response;
      });
}

function renamedataset(id, option) {
   return http
      .patch("/renamedataset", {
         token: localStorage.getItem("user_token"),
         id: id,
         option: option,
      })
      .then((response) => {
         return response?.data;
      });
}

function renamedatasource(id, option) {
   return http
      .patch("/renamedatasource", {
         token: localStorage.getItem("user_token"),
         id: id,
         option: option,
      })
      .then((response) => {
         return response?.data;
      });
}

function changeDataProxyCell(datasetId, rowId, cellName, newValue) {
   return http
      .patch("/changeProxyCell", {
         token: localStorage.getItem("user_token"),
         datasetId: datasetId,
         rowId: rowId,
         cellName: cellName,
         newValue: newValue,
      })
      .then((response) => {
         return response?.data;
      });
}

function changeDataSourceCell(datasetId, rowId, cellName, newValue) {
   return http
      .patch("/changeSourceCell", {
         token: localStorage.getItem("user_token"),
         datasetId: datasetId,
         rowId: rowId,
         cellName: cellName,
         newValue: newValue,
      })
      .then((response) => {
         return response?.data;
      });
}

function fillColumn(
   datasetId,
   columnName,
   columnType,
   targetValue,
   method,
   newValue
) {
   return http
      .patch("/fillColumn", {
         token: localStorage.getItem("user_token"),
         datasetId: datasetId,
         columnName: columnName,
         columnType: columnType,
         targetValue: targetValue,
         method: method,
         newValue: newValue,
      })
      .then((response) => {
         return response?.data;
      });
}

function fillSourceColumn(
   datasetId,
   columnName,
   columnType,
   targetValue,
   method,
   newValue
) {
   return http
      .patch("/fillSourceColumn", {
         token: localStorage.getItem("user_token"),
         datasetId: datasetId,
         columnName: columnName,
         columnType: columnType,
         targetValue: targetValue,
         method: method,
         newValue: newValue,
      })
      .then((response) => {
         return response?.data;
      });
}

const datasetService = {
   uploaddatasource,
   uploaddatasourcewiseml,
   linkonedatasource,
   linkmanydatasources,
   copydataproxy,
   copydatasource,
   getdataproxy,
   getproxylist,
   getsourcelist,
   importdatasourcesql,
   importdatasourcemongo,
   getsourcelistwiseml,
   getdatasource,
   getimagesource,
   getimageproxy,
   deletedataset,
   deletedatasource,
   deletemanydatasources,
   deletemanydatasets,
   renamedataset,
   renamedatasource,
   changeDataProxyCell,
   changeDataSourceCell,
   fillColumn,
   fillSourceColumn,
};

export default datasetService;
