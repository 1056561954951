import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

import { useTranslation } from "react-i18next"
import "./../../translation/i18n";

function MultilayerPerceptronD3({datas, framework}){

    const {t} = useTranslation();

    let output = 1;
    datas.nodes.forEach(node => {if(node.layer > output) output = node.layer});

    const d3Chart = useRef(null);

    function layout(width, height) {
        let lsz = datas.nodes.reduce((a, i) => {
          a[i.layer] = a[i.layer] ? a[i.layer] + 1 : 1;
          return a;
        }, {});
        let horz = d3
          .scaleLinear()
          .domain([0, Math.max(...datas.nodes.map((n) => n.layer))])
          .range([0, width]);
    
        const pos = (id) => {
            const node = datas.nodes.find((e) => e.id === id);
            return {
                x: horz(node.layer),
                y: d3
                    .scaleLinear()
                    .domain([0, lsz[node.layer] + 1])
                    .range([0, height])(node.nr),
            };
        };
    
        return pos;
    }

    useEffect(() => {
        const margin = {top: 0, right: 0, bottom: 0, left: 0};
        const width = parseInt(d3.select(".model-result-2").style("width"), 10) - margin.left - margin.right;
        const height = parseInt(d3.select(".model-result-2").style("height"), 10) - margin.top - margin.bottom;

        const svg = d3
            .select(d3Chart.current)
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        let pos = layout(width, height);

        let thick = d3.scaleLinear().domain([Math.min(...datas?.edges?.map(e => e.w)), 0, Math.max(...datas?.edges?.map(e => e.w))]).range([2,2,2])
        let color = d3.scaleLinear().domain([Math.min(...datas?.edges?.map(e => e.w)), 0, Math.max(...datas?.edges?.map(e => e.w))]).range(['rgba(2, 62, 138, 0.20)', 'rgba(2, 62, 138, 0.50)', 'rgba(2, 62, 138, 0.65)', 'rgba(2, 62, 138, 0.85)', '#023E8A'])

        // Determine the bounds of the graph
        const allX = datas.nodes.map((node) => pos(node.id).x);
        const allY = datas.nodes.map((node) => pos(node.id).y);
        const minX = Math.min(...allX);
        const maxX = Math.max(...allX);
        const minY = Math.min(...allY);
        const maxY = Math.max(...allY);

        // Calculate translation to center the graph
        const translateX = (width - (maxX - minX)) / 2 - minX;
        const translateY = (height - (maxY - minY)) / 2 - minY;

        const graphGroup = svg
        .append("g")
        .attr("transform", `translate(${translateX},${translateY})`);

        if(framework === "sklearn"){
            graphGroup
                .selectAll(".line")
                .data(datas?.edges)
                .enter().append("line")
                .attr("x1", d => pos(d.s).x)    
                .attr("y1", d => pos(d.s).y)
                .attr("x2", d => pos(d.t).x)
                .attr("y2", d => pos(d.t).y)
                .attr("stroke-width", d => thick(d.w))
                .attr("stroke", d => color(d.w))
                .attr("stroke-opacity", 0.6)
                .append("title").text(d => `${t("model.multilayerperceptron.weight")} ${d.w.toFixed(5)}`);
        } else {
            graphGroup
                .selectAll(".line")
                .data(datas?.edges)
                .enter().append("line")
                .attr("x1", d => pos(d.s).x)    
                .attr("y1", d => pos(d.s).y)
                .attr("x2", d => pos(d.t).x)
                .attr("y2", d => pos(d.t).y)
                .attr("stroke-width", d => thick(d.w))
                .attr("stroke", d => color(d.w))
                .attr("stroke-opacity", 0.6)
        }
        
        graphGroup
            .selectAll(".node")
            .data(datas?.nodes )
            .enter().append("circle")
                .attr("id", (d => `n-${d.layer}-${d.id}`))
                .attr("cx", d => pos(d.id).x)
                .attr("cy", d => pos(d.id).y)
                .attr("r", window.innerWidth < 500 ? 15 : 25)
                .attr("fill", d => { return (d.layer === 1 || d.layer === output) ? "#D9D9D9" : "#22577A"})
        // eslint-disable-next-line 
    }, []);

    return <svg ref={d3Chart}></svg>;
}

export default MultilayerPerceptronD3;