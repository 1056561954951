import React from "react";

import "../../../assets/css/views/home/start_page.css";

import { useTranslation } from "react-i18next";
import "../../../translation/i18n";
import { AuthCard } from "./AuthCard";

export const StartPage = () => {
   const { t } = useTranslation();

   return (
      <div className="container-startpage">
         <div className="container-logo">
            <img
               src={require("../../../assets/images/logo.svg").default}
               alt="logo WiseML"
            />
            <article>
               <p>Machine</p>
               <p>Learning</p>
               <p>{t("home.startpage.title")}</p>
            </article>
         </div>
         <div className="container-login">
            <AuthCard />
         </div>
      </div>
   );
};
