import "../../../assets/css/views/popups/messages/messagepopup.css";

import { Button } from "../../../components/Button";


export const MessagePopup = ({ title, description, setTrigger }) => {

  return (
    <div className="mensagem-popup">
      <div className="title">
        {title}
        <div id="spacer">
        </div>
      </div>
      <div id="description">{description}</div>
      <div className="buttons">
        <span id="btn">
          <Button
            type="button"
            buttonStyle="btn--primary--outline"
            buttonSize="btn--auto"
            onClick={() => {
              setTrigger(false)
              // confirmar delete
              // chamar task de cancelar processo do backend
            }}
          >
            OK
          </Button>
        </span>

      </div>
    </div >
  );
};
