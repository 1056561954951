import { useEffect, useState, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import "./../translation/i18n";
import "./../assets/css/components/selectlanguage.css";

import CheckIcon from "../assets/icons/jsxIcons/CheckIcon";
import { GrLanguage } from "react-icons/gr";

import flagES from "./../assets/icons/svgIcons/flagES.svg";
import flagFR from "./../assets/icons/svgIcons/flagFR.svg";
import flagBR from "./../assets/icons/svgIcons/flagBR.svg";
import flagUS from "./../assets/icons/svgIcons/flagUS.svg";
import flagPT from "./../assets/images/flagPT.png";
import { t } from "i18next";

function SelectLanguage({
    titleShow,
    vertical,
    horizontal,
    space = 10,
    horizontalFill
}) {
   const { i18n } = useTranslation();
   const [selectLng, setSelectLng] = useState({ src: "", value: "" });
   const refOne = useRef(null);

   const language = useMemo(
      () => [
         { name: "pt-BR", value: "pt_br", src: flagBR },
         { name: "pt-PT", value: "pt_pt", src: flagPT },
         { name: "eu-US", value: "en_us", src: flagUS },
         { name: "es-ES", value: "es_es", src: flagES },
         { name: "fr-FR", value: "fr_fr", src: flagFR },
      ],
      []
   );

    useEffect(() => {
        const lng = localStorage.getItem("language");

        switch (lng) {
            case "en_us":
                setSelectLng({ value: "en_us", src: flagUS });
                break;
            case "es_es":
                setSelectLng({ value: "es_es", src: flagES });
                break;
            case "fr_fr":
                setSelectLng({ value: "fr_fr", src: flagFR });
                break;
            case "pt_pt":
                setSelectLng({ value: "pt_pt", src: flagPT });
                break;
            default:
                setSelectLng({ value: "pt_br", src: flagBR });
                break;
        }
        // eslint-disable-next-line
    }, []);

    function verticalCenter() {
        const select = document.getElementById("select-language");
        const coordSelect = document
            .getElementById("select-language")
            ?.getBoundingClientRect();
        const coordContainer = document
            .getElementById("container-language")
            ?.getBoundingClientRect();

        select.style.top = `${coordContainer.y + coordContainer.height / 2 - coordSelect.height / 2}px`;
    }

    function verticalBottom() {
        const select = document.getElementById("select-language");
        const coordContainer = document
            .getElementById("container-language")
            ?.getBoundingClientRect();

        select.style.top = `${coordContainer.y + coordContainer.height  + space}px`;
    }

    function verticalTop() {
        const select = document.getElementById("select-language");
        const coordSelect = document
            .getElementById("select-language")
            ?.getBoundingClientRect();
        const coordContainer = document
            .getElementById("container-language")
            ?.getBoundingClientRect();

        select.style.top = `${coordContainer.y - coordSelect.height  - space}px`;
    }

    function horizontalRight(){
        const select = document.getElementById("select-language");
        const coordContainer = document
            .getElementById("container-language")
            ?.getBoundingClientRect();

        let x = coordContainer.x;
        if(!horizontalFill){
            x += coordContainer.width + space;
        }
        select.style.left = `${x}px`;
    }

    function horizontalLeft(){
        const select = document.getElementById("select-language");
        const coordSelect = document
            .getElementById("select-language")
            ?.getBoundingClientRect();
        const coordContainer = document
            .getElementById("container-language")
            ?.getBoundingClientRect();

        let x = 0;
        if(horizontalFill){
            x = coordContainer.x + coordContainer.width - coordSelect.width;
        } else {
            x = coordContainer.x - coordSelect.width - space;
        }

        select.style.left = `${x}px`;
    }

    function horizontalCenter(){
        const select = document.getElementById("select-language");
        const coordSelect = document
            .getElementById("select-language")
            ?.getBoundingClientRect();
        const coordContainer = document
            .getElementById("container-language")
            ?.getBoundingClientRect();

        select.style.left = `${coordContainer.x + coordContainer.width / 2 - coordSelect.width / 2}px`;
    }

    const handleSelect = () => {
        const containerSelect = document.querySelectorAll(
            ".select-language"
        )[0];

        if (containerSelect.style.display === "flex") {
            containerSelect.style.display = "none";
        } else {
            containerSelect.style.display = "flex";
            switch (vertical) {
                case "top":
                    verticalTop();
                    break;
                case "bottom":
                    verticalBottom();
                    break;
                default:
                    verticalCenter();
                    break;
            }

            switch (horizontal) {
                case "right":
                    horizontalRight();
                    break;
                case "left":
                    horizontalLeft();
                    break;
                default:
                    horizontalCenter();
                    break;
            }
        }
    };

   useEffect(() => {
      const handleClickOutside = (e) => {
         if (refOne.current && !refOne.current.contains(e.target)) {
            const containerSelect =
               document.querySelectorAll(".select-language")[0];
            containerSelect.style.display = "none";
         }
      };
      document.addEventListener("click", handleClickOutside, true);
      return () => {
         document.removeEventListener("click", handleClickOutside, true);
      };
   }, []);

   const handleChangeLanguage = (object) => {
      i18n.changeLanguage(object.value);
      setSelectLng({ src: object.src, value: object.value });
      localStorage.setItem("selectedLanguage", object.value);
      const containerSelect = document.querySelectorAll(".select-language")[0];
      containerSelect.style.display = "none";
   };

   const link = "components.selectlanguage";

    return (
        <>
            <div
                id="container-language"
                onClick={handleSelect}
                className="container-language"
                ref={refOne}
            >
                <div>
                    {titleShow && <p>{t(`${link}.language`)}</p>}
                    {selectLng && <img src={selectLng.src} alt="" />}
                </div>
                <GrLanguage size={24} />
            </div>

            <div id="select-language" className="select-language">
                <header>
                    <h6>{t(`${link}.header`)}</h6>
                </header>
                <main className="select-language-content">
                    {language.map((object, index) => {
                        return (
                            <div
                                id={object.value}
                                key={index}
                                className="language"
                                onClick={() => {
                                    i18n.changeLanguage(object.value);
                                    localStorage.setItem("language", object.value);
                                    setSelectLng({
                                        src: object.src,
                                        value: object.value,
                                    });
                                    const containerSelect =
                                        document.querySelectorAll(
                                            ".select-language"
                                        )[0];
                                    containerSelect.style.display = "none";
                                }}
                            >
                                <div>
                                    <img src={object.src} alt="" />
                                    <span>{object.name}</span>
                                </div>
                                {selectLng.value === object.value && (
                                    <CheckIcon style={{ color: "green" }} />
                                )}
                            </div>
                        );
                    })}
                </main>
            </div>
        </>
    );
}

export default SelectLanguage;
