import React from "react";
import { Dropdown, Input } from "semantic-ui-react";
import { useTranslation } from "react-i18next"
import "./../translation/i18n";

import "../assets/css/components/searchbar.css";

function SearchBar({ onChange, enableSort, enableOptions, onChangeOption, onChangeType, isShared }) {
    const {t} = useTranslation();
    const link = "components.searchbar";

    // array contendo as opções de ordenação
    // atualmente implementado porém não sendo utilizado
    const sortOptions = [
        {
            key: "recent",
            text: t(`${link}.last`),
            value: "Mais recente",
            icon: "sort content ascending",
        },

        {
            key: "old",
            text: t(`${link}.older`),
            value: "Mais antigo",
            icon: "sort content descending",
        },

        {
            key: "az",
            text: "A - Z",
            value: "A - Z",
            icon: "sort alphabet down",
        },
        {
            key: "za",
            text: "Z - A",
            value: "Z - A",
            icon: "sort alphabet up",
        },
    ];

const searchOptions = [
    {
        key: "Todos",
        text: t(`${link}.all`),
        value: "Todos",
    },
    {
        key: "DataSets",
        text: "DataSets",
        value: "DataSets",
    },
    {
        key: "Models",
        text: t(`${link}.model`),
        value: "Models",
    },
];

    return (
        <span className="SearchBar">
            <span className="Input">
                <Input
                    action={
                        enableOptions && !isShared && (
                            <Dropdown
                                button
                                basic
                                floating
                                options={searchOptions}
                                defaultValue="Todos"
                                onChange={(e) => onChangeType(e.target.textContent)}
                            />
                        )
                    }
                    icon="search"
                    iconPosition="left"
                    placeholder={t(`${link}.input.placeholder`)}
                    fluid
                    onChange={onChange}
                />
            </span>
            {enableSort && (
                <span className="Sort">
                    <Dropdown
                        // placeholder="Ordenar"
                        fluid
                        selection
                        options={sortOptions}
                        defaultValue={sortOptions[0].value}
                        onChange={(e) => onChangeOption(e.target.textContent)}
                    />
                </span>
            )}
        </span>
    );
}

export default SearchBar;
