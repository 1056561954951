import http from "../http-common";
import JSZip from "jszip";

/**
 * return the prediction of the model.
 *
 * @param {string} model_id - The ID of the model.
 * @returns {string} All of fields values of the model.
 */
function makesingleprediction(model_id, fields_values) {
    if (typeof fields_values === "object") {
        fields_values = JSON.stringify(fields_values);
    }
    return http.post("/makeprediction", {
        token: localStorage.getItem("user_token"),
        modelid: model_id,
        fieldsandvalues: fields_values,
    }).then((response) => {
        return response?.data;
    });
}

/**
 * return the prediction in batch of the model.
 *
 * @param {string} model_id - The ID of the model.
 * @returns {string} All of fields values of the model.
 */
function makebatchprediction(model_id, file) {
    let zip = new JSZip();
    zip.file(file.name, file);
    // zipa o arquivo usando o nome dele (file.name)
    return zip.generateAsync({ type: "blob" }).then(function (content) {
        // cria a estrutura da requisição em formData
        let formData = new FormData();
        let zipfile = new File([content], "data.zip");
        // adiciona o arquivo, o token e o project id ao formData
        formData.append("file", zipfile);
        formData.append("token", localStorage.getItem("user_token"));
        formData.append("mlresultid", model_id);

        return http.post("/makebatchprediction", formData).then((response) => {
            return response?.data
        });
    });
}

function getprediction(task_id) {
    const token = localStorage.getItem("user_token");
    return http.get(`/getprediction/${token}/${task_id}`)
        .then(response => {
            return response?.data;
        })
}

function getpredictionlistbymodelid(model_id){
    const token = localStorage.getItem("user_token");
    return http.get(`/getpredictionlistbymodelid/${token}/${model_id}`)
        .then(response => {
            return response?.data;
        })
}

function predictiondownload(predictionid) {
    const token = localStorage.getItem("user_token");
    return fetch(`predictiondownload/${predictionid}/${token}`)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.blob();
        })
        .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = "prediction.zip";
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        })
}

function deleteprediction(id){
    const token = localStorage.getItem("user_token");
    return http.delete(`/deleteprediction/${id}/${token}`).then(response => {
        return response?.data;
    })
}

function deleteallmodelprediction(modelId){
    const token = localStorage.getItem("user_token");
    return http.delete(`/deleteallmodelprediction/${modelId}/${token}`).then(response => {
        return response?.data;
    })
}

const predictionService = {
    makesingleprediction,
    makebatchprediction,
    getprediction,
    getpredictionlistbymodelid,
    predictiondownload,
    deleteprediction,
    deleteallmodelprediction
};

export default predictionService;