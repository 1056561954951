import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mainAttribute: "",
};

export const mainAttributeSlice = createSlice({
  name: "mainAttribute",
  initialState,
  reducers: {
    setMainAttribute: (state, action) => {
      state.mainAttribute = action.payload;
    },
  },
});

export const { setMainAttribute } = mainAttributeSlice.actions;
export const selectMainAttribute = (state) => state.mainAttribute.mainAttribute;

export default mainAttributeSlice.reducer;
