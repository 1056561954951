import Slider from "@material-ui/core/Slider";
import { makeStyles } from "@material-ui/core/styles";

import "../assets/css/components/inputslider.css";

function InputSlider({
   currentValue,
   setCurrentValue,
   isPercentage,
   min,
   max,
   id,
   step,
   handleValue,
   disabled,
}) {
   const handleChange = (event, newValue) => {
      if (handleValue !== undefined) {
         handleValue(newValue);
      } else {
         setCurrentValue(newValue);
      }
   };

   const useStyles = makeStyles({
      root: {
         color: "#38A3A5", // Substitua pela cor desejada
      },
      thumb: {
         border: "1px solid currentColor",
         "&:focus, &:hover, &$active": {
            boxShadow: "#ccc 0 0px 0px 0px",
         },
      },
      active: {},
   });

   const classes = useStyles();

   return (
      <div className="containerInputSlider">
         <div className="slider">
            <Slider
               value={currentValue}
               min={min}
               max={max}
               step={typeof step === "undefined" ? 1 : step}
               onChange={handleChange}
               disabled={disabled}
               valueLabelDisplay="off"
               id={id}
               classes={{ root: classes.root, thumb: classes.thumb }}
            />
         </div>
         <div className="input">
            {/*Para utilizar range slider*/}
            {typeof currentValue == "number"
               ? currentValue
               : currentValue[0] + "," + currentValue[1]}
         </div>
         {isPercentage && <span>%</span>}
      </div>
   );
}

export default InputSlider;
