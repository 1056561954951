import React, { useEffect, useRef } from "react";
import "../assets/css/components/popup.css";
import CloseIcon from "../assets/icons/jsxIcons/CloseIcon";

export const Popup = ({
   children,
   trigger,
   setTrigger,
   enableCloseIcon,
   css,
}) => {
   const popupRef = useRef(null);

   useEffect(() => {
      const handleKeyDown = (e) => {
         if (e.key === "Escape") {
            setTrigger(false);
         }
      };

      if (trigger) {
         document.addEventListener("keydown", handleKeyDown);
         if (popupRef.current) {
            popupRef.current.focus();
         }
      } else {
         document.removeEventListener("keydown", handleKeyDown);
      }

      return () => {
         document.removeEventListener("keydown", handleKeyDown);
      };
   }, [setTrigger, trigger]);

   useEffect(() => {
      if (trigger && popupRef.current) {
         popupRef.current.focus();
      }
   }, [trigger]);

   return trigger ? (
      <div className="popup">
         <div className={`popup-inner ${css}`} tabIndex="-1" ref={popupRef}>
            {!enableCloseIcon && (
               <button className="close-btn" onClick={() => setTrigger(false)}>
                  <CloseIcon style={{ size: 24 }} />
               </button>
            )}
            {children}
         </div>
      </div>
   ) : null;
};
