import "../../../assets/css/views/models/IsolationForest/anomalies.css"
import ArrowDownFilledIcon from "../../../assets/icons/jsxIcons/ArrowDownFilledIcon";
import ArrowRightFilledIcon from "../../../assets/icons/jsxIcons/ArrowRightFilledIcon"

import Histogram from "../../../graphicsComponents/others/Histogram";

function Anomalies({
    number,
    data,
    onClick,
    showHistogram
}){
    return (
        <div key={number} className="anomalies" style={showHistogram ? {height: 117, background: "#F2F2F2"} : {height: 37}}>
            <div 
                className="anomalies-head" 
                style={showHistogram ? {height: "31.62%"} : {height: "100%"}}
                onClick={onClick}
            >
                <p id="number">{number}</p>
                <div className="anomalies-score">
                    <div style={{width: `${data.score}%`}}></div>
                </div>
                <p id="score">{data.score}%</p>
                <div className="anomalies-arrow">
                    {showHistogram ? (
                        <ArrowDownFilledIcon style={{size: 16, color: "#38A3A5"}}/>
                    ) : (
                        <ArrowRightFilledIcon style={{size: 16, color: "#38A3A5"}}/>
                    )}
                </div>
            </div>
            {showHistogram && (
                <div className="anomalies-histogram">
                    <Histogram data={data?.info}/>
                </div>
            )}
        </div>
    )
}

export default Anomalies;