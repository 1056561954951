import React from "react";

import "../assets/css/components/checkbox.css";


export const Checkbox = ({ defaultValue, disabled, onChange }) => {
  return (
    <form className="cb-box">
      <input
        type="checkbox"
        defaultChecked={defaultValue}
        disabled={disabled}
        onChange={() => {
          onChange();
        }}
      />
    </form>
  );
};
