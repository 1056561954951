import styles from "../../../assets/css/views/popups/creations/newprojectpopup.module.css";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import ws from "../../../services/wiselib";
import { useDispatch, useSelector } from "react-redux";
import { setNeedUpdateProjectList } from "../../../store/slices/projects/projectsSlice";
import { Loader } from "semantic-ui-react";

import { useTranslation } from "react-i18next";
import "./../../../translation/i18n";
import handleErrorResponse from "../../../helpers/handleErrorResponse";
import { toast } from "react-toastify";
import NewProjectTour from "../../../components/Wizard/NewProjectTour";
import { selectTutorialDone } from "../../../store/slices/wizard/wizardSlice";

function NewProjectPopup({ setTrigger }) {
   const { t } = useTranslation();
   const [description, setDescription] = useState("");
   const [name, setName] = useState("");
   const [create, setCreate] = useState(true);
   const [isLoading, setIsLoading] = useState(false);
   const token = localStorage.getItem("user_token");

   const tutorialDone = useSelector(selectTutorialDone);

   const history = useHistory();
   const dispatch = useDispatch();

   const link = "popup.creations.newproject";

   const handleCreateProject = (e) => {
      e.preventDefault();
      if (name.trim() !== "") {
         setCreate(false);
         setIsLoading(true);
         ws.createproject(token, name, description)
            .then((response) => {
               dispatch(setNeedUpdateProjectList(true));
               toast.success(t(`backend.SUCCESS._SUCCESS_31`));
               history.push(`/project/${response}`);
            })
            .catch((error) => {
               handleErrorResponse(history, error, t);
            })

            .finally(() => {
               setTrigger(false);
               setCreate(true);
               setIsLoading(false);
            });
      } else {
         toast.error(t(`${link}.error`));
      }
   };

   return (
      <>
         <div className={styles.containerNewProjectPopup}>
            <div className={styles.containerTitle}>{t(`${link}.title`)}</div>
            <form
               onSubmit={handleCreateProject}
               className={styles.containerInput}
            >
               <div className={styles.containerName}>
                  {t(`${link}.input.name`)}
                  <input
                     maxLength={22}
                     type="text"
                     placeholder={t(`${link}.input.name`)}
                     autoFocus
                     onChange={(e) => {
                        setName(e.target.value);
                     }}
                  />
               </div>
               <div className={styles.containerDescription}>
                  {t(`${link}.input.description`)}
                  <textarea
                     type="text"
                     spellCheck="false"
                     placeholder={t(`${link}.input.description`) + "..."}
                     maxLength={80}
                     onChange={(e) => {
                        setDescription(e.target.value);
                     }}
                  />
                  <span>{description.length}/80</span>
               </div>
            </form>
            <button
               className={create ? styles.create : styles.creating}
               disabled={!create || isLoading}
               onClick={handleCreateProject}
            >
               {t(`${link}.button`)}
            </button>
         </div>
         {isLoading && <Loader active />}
         {!tutorialDone && <NewProjectTour />}
      </>
   );
}

export default NewProjectPopup;
