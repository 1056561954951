import {useEffect, useState} from 'react';

import '../../../../assets/css/views/popups/actions/sharemodelpopup/userinput.css';

import { Button } from '../../../../components/Button';
import TextInput from '../../../../components/TextInput';

import { useTranslation } from "react-i18next"
import "./../../../../translation/i18n";


const UserInput = ({ enableConfirmSharingPopup }) => {

    const {t} = useTranslation();

    const [typedEmail, setTypedEmail] = useState("")
    
    const [validEmail, setValidEmail] = useState(false)

    useEffect(()=> {
        typedEmail.length > 0 ? setValidEmail(true) : setValidEmail(false)
    }, [typedEmail])

    return (
        <div className="userinput">
            <span className="input-heading">{t("popup.action.sharedmodel.input.title")}</span>
            <div className="input-container">
                <TextInput
                    placeholder={t("popup.action.sharedmodel.input.placeholder")}
                    onChange={setTypedEmail}
                />
                <Button
                    type="button"
                    buttonStyle="btn--primary--solid"
                    buttonSize="btn--auto"
                    disabled={!validEmail}
                    onClick={() => {
                        enableConfirmSharingPopup(typedEmail);
                    }}
                    
                >
                    {t("popup.action.sharedmodel.input.button")}
                </Button>
            </div>
        </div>
    )
}

export default UserInput;
