import React, { useEffect, useState } from 'react';

import "../assets/css/components/editablediv.css";
import PencilIcon from '../assets/icons/jsxIcons/PencilIcon';
import CheckIcon from '../assets/icons/jsxIcons/CheckIcon';

export const EditableDiv = ({name, editFunction, id, index}) => {
  const [editing, setEditing] = useState(false);
  const [text, setText] = useState(name);

  useEffect(() => {
    setText(name);
  }, [name])

  const handleDoubleClick = () => {
    setEditing(true);
  };

  const handleChange = (event) => {
    setText(event.target.value);
  };

  const handleBlur = () => {
    setEditing(false);
    if (text.trim() !== "") {
      if (text !== name) {
        editFunction(id, index, text.trim());
      }
    } else {
      setText(name);
    }
  };

  return (
    <div className="container-editable">
      {editing ? (
        <>
          <input
            type="text"
            maxLength={15}
            value={text}
            onChange={handleChange}
            onBlur={handleBlur}
            autoFocus
          />
          <button onClick={handleDoubleClick}>
            <CheckIcon />
          </button>
        </>
      ) : (
        <>
          <p>{text}</p>
          <button onClick={handleDoubleClick}>
            <PencilIcon/>
          </button>
        </>
      )}
    </div>
  );
};

export default EditableDiv;
