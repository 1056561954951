import "../assets/css/components/historycard.css";
import ClockIcon from "../assets/icons/jsxIcons/ClockIcon";
import RefreshIcon from "../assets/icons/jsxIcons/RefreshIcon";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";

import { useTranslation } from "react-i18next";
import "./../translation/i18n";

import { useDispatch } from "react-redux";
import { setRunningTask } from "../store/slices/tasks/tasksSlice";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Popup } from "./Popup";
import DeleteGenericPopup from "../views/popups/actions/DeleteGenericPopup";

import taskService from "../services/TaskService";
import handleErrorResponse from "../helpers/handleErrorResponse";

function HistoryCard({ content, index, setTaskDelete, getusertasks }) {
   const { t } = useTranslation();
   const dispatch = useDispatch();
   const [infoPopup, setInfoPopup] = useState(false);
   const [taskDeletePopup, setTaskDeletePopup] = useState(false);
   const history = useHistory();

   const restart = () => {
      taskService
         .restartusertask(content.taskid)
         .then(() => {
            dispatch(setRunningTask(true));
            getusertasks();
         })
         .catch((error) => {
            handleErrorResponse(history, error, t);
         });
   };

   const contentButtonModel = () => {
      if (!content?.status) {
         if (content?.message) {
            return (
               <div>
                  <button
                     className="buttonCancel"
                     style={{ width: "50%" }}
                     onClick={() => {
                        handleErrorResponse(history, content.message, t);
                     }}
                  >
                     <p>{t("history.type_button.type_3")}</p>
                  </button>
                  <button onClick={restart}>
                     <RefreshIcon style={{ color: "#22577a", size: 17 }} />
                  </button>
                  <button
                     onClick={() => {
                        setTaskDeletePopup(true);
                     }}
                  >
                     <DeleteForeverOutlinedIcon style={{ color: "#22577a", width: 24, height: 24 }} />
                  </button>
               </div>
            );
         } else {
            if (content?.result) {
               return (
                  <button
                     className="buttonView"
                     onClick={() => {
                        setInfoPopup(true);
                     }}
                  >
                     {t("history.type_button.type_5")}
                  </button>
               );
            }
            return (
               <div>
                  <button onClick={restart}>
                     <RefreshIcon style={{ color: "#22577a", size: 17 }} />
                  </button>
                  <button
                     onClick={() => {
                        setTaskDeletePopup(true);
                     }}
                  >
                     <DeleteForeverOutlinedIcon style={{ color: "#22577a", width: 24, height: 24 }} />
                  </button>
               </div>
            );
         }
      }
      return (
         <button
            className="buttonCancel"
            onClick={() => {
               //parar task
            }}
         >
            {t("history.type_button.type_2") + " (" + content.status + "%)"}
         </button>
      );
   };

   const contentButtonDataset = () => {
      if (!content?.status) {
         if (content?.result && content?.id) {
            return (
               <div>
                  <button
                     onClick={() => {
                        history.push(`/${content.type}/${content.id}`);
                     }}
                  >
                     {t("history.type_button.type_1")}
                  </button>
                  <button
                     onClick={() => {
                        setTaskDeletePopup(true);
                     }}
                  >
                     <DeleteForeverOutlinedIcon style={{ color: "#22577a", width: 24, height: 24 }} />
                  </button>
               </div>
            );
         }
         return (
            <div>
               <p>{t("history.type_button.type_4")}</p>
               <button
                  onClick={() => {
                     setTaskDeletePopup(true);
                  }}
               >
                  <DeleteForeverOutlinedIcon style={{ color: "#22577a", width: 24, height: 24 }} />
               </button>
            </div>
         );
      }
      return (
         <button
            className="buttonCancel"
            onClick={() => {
               //parar task
            }}
         >
            {t("history.type_button.type_2") + " (" + content.status + "%)"}
         </button>
      );
   };

   const ModelPopup = () => {
      return (
         <div className="container-modelpopup">
            <div className="info-top">
               {content.algorithm}
               <div className="content-top">
                  <div>
                     <span>Nome:</span>
                     <span>
                        <p>{content.name}</p>
                     </span>
                  </div>
                  <div>
                     <span>Projeto:</span>
                     <span>
                        <p>{content.project}</p>
                     </span>
                  </div>
                  <div>
                     <span>Dataset:</span>
                     <span>
                        <p>{content.dataset}</p>
                     </span>
                  </div>
               </div>
            </div>
            <div className="info-bottom">
               <div>
                  <span>Data:</span>
                  <div className="date">
                     <ClockIcon style={{ size: 18 }} />
                     <p>{content.date}</p>
                  </div>
               </div>
               <div>
                  <span>Size:</span>
                  <span>
                     <p>{content.size}</p>
                  </span>
               </div>
               <div className="buttons">
                  <button
                     id="open"
                     onClick={() => {
                        history.push(`/model/${content.id}`);
                     }}
                  >
                     {t("history.type_button.type_1")}
                  </button>
                  <button
                     onClick={() => {
                        setInfoPopup(false);
                        setTaskDeletePopup(true);
                     }}
                  >
                     <DeleteForeverOutlinedIcon style={{ color: "#22577a", width: 24, height: 24 }} />
                  </button>
               </div>
            </div>
         </div>
      );
   };

   if (content.type === "model") {
      return (
         <>
            <div key={index} className="container-historycard">
               <div className="container-content">
                  <div className="container-svg">{content.algorithm}</div>
                  <div className="container-info">
                     <div id="name">
                        <span>Nome:</span>
                        <span>
                           <p>{content.name}</p>
                        </span>
                     </div>
                     <div id="date">
                        <ClockIcon />
                        <p>{content.date}</p>
                     </div>
                  </div>
               </div>
               <div className="container-button">{contentButtonModel()}</div>
            </div>
            <Popup trigger={infoPopup} setTrigger={setInfoPopup}>
               {ModelPopup()}
            </Popup>
            <Popup trigger={taskDeletePopup} setTrigger={setTaskDeletePopup}>
               <DeleteGenericPopup
                  setTrigger={setTaskDeletePopup}
                  onClickDelete={() => {
                     setTaskDelete(content.taskid);
                  }}
               />
            </Popup>
         </>
      );
   } else {
      return (
         <>
            <div key={index} className="container-historycard">
               <div className="container-content">
                  <div className="container-svg">{content.icon}</div>
                  <div className="container-info">
                     <div id="name">
                        <span>Nome:</span>
                        <span>
                           <p>{content.name}</p>
                        </span>
                     </div>
                     <div id="date">
                        <ClockIcon />
                        <p>{content.date}</p>
                     </div>
                  </div>
               </div>
               <div className="container-button">{contentButtonDataset()}</div>
            </div>
            <Popup trigger={taskDeletePopup} setTrigger={setTaskDeletePopup}>
               <DeleteGenericPopup
                  setTrigger={setTaskDeletePopup}
                  onClickDelete={() => {
                     setTaskDelete(content.taskid);
                  }}
               />
            </Popup>
         </>
      );
   }
}

export default HistoryCard;
