import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const StyledTableCell = withStyles(() => ({
   head: {
      backgroundColor: "#F4F4F4",
      color: "#000000",
      height: 44,
   },
   body: {
      fontFamily: "Montserrat",
      fontSize: 12,
      fontWeight: 500,
      letterSpacing: 0.2,
      borderBottom: "1px solid #E0E0E0",
   },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
   root: {
      backgroundColor: "#FFFFFF",
      color: "#000000",
      height: 64,
   },
   body: {
      fontFamily: "Montserrat",
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: 0.2,
      borderBottom: "1px solid #E0E0E0",
   },
}))(TableRow);

const ModelScores = ({ metrics }) => {
   const { t } = useTranslation();
   const options = metrics ? Object.keys(metrics?.scoreList[0]) : null;
   const [typeScore, setTypeScore] = useState("weighted");

   let models = metrics
      ? metrics.scoreList.map((model) => {
           return model[typeScore];
        })
      : [];

   let header = [];
   let table = [];

   if (metrics) {
      if (typeScore === "weighted") {
         header = [
            t("dataset.models.score.th_1"),
            t("dataset.models.score.th_2"),
            t("dataset.models.score.th_3"),
            t("dataset.models.score.th_4"),
            t("dataset.models.score.th_5"),
            t("dataset.models.score.th_6"),
            t("dataset.models.score.th_7"),
         ];
         table = models.map((model) => {
            return (
               <StyledTableRow key={model["name"]}>
                  <StyledTableCell align="center">
                     {model["name"] ? model["name"] : "-"}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                     {model["f-score"] ? model["f-score"] : "0"}%
                  </StyledTableCell>
                  <StyledTableCell align="center">
                     {model["acurácia"] ? model["acurácia"] : "0"}%
                  </StyledTableCell>
                  <StyledTableCell align="center">
                     {model["f1"] ? model["f1"] : "0"}%
                  </StyledTableCell>
                  <StyledTableCell align="center">
                     {model["precisão"] ? model["precisão"] : "0"}%
                  </StyledTableCell>
                  <StyledTableCell align="center">
                     {model["recall"] ? model["recall"] : "0"}%
                  </StyledTableCell>
                  <StyledTableCell align="center">
                     {model["hammingLoss"] ? model["hammingLoss"] : "0"}%
                  </StyledTableCell>
               </StyledTableRow>
            );
         });
      } else {
         header = [
            t("dataset.models.score.th_1"),
            t("dataset.models.score.th_5"),
            t("dataset.models.score.th_6"),
            t("dataset.models.score.th_4"),
         ];
         table = models.map((model) => {
            return (
               <StyledTableRow key={model["name"]}>
                  <StyledTableCell align="center">
                     {model["name"] ? model["name"] : "-"}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                     {model["precisão"] ? model["precisão"] : "0"}%
                  </StyledTableCell>
                  <StyledTableCell align="center">
                     {model["recall"] ? model["recall"] : "0"}%
                  </StyledTableCell>
                  <StyledTableCell align="center">
                     {model["f1"] ? model["f1"] : "0"}%
                  </StyledTableCell>
               </StyledTableRow>
            );
         });
      }
   }

   if (table.length === 0) {
      return <span id="no-data">{t("project.info.no_data")}</span>;
   } else {
      return (
         <>
            <header>
               <select
                  onChange={(e) => setTypeScore(e.target.value)}
                  style={{
                     width: 200,
                     height: "3em",
                     borderRadius: 10,
                     border: "1px solid #BDBDBD",
                     marginBottom: 20,
                     paddingLeft: "1em",
                  }}
               >
                  {options?.map((option) => {
                     return (
                        <option key={option} value={option}>
                           {option}
                        </option>
                     );
                  })}
               </select>
            </header>
            <div style={{ maxWidth: "90%" }}>
               <TableContainer
                  component={Paper}
                  style={{
                     width: "98%",
                     border: "1px solid #BDBDBD",
                  }}
               >
                  <Table aria-label="customized table">
                     <TableHead style={{ position: "sticky", top: 0 }}>
                        <TableRow>
                           {header.map((name) => {
                              return (
                                 <StyledTableCell key={name} align="center">
                                    {name}
                                 </StyledTableCell>
                              );
                           })}
                        </TableRow>
                     </TableHead>
                     <TableBody>{table}</TableBody>
                  </Table>
               </TableContainer>
            </div>
         </>
      );
   }
};

export default ModelScores;
