import styles from "./../../../assets/css/monitoring/views/screens/Server.module.css";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title, CategoryScale, LinearScale, PointElement, LineElement, Filler } from "chart.js"
import { Doughnut, Line } from "react-chartjs-2";
import { useState, useEffect } from "react";
import { t } from "i18next";

import LoadingWheel from "../../../components/LoadingWheel";

// cache:
import { useSelector } from "react-redux";
import { selectMemoriesServer, selectCpudiskServer } from "../../../store/slices/monitoring/monitoringSlice";

function Server() {

    const [isLoading, setIsLoading] = useState(true);
    const [graphicCpu, setGraphicCpu] = useState(false);
    const [graphicDisk, setGraphicDisk] = useState(false);
    const [graphicMemories, setGraphicMemories] = useState(false);
    const memoriesServer = useSelector(selectMemoriesServer);
    const cpuDiskServer = useSelector(selectCpudiskServer);
    const link = "monitoring.dashboard.service.server";

    function convertPorcentage(data) {
        let sum = parseFloat(data[0]) + parseFloat(data[1]);
        return ((parseFloat(data[0]) / sum) * 100).toFixed(2);
    }

    function getGraphicCpu() {
        try {
            setGraphicCpu({
                data: {
                    labels: [t(`${link}.used`) + "(%)", t(`${link}.available`) + "(%)"],
                    datasets: [{
                        label: t(`${link}.available`) + "(%)",
                        data: [cpuDiskServer[0].CpuUse, 100 - cpuDiskServer[0].CpuUse],
                        backgroundColor: ['rgba(0, 92, 200)', 'rgba(220, 220, 220)']
                    }]
                }, options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        title: {
                            text: t(`${link}.cpu`) + ":", display: true
                        },
                    },
                }, plugins: [
                    {
                        id: "textCenter",
                        beforeDatasetsDraw(chart) {
                            const { ctx, data } = chart;
                            ctx.save();
                            ctx.font = "700 25px sans-serif";
                            ctx.fillStyle = "rgba(0, 92, 200)";
                            ctx.textAlign = "center";
                            ctx.textBaseline = "middle";
                            ctx.fillText(`${convertPorcentage(data.datasets[0].data)}%`, chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y - 10);
                        }
                    }, {
                        id: "textCenter",
                        beforeDatasetsDraw(chart) {
                            const { ctx } = chart;
                            ctx.save();
                            ctx.font = "200 14px sans-serif";
                            ctx.fillStyle = "rgba(0, 92, 200)";
                            ctx.textAlign = "center";
                            ctx.textBaseline = "middle";
                            ctx.fillText(t(`${link}.used`), chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y + 20);
                        }
                    }
                ]
            });
        } catch {

        }
    }

    function getGraphicDisk() {
        try {
            setGraphicDisk({
                data: {
                    labels: [t(`${link}.used`) + "(Gb)", t(`${link}.available`) + "(Gb)"],
                    datasets: [{
                        label: t(`${link}.available`) + "(Gb)",
                        data: [cpuDiskServer[0].DiskUse, cpuDiskServer[0].DiskTotal - cpuDiskServer[0].DiskUse],
                        backgroundColor: ['rgba(0, 92, 200)', 'rgba(220, 220, 220)']
                    }]
                }, options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        title: {
                            text: t(`${link}.disc`) + ":", display: true
                        },
                    },
                }, plugins: [
                    {
                        id: "textCenter",
                        beforeDatasetsDraw(chart) {
                            const { ctx, data } = chart;
                            ctx.save();
                            ctx.font = "700 25px sans-serif";
                            ctx.fillStyle = "rgba(0, 92, 200)";
                            ctx.textAlign = "center";
                            ctx.textBaseline = "middle";
                            ctx.fillText(`${convertPorcentage(data.datasets[0].data)}%`, chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y - 10);
                        }
                    }, {
                        id: "textCenter",
                        beforeDatasetsDraw(chart) {
                            const { ctx } = chart;
                            ctx.save();
                            ctx.font = "200 14px sans-serif";
                            ctx.fillStyle = "rgba(0, 92, 200)";
                            ctx.textAlign = "center";
                            ctx.textBaseline = "middle";
                            ctx.fillText(t(`${link}.used`), chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y + 20);
                        }
                    }
                ]
            });
        } catch {

        }
    }

    function getGraphicMemories() {
        try {
            setGraphicMemories({
                data: {
                    labels: memoriesServer.map(memorie => { return memorie.Time }).reverse(),
                    datasets: [{
                        label: t(`${link}.chart.buttons.swap`),
                        data: memoriesServer.map(memorie => { return memorie.SwapMemUsed }).reverse(),
                        backgroundColor: 'rgba(255, 0, 0, 0.2)',
                        borderColor: 'rgba(255, 0, 0, 0.6)',
                        fill: true,
                        pointRadius: 0,
                        lineTension: 0.2
                    }, {
                        label: t(`${link}.chart.buttons.resident`),
                        data: memoriesServer.map(memorie => { return memorie.ResidentMemUsed }).reverse(),
                        backgroundColor: 'rgba(0, 0, 255, 0.2)',
                        borderColor: 'rgba(0, 0, 255, 0.6)',
                        fill: true,
                        pointRadius: 0,
                        lineTension: 0.2
                    }]
                },
                options: {
                    maintainAspectRatio: false,
                    responsive: true,
                    plugins: {
                        title: {
                            display: true,
                            text: t(`${link}.chart.title`)
                        },
                        legend: {
                            position: "top"
                        },
                    },
                }
            })
        } catch {
            
        }
    }

    useEffect(() => {
        ChartJS.register(CategoryScale, LinearScale, PointElement, ArcElement, LineElement, Tooltip, Legend, Title, Filler);
        getGraphicCpu();
        getGraphicDisk();
        getGraphicMemories();
        setIsLoading(false);
        // eslint-disable-next-line
    }, [memoriesServer]);

    if (isLoading) {
        return (
            <main className={styles.isloading}>
                <LoadingWheel loadingSize="large" />
            </main>
        )
    } else {
        return (
            <main className={styles.containerserver}>
                <section className={styles.containergraphicstop}>
                    <div className={styles.graphiccpu}>
                        {graphicCpu &&
                            <Doughnut
                                data={graphicCpu.data}
                                options={graphicCpu.options}
                                plugins={graphicCpu.plugins}
                            />
                        }
                    </div>
                    <div className={styles.graphicdisk}>
                        {graphicDisk &&
                            <Doughnut
                                data={graphicDisk.data}
                                options={graphicDisk.options}
                                plugins={graphicDisk.plugins}
                            />
                        }
                    </div>
                </section>
                <section className={styles.containergraphicmemories}>
                    {graphicMemories && <Line data={graphicMemories.data} options={graphicMemories.options} />}
                </section>
            </main>
        )
    }

}

export default Server;