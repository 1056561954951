import React, { useEffect, useState } from "react";
import SearchBar from "../../SearchBar";
import { Button } from "../../Button";
import styles from "../../../assets/css/components/Dataset/Models/modelcards.module.css";
import { Add } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Loader } from "semantic-ui-react";
import { Card } from "../../Card";
import dp from "../../../helpers/data_parse";
import { Popup } from "../../Popup";
import { useSelector } from "react-redux";
import { selectDataSetsContent } from "../../../store/slices/cache/cacheSlice";
import { selectProjectList } from "../../../store/slices/projects/projectsSlice";
import ChooseAlgorithmPopup from "../../../views/popups/creations/ChooseAlgorithmPopup";

const ModelCards = () => {
   const { t } = useTranslation();
   const location = useLocation();
   const datasetId = location.pathname.split("/").pop();

   // Traz os dados dos modelos do dataset que já estão no cach   e, evitando chamar o endpoint getdsmdlist
   const projectList = useSelector(selectProjectList);

   const modelsCached = projectList.flatMap((project) =>
      project.models.filter((model) => model.dataProxyId === datasetId),
   );

   const dataset = useSelector(selectDataSetsContent).find((dataset) => dataset.id === datasetId);

   //flag que define se select all está ativo dos models
   const [isSelectedActiveModels, setIsSelectedActiveModels] = useState(false);
   // trigger pro popup de escolher algoritmo
   const [escolherAlgPopup, setEscolherAlgPopup] = useState(false);
   // Stata com os ids dos models selecionados
   const [selectedModels, setSelectedModels] = useState([]);

   const [searchedTerm, setSearchedTerm] = useState("");

   const [optionSort, setOptionSort] = useState("Mais recente");

   const [modelCards, setModelCards] = useState(null);

   const [isLoading, setIsLoading] = useState(true);

   useEffect(() => {
      if (modelsCached) {
         handleSetModelCards();
         setIsLoading(false);
      }
      // eslint-disable-next-line
   }, [projectList, searchedTerm]);

   // Função que seta e lida com os cards de modelos
   const handleSetModelCards = () => {
      const renderCard = (model) => (
         <Link
            to={!isSelectedActiveModels && "/model/" + model.id}
            key={model.id}
            onClick={(e) => {
               if (e.ctrlKey || e.metaKey || isSelectedActiveModels) {
                  handleModelsSelected(e, model.id);
                  !isSelectedActiveModels && setIsSelectedActiveModels(true);
               }
            }}
         >
            <Card
               type="model"
               algorithm={model.algorithm}
               name={model.name}
               date={model.date}
               elementId={model.id}
               ownerEmail={model?.ownerEmail ? model.ownerEmail : ""}
            />
         </Link>
      );

      if (modelsCached?.length === 0) {
         return setModelCards(<span id="no-data">{t("project.info.no_data")}</span>);
      }

      let copyModels = [...modelsCached];
      if (searchedTerm) {
         copyModels = modelsCached.filter((value) =>
            value.name.toLowerCase().trim().includes(searchedTerm.toLowerCase().trim()),
         );
         if (copyModels.length === 0) {
            return setModelCards(
               <span id="no-data">
                  {t("project.info.none_model") + " " + searchedTerm.trim() + " " + t("project.info.found")}
               </span>,
            );
         }
      }

      copyModels = dp.searchSort(optionSort, copyModels);
      setModelCards(copyModels.map(renderCard));
   };

   // Função que lida com a seleção de modelos para deletar, etc.
   const handleModelsSelected = (e, modelId) => {
      e?.preventDefault();
      // Verifico se o modelo já está selecionado
      const isSelected = selectedModels.includes(modelId);

      if (isSelected) {
         // Se estiver selecionado, removo da lista de modelos selecionados
         setSelectedModels(selectedModels.filter((id) => id !== modelId));
      } else {
         // Se não estiver selecionado, adiciono à lista de modelos selecionados
         setSelectedModels([...selectedModels, modelId]);
      }
   };

   // Função que literalmente renderiza os cards de modelos pré-preparados na função handleSetModelCards
   const renderModelsCards = () => {
      if (isSelectedActiveModels && modelCards.length > 0) {
         return modelCards.map((model) => (
            <div
               key={model.key}
               className={`project-card background ${selectedModels.includes(model.key) ? "selected" : ""}`}
            >
               <input
                  type="checkbox"
                  className="checkbox"
                  checked={selectedModels.includes(model.key)}
                  onChange={() => handleModelsSelected(undefined, model.key)}
               />
               {model}
            </div>
         ));
      } else {
         return modelCards;
      }
   };

   return (
      <>
         <nav className={styles.navModels}>
            <SearchBar
               enableSort={true}
               onChange={(e) => setSearchedTerm(e.target.value)}
               onChangeOption={setOptionSort}
            />
            <Button buttonSize="btn--medium" onClick={() => setEscolherAlgPopup(true)} disabled={dataset === undefined}>
               <Add color="white" /> {t(`project.create_model`)}
            </Button>
         </nav>
         {isLoading ? <Loader active /> : <div className={styles.modelCards}>{renderModelsCards()}</div>}

         <Popup trigger={escolherAlgPopup} setTrigger={setEscolherAlgPopup}>
            <ChooseAlgorithmPopup dataset={dataset} setTrigger={setEscolherAlgPopup} />
         </Popup>
      </>
   );
};

export default ModelCards;
