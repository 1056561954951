import JSZip from "jszip";
import React from "react";
import { useHistory } from "react-router-dom";

import "../../../assets/css/views/popups/actions/downloamodelpopup.css";

import { Button } from "../../../components/Button";

import ws from "../../../services/wiselib";

import { useTranslation } from "react-i18next";
import "./../../../translation/i18n";
import { toast } from "react-toastify";
import handleErrorResponse from "../../../helpers/handleErrorResponse";

export const DownloadModelPopup = ({
   modelName,
   setTrigger,
   modelResultId,
}) => {
   const { t } = useTranslation();

   const history = useHistory();

   const onChangeFile = async (data) => {
      //codigo "feito" a partir do link: https://stackoverflow.com/questions/51759103/js-download-zip-file-from-a-string-with-javascript
      const zip = new JSZip();
      await zip.loadAsync(data.data, { base64: true });
      const blob = await zip.generateAsync({ type: "blob" });

      const element = document.createElement("a");
      element.setAttribute("href", window.URL.createObjectURL(blob));
      element.setAttribute("download", `${modelName}_${modelResultId}.zip`);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();

      document.body.removeChild(element);
   };

   return (
      <div id="download-model-popup">
         <div id="title-msg">{t("popup.action.downloadmodel.title")}</div>
         <div id="description"></div>
         <div id="buttons">
            <span id="btn">
               <Button
                  type="button"
                  buttonStyle="btn--primary--outline"
                  buttonSize="btn--auto"
                  onClick={() => {
                     const token = localStorage.getItem("user_token");
                     ws.downloadmodelresult(token, modelResultId)
                        .then((result) => {
                           if (result) {
                              onChangeFile(result);
                           }
                           toast.success(
                              t("popup.action.downloadmodel.success")
                           );
                        })
                        .catch((error) => {
                           handleErrorResponse(history, error, t);
                        });
                     setTrigger(false);
                  }}
               >
                  {t("popup.action.downloadmodel.yes")}
               </Button>
            </span>

            <span id="btn">
               <Button
                  type="button"
                  buttonStyle="btn--primary--solid"
                  buttonSize="btn--auto"
                  onClick={() => {
                     setTrigger(false);
                  }}
               >
                  {t("popup.action.downloadmodel.no")}
               </Button>
            </span>
         </div>
      </div>
   );
};
