import "./../../../../assets/css/views/popups/creations/NewDataset/newdatasetpopup.css";
import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

import AvailableDataset from "./AvailableDataset";
import UploadDataset from "./UploadDataset.jsx";
import DatabaseDataset from "./DatabaseDataset.jsx";
import WebAdressDataset from "./WebAdressDataset.jsx";
import AvailableWiseml from "./AvailableWiseml";
import { selectIsUploading, setUploading } from "../../../../store/slices/uploadingFlag/uploadingSlice.js";
import { setOpenModalTask, setRunningTask } from "../../../../store/slices/tasks/tasksSlice.js";
import { useDispatch, useSelector } from "react-redux";

import DoneIcon from "./../../../popups/creations/ChooseAlgorithmPopUp/assets/icons/jsxIcons/DoneIcon.jsx";
import BouncingDotsLoader from "../../../../components/BouncingDotsLoader.jsx";
import ConfirmationPopup from "./../../actions/ConfirmationPopup.jsx";
import UploadIcon from "./../../../../assets/icons/jsxIcons/UploadIcon.jsx";
import { Popup } from "./../../../../components/Popup.jsx";

import datasetService from "./../../../../services/DatasetService.js";
import { useTranslation } from "react-i18next";
import "./../../../../translation/i18n";
import { toast } from "react-toastify";
import handleErrorResponse from "../../../../helpers/handleErrorResponse.js";
import NewDatasetTour from "../../../../components/Wizard/NewDatasetTour.jsx";
import { selectTutorialDone } from "../../../../store/slices/wizard/wizardSlice.js";
import { Button } from "../../../../components/Button.jsx";

function NewDatasetPopup({ setPopupTrigger, isProjectPage }) {
   const { t } = useTranslation();
   /*
        state utilizado para renderizar o conteúdo da aba disponíveis, se este pop up é da página Projetos,
        caso contrário não vai aparecer (por isso o motivo do state ...)
    */
   const [available, setAvailable] = useState(isProjectPage);
   /*
        state utilizado para abrir ou fechar o pop up de confirmação de importação do dataset
        vale destacar que eu inicializo os parâmetros do pop up com as informações que armazenei neste state
    */
   const [confirmationPopup, setConfirmationPopup] = useState(false);
   //state utilizado para renderizar o conteúdo da aba upload
   const [upload, setUpload] = useState(!isProjectPage);
   //state utilizado para renderizar o conteúdo da aba banco de dados
   const [database, setDatabase] = useState(false);
   //state utilizado para renderizar o conteúdo da aba endereço web
   const [webAdress, setWebAdress] = useState(false);

   const [datasetsWiseml, setDatasetsWiseml] = useState(false);

   const [availableWiseml, setAvailableWiseml] = useState(false);

   //state utilizado para renderizar a lista de projetos no dropdown próximo ao rodabé no pop up
   const [select, setSelect] = useState(false);
   //state contendo a lista de todas as informações de todos os projetos do usuário
   const [options, setOptions] = useState([]);
   const projectList = useSelector((state) => state.projects.projectList);
   //state utilizado para renderizar o nome dos projetos selecionado pelo usuário
   const [listOptions, setListOptions] = useState("Nenhum");
   //ref utilizado para passar pro endpoint de upload, pois este ref contém uma string de IDs de projeto separado por uma vírgula
   const IDListRef = useRef("");
   //ref utilizado para manipular o click do usuário e fechar o dropdown
   const selectRef = useRef("newDataset");

   const [file, setFile] = useState(false);

   const [isLoading, setIsLoading] = useState(false);

   const userIsAdm = useSelector((state) => state.user.isAdmin);

   //token que é passado para o endpoint uma única vez.
   const token = localStorage.getItem("user_token");

   const dispatch = useDispatch();

   const history = useHistory();

   const tutorialDone = useSelector(selectTutorialDone);

   const handleButtonClick = () => {
      dispatch(setOpenModalTask(true));
   };

   useEffect(() => {
      /*
            Foi uma forma de toda vez que eu clicar
             fora da dropdown ele var ser fechado
        */
      selectRef.current = "newDataset";
   });

   useEffect(() => {
      /*
            Toda vez que eu selecionar um projeto no dropdown,
            eu atualizo a lista de IDs (usado pro upload) e nome (para ser renderizado na dropdown)
        */
      if (options.length) {
         let auxListOptions = "";
         IDListRef.current = "";
         options.forEach((option) => {
            if (option.select) {
               auxListOptions += option.name + ", ";
               IDListRef.current += option.id + ",";
            }
         });
         if (auxListOptions === "") {
            auxListOptions = t("popup.creations.newdataset.footer.input.none");
         } else {
            auxListOptions = auxListOptions.substring(0, auxListOptions.length - 2);
            IDListRef.current = IDListRef.current.substring(0, IDListRef.current.length - 1);
         }
         setListOptions(auxListOptions);
      }
      // eslint-disable-next-line
   }, [options]);

   useEffect(() => {
      /*
        pega a lista de projetos, pois preciso
        1. nome: para usar no dropdown;
        2. id: para utilizar no upload do dataset no projeto selecionado no dropdown  
      */
      let projectListAux = projectList.slice();
      projectListAux = projectListAux.filter((project) => !project.shared);
      projectListAux = projectListAux.map((project) => ({
         ...project,
         select: false,
      }));
      setOptions(projectListAux);
      // eslint-disable-next-line
   }, [token]);

   function resetVariables() {
      /*
            Reseta todas os states das abas (para não ter repetição);
        */
      setAvailable(false);
      setUpload(false);
      setDatabase(false);
      setWebAdress(false);
      setDatasetsWiseml(false);
      setAvailableWiseml(false);
      setFile(false);
   }

   function getListID() {
      let arrayIDString = [];
      if (isProjectPage === false) {
         arrayIDString = IDListRef.current.split(",");
         arrayIDString = arrayIDString[0] === "" ? [] : arrayIDString;
         return arrayIDString;
      }
      arrayIDString.push(isProjectPage);
      return arrayIDString;
   }

   function linkDataSource() {
      if (typeof available === "object" && available.length && isProjectPage) {
         dispatch(setUploading(true));
         setAvailable(false);
         setIsLoading(true);
         datasetService
            .linkmanydatasources(isProjectPage, available)
            .then((id) => {
               setDatabase(false);
               setWebAdress(false);
               setAvailable(false);
               setAvailableWiseml(false);
               dispatch(setRunningTask(true));
               toast.info(
                  <div>
                     {t("popup.creations.newdataset.upload.info")}
                     <Button className="btn--primary--solid" onClick={handleButtonClick}>
                        {t("history.type_button.type_1")}
                     </Button>
                  </div>,
               );
               // setTaskId(id);
               dispatch(setUploading(false));
            })
            .catch((error) => {
               dispatch(setUploading(false));
               handleErrorResponse(history, error, t);
            })
            .finally(() => {
               setIsLoading(false);
               setPopupTrigger(false);
            });
      } else {
         toast.error("Selecione um dataset");
      }
   }

   function importDataSourceUpload() {
      /*
            Faz a importação do dataset da aba upload
        */

      let file = upload;
      dispatch(setUploading(true));
      setUpload(false);
      setIsLoading(true);
      datasetService
         .uploaddatasource(file, getListID())
         .then((id) => {
            setDatabase(false);
            setWebAdress(false);
            setAvailable(false);
            setAvailableWiseml(false);
            dispatch(setRunningTask(true));
            toast.info(
               <div>
                  {t("popup.creations.newdataset.upload.info")}
                  <Button className="btn--primary--solid" onClick={handleButtonClick}>
                     {t("history.type_button.type_1")}
                  </Button>
               </div>,
            );
            // setTaskId(id);
            dispatch(setUploading(false));
         })
         .catch((error) => {
            handleErrorResponse(history, error, t);
            dispatch(setUploading(false));
         })
         .finally(() => {
            setIsLoading(false);
            setPopupTrigger(false);
         });
   }

   function importDataSourceMySQL() {
      if (
         (database?.connectionString !== "" && database?.databaseTargetTable !== "" && database?.databaseType !== "") ||
         (database?.databaseHost !== "" &&
            database?.databasePort !== "" &&
            database?.databaseUserName !== "" &&
            database?.databaseUserPassword !== "" &&
            database?.databaseTargetSchema !== "")
      ) {
         let auxDatabase = {
            ...database,
            projectID: isProjectPage,
         };
         dispatch(setUploading(true));
         setDatabase(false);
         setIsLoading(true);
         datasetService
            .importdatasourcesql(auxDatabase)
            .then((id) => {
               setUpload(false);
               setWebAdress(false);
               setAvailable(false);
               setAvailableWiseml(false);
               dispatch(setRunningTask(true));
               toast.info(
                  <div>
                     {t("popup.creations.newdataset.upload.info")}
                     <Button className="btn--primary--solid" onClick={handleButtonClick}>
                        {t("history.type_button.type_1")}
                     </Button>
                  </div>,
               );
               // setTaskId(id);
               dispatch(setUploading(false));
            })
            .catch((error) => {
               dispatch(setUploading(false));
               handleErrorResponse(history, error, t);
            })
            .finally(() => {
               setIsLoading(false);
               setPopupTrigger(false);
            });
      } else {
         toast.error(t("popup.creations.newdataset.errors.error_1"));
      }
   }

   function importDataSourceMongoDB() {
      if (database?.confirmationPopup !== "" && database?.targetDatabase !== "" && database?.targetCollection !== "") {
         let auxDatabase = {
            ...database,
            projectID: isProjectPage,
         };
         dispatch(setUploading(true));
         setDatabase(false);
         setIsLoading(true);
         datasetService
            .importdatasourcemongo(auxDatabase)
            .then((id) => {
               setUpload(false);
               setWebAdress(false);
               setAvailable(false);
               setAvailableWiseml(false);
               dispatch(setRunningTask(true));
               toast.info(
                  <div>
                     {t("popup.creations.newdataset.upload.info")}
                     <Button className="btn--primary--solid" onClick={handleButtonClick}>
                        {t("history.type_button.type_1")}
                     </Button>
                  </div>,
               );
               // setTaskId(id);
               dispatch(setUploading(false));
            })
            .catch((error) => {
               dispatch(setUploading(false));
               handleErrorResponse(history, error, t);
            })
            .finally(() => {
               setIsLoading(false);
               setPopupTrigger(false);
            });
      } else {
         toast.error(t("popup.creations.newdataset.errors.error_1"));
      }
   }

   const handleFileChange = (e) => {
      if (e.target.files) {
         const selectedFile = e.target.files[0];
         const allowedTypes = ["application/json", "text/csv"];

         if (!allowedTypes.includes(selectedFile?.type)) {
            toast.error(t("popup.creations.newdataset.upload.errors.error_1"));
            e.target.value = "";
            return;
         }

         setFile(selectedFile);
      }
   };
   return (
      <div
         className="containerNewDatasetPopup"
         id="newDataset"
         onClick={() => {
            if (select && selectRef.current === "newDataset") {
               setSelect(false);
            }
         }}
      >
         <header className="containerHeaderNewDataset">{t("popup.creations.newdataset.title")}</header>
         <div className="containerContent">
            <nav className="containerHeaderContent">
               {isProjectPage && (
                  <button
                     className={available ? "selected" : ""}
                     onClick={() => {
                        resetVariables();
                        setAvailable(true);
                     }}
                  >
                     {t("popup.creations.newdataset.button.button_1")}
                  </button>
               )}
               {!isProjectPage && (
                  <button
                     className={availableWiseml ? "selected" : ""}
                     onClick={() => {
                        resetVariables();
                        setAvailableWiseml(true);
                     }}
                  >
                     {t("popup.creations.newdataset.button.button_2")}
                  </button>
               )}
               <button
                  className={upload ? "selected" : ""}
                  onClick={() => {
                     resetVariables();
                     setUpload(true);
                  }}
               >
                  {t("popup.creations.newdataset.button.button_3")}
               </button>
               <button
                  className={database ? "selected" : ""}
                  onClick={() => {
                     resetVariables();
                     setDatabase(true);
                  }}
               >
                  {t("popup.creations.newdataset.button.button_4")}
               </button>
            </nav>
            <div className="containerUpload">
               {isLoading && <BouncingDotsLoader />}
               {availableWiseml && !isProjectPage && <AvailableWiseml />}
               {available && isProjectPage && <AvailableDataset setIDs={setAvailable} />}
               {upload && <UploadDataset setFile={setUpload} file={upload} />}
               {database && <DatabaseDataset setDatabase={setDatabase} database={database} />}
               {webAdress && <WebAdressDataset setWebAdress={setWebAdress} />}
            </div>
            <div className="containerLinkProject">
               {!isProjectPage && !datasetsWiseml && !availableWiseml && (
                  <>
                     <p id="info">{t("popup.creations.newdataset.footer.input.info")}</p>
                     <div
                        className="containerSelect"
                        onClick={() => {
                           setSelect(!select && selectRef.current === "newDataset" ? true : select);
                        }}
                     >
                        {select && (
                           <div className="containerOptions">
                              {options.map((option, index) => {
                                 return (
                                    <div
                                       className={option.select ? "selected" : ""}
                                       key={option.name}
                                       onClick={() => {
                                          let string = JSON.stringify(options);
                                          let auxOptions = JSON.parse(string);
                                          auxOptions[index] = {
                                             ...auxOptions[index],
                                             name: option.name,
                                             select: !auxOptions[index].select,
                                          };
                                          setOptions(auxOptions);
                                          selectRef.current = null;
                                       }}
                                    >
                                       {option.name}
                                       {option.select && <DoneIcon style={{ width: 15, height: 15 }} />}
                                    </div>
                                 );
                              })}
                           </div>
                        )}
                        <p>{listOptions}</p>
                     </div>
                  </>
               )}
               {!availableWiseml && (
                  <button
                     style={{ backgroundColor: "#22577A" }}
                     onClick={() => {
                        setConfirmationPopup({
                           title: t("popup.creations.newdataset.confirm.confirm_1"),
                           onConfirm: () => {
                              if (upload) {
                                 if (typeof upload == "object") {
                                    importDataSourceUpload();
                                 } else {
                                    toast.error("Selecione um arquivo");
                                 }
                              } else if (available) {
                                 linkDataSource();
                              } else if (database?.type === "mysql") {
                                 importDataSourceMySQL();
                              } else if (database?.type === "mongodb") {
                                 importDataSourceMongoDB();
                              }
                              setConfirmationPopup(false);
                           },
                           onDeny: () => {
                              setConfirmationPopup(false);
                           },
                        });
                     }}
                  >
                     {t("popup.creations.newdataset.footer.input.button_2")}
                  </button>
               )}
               {availableWiseml && userIsAdm && (
                  <div className="containerImportDatasetWiseml">
                     <div className="containerInputFile">
                        <input
                           type="file"
                           id="upload"
                           style={{ display: "none" }}
                           accept=".csv, .json"
                           onChange={handleFileChange}
                        />
                        <label htmlFor="upload">
                           <UploadIcon style={{ color: "#FDFDFD", size: "16" }} />
                           <p>{t("popup.creations.newdataset.footer.button.button_1")}</p>
                        </label>
                     </div>
                     <div className="containerNameFile">
                        {file?.name && (
                           <p>
                              {t("popup.creations.newdataset.footer.button.file")}: <span>{file.name}</span>
                           </p>
                        )}
                     </div>
                     <button
                        style={{ backgroundColor: "#22577A" }}
                        onClick={() => {
                           setConfirmationPopup({
                              title: t("popup.creations.newdataset.confirm.confirm_2"),
                              onConfirm: () => {
                                 dispatch(setUploading(true));
                                 datasetService
                                    .uploaddatasourcewiseml(file)
                                    .then((id) => {
                                       setUpload(false);
                                       setDatabase(false);
                                       setWebAdress(false);
                                       setAvailable(false);
                                       setAvailableWiseml(false);
                                       dispatch(setRunningTask(true));
                                       // setTaskId(id);
                                       dispatch(setUploading(false));
                                    })
                                    .catch((error) => {
                                       dispatch(setUploading(false));
                                       handleErrorResponse(history, error, t);
                                    })
                                    .finally(() => setPopupTrigger(false));
                                 setConfirmationPopup(false);
                              },
                              onDeny: () => {
                                 setConfirmationPopup(false);
                              },
                           });
                        }}
                     >
                        {t("popup.creations.newdataset.footer.button.button_2")}
                     </button>
                  </div>
               )}
            </div>
         </div>
         <Popup trigger={confirmationPopup} setTrigger={setConfirmationPopup}>
            <ConfirmationPopup
               title={confirmationPopup?.title}
               onConfirm={confirmationPopup?.onConfirm}
               onDeny={confirmationPopup?.onDeny}
            />
         </Popup>
         {!tutorialDone && <NewDatasetTour />}
      </div>
   );
}

export default NewDatasetPopup;
