import React, { useEffect, useRef, useState } from "react";

import { Input, Loader } from "semantic-ui-react";

import "../../../assets/css/views/popups/actions/renamecardspopup.css";

import { Button } from "../../../components/Button";

import projectService from "../../../services/ProjectService";
import datasetService from "../../../services/DatasetService";
import modelService from "../../../services/ModelService";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
   setNeedUpdateDataSource,
   setNeedUpdateDataset,
   setNeedUpdateModelResult,
   setNeedUpdateMyDatasources,
} from "../../../store/slices/cache/cacheSlice";
import { setNeedUpdateProjectList } from "../../../store/slices/projects/projectsSlice";

import { useTranslation } from "react-i18next";
import "./../../../translation/i18n";
import { toast } from "react-toastify";
import handleErrorResponse from "../../../helpers/handleErrorResponse";

export const RenameCardsPopup = ({
   setTrigger,
   modelResultId,
   cardtype,
   renameType,
}) => {
   const { t } = useTranslation();
   const [newModelName, setNewModelName] = useState("");
   const dispatch = useDispatch();
   const [isLoading, setIsLoading] = useState(false);
   const inputRef = useRef(null);
   const history = useHistory();

   const handleChange = (event) => {
      setNewModelName(event.target.value);
   };

   const renameFunction = () => {
      if (!newModelName) {
         toast.error(t("popup.action.renamecards.error"));
         return
      }
      let renamePromise;

      switch (cardtype) {
         case "model":
            renamePromise = modelService.renamemodel(
               modelResultId,
               newModelName
            );
            break;
         case "dataset":
            renamePromise = datasetService.renamedataset(
               modelResultId,
               newModelName
            );
            break;
         case "project":
            if (renameType === "rename")
               renamePromise = projectService.renameproject(
                  modelResultId,
                  newModelName
               );
            else if (renameType === "description")
               renamePromise = projectService.changedescription(
                  modelResultId,
                  newModelName
               );
            break;
         case "datasource":
            renamePromise = datasetService.renamedatasource(
               modelResultId,
               newModelName
            );
            break;
         default:
            break;
      }

      setIsLoading(true);
      renamePromise
         .then((response) => {
            setTrigger(false);
            toast.success(t(`backend.SUCCESS.${response}`));
         })
         .catch((error) => {
            handleErrorResponse(history, error, t);
         })
         .finally(() => {
            setIsLoading(false);
            switch (cardtype) {
               case "datasource":
                  dispatch(setNeedUpdateDataSource(true));
                  dispatch(setNeedUpdateMyDatasources(true));
                  break;

               case "model":
                  dispatch(setNeedUpdateModelResult(true));
                  dispatch(setNeedUpdateProjectList(true));
                  break;

               default:
                  dispatch(setNeedUpdateDataset(true));
                  dispatch(setNeedUpdateProjectList(true));
            }
         });
   };

   useEffect(() => {
      const keyDownHandler = (event) => {
         if (event.key === "Enter") {
            event.preventDefault();
            if (newModelName) {
               renameFunction();
            } else {
               toast.error(t("popup.action.renamecards.error"));
            }
         }
      };

      document.addEventListener("keydown", keyDownHandler);

      return () => {
         document.removeEventListener("keydown", keyDownHandler);
      };
      // eslint-disable-next-line
   }, [newModelName]);

   //Ao renderizar o componente, focar diretamente no input
   useEffect(() => {
      inputRef.current.focus();
   }, []);

   return (
      <div id="rename-model-popup">
         <div id="title-msg">
            {cardtype === "model"
               ? t("popup.action.renamecards.header.title_1")
               : cardtype === "dataset"
               ? t("popup.action.renamecards.header.title_2")
               : renameType === "rename"
               ? t("popup.action.renamecards.header.title_3")
               : t("popup.action.renamecards.header.title_4")}
         </div>
         <div id="description">
            {/* <input type="text" onChange={handleChange}></input>
             */}
            <span className="rename-input">
               <Input
                  fluid
                  onChange={handleChange}
                  ref={inputRef}
                  maxLength={20}
                  onKeyDown={(event) => {
                     if (event.key === " ") {
                        // Adicionado para evitar que a tecla espaço entre no link
                        event.stopPropagation();
                        event.preventDefault();
                     }
                  }}
               />
            </span>
         </div>
         {isLoading && <Loader active />}
         <div id="buttons">
            <span id="btn">
               <Button
                  type="button"
                  buttonStyle="btn--primary--outline"
                  buttonSize="btn--auto"
                  disabled={isLoading}
                  onClick={renameFunction}
               >
                  {t("popup.action.renamecards.buttons.save")}
               </Button>
            </span>

            <span id="btn">
               <Button
                  type="button"
                  buttonStyle="btn--primary--solid"
                  buttonSize="btn--auto"
                  onClick={() => {
                     setTrigger(false);
                  }}
               >
                  {t("popup.action.renamecards.buttons.cancel")}
               </Button>
            </span>
         </div>
      </div>
   );
};
