import "./../../../assets/css/views/popups/creations/newkeyapipopup.css";

import { useEffect, useState } from "react";
import ButtonSwitch from "../../../components/ButtonSwitch";

import externalApi from "../../../services/ExternalApi";
import ParseData from "../../../helpers/DataParse";

import { useTranslation } from "react-i18next"
import "./../../../translation/i18n";

function NewKeyApiPopup({
    setApiPopup, 
    setTrigger, 
    setSuccessfullyCreatedKeyPopup,
    modelID,
    token,
    setApiKey,
    listKeys,
}){
    const {t} = useTranslation();
    const [validity, setValidity] = useState({
        number: 0,
        text: "",
    });
    const [active, setActive] = useState(false);
    const [textInput, setTextInput] = useState("");
    const [listAddressIP, setListAddressIP] = useState([]);
    const [name, setName] = useState("");
    const [errorMessage, setErrorMessage] = useState({address: "", name: ""});
    const timeCurrent = ((new Date()).getTime() / 1000);
    useEffect(() => {
        let number = 30 * 24 * 60 * 60 + timeCurrent;
        let text = ParseData.parseDate(number).day;
        setValidity({
            number: number,
            text: t(`${link}.main.input_2.placeholder`) + " " + text,
        })
        // eslint-disable-next-line
    }, []);

    let link = "popup.creations.newkeyapi";

    return (
        <div className="containerNewKeyApiPopup">
            <div className="titlePopup">
                <p>{t(`${link}.header`)}</p>
            </div>
            <div className="inputs-NewKeyPopup">
                <div className="containerNameKey">
                    <p>{t(`${link}.main.input_1.name`)}</p>
                    <input type="text" placeholder={t(`${link}.main.input_1.placeholder`)} onChange={(e) => {setName(e.target.value)}}/>
                    {errorMessage.name && <p id="error">{errorMessage.name}</p>}
                </div>
                <div className="containerValidity">
                    <p>{t(`${link}.main.input_2.name`)}</p>
                    <div className="containerSelect">
                        <select onChange={(e) => {
                            let number = e.target.value;
                            let text = t(`${link}.main.input_2.no_validity`);
                            if(number !== "indefinido"){
                                let timeCurrent = parseInt((new Date()).getTime() / 1000);
                                number = number * 24 * 60 * 60 + timeCurrent;
                                text = ParseData.parseDate(number);
                                if(text){
                                    text = t(`${link}.main.input_2.placeholder`) + " " + text.day;
                                } else {
                                    text = "error";
                                }
                            } else {
                                number = null;
                            }
                            setValidity({
                                number: number,
                                text: text,
                            });
                        }}>
                            <option value={30} selected>{30 + " "+ t(`${link}.main.input_2.days`)}</option>
                            <option value={15}>{15 + " "+ t(`${link}.main.input_2.days`)}</option>
                            <option value={7}>{7 + " "+ t(`${link}.main.input_2.days`)}</option>
                            <option value="indefinido">{t(`${link}.main.input_2.undefined`)}</option>
                        </select>
                        <p>{validity.text}</p>
                    </div>
                </div>
            </div>
            <div className="containerAddressIP">
                <div className="containerAccess">
                    <p>{t(`${link}.main.input_3.switch`)}</p>
                    <div>
                        <ButtonSwitch trigger={active} setTrigger={setActive}/>
                    </div>
                </div>
                <div className="containerAddressInput">
                    <p>{t(`${link}.main.input_3.name`)}</p>
                    <input 
                        id="input" 
                        type="number" 
                        value={!active ? "" : textInput} 
                        placeholder={t(`${link}.main.input_3.placeholder`)} 
                        onChange={(e) => setTextInput(e.target.value)}
                        disabled={!active}
                    />
                    {errorMessage.address && <p id="error">{errorMessage.address}</p>}
                </div>
                <div className="containerAddIP">
                    <select disabled={!active || listAddressIP.length === 0}>
                        <option>{t(`${link}.main.input_3.option`)}</option>
                        {listAddressIP && listAddressIP.map((element, index) => {
                            return (
                                <option key={index} value={element}>{element}</option>
                            )
                        })}
                    </select>
                    <button 
                        id="buttonAddApi" 
                        className={!active || !document.querySelector("#input").value ? "buttonInactive" : "buttonActive"} 
                        disabled={!active || !document.querySelector("#input").value}
                        onClick={() => {
                            let auxAddresIP = listAddressIP;
                            let input = document.querySelector("#input");
                            let value = input.value;
                            let i = 0;
                            let flag = false;
                            while(i < listAddressIP.length && !flag){
                                if(value === listAddressIP[i]){
                                    flag = !flag;
                                }
                                i++;
                            }
                            let error = "";
                            if(!flag){
                                auxAddresIP.push(textInput);
                                setListAddressIP(auxAddresIP);
                                setTextInput("");
                                value = "";
                            } else {
                                error = t(`${link}.info.error_1`);
                            }
                            setErrorMessage({
                                ...errorMessage,
                                address: error,
                            })
                        }}
                    >
                        {t(`${link}.main.input_3.button`)}
                    </button>
                </div>
            </div>
            <div className="containerButtons">
                <button id="buttonCancel" onClick={() => {
                    setTrigger(false);
                    setApiPopup(true);
                }}>{t(`${link}.buttons.cancel`)}</button>
                <button id="buttonCreate" onClick={() => {
                    let auxName = name.trim();
                    if(typeof auxName === "string"){
                        if(auxName !== ""){
                            let apirestricted = false;
                            let authorizedipaddresses = null;
                            let dateLimit = validity.number;
                            if(active){
                                apirestricted = (listAddressIP.length > 0 ? true : false);
                                authorizedipaddresses = (apirestricted ? listAddressIP : null);
                            }
                            let i = 0;
                            let flag = false;
                            while(i < listKeys.length && !flag){
                                if(auxName === listKeys[i].alias){
                                    flag = !flag;
                                }
                                i++;
                            }
                            if(!flag){
                                externalApi.create(
                                    token,
                                    modelID, 
                                    auxName, 
                                    dateLimit, 
                                    authorizedipaddresses, 
                                    apirestricted
                                )
                                .then(data => {
                                    if(data){
                                        setApiKey({key: data})
                                        setTrigger(false);
                                        setSuccessfullyCreatedKeyPopup(true);
                                    }
                                })
                            } else {
                                setErrorMessage({
                                    address: "",
                                    name: t(`${link}.info.error_2`),
                                });
                            } 
                        } else {
                            setErrorMessage({
                                address: "",
                                name: t(`${link}.info.error_3`),
                            });
                        }
                    }
                }}>{t(`${link}.buttons.create`)}</button>
            </div>
        </div>
    )
}

export default NewKeyApiPopup;