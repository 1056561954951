import http from "../http-common";

function getdashboard() {
    return http.get(`getdashboard/${localStorage.getItem("user_token")}`)
        .then(response => { return response.data });
}

//Retorna os 100 ultimos registros do log do mongodb
function getmongolog() {
    return http.get(`getmongolog/${localStorage.getItem("user_token")}`)
        .then(response => { return response.data });
}

//Retorna todos os logs do spring boot
function getspringbootlog() {
    return http.get(`getspringbootlog/${localStorage.getItem("user_token")}`)
        .then(response => { return response.data })
}

//TODO: alterar o método post para get
//Retorna a memória utiliza pelo Servidor
function getservermemoryusegraph() {
    return http.post(`getservermemoryusegraph/${localStorage.getItem("user_token")}`)
        .then(response => { return response.data })
}

//função que retorna todos os usuários do sistema
function getsystemusers() {
    return http.get(`/getsystemusers/${localStorage.getItem("user_token")}`)
        .then(response => { return response.data })
}

//TODO: alterar o método post para get
//função que retorna os registros do sistema de um usuário em específico pelo seu ID
function getalluserinfo(id) {
    return http.get(`/admin/getalluserinfo/${localStorage.getItem("user_token")}/${id}`)
        .then(response => { return response.data });
}

function modifyuser(user) {
    return http.patch(`/admin/modifyuser/${localStorage.getItem("user_token")}`, user)
        .then((response) => { return response.data.body });

}

//função que retorna os attemp limits do wiseml
function getattemptlimits() {
    return http.post("/getattemptlimits", { token: localStorage.getItem("user_token") })
        .then(response => { return response.data });
}

function deletelog(id_list) {
    return http.delete("/admin/deletelog", {
        data: {
            token: localStorage.getItem("user_token"),
            options: id_list
        }
    })
        .then(response => { return response.data.body });
}

//função que deleta projetos
// id_list é um array de ids
function deleteproject(id_list) {
    return http.delete("/admin/deleteproject", {
        data: {
            token: localStorage.getItem("user_token"),
            options: id_list
        }
    })
        .then(response => { return response.data.body });
}

//função que deleta datasets
// id_list é um array de ids
function deletedataset(id_list) {
    return http.delete("/admin/deletedataset", {
        data: {
            token: localStorage.getItem("user_token"),
            options: id_list
        }
    })
        .then(response => { return response.data.body });
}

//função que deleta models
// id_list é um array de ids
function deletemodel(id_list) {
    return http.delete("/admin/deletemodel", {
        data: {
            token: localStorage.getItem("user_token"),
            options: id_list
        }
    })
        .then(response => { return response.data.body });
}

//função que deleta predictions
// id_list é um array de ids
function deletepredictions(id_list) {
    return http.delete("/admin/deleteprediction", {
        data: {
            token: localStorage.getItem("user_token"),
            options: id_list
        }
    })
        .then(response => { return response.data.body });
}

//função que deleta usuários
// id_list é um array de ids
function deleteuser(id_list) {
    return http.delete("/admin/deleteuser", {
        data: {
            token: localStorage.getItem("user_token"),
            options: id_list
        }
    })
        .then(response => { return response.data.body });
}

//função que deleta attemplimits
// id_list é um array de ids
function deleteattemptlimit(id_list) {
    return http.delete("/admin/deleteattemptlimit", {
        data: {
            token: localStorage.getItem("user_token"),
            options: id_list
        }
    })
        .then(response => { return response.data.body });
}

function deleteanythingbyid(id_list, type) {
    switch (type) {
        case "Log":
            return deletelog(id_list);
        case "Project":
            return deleteproject(id_list);
        case "Dataset":
            return deletedataset(id_list);
        case "Model":
            return deletemodel(id_list);
        case "Prediction":
            return deletepredictions(id_list);
        case "AttemptLimit":
            return deleteattemptlimit(id_list);
        case "User":
            return deleteuser(id_list);
        default:
            return "erro";
    }
}

function createproject(project) {
    return http.post(`/admin/createproject/${localStorage.getItem("user_token")}`, project)
        .then(response => { return response.data.body });
}

const monitoringService = {
    getdashboard,
    getmongolog,
    getspringbootlog,
    getservermemoryusegraph,
    getsystemusers,
    getalluserinfo,
    getattemptlimits,
    modifyuser,
    deleteanythingbyid,
    createproject
};

export default monitoringService;