import { Link, useHistory, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
/* Placeholder até fotos serem implementadas, não remover ↓ */
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { useSelector, useDispatch } from "react-redux";
import "../assets/css/components/sidebar.css";

import TableIcon from "../assets/icons/jsxIcons/TableIcon";
import KmeansIcon from "../assets/icons/jsxIcons/KmeansIcon";
import DecisionTreeIcon from "../assets/icons/jsxIcons/DecisionTreeIcon";
import RandomForestIcon from "../assets/icons/jsxIcons/RandomForestIcon";
import ArrowRightFilledIcon from "../assets/icons/jsxIcons/ArrowRightFilledIcon";
import LinearRegressionIcon from "../assets/icons/jsxIcons/LinearRegressionIcon";

import { Popup } from "./Popup";
import { UserProfilePopup } from "./UserProfilePopup";

import ws from "../services/wiselib";
import projectService from "../services/ProjectService";

import { LogoutPopup } from "../views/popups/actions/LogoutPopup";
import { setUrl } from "../store/slices/url/urlSlice";
import { selectTasksQnt } from "../store/slices/tasks/tasksSlice";
import { selectUser, setNeedRefetchUser, setUser } from "../store/slices/user/userSlice";
import {
   selectNeedUpdateProjectList,
   setNeedUpdateProjectList,
   setProjectList,
} from "../store/slices/projects/projectsSlice";
import Tasks from "./Tasks";
import SelectLanguage from "./SelectLanguage";

import ProjectIcon from "../assets/icons/jsxIcons/ProjectIcon";
import FileIcon from "../assets/icons/jsxIcons/FileIcon";
import { LuHistory } from "react-icons/lu";
import { PiBinoculars } from "react-icons/pi";
import { IoShareSocialOutline } from "react-icons/io5";

import { useTranslation } from "react-i18next";
import "../translation/i18n";
import { setNeedUpdateMyDatasources } from "../store/slices/cache/cacheSlice";
import handleErrorResponse from "../helpers/handleErrorResponse";
import { setTutorialDone } from "../store/slices/wizard/wizardSlice";
import CustomTooltipWrapper from "./CustomTooltip";

export const Sidebar = ({ editProfilePopupTrigger, setEditProfilePopupTrigger }) => {
   const { t } = useTranslation();

   const [sidebarData, setSidebarData] = useState([]);
   // "nivel 1" = botão
   const [selectedBtn, setSelectedBtn] = useState("");
   // "nivel 2" = option
   const [selectedOpt, setSelectedOpt] = useState("");
   // "nivel 3" = sub option
   const [selectedSubOpt, setSelectedSubOpt] = useState("");
   // flag que define se a sidebar aparecerá naquela tela
   const [showSidebar, setShowSidebar] = useState(false);
   /** Trigger do logout popup */
   const [logoutPopup, setLogoutPopup] = useState(false);
   /** hook para obter qual url atual */
   let location = useLocation();
   /* hook do redux para "despachar" uma action*/
   const dispatch = useDispatch();
   /* recuperando state global com useSelector */

   //Objeto que armazena o usuario do state global
   const user = useSelector(selectUser);

   const needUpdateProjectList = useSelector(selectNeedUpdateProjectList);

   let url = location.pathname;

   const tasksQnt = useSelector(selectTasksQnt);

   const history = useHistory();

   const token = localStorage.getItem("user_token");

   const paddingLeft = 20;

   useEffect(() => {
      dispatch(setUrl(url));
      // eslint-disable-next-line
   }, [tasksQnt]);

   //Verifica se o usuário está ativo, caso não esteja será redirecionado para pagina de login
   useEffect(() => {
      if (url !== "/") {
         if (user.email === "" || user.needRefetch) {
            ws.getuser(token)
               .then((response) => {
                  let user = response.data;
                  dispatch(setUser(user));
                  dispatch(setTutorialDone(user.tutorialDone));
                  dispatch(setNeedUpdateProjectList(true));
                  dispatch(setNeedUpdateMyDatasources(true));
                  setShowSidebar(true);
               })
               .catch(() => history.push("/"));
            dispatch(setNeedRefetchUser(false));
         } else {
            setShowSidebar(!url.includes("/monitoring"));
         }
      } else {
         setShowSidebar(false);
      }
      // eslint-disable-next-line
   }, [url, user.needRefetch]);

   useEffect(() => {
      setSelectedOpt("");
      setSelectedSubOpt("");
      if (showSidebar && needUpdateProjectList) {
         dispatch(setNeedUpdateProjectList(false));
         projectService
            .getprojectlist()
            .then((data) => {
               setSidebarData(data);
               dispatch(setProjectList(data));
            })
            .catch((error) => {
               handleErrorResponse(history, error, t);
            });
      }
      // eslint-disable-next-line
   }, [showSidebar, needUpdateProjectList]);

   const renderProjectsSide = () => {
      if (sidebarData === null || typeof sidebarData !== "object") {
         return null;
      }

      return (
         <>
            {/* Renderiza os projetos na sidebar */}
            {sidebarData.map((project) => (
               <React.Fragment key={project.id}>
                  <Link
                     to={project.shared ? `/shared/${project.id}` : `/project/${project.id}`}
                     onClick={() => handleProjectClick(project.id)}
                     className="sidebar-link"
                  >
                     <div className="sidebar-button">
                        {project.shared ? (
                           <>
                              <span>{t("sidebar.buttons.shared")}</span>
                              <IoShareSocialOutline size={24} fill="#282828" />
                           </>
                        ) : (
                           <>
                              <span>{project.name}</span>
                              <span>
                                 <ArrowRightFilledIcon style={{ size: "16" }} />
                              </span>
                           </>
                        )}
                     </div>
                  </Link>
                  {/* Renderiza os datasets do projeto selecionado */}
                  {selectedBtn === project.id && renderDatasets(project)}
               </React.Fragment>
            ))}
         </>
      );
   };

   const renderDatasets = (project) => {
      if (!project.hasOwnProperty("datasets") || project.datasets === null || !Array.isArray(project.datasets)) {
         return null;
      }

      return (
         <>
            {project.datasets.map((dataset) => (
               <div key={dataset.id}>
                  <Link to={`/dataset/${dataset.id}`} onClick={() => handleDatasetClick(dataset.id)}>
                     <div className="sidebar-button">
                        <span style={{ paddingLeft: `${paddingLeft * 1}px` }}>{dataset.name}</span>
                        <span>
                           {" "}
                           <ArrowRightFilledIcon style={{ size: "16" }} />
                        </span>
                     </div>
                  </Link>

                  {selectedOpt === dataset.id && renderAlgorithms(dataset.id)}
               </div>
            ))}
         </>
      );
   };

   const renderAlgorithms = (datasetId) => {
      // Verifica se não há dados na barra lateral ou se não foi selecionado nenhum botão
      if (sidebarData.length === 0 || !selectedBtn) {
         return null;
      }

      // Encontra o projeto selecionado na barra lateral
      const selectedProject = sidebarData.find((project) => project.id === selectedBtn);

      // Verifica se o projeto selecionado não foi encontrado
      if (!selectedProject) {
         return null;
      }

      // Filtra os modelos do projeto selecionado com base no datasetId
      const filteredModels = selectedProject.models.filter((model) => model.dataProxyId === datasetId);

      // Verifica se não há modelos filtrados
      if (filteredModels.length === 0) {
         return null;
      }

      // Renderiza os algoritmos encontrados
      return (
         <>
            {filteredModels.map((algorithm, algorithmIndex) => (
               <div key={algorithm.id}>
                  <Link to={`/model/${algorithm.id}`} onClick={() => handleAlgorithmClick(algorithm.id)}>
                     <div className="sidebar-button">
                        <span style={{ paddingLeft: `${paddingLeft * 2}px` }}>{algorithm.name}</span>
                        <span>{getAlgorithmIcon(algorithm.algorithm)}</span>
                     </div>
                  </Link>
               </div>
            ))}
         </>
      );
   };

   const handleProjectClick = (projectId) => {
      setSelectedBtn(selectedBtn !== projectId ? projectId : "");
   };

   const handleDatasetClick = (datasetId) => {
      setSelectedOpt(selectedOpt !== datasetId ? datasetId : "");
   };

   const handleAlgorithmClick = (algorithmId) => {
      setSelectedSubOpt(selectedSubOpt !== algorithmId ? algorithmId : "");
   };

   const getAlgorithmIcon = (algorithm) => {
      switch (algorithm) {
         case "decisiontree":
            return <DecisionTreeIcon style={{ size: "17" }} />;
         case "linearregression":
            return <LinearRegressionIcon style={{ size: "17" }} />;
         case "randomforest":
            return <RandomForestIcon style={{ size: "17" }} />;
         case "kmeans":
            return <KmeansIcon style={{ size: "17" }} />;
         case "filtrocolaborativo":
            return <TableIcon style={{ size: "17" }} />;
         default:
            return null;
      }
   };

   const renderPicture = () => {
      if (!user.picture) {
         return <AccountCircleIcon style={{ fontSize: 64 }} />;
      } else {
         return <img src={`${user.picture}`} alt={<AccountCircleIcon style={{ fontSize: 64 }} />} />;
      }
   };

   return (
      <>
         {showSidebar && (
            <div id="sidebar" className="container-sidebar">
               <div id="sidebar-menu" className="sidebar-menu">
                  <CustomTooltipWrapper title={t("sidebar.info")}>
                     <div id="beta-warning" className="sidebar-version">
                        <span>{t("sidebar.header")}</span>
                     </div>
                  </CustomTooltipWrapper>
                  <button
                     className="sidebar-profile"
                     onClick={() => {
                        setEditProfilePopupTrigger(!editProfilePopupTrigger);
                     }}
                  >
                     {renderPicture()}
                     <div className="sidebar-profile-name">
                        <span>{user.firstName}</span>
                        <span>{user.lastName}</span>
                     </div>
                  </button>
                  <div className="sidebar-buttons">
                     <div className="sidebar-links-1">
                        {user.isAdmin && (
                           <Link
                              to="/monitoring"
                              onClick={() => {
                                 setSelectedBtn(selectedBtn === "monitoramento" ? "" : "monitoramento");
                              }}
                           >
                              <div
                                 className="sidebar-button"
                                 style={selectedBtn === "monitoramento" ? { background: "#F2F2F2" } : {}}
                              >
                                 <span>{t("sidebar.buttons.monitoring")}</span>
                                 <PiBinoculars size={27} fill="#282828" />
                              </div>
                           </Link>
                        )}
                        <Link
                           to="/myprojects"
                           onClick={() => {
                              setSelectedBtn(selectedBtn === "myprojects" ? "" : "myprojects");
                           }}
                        >
                           <div
                              className="sidebar-button"
                              style={selectedBtn === "myprojects" ? { background: "#F2F2F2" } : {}}
                           >
                              <span>{t("sidebar.buttons.myprojects")}</span>
                              <ProjectIcon />
                           </div>
                        </Link>
                        <span className="sidebar-render-projects">{renderProjectsSide()}</span>
                        <Link
                           to="/mydatasets"
                           onClick={() => {
                              setSelectedBtn(selectedBtn === "mydatasets" ? "" : "mydatasets");
                           }}
                        >
                           <div
                              className="sidebar-button"
                              style={selectedBtn === "mydatasets" ? { background: "#F2F2F2" } : {}}
                           >
                              <span>{t("sidebar.buttons.mydatasets")}</span>
                              <FileIcon />
                           </div>
                        </Link>
                        <Link
                           to="/history"
                           onClick={() => {
                              setSelectedBtn(selectedBtn === "history" ? "" : "history");
                           }}
                        >
                           <div
                              className="sidebar-button"
                              style={selectedBtn === "history" ? { background: "#F2F2F2" } : {}}
                           >
                              <span>{t("sidebar.buttons.history")}</span>
                              <LuHistory size={24} color="#282828" />
                           </div>
                        </Link>
                     </div>
                     <div className="sidebar-links-2">
                        <SelectLanguage titleShow={true} horizontal="right" vertical="center" />
                        <Tasks />
                     </div>
                     <div className="sidebar-logo">
                        <div className="sidebar-logo-1">
                           <img src={require("../assets/images/logo-sidebar.svg").default} alt="logo sidebar" />
                        </div>
                        <div className="sidebar-logo-2">
                           <a href="https://www.wises.com.br/" target="_blank" rel="noopener noreferrer">
                              <img
                                 className="logo"
                                 src={require("../assets/images/wise.svg").default}
                                 alt="logo wise"
                              />
                           </a>
                           <a href="http://lapti.ct.utfpr.edu.br/" target="_blank" rel="noopener noreferrer">
                              <img
                                 className="logo"
                                 src={require("../assets/images/lapti_logo.svg").default}
                                 alt="logo lapti"
                              />
                           </a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         )}
         {editProfilePopupTrigger && (
            <UserProfilePopup
               userFirstName={user.firstName}
               userLastName={user.lastName}
               userEmail={user.email}
               setEditProfilePopupTrigger={setEditProfilePopupTrigger}
               userId={user.id}
            />
         )}
         <Popup trigger={logoutPopup} setTrigger={setLogoutPopup} enableCloseIcon={false}>
            <LogoutPopup setTrigger={setLogoutPopup} />
         </Popup>
      </>
   );
};
