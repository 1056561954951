import React, { useState } from "react";
import { RocCurveChartD3 } from "../../../graphicsComponents/others/RocCurveChartD3";
import { useTranslation } from "react-i18next";
import styles from "../../../assets/css/components/Dataset/Models/modelROCs.module.css";

const ModelRocs = ({ metrics }) => {
   const { t } = useTranslation();
   const [rocCurveIndex, setRocCurveIndex] = useState(0);
   const [categorie, setCategorie] = useState(0);

   const renderContent = () => {
      if (metrics) {
         return (
            <>
               <div className={styles.containerOptions}>
                  <div className={styles.categories}>
                     <span>{t("dataset.models.roc.options.category")}:</span>
                     <select
                        onChange={(e) => {
                           setCategorie(e.target.value);
                        }}
                        className={styles.select}
                     >
                        {metrics?.rocCurveList[0].categories.map(
                           (rocCurve, index) => {
                              return (
                                 <option key={rocCurve} value={index}>
                                    {t("dataset.models.roc.input.field")}{" "}
                                    {rocCurve}
                                 </option>
                              );
                           }
                        )}
                     </select>
                  </div>
                  <div className={styles.models}>
                     <header>
                        <strong>
                           {t("dataset.models.roc.options.models")}
                        </strong>
                     </header>
                     <ul>
                        {metrics.scoreList.map((rocCurve, index) => {
                           return (
                              <li key={rocCurve["weighted"].name}>
                                 {rocCurve["weighted"].name}
                                 <input
                                    type="checkbox"
                                    checked={rocCurveIndex === index}
                                    onClick={() => {
                                       setRocCurveIndex(index);
                                    }}
                                 />
                              </li>
                           );
                        })}
                     </ul>
                  </div>
               </div>
               <div className={styles.rocCurve}>
                  <RocCurveChartD3
                     datas={metrics.rocCurveList[rocCurveIndex].curves}
                     selectedCategory={categorie}
                  />
               </div>
            </>
         );
      } else {
         return <span id="no-data">{t("project.info.no_data")}</span>;
      }
   };

   return <main className={styles.main}>{renderContent()}</main>;
};

export default ModelRocs;
