function ArrowRightFilledIcon({ style }) {
  if (style == null) {
    style = [];
  }

  return (
    <svg
      width={style.hasOwnProperty("size") ? style.size : 24}
      height={style.hasOwnProperty("size") ? style.size : 24}
      style={{cursor: 'pointer'}}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 17.879V6.707C9.00004 6.50925 9.05871 6.31595 9.1686 6.15154C9.27848 5.98713 9.43465 5.85899 9.61735 5.78332C9.80005 5.70765 10.0011 5.68785 10.195 5.72642C10.389 5.76499 10.5671 5.86019 10.707 6L16.293 11.586C16.4805 11.7735 16.5858 12.0278 16.5858 12.293C16.5858 12.5582 16.4805 12.8125 16.293 13L10.707 18.586C10.5671 18.7258 10.389 18.821 10.195 18.8596C10.0011 18.8982 9.80005 18.8783 9.61735 18.8027C9.43465 18.727 9.27848 18.5989 9.1686 18.4345C9.05871 18.27 9.00004 18.0768 9 17.879Z"
        fill={style.hasOwnProperty("color") ? style.color : "#282828"}
      />
    </svg>
  );
}

export default ArrowRightFilledIcon;
