import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    tasksQnt: 0,
    taskIsLoading: false,
    //novo
    taskList: [],
    runningTask: true,
    openModalTask: false,
};

export const tasksSlice = createSlice({
    name: "tasks",
    initialState,
    reducers: {
        incrementTaskQnt: (state) => {
            state.tasksQnt++;
        },
        decrementTaskQnt: (state) => {
            state.tasksQnt--;
            if (state.tasksQnt < 0) {
                state.tasksQnt = 0;
            }
        },
        setTasksQnt: (state, action) => {
            state.tasksQnt = action.payload;
        },
        setTaskIsLoading: (state, action) => {
            state.taskIsLoading = action.payload;
        },


        //novo:
        setTaskList: (state, action) => {
            state.taskList = action.payload;
        },
        setRunningTask: (state, action) => {
            state.runningTask = action.payload;
        },
        setOpenModalTask: (state, action) => {
            state.openModalTask = action.payload;
        }


    },
});

export const {
    incrementTaskQnt,
    decrementTaskQnt,
    setTasksQnt,
    setTaskIsLoading,
    setTaskList,
    setRunningTask,
    setOpenModalTask
} = tasksSlice.actions;
export default tasksSlice.reducer;
export const selectTaskIsLoading = (state) => state.tasks.taskIsLoading;
export const selectTasksQnt = (state) => state.tasks.tasksQnt;
export const selectOpenModalTask = (state) => state.tasks.openModalTask;
