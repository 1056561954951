import React, { useState } from "react";

import "../../../assets/css/views/home/start_page.css";

import { useTranslation } from "react-i18next";
import "./../../../translation/i18n";
import LoginCard from "./LoginCard";
import SignupCard from "./SignupCard";

import CustomTooltipWrapper from "../../../components/CustomTooltip";

export const AuthCard = () => {
   /** Bool que determina se a aba do cartão é login ou cadastro */
   const [loginTab, setLoginTab] = useState(true);

   const { t } = useTranslation();

   const toogleTabLogin = () => {
      setLoginTab(true);
   };

   const toogleTabSignUp = () => {
      setLoginTab(false);
   };

   return (
      <div className="auth-card">
         <div className="tabs">
            <button className={loginTab ? "tab--selected" : "login-tab"} onClick={toogleTabLogin}>
               {t("home.startpage.card.header.login")}
            </button>
            <button className={loginTab ? "signup-tab" : "tab--selected"} onClick={toogleTabSignUp}>
               {t("home.startpage.card.header.register")}
            </button>
         </div>
         <CustomTooltipWrapper placement="top" title={t("sidebar.info")}>
            <div id="beta-warning">{t("home.startpage.card.content.version")}</div>
         </CustomTooltipWrapper>
         {loginTab ? <LoginCard /> : <SignupCard setLoginTab={setLoginTab} />}
      </div>
   );
};
