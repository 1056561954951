function DoneIcon({ style }) {
    if(style == null){
        style = [];
    }
  return (
    <svg
      width={style.hasOwnProperty('width') ? style.width : 10}
      height={style.hasOwnProperty('height') ? style.height : 6}
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.66641 4.95953L1.70691 3.00003C1.61314 2.90629 1.48599 2.85363 1.35341 2.85363C1.22083 2.85363 1.09367 2.90629 0.999909 3.00003C0.906173 3.09379 0.853516 3.22094 0.853516 3.35353C0.853516 3.48611 0.906173 3.61326 0.999909 3.70703L2.95941 5.66653C3.05227 5.75943 3.16253 5.83312 3.28388 5.8834C3.40523 5.93368 3.5353 5.95956 3.66666 5.95956C3.79801 5.95956 3.92808 5.93368 4.04944 5.8834C4.17079 5.83312 4.28105 5.75943 4.37391 5.66653L8.99991 1.04053C9.09364 0.946763 9.1463 0.819609 9.1463 0.687027C9.1463 0.554445 9.09364 0.427291 8.99991 0.333527C8.90614 0.239791 8.77899 0.187134 8.64641 0.187134C8.51383 0.187134 8.38667 0.239791 8.29291 0.333527L3.66641 4.95953Z"
        fill={style.hasOwnProperty('color') ? style.color : "#282828"}
      />
    </svg>
  );
}

export default DoneIcon;
