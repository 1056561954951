import { React, useState, useEffect, useCallback } from "react";
import { Switch, Route, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Loader } from "semantic-ui-react";

import "../../assets/css/views/screens/myprojects.css";

import ProjectAddIcon from "../../assets/icons/jsxIcons/ProjectAddIcon";

import { Card } from "../../components/Card";
import SearchBar from "../../components/SearchBar";
import { Popup } from "../../components/Popup";

import dp from "./../../helpers/data_parse";

import NewProjectPopup from "../popups/creations/NewProjectPopup";
import CheckboxLabeled from "../../components/CheckboxLabeled";
import TrashIcon from "../../assets/icons/jsxIcons/TrashIcon";
import { DeletePopup } from "../popups/actions/DeletePopup";

import { useTranslation } from "react-i18next";
import "./../../translation/i18n";
import MyProjectTour from "../../components/Wizard/MyProjectTour";
import { selectTutorialDone } from "../../store/slices/wizard/wizardSlice";

export const MyProjects = () => {
   const { t } = useTranslation();

   //State que armazenará os cards de projetos vindo da projectList
   const [projectCards, setProjectCards] = useState([]);
   // Trigger para abrir o pop-up de novo projeto
   const [newProjectPopup, setNewProjectPopup] = useState(false);
   // Trigger para abrir o pop-up de deletar projetos
   const [deleteProjectPopup, setDeleteProjectPopup] = useState(false);
   // State que armazenará todos os componentes de cards de projetos
   const [content, setContent] = useState([]);
   // State que armazena o termo procurado na search bar
   const [searchedTerm, setSearchedTerm] = useState("");
   // State que armazena o termo com um delay para não renderizar a cada digito
   const [debouncedTerm, setDebouncedTerm] = useState("");
   // State global do redux de todos os projetos
   const projectList = useSelector((state) => state.projects.projectList);
   // State com todas as opcões de ordenação
   const [optionSort, setOptionSort] = useState("Mais recente");
   // State com os ids dos projetos selecionados
   const [selectedProjects, setSelectedProjects] = useState([]);
   //flag que define se select all está ativo
   const [isSelectedActive, setIsSelectedActive] = useState(false);

   const [isLoading, setIsLoading] = useState(true);

   const tutorialDone = useSelector(selectTutorialDone);

   useEffect(() => {
      // Após o projectList carregar(sempre terá um projeto pelo menos(compartilhados)) o loader desativa
      if (projectList.length > 0) {
         setIsLoading(false);
      }
      setProjectCards(projectList);
   }, [projectList]);

   const handleProjectSelection = useCallback(
      (e, projectId) => {
         e?.preventDefault();
         // Verifico se o projeto já está selecionado
         const isSelected = selectedProjects.includes(projectId);

         if (isSelected) {
            // Se estiver selecionado, removo da lista de projetos selecionados
            setSelectedProjects(selectedProjects.filter((id) => id !== projectId));
         } else {
            // Se não estiver selecionado, adiciono à lista de projetos selecionados
            setSelectedProjects([...selectedProjects, projectId]);
         }
      },
      [selectedProjects],
   );

   useEffect(() => {
      if (projectCards.length > 0) {
         let projectsCardsAux = projectCards.slice();
         let filteredProjects = projectsCardsAux.filter(
            (project) => (project.shared && project?.models?.length > 0) || !project.shared,
         );
         let cardsToRender = dp
            .searchSort(optionSort, filteredProjects)
            .filter((value) => value.name.toLowerCase().trim().includes(searchedTerm.toLowerCase().trim()))
            .map((project) => {
               let sizeDatasets = project.datasets.length;
               let sizeModels = project.models.length;

               return (
                  <Link
                     to={!isSelectedActive && `${project?.shared ? "/shared/" : "/project/"}` + project.id}
                     key={project.id}
                     onClick={(e) => {
                        if (e.ctrlKey || e.metaKey || isSelectedActive) {
                           if (!project.shared) handleProjectSelection(e, project.id);
                           else e.preventDefault();
                           !isSelectedActive && setIsSelectedActive(true);
                        }
                     }}
                  >
                     <Card
                        type="project"
                        name={project.shared ? t("myprojects.card.title.shared") : project.name}
                        description={project.description}
                        datasets={sizeDatasets}
                        models={sizeModels}
                        date={project.date}
                        elementId={project.id}
                        isSharedProject={project.shared}
                     />
                  </Link>
               );
            });
         if (cardsToRender.length === 0) {
            setContent([
               <div key="empty" className="noProjects">
                  <p>{t("myprojects.empty")}</p>
               </div>,
            ]);
         } else {
            setContent(cardsToRender);
         }
      }
   }, [projectCards, searchedTerm, optionSort, selectedProjects, isSelectedActive, t, handleProjectSelection]);

   useEffect(() => {
      const timer = setTimeout(() => {
         setSearchedTerm(debouncedTerm);
      }, 1000);
      return () => clearTimeout(timer);
   }, [debouncedTerm]);

   return (
      <>
         <Switch>
            <Route path="/myprojects">
               <div className="myprojects">
                  <div className="title">
                     <p>{t("myprojects.header.title")}</p>
                  </div>
                  <div className="header">
                     <SearchBar
                        onChange={(e) => setDebouncedTerm(e.target.value)}
                        onChangeOption={setOptionSort}
                        enableSort={true}
                     />
                  </div>
                  <div className="section-title">
                     <div>
                        <p>{t("myprojects.content.title")}</p>
                        <button
                           id="icon-new-project"
                           onClick={() => {
                              // impedir de ir pra /myprojects imediatamente
                              // e.preventDefault();
                              setNewProjectPopup(true);
                           }}
                        >
                           <ProjectAddIcon />
                        </button>
                     </div>
                     <div className="select-all">
                        {isSelectedActive && selectedProjects.length > 0 && (
                           <button className="trash-icon" onClick={() => setDeleteProjectPopup(true)}>
                              <TrashIcon />
                           </button>
                        )}
                        {projectCards.length > 0 && (
                           <CheckboxLabeled
                              isSelectedActive={isSelectedActive}
                              setIsSelectedActive={setIsSelectedActive}
                              setSelectedIds={setSelectedProjects}
                              selectedIds={selectedProjects}
                              cards={projectCards}
                           />
                        )}
                     </div>
                  </div>

                  {isLoading && <Loader active />}
                  {isSelectedActive ? (
                     <div className="conteudo">
                        {content.map((project, index) => (
                           <div
                              key={project.key}
                              className={`project-card background ${
                                 selectedProjects.includes(project.key) ? "selected" : ""
                              }`}
                           >
                              <input
                                 type="checkbox"
                                 className="checkbox"
                                 checked={selectedProjects.includes(project.key)}
                                 disabled={project.props.children?.props.isSharedProject}
                                 onChange={() => handleProjectSelection(undefined, project.key)}
                              />
                              {project}
                           </div>
                        ))}
                     </div>
                  ) : (
                     <div className="conteudo">{content}</div>
                  )}
               </div>
            </Route>
         </Switch>
         <Popup trigger={newProjectPopup} setTrigger={setNewProjectPopup}>
            <NewProjectPopup setTrigger={setNewProjectPopup} />
         </Popup>
         <Popup trigger={deleteProjectPopup} setTrigger={setDeleteProjectPopup}>
            <DeletePopup
               cardtype={"project"}
               modelResultId={selectedProjects}
               setSelectedArray={setSelectedProjects}
               setIsSelectedActive={setIsSelectedActive}
               setTrigger={setDeleteProjectPopup}
            />
         </Popup>
         {!tutorialDone && <MyProjectTour />}
      </>
   );
};
