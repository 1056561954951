import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    memoriesMongoDB: [],
    memoriesJava: [],
    memoriesServer: [],
    cpudiskServer: [],
    loggedUsers: [],
    dashBoardUpdated: false,
    mongoDBLogs: [],
    mongoDBLogsUpdated: false,
    springLogs: [],
    springLogsUpdated: false,
};

export const monitoringSlice = createSlice({
    name: "monitoring",
    initialState,
    reducers: {
        setMemoriesMongoDB: (state, action) => {
            state.memoriesMongoDB = action.payload;
        },
        setMemoriesJava: (state, action) => {
            state.memoriesJava = action.payload;
        },
        setMemoriesServer: (state, action) => {
            state.memoriesServer = action.payload;
        },
        setCpudiskServer: (state, action) => {
            state.cpudiskServer = action.payload;
        },
        setLoggedUsers: (state, action) => {
            state.loggedUsers = action.payload;
        },
        setDatashBoardUpdated: (state, action) => {
            state.dashBoardUpdated = action.payload;
        },

        setMongoDBLogs: (state, action) => {
            state.mongoDBLogs = action.payload;
        },
        setMongoDBLogsUpdated: (state, action) => {
            state.mongoDBLogsUpdated = action.payload;
        },

        setSpringLogs: (state, action) => {
            state.springLogs = action.payload;
        },
        setSpringLogsUpdated: (state, action) => {
            state.springLogsUpdated = action.payload;
        },
    },
});

export const { 
    setMemoriesMongoDB,
    setMemoriesJava,
    setMemoriesServer,
    setCpudiskServer,
    setLoggedUsers,
    setDatashBoardUpdated,
    setMongoDBLogs,
    setMongoDBLogsUpdated,
    setSpringLogs,
    setSpringLogsUpdated
} = monitoringSlice.actions;

export const selectMemoriesMongoDB = (state) => state.monitoring.memoriesMongoDB;
export const selectMemoriesJava = (state) => state.monitoring.memoriesJava;
export const selectMemoriesServer = (state) => state.monitoring.memoriesServer;
export const selectCpudiskServer = (state) => state.monitoring.cpudiskServer;
export const selectLoggedUsers = (state) => state.monitoring.loggedUsers;
export const selectDashBoardUpdated = (state) => state.monitoring.dashBoardUpdated;
export const selectMongoDBLogs = (state) => state.monitoring.mongoDBLogs;
export const selectMongoDBLogsUpdated = (state) => state.monitoring.mongoDBLogsUpdated;
export const selectSpringLogs = (state) => state.monitoring.springLogs;
export const selectSpringLogsUpdated = (state) => state.monitoring.springLogsUpdated;


export default monitoringSlice.reducer;
