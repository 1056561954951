import * as d3 from "d3";
import React, { useEffect, useRef, useState } from "react";

export const DecisionTreeD3 = ({ result, fieldList, setCaminhoAtual }) => {
   // inicializa um Ref
   const [currentData, setCurrentData] = useState(result);
   const d3Chart = useRef();
   const currentNode = useRef();
   const colorPalette = require("../../assets/data/colorPalette.json");
   const modelColors = colorPalette.models;
   const predictTrueColor = colorPalette.decision.predictTrue;
   const predictFalseColor = colorPalette.decision.predictFalse;

   // vetor com as paletas de cores indicadas no figma

   // Key Performance Indicator (indicador de performance)
   // comumente usado para atribuir cores referentes aos valores
   // return preditcTrueColor
   function DecisionTreeKPI(node) {
      if (node.data.Feature === undefined) {
         if (node.data.Predict === "1.0" || node.data.Predict === 1) {
            return predictTrueColor;
         } else {
            return predictFalseColor;
         }
      } else if (fieldList !== null && fieldList !== undefined) {
         for (let i = 0; i < fieldList.length; i++) {
            if (i > modelColors.length) {
               return modelColors[modelColors.length - (i % modelColors.length)];
            } else if (fieldList[i] === node.data.Feature) {
               return modelColors[i];
            }
         }
      }
      return "#404040";
   }

   // função que desenha o line chart de fato - feito em função pra poder ser chamado tanto no use effect
   // onMount quanto no side effect que é ativado quando o secondary target for alterado
   function desenharDecisionTree() {
      /** Necessário pra evitar que quebre em algumas situações específicas */
      if (currentData === null || currentData === undefined) {
         return;
      }

      d3.select("#decisiontree-graph").remove();
      d3.select("#decisiontree-links").remove();
      d3.select("#decisiontree-nodes").remove();

      const margin = { top: -55, right: 10, bottom: 90, left: -30 };
      const width = parseInt(d3.select(".model-result-1").style("width"), 10) - margin.left - margin.right;
      const height = parseInt(d3.select(".model-result-1").style("height"), 10) - margin.top - margin.bottom;
      const svg = d3
         .select(d3Chart.current)
         .attr("viewBox", `0 0 ${width} ${height}`) // Define a caixa de visualização
         .attr("preserveAspectRatio", "xMidYMid meet") // Mantém a proporção do gráfico
         .append("g")
         .attr("transform", `translate(${margin.left},${margin.top})`);

      // Apenas cria um grupo do gráfico (contem grupos nodes e links, e desloca a posição)
      let graph = svg.append("g").attr("id", "decisiontree-graph").attr("transform", "translate(50,50)");

      // Gera nós em formato usado pelo d3
      let hierarchy = d3.hierarchy(currentData);

      // Cria a estrutura da Árvore
      // ver quantas têm
      // dividir? nesse número
      // -> identar resultado contando
      let tree = d3.tree().size([width - 40, height - 0]);

      // Popula a Árvore com os dados
      let myTree = tree(hierarchy);

      // Faz uma seleção para os nós e associa dados
      const nodes = graph.selectAll(".node").data(myTree.descendants());
      // get link selection and join data
      let links = graph.selectAll(".link").data(myTree.links());

      // adicionar ligações
      links
         .enter()
         .append("path")
         .attr("id", "decisiontree-links")
         // 0 pra não sumir
         .transition()
         .duration(0)
         .attr("class", "link")
         .attr("fill", "none")
         .attr("stroke", "#aaa")
         .attr("stroke-width", 2)
         .attr(
            "d",
            d3
               .linkVertical()
               .x((d) => d.x)
               .y((d) => d.y),
         )
         .transition();

      // adiciona os círculos nas posições dos nós
      nodes
         .enter()
         .append("g")
         .attr("id", "decisiontree-nodes")
         .on("click mouseover", (event) => handleEvent(event))
         .attr("class", "node")
         .attr("transform", (node) => `translate(${node.x},${node.y})`)
         .append("circle")
         .attr("r", 15)
         .attr("fill", (node) => {
            return DecisionTreeKPI(node);
         });
      // .transition()
      // .attr("cy", (node) => 25);
   }

   useEffect(() => {
      desenharDecisionTree();
      // eslint-disable-next-line
   }, [currentData]);

   useEffect(() => {
      desenharDecisionTree();
      // eslint-disable-next-line
   }, [fieldList]);

   function handleEvent(event) {
      let target = event.target;
      let ancestors = target.__data__.ancestors();
      switch (event.type) {
         case "click":
            handleClick(target, ancestors);
            break;
         case "mouseover":
            handleMouseOver(target, ancestors);
            break;
         default:
      }
   }

   function handleClick(target, ancestors) {
      let targetNode = target.__data__;
      let rootNode = ancestors[parseInt(ancestors.length) - 1];
      if (targetNode === rootNode || currentData !== result) setCurrentData(result);
      else {
         let selectedNodePath = recursiveNodeConvert(rootNode, ancestors);
         setCurrentData(selectedNodePath);
      }
   }

   function recursiveNodeConvert(node, ancestors) {
      if (node.children == null || node.children === undefined) return { Predict: node.data.Predict };
      return {
         children: node.children
            .filter((childNode) => ancestors.includes(childNode.parent))
            .map((childNode) => recursiveNodeConvert(childNode, ancestors)),
         Operation: node.data.Operation,
         Feature: node.data.Feature,
         Threshold: node.data.Threshold,
         Side: node.data.Side,
      };
   }

   function handleMouseOver(target, ancestors) {
      let currentNodePath = [];
      ancestors.forEach((node) => {
         currentNodePath.unshift({
            Feature: node.data.Feature !== undefined ? node.data.Feature : "Predict",
            Threshold: node.data.Threshold !== undefined ? node.data.Threshold : node.data.Predict,
            Operation: node.data.Operation,
            Side: node.data.Side,
         });
      });
      setCaminhoAtual(currentNodePath);

      if (currentNode.current != null && currentNode.current !== undefined) {
         d3.select(currentNode.current)
            .attr("fill", (node) => DecisionTreeKPI(node))
            .attr("stroke", "transparent")
            .attr("stroke-width", 0);
      }

      d3.select(target).attr("fill", "#333").attr("stroke", "#aaa").attr("stroke-width", 3);

      currentNode.current = target;
   }

   return <svg ref={d3Chart} id="decisiond3-svg" />;
};
