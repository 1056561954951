import { BrowserRouter as Router, Route } from "react-router-dom";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./app.css";

import { Sidebar } from "../components/Sidebar";
import { Home } from "./home/Home";
import History from "./screens/History";
import { Project } from "./screens/Project";
import { Dataset } from "./screens/Dataset";
import { MyProjects } from "./screens/MyProjects";
import MyDatasets from "./screens/MyDatasets";

import Monitoring from "./../monitoring/Monitoring";
import NotificationQuickStart from "../components/NotificationQuickStart";
import { Datasource } from "./screens/Datasource";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Model } from "./screens/Model/Model";
import { Shared } from "./screens/SharedPage/Shared";

const App = () => {
   const { i18n } = useTranslation();
   const [editProfilePopupTrigger, setEditProfilePopupTrigger] = useState(false);

   useEffect(() => {
      const lng = navigator.language;
      const storageLanguage = localStorage.getItem("language");
      if (storageLanguage) {
         i18n.changeLanguage(storageLanguage);
      } else {
         if (lng.includes("en")) {
            i18n.changeLanguage("en_us");
            localStorage.setItem("language", "en_us");
         } else if (lng.includes("es")) {
            i18n.changeLanguage("es_es");
            localStorage.setItem("language", "es_es");
         } else if (lng.includes("fr")) {
            i18n.changeLanguage("fr_fr");
            localStorage.setItem("language", "fr_fr");
         } else if (lng.includes("pt-PT")) {
            i18n.changeLanguage("pt_pt");
            localStorage.setItem("language", "pt_pt");
         } else {
            i18n.changeLanguage("pt_br");
            localStorage.setItem("language", "pt_br");
         }
      }
      // eslint-disable-next-line
   }, []);

   return (
      <span className="App">
         <Router>
            <Sidebar
               setEditProfilePopupTrigger={setEditProfilePopupTrigger}
               editProfilePopupTrigger={editProfilePopupTrigger}
            />
            <NotificationQuickStart />

            <Route exact path="/" component={Home}></Route>
            <Route path="/myprojects">
               <MyProjects title="Meus projetos" />
            </Route>
            <Route path="/mydatasets">
               <MyDatasets />
            </Route>
            {/* Componente de tela 'Model' com pageId dinâmico, props e as respectivas flags */}
            <Route
               path="/model/:pageId"
               render={(props) => <Model {...props} />}
            />
            {/* Componente de tela 'Dataset' com pageId dinâmico, props e as respectivas flags */}
            <Route
               path="/dataset/:pageId"
               render={(props) => <Dataset {...props} />}
            />
            {/* Componente de tela 'Dataset' com pageId dinâmico, props e as respectivas flags */}
            <Route
               path="/datasource/:pageId"
               render={(props) => <Datasource {...props} />}
            />
            <Route
               path="/project/:pageId"
               render={(props) => <Project {...props} />}
            />
            <Route
               path="/shared/:pageId"
               render={(props) => <Shared {...props} />}
            />
            <Route path="/history">
               <History />
            </Route>
            <Route path="/monitoring">
               <Monitoring />
            </Route>
         </Router>
         <ToastContainer />
      </span>
   );
};

export default App;
