import ws from "../services/wiselib";

/**
 * O arquivo data_parse contém diferentes funções para
 * transformar, converter, adaptar e requisitar dados
 * de acordo com necessidade.
 */

/**
 * Função que remove a vírgula excedente que é retornada pelo
 * back-end em alguns algoritmos, impossibilitando a utilização
 *
 * https://stackoverflow.com/questions/17720264/remove-last-comma-from-a-string
 */
function removeExtraComma(string) {
   let regex = /,(?!\s*?[{["'\w])/g;
   let correct = string.replace(regex, "");
   return correct;
}

/**
 * Função que remove os decimais excedentes
 * de um float ou double quaisquer
 */
function removeDecimals(num) {
   if (typeof num === "number") {
      if (num % 1 !== 0) {
         return num.toFixed(2);
      } else return num;
   } else {
      return num;
   }
}

/**
 * Função que remove os decimais excedentes
 * de um float ou double quaisquer, especificamente
 * para o histograma
 */
function removeHistogramDecimals(str) {
   var labels = [];

   for (var i = 0; i < str.length; i++) {
      /**
       * Recebe 'valorMin - valorMax' e separa
       * ambos em vetores, utilizando a substring
       * " - " como separador.
       */
      var y = str[i].split(" / ");
      if (y.length < 2) {
         labels.push(y[0]);
      } else {
         /** Transforma as strings em float */
         var min = parseFloat(y[0]);
         var max = parseFloat(y[1]);
         /**
          * Transforma os floats em uma string usando
          * concatenação e os adiciona ao vetor 'labels',
          * com suas casas devidamente ajustadas
          */
         labels.push(min.toFixed(2) + " - " + max.toFixed(2));
      }
   }

   return labels;
}

/**
 * Função que transforma um float ou double em int
 */
function toInt(num) {
   if (typeof num === "number") {
      if (num % 1 !== 0) {
         return num.toFixed(0);
      } else return num;
   } else {
      return num;
   }
}

/**
 * Função que transforma uma data em formato puramente
 * numérico para string visualizável
 */
function epochdateparse(moment) {
    const lng = localStorage.getItem("language");

    let date = new Date(moment * 1000);

    if (lng === "pt_br" || lng === "pt_pt") {
        const optionsDate = {
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "numeric",
        };
        const optionsTime = {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
        };
        let formattedDate = date.toLocaleDateString("pt-BR", optionsDate).replace(/de /g, "").replace(/\./g, "");
        formattedDate = formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
        const formattedTime = date.toLocaleTimeString("pt-BR", optionsTime);
        return `${formattedDate} ${formattedTime}`;
    } else {
        let dateSeconds = date.toString();
        dateSeconds = dateSeconds.split("GMT")[0];
        return dateSeconds;
    }
}

function parseDate(moment) {
   var dateSeconds = new Date(moment * 1000).toString();
   const arr = dateSeconds.split(" ");
   //let weekday = arr[0]
   let month = arr[1];
   let day = arr[2];
   let year = arr[3];
   let hour = arr[4];

   var seconds = hour.lastIndexOf(":");
   hour = hour.substring(0, seconds);

   return day + " " + month + " " + year + " às " + hour;
}

function parseType(type) {
   if (type.includes("upload")) {
      return "Upload";
   } else if (type.includes("histogram")) {
      return "Histograma";
   } else if (type.includes("ALSPrediction")) {
      return "Predição de Filtro colaborativo";
   } else if (type.includes("DecisionTreePrediction")) {
      return "Predição de Árvore de decisão";
   } else if (type.includes("RandomForestPrediction")) {
      return "Predição de Floresta aleatória";
   } else if (type.includes("KmeansPrediction")) {
      return "Predição de Cluster";
   } else if (type.includes("LinearRegressionPrediction")) {
      return "Predição de Regressão linear";
   } else if (type.includes("ALS")) {
      return "Filtro colaborativo";
   } else if (type.includes("import")) {
      return "Import";
   } else if (type.includes("DecisionTree")) {
      return "Árvore de decisão";
   } else if (type.includes("RandomForest")) {
      return "Floresta aleatória";
   } else if (type.includes("Kmeans")) {
      return "Cluster";
   } else if (type.includes("LinearRegression")) {
      return "Regressão linear";
   }
}

function parseDiffTime(moment) {
   let dateNow = new Date().getTime();
   let dif = new Date(dateNow - moment * 1000);
   let hour = dif.getUTCHours() + "h ";
   let min = dif.getUTCMinutes() + "m ";
   //let sec = dif.getUTCSeconds() + "s "
   let res = "";

   if (!hour.includes("0")) {
      res = hour + min;
      return res;
   } else {
      return min;
   }
}

function base64ToUrl(binaryData) {
   const byteArray = new Uint8Array(binaryData.length);
   for (let i = 0; i < binaryData.length; i++) {
      byteArray[i] = binaryData.charCodeAt(i);
   }
   // Divide o byteArray em pedaços menores para evitar o erro RangeError
   const CHUNK_SIZE = 0x8000;
   let base64String = "";
   for (let i = 0; i < byteArray.length; i += CHUNK_SIZE) {
      const chunk = byteArray.subarray(i, i + CHUNK_SIZE);
      base64String += String.fromCharCode.apply(null, chunk);
   }
   // Converte o array binário de volta para uma string base64
   base64String = btoa(base64String);
   // Atualiza a URL da imagem com a string base64
   return "data:image/jpeg;base64," + base64String;
}

function parseMetric(name) {
   if (name === "weightedFalsePositiveRate") {
      return "Taxa de Falsos Positivo Ponderada";
   } else if (name === "weightedFMeasure") {
      return "Media F Ponderada";
   } else if (name === "falsePositiveRateByLabel") {
      return "Taxa de Falsos positivo por Rótulo";
   } else if (name === "trueNegativeRateByLabel") {
      return "Taxa de Verdadeiros Negativo por Rótulo";
   } else if (name === "accuracy") {
      return "Precisão";
   } else if (name === "weightedPrecision") {
      return "Precisão Ponderada";
   } else if (name === "weightedTruePositiveRate") {
      return "Taxa de Verdadeiros Positivo Pontedara";
   } else if (name === "precisionByLabel") {
      return "Precisão por Rótulo";
   } else if (name === "fMeasureByLabel") {
      return "Media F por Rótulo";
   } else if (name === "weightedRecall") {
      return "Recall Ponderada";
   } else if (name === "truePositiveRateByLabel") {
      return "Taxa de Verdadeiros Positivo por Rótulo";
   } else if (name === "falseNegativeRateByLabel") {
      return "Taxa de Falsos Negativo por Rótulo";
   } else if (name === "hammingLoss") {
      return "Perda de Hamming";
   }
   return name;
}

function searchSort(optionSort, data) {
   // Create a copy of the array using the spread operator
   const dataCopy = [...data];

   switch (optionSort) {
      case "Mais recente":
         dataCopy.sort((a, b) => b.date - a.date);
         break;
      case "Mais antigo":
         dataCopy.sort((a, b) => a.date - b.date);
         break;
      case "A - Z":
         dataCopy.sort((a, b) => a.name.localeCompare(b.name));
         break;
      case "Z - A":
         dataCopy.sort((a, b) => b.name.localeCompare(a.name));
         break;
      default:
         break;
   }

   // Verifica se o elemento "Compartilhados" existe no array
   const compartilhadosIndex = dataCopy.findIndex((item) => item.shared);

   if (compartilhadosIndex !== -1) {
      // Remove o elemento "Compartilhados" do array
      const compartilhados = dataCopy.splice(compartilhadosIndex, 1)[0];
      // Adiciona o elemento "Compartilhados" no início do array
      dataCopy.unshift(compartilhados);
   }

   return dataCopy;
}

const data_parse = {
   removeExtraComma,
   removeDecimals,
   removeHistogramDecimals,
   toInt,
   epochdateparse,
   parseDate,
   parseType,
   parseDiffTime,
   parseMetric,
   base64ToUrl,
   searchSort,
};

export default data_parse;
