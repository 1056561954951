function PencilIcon({ style }) {
    if (style == null) {
        style = [];
    }
    return (
        <svg 
            width={style.hasOwnProperty("size") ? style.size : 18}
            height={style.hasOwnProperty("size") ? style.size : 18}
            viewBox="0 0 18 18" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_3080_2611)">
                <path d="M17.1398 0.860965C16.6299 0.351863 15.9388 0.065918 15.2183 0.065918C14.4977 0.065918 13.8066 0.351863 13.2968 0.860965L1.09876 13.059C0.74945 13.4063 0.472483 13.8195 0.283885 14.2746C0.0952868 14.7296 -0.00119848 15.2176 1.12359e-05 15.7102V17.25C1.12359e-05 17.4489 0.0790289 17.6396 0.219681 17.7803C0.360333 17.9209 0.551099 18 0.750011 18H2.28976C2.78233 18.0014 3.27029 17.905 3.72538 17.7166C4.18047 17.5281 4.59365 17.2512 4.94101 16.902L17.1398 4.70322C17.6486 4.19336 17.9344 3.50244 17.9344 2.78209C17.9344 2.06174 17.6486 1.37082 17.1398 0.860965ZM3.88051 15.8415C3.45751 16.2617 2.88598 16.4982 2.28976 16.5H1.50001V15.7102C1.49925 15.4146 1.55713 15.1219 1.67029 14.8488C1.78345 14.5758 1.94965 14.3279 2.15926 14.1195L11.4165 4.86222L13.1415 6.58722L3.88051 15.8415ZM16.0785 3.64272L14.199 5.52297L12.474 3.80172L14.3543 1.92147C14.4675 1.80845 14.6019 1.71884 14.7498 1.65777C14.8977 1.5967 15.0562 1.56536 15.2162 1.56553C15.3762 1.56571 15.5346 1.59739 15.6824 1.65879C15.8301 1.72018 15.9644 1.81008 16.0774 1.92334C16.1904 2.03661 16.28 2.17102 16.3411 2.31892C16.4022 2.46681 16.4335 2.62528 16.4333 2.78529C16.4331 2.9453 16.4015 3.1037 16.3401 3.25146C16.2787 3.39923 16.1888 3.53345 16.0755 3.64647L16.0785 3.64272Z" fill="#282828" />
            </g>
            <defs>
                <clipPath id="clip0_3080_2611">
                    <rect width="18" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default PencilIcon;