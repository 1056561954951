import React from "react";
import {
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   Button,
   Typography,
   List,
   ListItem,
   ListItemText,
} from "@material-ui/core";
import { OpenInNew } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

const algorithmDetails = {
   "Decision Tree": {
      description: "how_to.DecisionTree.description",
      usage: "how_to.DecisionTree.usage",
      examples: [
         {
            name: "Titanic: Machine Learning from Disaster",
            url: "https://www.kaggle.com/c/titanic",
         },
         {
            name: "Heart Disease UCI",
            url: "https://www.kaggle.com/ronitf/heart-disease-uci",
         },
      ],
      advantages: ["how_to.DecisionTree.advantages.0", "how_to.DecisionTree.advantages.1"],
      disadvantages: ["how_to.DecisionTree.disadvantages.0", "how_to.DecisionTree.disadvantages.1"],
   },
   "Random Forest": {
      description: "how_to.RandomForest.description",
      usage: "how_to.RandomForest.usage",
      examples: [
         {
            name: "Loan Prediction",
            url: "https://www.kaggle.com/altruistdelhite04/loan-prediction-problem-dataset",
         },
         {
            name: "House Prices: Advanced Regression Techniques",
            url: "https://www.kaggle.com/c/house-prices-advanced-regression-techniques",
         },
      ],
      advantages: ["how_to.RandomForest.advantages.0", "how_to.RandomForest.advantages.1"],
      disadvantages: ["how_to.RandomForest.disadvantages.0", "how_to.RandomForest.disadvantages.1"],
   },
   "Linear Regression": {
      description: "how_to.LinearRegression.description",
      usage: "how_to.LinearRegression.usage",
      examples: [
         {
            name: "House Prices",
            url: "https://www.kaggle.com/c/house-prices-advanced-regression-techniques",
         },
         {
            name: "Boston Housing",
            url: "https://www.kaggle.com/c/boston-housing",
         },
      ],
      advantages: ["how_to.LinearRegression.advantages.0", "how_to.LinearRegression.advantages.1"],
      disadvantages: ["how_to.LinearRegression.disadvantages.0", "how_to.LinearRegression.disadvantages.1"],
   },
   "Gradient Boosting": {
      description: "how_to.GradientBoosting.description",
      usage: "how_to.GradientBoosting.usage",
      examples: [
         {
            name: "Otto Group Product Classification Challenge",
            url: "https://www.kaggle.com/c/otto-group-product-classification-challenge",
         },
         {
            name: "Rossmann Store Sales",
            url: "https://www.kaggle.com/c/rossmann-store-sales",
         },
      ],
      advantages: ["how_to.GradientBoosting.advantages.0", "how_to.GradientBoosting.advantages.1"],
      disadvantages: ["how_to.GradientBoosting.disadvantages.0", "how_to.GradientBoosting.disadvantages.1"],
   },
   "Support Vector Machine": {
      description: "how_to.SupportVectorMachine.description",
      usage: "how_to.SupportVectorMachine.usage",
      examples: [
         {
            name: "Digit Recognizer",
            url: "https://www.kaggle.com/c/digit-recognizer",
         },
         {
            name: "Spam Classification",
            url: "https://www.kaggle.com/uciml/sms-spam-collection-dataset",
         },
      ],
      advantages: ["how_to.SupportVectorMachine.advantages.0", "how_to.SupportVectorMachine.advantages.1"],
      disadvantages: ["how_to.SupportVectorMachine.disadvantages.0", "how_to.SupportVectorMachine.disadvantages.1"],
   },
   "Multilayer Perceptron": {
      description: "how_to.MultilayerPerceptron.description",
      usage: "how_to.MultilayerPerceptron.usage",
      examples: [
         {
            name: "MNIST Handwritten Digit Classification",
            url: "https://www.kaggle.com/c/digit-recognizer",
         },
         {
            name: "Breast Cancer Wisconsin (Diagnostic)",
            url: "https://www.kaggle.com/uciml/breast-cancer-wisconsin-data",
         },
      ],
      advantages: ["how_to.MultilayerPerceptron.advantages.0", "how_to.MultilayerPerceptron.advantages.1"],
      disadvantages: ["how_to.MultilayerPerceptron.disadvantages.0", "how_to.MultilayerPerceptron.disadvantages.1"],
   },
   Cluster: {
      description: "how_to.Cluster.description",
      usage: "how_to.Cluster.usage",
      examples: [
         {
            name: "Customer Segmentation",
            url: "https://www.kaggle.com/akram24/customer-segmentation",
         },
         {
            name: "Mall Customer Segmentation Data",
            url: "https://www.kaggle.com/vjchoudhary7/customer-segmentation-tutorial-in-python",
         },
      ],
      advantages: ["how_to.Cluster.advantages.0", "how_to.Cluster.advantages.1"],
      disadvantages: ["how_to.Cluster.disadvantages.0", "how_to.Cluster.disadvantages.1"],
   },
   ALS: {
      description: "how_to.ALS.description",
      usage: "how_to.ALS.usage",
      examples: [
         {
            name: "MovieLens Dataset",
            url: "https://grouplens.org/datasets/movielens/",
         },
         {
            name: "Goodbooks-10k",
            url: "https://www.kaggle.com/zygmunt/goodbooks-10k",
         },
      ],
      advantages: ["how_to.ALS.advantages.0", "how_to.ALS.advantages.1"],
      disadvantages: ["how_to.ALS.disadvantages.0", "how_to.ALS.disadvantages.1"],
   },
   "Isolation Forest": {
      description: "how_to.IsolationForest.description",
      usage: "how_to.IsolationForest.usage",
      examples: [
         {
            name: "Anomaly Detection",
            url: "https://www.kaggle.com/boltzmannbrain/nab",
         },
         {
            name: "Credit Card Fraud Detection",
            url: "https://www.kaggle.com/mlg-ulb/creditcardfraud",
         },
      ],
      advantages: ["how_to.IsolationForest.advantages.0", "how_to.IsolationForest.advantages.1"],
      disadvantages: ["how_to.IsolationForest.disadvantages.0", "how_to.IsolationForest.disadvantages.1"],
   },
   FPGrowth: {
      description: "how_to.FPGrowth.description",
      usage: "how_to.FPGrowth.usage",
      examples: [
         {
            name: "Market Basket Analysis",
            url: "https://www.kaggle.com/learn/market-basket-analysis",
         },
         {
            name: "Retail Transaction Data",
            url: "https://www.kaggle.com/datasets",
         },
      ],
      advantages: ["how_to.FPGrowth.advantages.0", "how_to.FPGrowth.advantages.1"],
      disadvantages: ["how_to.FPGrowth.disadvantages.0", "how_to.FPGrowth.disadvantages.1"],
   },
};

const HowToDialog = ({ open, onClose, algorithmName }) => {
   const { t } = useTranslation();
   const details = algorithmDetails[algorithmName] || {
      description: "Informação sobre o algoritmo não disponível.",
      usage: "",
      examples: [],
      advantages: [],
      disadvantages: [],
   };

   return (
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
         <DialogTitle>{algorithmName}</DialogTitle>
         <DialogContent dividers>
            <Typography variant="h6">{t("how_to.description")}</Typography>
            <Typography variant="body1" paragraph>
               {t(details.description)}
            </Typography>
            <Typography variant="h6">{t("how_to.when_to_use")}</Typography>
            <Typography variant="body1" paragraph>
               {t(details.usage)}
            </Typography>
            <Typography variant="h6">{t("how_to.examples_of_datasets")}</Typography>
            <List>
               {details.examples.map((example, index) => (
                  <ListItem
                     key={index}
                     button
                     component="a"
                     href={example.url}
                     target="_blank"
                     rel="noopener noreferrer"
                  >
                     <ListItemText primary={example.name} />
                     <OpenInNew />
                  </ListItem>
               ))}
            </List>
            <Typography variant="h6">{t("how_to.advantages")}</Typography>
            <List>
               {details.advantages.map((advantage, index) => (
                  <ListItem key={index}>
                     <ListItemText primary={t(advantage)} />
                  </ListItem>
               ))}
            </List>
            <Typography variant="h6">{t("how_to.disadvantages")}</Typography>
            <List>
               {details.disadvantages.map((disadvantage, index) => (
                  <ListItem key={index}>
                     <ListItemText primary={t(disadvantage)} />
                  </ListItem>
               ))}
            </List>
         </DialogContent>
         <DialogActions>
            <Button onClick={onClose} color="primary">
            {t("how_to.close")}
            </Button>
         </DialogActions>
      </Dialog>
   );
};

export default HowToDialog;
