import "./../../../assets/css/views/popups/actions/editapikeypopup.css";
import ButtonSwitch from "./../../../components/ButtonSwitch";

import { useEffect, useState } from "react";
import DataParse from "../../../helpers/DataParse";
import externalApi from "../../../services/ExternalApi";

import { useTranslation } from "react-i18next"
import "./../../../translation/i18n";

function EditApiKeyPopup({
    token,
    apiKey,
    setTrigger,
    setApiKeyPopup,
    listKeys,
}){
    const {t} = useTranslation();
    const messageTime = 3000;
    const [editName, setEditName] = useState(true);
    const [editValidity, setEditValidity] = useState(false);
    const [editAddress, setEditAddress] = useState(false);
    const [newApiKey, setNewApiKey] = useState(apiKey);
    const [messageName, setMessageName] = useState("");
    const [messageAddress, setMessageAddress] = useState("");
    const [messageValidity, setMessageValidity] = useState("");
    const now = parseInt((new Date()).getTime() / 1000);
    const array = apiKey.authorizedIPAddresses;
    const date = (time) => {
        if(typeof time === "number"){
            let aux = DataParse.parseDate(time);
            if(aux){
                return aux.day; 
            }
        }
        return "error";
    }

    useEffect(() => {
        setNewApiKey(apiKey);
        // eslint-disable-next-line 
    }, [])

    function handleName(){
        let newAlias = document.querySelector("#inputName").value;
        newAlias = newAlias.trim();
        let error = "";
        if(newAlias === ""){
            error = t("popup.action.editapikey.error.error_1");
        } else if(newAlias === apiKey.alias){
            error = t("popup.action.editapikey.error.error_2");
        } 
        if(error === ""){
            let i = 0;
            while(i < listKeys.length){
                if(newAlias === listKeys[i].alias){
                    setMessageName(t("popup.action.editapikey.error.error_3"));
                    return "";
                }
                i++;
            }
            return newAlias;
        } else {
            setMessageName(error);
            return "";
        } 
    }

    function handleValidity(){
        let value = document.querySelector("#selectValidity").value;
        if(apiKey.dateLimit === "indefinido" && value === t("popup.action.editapikey.content.validity.undefined_1")){
            setMessageValidity(t("popup.action.editapikey.error.error_4"));
            return "";
        }
        let dateLimit = 0;
        if(value === t("popup.action.editapikey.content.validity.undefined_1")){
            dateLimit = value;
        } else {
            let day = parseInt(value);
            dateLimit = now + day * 24 * 60 * 60;
        }
        return dateLimit;
    }

    function handleAddressIP(){
        if(JSON.stringify(array) === JSON.stringify(newApiKey.authorizedIPAddresses)){
            setMessageAddress(t("popup.action.editapikey.error.error_5"))
            return false;
        }
        return newApiKey.authorizedIPAddresses;
    }

    function addAddressIP(){
        let ip = document.querySelector("#inputAddIP");
        let error = "";
        if(ip.value.length >= 4 && ip.value.length <= 12){
            let authorizedIPAddresses = newApiKey.authorizedIPAddresses;
            let i = 0;
            let found = false;
            let length = authorizedIPAddresses.length; 
            while(i !== length && !found){
                if(authorizedIPAddresses[i] === ip.value){
                    error = t("popup.action.editapikey.error.error_6");
                    found = !found;
                }
                i++;
            }
            if(!found){
                authorizedIPAddresses = authorizedIPAddresses.concat(ip.value);
                setNewApiKey({
                    ...newApiKey,
                    authorizedIPAddresses: authorizedIPAddresses,
                })
                ip.value = "";
            }
        } else {
            error = t("popup.action.editapikey.error.error_7");
        }
        setMessageAddress(error);
        const timeout = setTimeout(() => {
            ip.value = "";
            setMessageAddress("")
        }, messageTime);
        clearTimeout(timeout);
    }

    function removeAddressIP(){
        const ip = document.querySelector("#AddressIpSelected").value;
        if(ip !== t("popup.action.editapikey.content.editipaddress.option")){
            let authorizedIPAddresses = newApiKey.authorizedIPAddresses;
            let pos = authorizedIPAddresses.indexOf(ip)
            let init = [];
            let end = [];
            let length = authorizedIPAddresses.length;
            if(pos === 0){
                end = authorizedIPAddresses.slice(pos + 1);
            } else if(pos === length - 1){
                init = authorizedIPAddresses.slice(0, length - 1);
            } else {
                init = authorizedIPAddresses.slice(0, pos);
                end = authorizedIPAddresses.slice(pos + 1);
            }
            authorizedIPAddresses = init.concat(end);
            setNewApiKey({
                ...newApiKey,
                authorizedIPAddresses: authorizedIPAddresses,
            });
        }
    }

    return newApiKey ? (
        <div className="containerEditApiKeyPopup">
            <header className="containerTitle">
                <p>{t("popup.action.editapikey.title")}</p>
            </header>
            <div className="containerInfo">
                <div className="containerEdit">
                    <div className="containerName">
                        <header>
                            <p>{t("popup.action.editapikey.content.name.title")}</p>
                            <ButtonSwitch trigger={editName} setTrigger={setEditName}/>
                        </header>
                        <div className={editName ? "switchNameValidity" : "switchNameValidity noActive"}>
                            <p id="title">{t("popup.action.editapikey.content.name.review")}:</p>
                            <p>{newApiKey.alias}</p>
                            <input 
                                id="inputName"
                                type="text" 
                                placeholder={t("popup.action.editapikey.content.name.placeholder") + "..."} 
                                disabled={!editName}
                            />
                            {messageName && <p id="errorMessage">{messageName}</p>}
                        </div>
                    </div>
                    <div className="containerValidity">
                        <header>
                            <p>{t("popup.action.editapikey.content.validity.title")}</p>
                            <ButtonSwitch trigger={editValidity} setTrigger={setEditValidity}/>
                        </header>
                        <div className={editValidity ? "switchNameValidity" : "switchNameValidity noActive"}>
                            <p id="title">{t("popup.action.editapikey.content.validity.review")}:</p>
                            <p>{newApiKey.dateLimit ? date(newApiKey.dateLimit) : t("popup.action.editapikey.content.validity.undefined_1")}</p>
                            <select 
                                id="selectValidity"
                                disabled={!editValidity}
                            >
                                <option value="30">{t("popup.action.editapikey.content.validity.prefix") + "30" + t("popup.action.editapikey.content.validity.suffix")}</option>
                                <option value="15">{t("popup.action.editapikey.content.validity.prefix") + "15" + t("popup.action.editapikey.content.validity.suffix")}</option>
                                <option value="7">{t("popup.action.editapikey.content.validity.prefix") + "7" + t("popup.action.editapikey.content.validity.suffix")}</option>
                                <option value="indefinido">{t("popup.action.editapikey.content.validity.undefined_2")}</option>
                            </select>
                        </div>
                        {messageValidity && <p id="errorMessage">{messageValidity}</p>}
                    </div>
                </div>
                <div className="containerEdit">
                    <header>
                        <p>{t("popup.action.editapikey.content.editipaddress.title")}</p>
                        <ButtonSwitch trigger={editAddress} setTrigger={setEditAddress}/>
                    </header>
                    <div className={editAddress ? "switchAddress" : "switchAddress noActive"}>
                        <p id="title">{t("popup.action.editapikey.content.editipaddress.review_1")}:</p>
                        <select 
                            id="AddressIpSelected"
                            disabled={!editAddress || newApiKey.authorizedIPAddresses.length === 0}
                        >
                            <option hidden>{t("popup.action.editapikey.content.editipaddress.option")}</option>
                            {newApiKey.authorizedIPAddresses && newApiKey.authorizedIPAddresses.map((data, index) => {
                                return (
                                    <option key={index} value={data}>{data}</option>
                                )
                            })}
                        </select> 
                        <button 
                            className={editAddress && newApiKey.authorizedIPAddresses.length > 0 ? "buttonActive" : "buttonInactive"}
                            disabled={newApiKey.authorizedIPAddresses.length === 0 || !editAddress}
                            onClick={removeAddressIP}
                        >
                            {t("popup.action.editapikey.content.editipaddress.button.remove")}
                        </button>
                        <p id="title">{t("popup.action.editapikey.content.editipaddress.review_2")}:</p>
                        <input 
                            id="inputAddIP"
                            type="number"
                            placeholder={t("popup.action.editapikey.content.editipaddress.option") + "... ex: 19216811"}
                            disabled={!editAddress}
                        />
                        <button
                            className={editAddress ? "buttonActive" : "buttonInactive"}
                            onClick={addAddressIP}
                        >
                            {t("popup.action.editapikey.content.editipaddress.button.add")}
                        </button>
                        {messageAddress && <p id="errorMessage">{messageAddress}</p>}
                    </div>
                </div>
            </div>
            <div className="containerButton">
                <button id="closedButton" onClick={() => {
                    setApiKeyPopup(true);
                    setTrigger(false);
                }}>
                    {t("popup.action.editapikey.button.back")}
                </button>
                <button id="editButton" onClick={() => {
                    if(editName || editValidity || editAddress){
                        const alias = editName ? handleName() : "";
                        const dateLimit = editValidity ? handleValidity() : "";
                        const authorizedIPAddresses = editAddress && handleAddressIP();
                        if((editName ? alias : true) && (editValidity ? dateLimit : true) &&
                        (editAddress ? authorizedIPAddresses : true) && token){
                            let id = apiKey.id;
                            const wait = async () => {
                                if(editName){
                                    const updatealias = async () => {
                                        return new Promise((resolve) => {
                                            externalApi.updatealias(token, id, alias)
                                            .then(() => {resolve()})
                                        })
                                    }
                                    await updatealias();
                                }
                                if(editValidity){
                                    const updatedatelimit = async () => {
                                        return new Promise((resolve) => {
                                            externalApi.updatedatelimit(token, id, dateLimit.toString())
                                            .then(() => {resolve()})
                                        })
                                    }
                                    await updatedatelimit();
                                }
                                if(editAddress){
                                    const updaterestricted = async (content) => {
                                        if(typeof content === "string"){
                                            return new Promise((resolve) => {
                                                externalApi.updaterestricted(token, id, content)
                                                .then(() => {resolve()})
                                            })
                                        }
                                        return null;
                                    }
                                    const updateauthorizedipaddresses = async () => {
                                        return new Promise((resolve) => {
                                            externalApi.updateauthorizedipaddresses(token, id, authorizedIPAddresses)
                                            .then(() => {resolve()})
                                        })
                                    }
                                    if(!apiKey.apiRestricted){
                                        await updaterestricted("True");
                                    } else if(authorizedIPAddresses.length === 0){
                                        await updaterestricted("");
                                    }
                                    await updateauthorizedipaddresses();
                                }
                                setApiKeyPopup(true);
                                setTrigger(false);
                            }
                            wait();
                        } else {
                            const timeout = setTimeout(() => {
                                setMessageAddress("")
                                setMessageName("");
                                setMessageValidity("");
                            }, messageTime);
                            clearTimeout(timeout);
                        }
                    }
                }}>
                    {t("popup.action.editapikey.button.edit")}
                </button>
            </div>
        </div>
    ) : (
        <div className="containerEditApiKeyPopup">Error</div>
    )
}

export default EditApiKeyPopup;