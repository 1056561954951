function RandomForestIcon({ style }) {
  if (style == null) {
    style = [];
  }

  return (
    <svg
      width={style.hasOwnProperty("size") ? style.size : 24}
      height={style.hasOwnProperty("size") ? style.size : 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="11.3453"
        cy="2.73913"
        rx="1.96429"
        ry="1.95652"
        fill={style.hasOwnProperty("color") ? style.color : "#282828"}
      />
      <ellipse
        cx="15.2739"
        cy="8.99999"
        rx="1.96428"
        ry="1.95652"
        fill={style.hasOwnProperty("color") ? style.color : "#282828"}
      />
      <ellipse
        cx="11.3453"
        cy="16.0435"
        rx="1.96429"
        ry="1.95652"
        fill={style.hasOwnProperty("color") ? style.color : "#282828"}
      />
      <ellipse
        cx="7.41668"
        cy="8.99999"
        rx="1.96429"
        ry="1.95652"
        fill={style.hasOwnProperty("color") ? style.color : "#282828"}
      />
      <ellipse
        cx="3.48809"
        cy="16.0435"
        rx="1.96429"
        ry="1.95652"
        fill={style.hasOwnProperty("color") ? style.color : "#282828"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7382 2.34783L15.252 8.40989L14.5715 8.80119L11.0577 2.73913L11.7382 2.34783Z"
        fill={style.hasOwnProperty("color") ? style.color : "#282828"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.80957 9.3913L11.3234 15.4534L10.6429 15.8447L7.12912 9.7826L7.80957 9.3913Z"
        fill={style.hasOwnProperty("color") ? style.color : "#282828"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.80951 8.40989L11.3233 2.34783L12.0038 2.73914L8.48996 8.8012L7.80951 8.40989Z"
        fill={style.hasOwnProperty("color") ? style.color : "#282828"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.88105 15.4534L7.39487 9.3913L8.07532 9.78261L4.5615 15.8447L3.88105 15.4534Z"
        fill={style.hasOwnProperty("color") ? style.color : "#282828"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3095 20.9316L16.8233 14.8696L17.5038 15.2609L13.99 21.3229L13.3095 20.9316Z"
        fill={style.hasOwnProperty("color") ? style.color : "#282828"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2382 14.8696L20.752 20.9316L20.0715 21.3229L16.5577 15.2609L17.2382 14.8696Z"
        fill={style.hasOwnProperty("color") ? style.color : "#282828"}
      />
      <ellipse
        cx="12.9167"
        cy="21.5217"
        rx="1.96429"
        ry="1.95652"
        fill={style.hasOwnProperty("color") ? style.color : "#282828"}
      />
      <ellipse
        cx="16.8453"
        cy="14.4783"
        rx="1.96429"
        ry="1.95652"
        fill={style.hasOwnProperty("color") ? style.color : "#282828"}
      />
      <ellipse
        cx="20.7739"
        cy="21.5217"
        rx="1.96428"
        ry="1.95652"
        fill={style.hasOwnProperty("color") ? style.color : "#282828"}
      />
    </svg>
  );
}

export default RandomForestIcon;
