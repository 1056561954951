import "./../../../../assets/css/views/popups/creations/NewDataset/uploaddataset.css";
import UploadIcon from "./../../../../assets/icons/jsxIcons/UploadIcon.jsx";
import { useTranslation } from "react-i18next";
import "./../../../../translation/i18n";
import { toast } from "react-toastify";

function UploadDataset({ setFile, file }) {
   const { t } = useTranslation();

   const handleFileChange = (e) => {
      if (e.target.files) {
         const selectedFile = e.target.files[0];
         const allowedTypes = ["application/json", "text/csv"];

         if (!allowedTypes.includes(selectedFile?.type)) {
            toast.error(t("popup.creations.newdataset.upload.errors.error_1"));
            e.target.value = "";
            return;
         }

         setFile(selectedFile);
         toast.success(t("popup.creations.newdataset.upload.ready_to_import"));
      }
   };

   let link = "popup.creations.newdataset.upload";

   return (
      <div className="containerUploadDataset">
         <ul>
            <li>{t(`${link}.header.info_1`)}</li>
            <li>{t(`${link}.header.info_2`)}</li>
            <li>{t(`${link}.header.info_3`)}</li>
         </ul>
         <div>
            <input
               type="file"
               id="upload"
               style={{ display: "none" }}
               accept=".csv, .json"
               onChange={handleFileChange}
            />
            <label htmlFor="upload">
               <UploadIcon style={{ color: "#FDFDFD", size: "16" }} />
               <p>{t(`${link}.button`)}</p>
            </label>
            <div className="containerFile">
               {file?.name && (
                  <p>
                     {t(`${link}.file`)}: <span>{file.name}</span>
                  </p>
               )}
            </div>
         </div>
      </div>
   );
}

export default UploadDataset;
