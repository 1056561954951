import Highcharts from 'highcharts';
import highcharts3d from 'highcharts/highcharts-3d';
import { useEffect } from 'react';

import { useTranslation } from "react-i18next";
import "./../../translation/i18n";

highcharts3d(Highcharts); // Inicializar o módulo 3D

function IsolationForestExample() {

    const { t } = useTranslation();

    const allInstance = [
        [35,0,0.627],[29,0,0.351],[0,0,0.672],[23,94,0.167],[0,0,0.201],[32,88,0.248],[0,0,0.232],[0,0,0.134],[0,0,0.191],[0,0,1.441],
        [0,0,0.537],[0,0,0.484],[47,230,0.551],[0,0,0.254],[38,83,0.183],[30,96,0.529],[41,235,0.704],[0,0,0.388],[0,0,0.451],[35,0,0.263],
        [33,146,0.254],[26,115,0.205],[0,0,0.257],[15,140,0.487],[19,110,0.245],[0,0,0.337],[26,0,0.546],[36,245,0.851],[11,54,0.267],
        [19,175,0.587],[33,192,0.966],[0,0,0.42],[37,0,0.665],[42,0,0.503],[47,207,1.39],[25,70,0.271],[0,0,0.188],[0,0,0.696],[18,0,0.235],
        [0,0,0.294],[39,0,1.893],[0,0,0.564],[31,0,0.512],[11,82,0.491],[15,36,0.526],[21,23,0.342],[34,300,0.467],[42,342,0.718],[10,0,0.248],
        [39,304,0.254],[60,110,0.962],[0,0,1.781],[41,142,0.173],[0,0,0.304],[27,0,0.586],[0,0,0.587],[34,128,0.699],[0,0,0.258],[27,0,0.203],
        [30,0,0.855],[0,0,0.845],[13,38,0.334],[27,100,0.189],[20,90,0.867],[35,140,0.411],[0,0,0.583],[20,270,0.231],[0,0,0.27],[30,0,0.396],
        [20,0,0.14],[0,0,0.391],[33,0,0.37],[0,0,0.27],[32,0,0.344],[22,0,0.307],[13,0,0.14],[26,71,0.767],[28,0,0.237],[29,125,0.698],[25,71,0.324],
        [32,110,0.153],[19,0,0.165],[0,0,0.258],[15,176,0.443],[40,48,0.261],[54,0,0.178],[0,0,0.277],[18,64,0.761],[27,228,0.255],[18,76,0.323],
        [28,0,0.13],[0,0,0.227],[0,0,1.222],[0,0,0.179],[0,0,0.262],[18,40,0.283],[0,0,0.93],[29,152,0.801],[0,0,0.207],[28,140,0.287]
    ]

    const anomalies = [
        [39,744,2.329],[48,465,2.137],[23,846,0.398],[0,0,0.261],[35,168,2.288],[33,680,0.427],[45,543,0.158],[0,0,0.832],
        [0,0,0.578],[32,265,1.159],[99,0,0.575],[0,0,0.102],[30,0,0.569],[46,130,0.319],[44,510,0.222],[24,240,0.721],[0,0,0.305],
        [7,258,0.926],[36,495,0.615]
    ]

    const anomalie = [[63,14,2.42]];

    useEffect(() => {
        const serieAllInstances = {
            data: allInstance,
            name: t("model.isolationforest.series.name_3"),
            colorByPoint: false,
            accessibility: {
                exposeAsGroupOnly: true
            },
            color: "#57CC99",
            marker: {
                symbol: 'circle'
            }
        }

        const serieAnomalies = {
            data: anomalies,
            name: t("model.isolationforest.series.name_2"),
            colorByPoint: false,
            accessibility: {
                exposeAsGroupOnly: true
            },
            color: "#22577A",
            marker: {
                symbol: 'circle'
            }
        }

        const serieAnomalie = {
            data: anomalie,
            name: t("model.isolationforest.series.name_1"),
            colorByPoint: false,
            accessibility: {
                exposeAsGroupOnly: true
            },
            color: "#EB5757",
            marker: {
                symbol: 'circle'
            }
        }

        Highcharts.chart('container', {
            chart: {
                renderTo: 'container',
                margin: 70,
                type: 'scatter3d',
                animation: false,
                backgroundColor: "transparent",
                options3d: {
                    enabled: true,
                    alpha: 20,
                    beta: 50,
                    depth: 110,
                    viewDistance: 10,
                    fitToPlot: false,
                    frame: {
                        bottom: { size: 1, color: 'rgba(0,0,0,0.02)' },
                        back: { size: 1, color: 'rgba(0,0,0,0.04)' },
                        side: { size: 1, color: 'rgba(0,0,0,0.06)' }
                    }
                }
            },
            title: {
                text: ''
            },
            plotOptions: {
                scatter: {
                    width: 10,
                    height: 10,
                    depth: 10,
                },
            },
            yAxis: {
                min: 0,
                max: 846,
                title: null,
                gridLineWidth: 1,
                visible: true,
            },
            xAxis: {
                min: 0,
                max: 99,
                gridLineWidth: 0,
                visible: true,
            },
            zAxis: {
                min: 0,
                max: 2.42,  
                gridLineWidth: 0,
                visible: true,
                showFirstLabel: false,
            },
            tooltip: {
                enabled: false,
            },
            legend: {
                enabled: true,
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'top',
                y: 0,
                x: 0,
                floating: false,
                borderWidth: 1,
                backgroundColor: 'transparent',
                shadow: true
            },
            accessibility: {
                enabled: false
            },
            series: [
                serieAllInstances, serieAnomalies, serieAnomalie
            ],
        });
    }, []);

    const style = {
        width: '100%',
        height: '100%',
    };

    return <div id="container" style={style}></div>;
}

export default IsolationForestExample;