function CodeIcon({ style }) {
    if (style == null) {
        style = [];
    }
    return (
        <svg 
            width={style.hasOwnProperty("size") ? style.size : 16}
            height={style.hasOwnProperty("size") ? style.size : 16}
            viewBox="0 0 16 16" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_3080_3588)">
                <path fillRule="evenodd" clipRule="evenodd" d="M0 2.66667C0 1.19391 1.19391 0 2.66667 0H13.3333C14.8061 0 16 1.19391 16 2.66667V13.3333C16 14.8061 14.8061 16 13.3333 16H2.66667C1.19391 16 0 14.8061 0 13.3333V2.66667ZM2.66667 1.33333C1.93029 1.33333 1.33333 1.93029 1.33333 2.66667V13.3333C1.33333 14.0697 1.93029 14.6667 2.66667 14.6667H13.3333C14.0697 14.6667 14.6667 14.0697 14.6667 13.3333V2.66667C14.6667 1.93029 14.0697 1.33333 13.3333 1.33333H2.66667ZM9.5286 5.5286C9.78895 5.26825 10.2111 5.26825 10.4714 5.5286L12.4714 7.52859C12.5964 7.65362 12.6667 7.82319 12.6667 8C12.6667 8.17681 12.5964 8.34638 12.4714 8.4714L10.4714 10.4714C10.2111 10.7318 9.78895 10.7318 9.5286 10.4714C9.26825 10.2111 9.26825 9.78895 9.5286 9.52859L11.0572 8L9.5286 6.4714C9.26825 6.21105 9.26825 5.78895 9.5286 5.5286ZM6.4714 5.5286C6.73175 5.78895 6.73175 6.21106 6.4714 6.47141L4.94281 8L6.4714 9.5286C6.73175 9.78895 6.73175 10.2111 6.4714 10.4714C6.21105 10.7318 5.78894 10.7318 5.52859 10.4714L3.5286 8.4714C3.40357 8.34638 3.33333 8.17681 3.33333 8C3.33333 7.82319 3.40357 7.65362 3.5286 7.52859L5.5286 5.5286C5.78895 5.26825 6.21105 5.26825 6.4714 5.5286Z" fill="#0F172A" />
            </g>
            <defs>
                <clipPath id="clip0_3080_3588">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default CodeIcon;