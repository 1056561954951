import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom"

import '../../../../assets/css/views/popups/actions/sharemodelpopup/sharemodelpopup.css';

import { Popup } from '../../../../components/Popup'

import ws from '../../../../services/wiselib';

import ConfirmationPopup from '../ConfirmationPopup';
import { MessagePopup } from '../../messages/MessagePopup';


import UserInput from './UserInput';
import UserCards from './UserCards';
import CheckIconGreen from '../../../../assets/icons/jsxIcons/CheckIconGreen';
import AlertIcon from '../../../../assets/icons/jsxIcons/AlertIcon';
import modelService from '../../../../services/ModelService';

import { useTranslation } from "react-i18next"
import "./../../../../translation/i18n";
import handleErrorResponse from '../../../../helpers/handleErrorResponse';
import { useDispatch } from 'react-redux';
import { setNeedUpdateProjectList } from '../../../../store/slices/projects/projectsSlice';

const ShareModelPopup = ({ modelId }) => {

    const {t} = useTranslation();

    const [emailToShare, setEmailToShare] = useState("");

    const [cardsModifications, setCardsModifications] = useState(false);

    const [errorMessagePopup, setErrorMessagePopup] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [confirmSharingPopup, setConfirmSharingPopup] = useState(false);
    const [confirmRemovingPopup, setConfirmRemovingPopup] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [message, setMessage] = useState("");
    const history = useHistory();
    const dispatch = useDispatch()

    function enableConfirmSharingPopup(email) {
        setEmailToShare(email);
        setConfirmSharingPopup(true);
    }

    function enableConfirmRemovingPopup(email) {
        setEmailToShare(email);
        setConfirmRemovingPopup(true);
    }

    function addUserSharedWith(email) {
        let usersToShareWith = email.split(' ');
        
        if (usersToShareWith.length === 0)
            enableErrorMessagePopup(t("popup.action.sharedmodel.info.name_1"))
        else {
            let token = localStorage.getItem("user_token")
            ws.sharemodelresult(token, modelId, usersToShareWith)
                .then((result) => {
                    setMessage(t(`backend.SUCCESS.${result}`)) 
                    dispatch(setNeedUpdateProjectList(true))
                })
                .catch((error) => {
                   handleErrorResponse(history, error, t);
                }).finally(() => setCardsModifications(true))
        }
    }

    function removeUserSharedWith(email) {
        if (!email)
            enableErrorMessagePopup(t("popup.action.sharedmodel.info.name_1"))
        else {
            modelService.removeusersharedwith(modelId, email)
            .then(() => {
                setMessage(t("popup.action.sharedmodel.info.name_2"))
                dispatch(setNeedUpdateProjectList(true))
            })
            .catch((error) => {
                handleErrorResponse(history, error, t);
            }).finally(() => setCardsModifications(true))
        }
        
    }

    function enableErrorMessagePopup(message) {
        setErrorMessage(message);
        setErrorMessagePopup(true);
    }

    useEffect(() => {
        if(message){
            const timer = setTimeout(() => {
                setMessage("")
            }, 3000)
            return () => clearTimeout(timer);
        }
    }, [message])

   
    return (
        <div className="share-model-popup">
            <div className="title">
                <span id="title-text">{t("popup.action.sharedmodel.header.title")}</span>
            </div>
            <div className="popup-content">
                <UserInput
                    enableConfirmSharingPopup={enableConfirmSharingPopup}
                />
                {message.includes(t("backend.SUCCESS._SUCCESS_17")) ||
                message.includes(t("popup.action.sharedmodel.message.message_2")) ? (
                    <p className="shared-success">
                        {" "}
                        <CheckIconGreen /> {message}
                    </p>
                ) : (
                    message && (
                        <p className="shared-error">
                            {" "}
                            <AlertIcon /> {message}
                        </p>
                    )
                )}
                <UserCards
                    enableConfirmSharingPopup={enableConfirmSharingPopup}
                    enableConfirmRemovingPopup={enableConfirmRemovingPopup}
                    modelId={modelId}
                    cardsModifications={cardsModifications}
                    setCardsModifications={setCardsModifications}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                />
            </div>
            <Popup
                trigger={confirmSharingPopup}
                setTrigger={setConfirmSharingPopup}
            >
                <ConfirmationPopup
                    title={t("popup.action.sharedmodel.confirm.title_1")}
                    description={t("popup.action.sharedmodel.confirm.description_1") + ":\n" + emailToShare}
                    onConfirm={() => {
                        addUserSharedWith(emailToShare);
                        setConfirmSharingPopup(false);
                        setIsLoading(true);
                    }}
                    onDeny={() => setConfirmSharingPopup(false)}
                    isLoading={isLoading}
                    confirmText={t("popup.action.sharedmodel.confirm.yes")}
                    denyText={t("popup.action.sharedmodel.confirm.no")}
                />
            </Popup>
            <Popup
                trigger={confirmRemovingPopup}
                setTrigger={setConfirmRemovingPopup}
            >
                <ConfirmationPopup
                    title={t("popup.action.sharedmodel.confirm.title_2")}
                    description={t("popup.action.sharedmodel.confirm.description_2") + ":\n" + emailToShare + " ?"}
                    onConfirm={() => {
                        removeUserSharedWith(emailToShare);
                        setConfirmRemovingPopup(false);
                        setIsLoading(true);
                    }}
                    onDeny={() => setConfirmRemovingPopup(false)}
                    isLoading={isLoading}
                    confirmText={t("popup.action.sharedmodel.confirm.yes")}
                    denyText={t("popup.action.sharedmodel.confirm.no")}
                />
            </Popup>
            <Popup
                trigger={errorMessagePopup}
                setTrigger={setErrorMessagePopup}
            >
                <MessagePopup title={""} description={errorMessage} />
            </Popup>
        </div>
    );
}

export default ShareModelPopup;
