import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: null,
};

export const tokenSlice = createSlice({
  name: "user_token",
  initialState,
  reducers: {
    setUserToken: (state, action) => {
      state.token = action.payload;
    },
  },
});

export const { setUserToken } = tokenSlice.actions

export default tokenSlice.reducer
