import { Link } from "react-router-dom";
import React, { useState, useEffect, useCallback } from "react";

import "../../../assets/css/views/screens/project.css";

import { Card } from "../../../components/Card";
import SearchBar from "../../../components/SearchBar";
import { Popup } from "../../../components/Popup";

import dp from "../../../helpers/data_parse";

import { useSelector } from "react-redux";
import TrashIcon from "../../../assets/icons/jsxIcons/TrashIcon";
import CheckboxLabeled from "../../../components/CheckboxLabeled";
import { DeletePopup } from "../../popups/actions/DeletePopup";
import { selectProjectList } from "../../../store/slices/projects/projectsSlice";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import { useTranslation } from "react-i18next";
import "./../../../translation/i18n";
import { selectUserEmail } from "../../../store/slices/user/userSlice";
import { CloudDownload, CloudUpload } from "@material-ui/icons";

export const Shared = () => {
   const { t } = useTranslation();

   const userEmail = useSelector(selectUserEmail);

   const { pageId } = useParams();
   // Encontra o projeto a partir dos projetos armazenados no cache com redux
   const projectData = useSelector(selectProjectList).find((project) => project.id === pageId);

   /** Cards de modelos compartilhados por mim */
   const [sharedByMeCards, setSharedByMeCards] = useState([]);
   /** Cards de modelos compartilhados comigo */
   const [sharedWithMeCards, setSharedWithMeCards] = useState([]);

   const [optionSort, setOptionSort] = useState("Mais recente");

   const [searchedTerm, setSearchedTerm] = useState("");

   // State com os ids dos modelos compartilhados selecionados
   const [selectedModels, setSelectedModels] = useState([]);
   //flag que define se select all está ativo dos modelos
   const [isSelectedActiveModels, setIsSelectedActiveModels] = useState(false);
   // Trigger para abrir o pop-up de deletar models
   const [deleteModelsPopup, setDeleteModelsPopup] = useState(false);

   // // Função que lida com a seleção de datasets para deletar, etc.
   const handleModelsSelected = useCallback(
      (e, datasetId) => {
         e?.preventDefault();
         // Verifico se o dataset já está selecionado
         const isSelected = selectedModels.includes(datasetId);

         if (isSelected) {
            // Se estiver selecionado, removo da lista de datasets selecionados
            setSelectedModels(selectedModels.filter((id) => id !== datasetId));
         } else {
            // Se não estiver selecionado, adiciono à lista de datasets selecionados
            setSelectedModels([...selectedModels, datasetId]);
         }
      },
      [selectedModels],
   );

   /**
    * Renders a card component for a shared model.
    *
    * @param {Object} model - The model object.
    * @param {boolean} isSharedByMe - Indicates if the model is shared by the current user.
    * @returns {JSX.Element} The rendered card component.
    */
   const renderCard = useCallback(
      (model, isSharedByMe) => {
         return (
            <Link
               to={!isSelectedActiveModels && "/model/" + model.id}
               key={model.id}
               onClick={(e) => {
                  if (e.ctrlKey || e.metaKey || isSelectedActiveModels) {
                     handleModelsSelected(e, model.id);
                     !isSelectedActiveModels && setIsSelectedActiveModels(true);
                  }
               }}
            >
               <Card
                  type={isSharedByMe ? "model" : "shared-model"}
                  algorithm={model.algorithm}
                  name={model.name}
                  date={model.date}
                  elementId={model.id}
                  isShared={true}
                  ownerEmail={isSharedByMe ? "" : model.ownerEmail}
                  sharedWith={isSharedByMe ? model.sharedWith : []}
               />
            </Link>
         );
      },
      [handleModelsSelected, isSelectedActiveModels],
   );

   // Função que seta e lida com os cards de modelos
   const handleModelsSharedByMe = useCallback(
      (data) => {
         const { models } = data;
         const modelsSharedByMe = models.filter((model) => model.ownerEmail === userEmail);

         if (modelsSharedByMe.length === 0) {
            return setSharedByMeCards(<span id="no-data">{t("project.info.no_data")}</span>);
         }

         let copyModels = modelsSharedByMe;
         if (searchedTerm) {
            copyModels = modelsSharedByMe.filter((value) =>
               value.name.toLowerCase().trim().includes(searchedTerm.toLowerCase().trim()),
            );
            if (copyModels.length === 0) {
               return setSharedByMeCards(
                  <span id="no-data">
                     {t("project.info.none_model") + " " + searchedTerm.trim() + " " + t("project.info.found")}
                  </span>,
               );
            }
         }

         copyModels = dp.searchSort(optionSort, copyModels);
         setSharedByMeCards(copyModels.map((model) => renderCard(model, true)));
      },
      [optionSort, renderCard, searchedTerm, t, userEmail],
   );

   // Função que seta e lida com os cards de modelos
   const handleModelsSharedWithMe = useCallback(
      (data) => {
         const { models } = data;
         const modelSharedWithMe = models.filter((model) => model.ownerEmail !== userEmail);

         if (modelSharedWithMe.length === 0) {
            return setSharedWithMeCards(<span id="no-data">{t("project.info.no_data")}</span>);
         }

         let copyModels = modelSharedWithMe;
         if (searchedTerm) {
            copyModels = modelSharedWithMe.filter((value) =>
               value.name.toLowerCase().trim().includes(searchedTerm.toLowerCase().trim()),
            );
            if (copyModels.length === 0) {
               return setSharedWithMeCards(
                  <span id="no-data">
                     {t("project.info.none_model") + " " + searchedTerm.trim() + " " + t("project.info.found")}
                  </span>,
               );
            }
         }

         copyModels = dp.searchSort(optionSort, copyModels);
         setSharedWithMeCards(copyModels.map((model) => renderCard(model, false)));
      },
      [optionSort, renderCard, searchedTerm, t, userEmail],
   );

   // Função que literalmente renderiza os cards de modelos pré-preparados na função handleModelsSharedWithMe
   const renderModelsCardsSharedWithMe = () => {
      if (isSelectedActiveModels && sharedWithMeCards.length > 0) {
         return sharedWithMeCards.map((model) => (
            <div
               key={model.key}
               className={`project-card background ${selectedModels.includes(model.key) ? "selected" : ""}`}
            >
               <input
                  type="checkbox"
                  className="checkbox"
                  checked={selectedModels.includes(model.key)}
                  onChange={() => handleModelsSelected(undefined, model.key)}
               />
               {model}
            </div>
         ));
      } else {
         return sharedWithMeCards;
      }
   };

   // Função que literalmente renderiza os cards de datasets pré-preparados na função handleModelsSharedByMe
   const renderModelsCardsSharedByMe = () => {
      if (isSelectedActiveModels && sharedByMeCards.length > 0) {
         return sharedByMeCards.map((dataset) => (
            <div
               key={dataset.key}
               className={`project-card background ${selectedModels.includes(dataset.key) ? "selected" : ""}`}
            >
               <input
                  type="checkbox"
                  className="checkbox"
                  checked={selectedModels.includes(dataset.key)}
                  onChange={() => handleModelsSelected(undefined, dataset.key)}
               />
               {dataset}
            </div>
         ));
      } else {
         return sharedByMeCards;
      }
   };

   useEffect(() => {
      if (projectData !== undefined) {
         handleModelsSharedByMe(projectData);
         handleModelsSharedWithMe(projectData);
      }
   }, [handleModelsSharedByMe, handleModelsSharedWithMe, projectData]);

   return (
      <>
         <div className="container-project">
            <div className="header">
               <div className="title">
                  <p>{projectData?.shared ? t("project.title.name") : projectData?.name}</p>
               </div>
               <SearchBar
                  enableOptions={true}
                  enableSort={true}
                  onChange={(e) => setSearchedTerm(e.target.value)}
                  onChangeOption={setOptionSort}
                  isShared={projectData?.shared}
               />
            </div>
            <main className="container-cards">
               <section className="container-section">
                  <div className="section-title">
                     <div className="new-dataset">
                        {t("sharedProject.sharedByMe.title")}
                        <span id="icon">
                           <CloudUpload />
                        </span>
                     </div>

                     <div className="checkbox-labeled">
                        {isSelectedActiveModels && selectedModels.length > 0 && (
                           <button className="trash-icon" onClick={() => setDeleteModelsPopup(true)}>
                              <TrashIcon />
                           </button>
                        )}
                        {sharedByMeCards.length > 0 && (
                           <CheckboxLabeled
                              isSelectedActive={isSelectedActiveModels}
                              setIsSelectedActive={setIsSelectedActiveModels}
                              setSelectedIds={setSelectedModels}
                              cards={sharedByMeCards}
                           />
                        )}
                     </div>
                  </div>

                  <div className="dataSetsContent">{renderModelsCardsSharedByMe()}</div>
               </section>
               <section className="container-section">
                  <div className="section-title">
                     <div className="models-section">
                        {t("sharedProject.sharedWithMe.title")}
                        <span id="icon">
                           <CloudDownload />
                        </span>
                     </div>
                     <div className="checkbox-labeled">
                        {isSelectedActiveModels && selectedModels.length > 0 && (
                           <button className="trash-icon" onClick={() => setDeleteModelsPopup(true)}>
                              <TrashIcon />
                           </button>
                        )}
                        {sharedWithMeCards.length > 0 && (
                           <CheckboxLabeled
                              isSelectedActive={isSelectedActiveModels}
                              setIsSelectedActive={setIsSelectedActiveModels}
                              setSelectedIds={setSelectedModels}
                              cards={sharedWithMeCards}
                           />
                        )}
                     </div>
                  </div>

                  <div className="modelsContent">{renderModelsCardsSharedWithMe()}</div>
               </section>
            </main>
         </div>

         <Popup trigger={deleteModelsPopup} setTrigger={setDeleteModelsPopup}>
            <DeletePopup
               cardtype={"model"}
               modelResultId={selectedModels}
               setSelectedArray={setSelectedModels}
               setIsSelectedActive={setIsSelectedActiveModels}
               setTrigger={setDeleteModelsPopup}
            />
         </Popup>
      </>
   );
};
