import React from "react";
import styles from "../../assets/css/components/Dataset/DatasetData.module.css";
import Data from "./Data";
import DatasetStatistics from "./DatasetStatistics";
import { useTranslation } from "react-i18next";
import "../../translation/i18n";

const DatasetData = ({ activeTab, setActiveTab }) => {
   const { t } = useTranslation();

   const toggleTab = (tab) => {
      setActiveTab(tab);
   };

   return (
      <>
         <nav className={styles.navButtons}>
            <button
               className={activeTab === "dados" ? styles.activeButton : ""}
               onClick={() => toggleTab("dados")}
            >
               {t("dataset.data.nav.button_1")}
            </button>
            <button
               className={
                  activeTab === "estatisticas" ? styles.activeButton : ""
               }
               onClick={() => toggleTab("estatisticas")}
            >
               {t("dataset.data.nav.button_2")}
            </button>
         </nav>
         {activeTab === "dados" ? <Data /> : <DatasetStatistics />}
      </>
   );
};

export default DatasetData;
