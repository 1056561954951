function NewNotificationIcon({ style }) {
  if (style == null) {
    style = [];
  }

  return (
    <svg
      width={style.hasOwnProperty("size") ? style.size : 24}
      height={style.hasOwnProperty("size") ? style.size : 24}
      viewBox="0 0 26 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5109 13.6621L20.5866 6.82606C20.0225 4.82339 18.7967 3.06434 17.1038 1.82853C15.411 0.592709 13.3484 -0.0489569 11.2438 0.00552316C9.1392 0.0600033 7.11345 0.807503 5.48854 2.1292C3.86363 3.45091 2.7328 5.27096 2.27581 7.30006L0.785953 13.9151C0.621385 14.646 0.62519 15.4041 0.797086 16.1334C0.968983 16.8627 1.30458 17.5445 1.77911 18.1286C2.25363 18.7127 2.85496 19.1841 3.53871 19.508C4.22245 19.8319 4.97115 20.0001 5.72953 20.0001H6.85781C7.09027 21.1303 7.71134 22.1465 8.6158 22.8764C9.52026 23.6063 10.6525 24.0051 11.8206 24.0051C12.9888 24.0051 14.121 23.6063 15.0255 22.8764C15.9299 22.1465 16.551 21.1303 16.7834 20.0001H17.6322C18.4129 20.0001 19.183 19.8219 19.8824 19.4795C20.5818 19.137 21.1916 18.6396 21.664 18.0259C22.1365 17.4123 22.4589 16.6991 22.6059 15.9421C22.753 15.1851 22.7209 14.4048 22.512 13.6621H22.5109ZM11.8206 22.0001C11.1944 21.9975 10.5843 21.804 10.0738 21.446C9.56329 21.088 9.17725 20.583 8.96853 20.0001H14.6727C14.464 20.583 14.078 21.088 13.5674 21.446C13.0569 21.804 12.4468 21.9975 11.8206 22.0001ZM20.0508 16.8151C19.7686 17.1847 19.4029 17.4843 18.9829 17.69C18.5629 17.8957 18.1 18.0019 17.6312 18.0001H5.72953C5.27455 18 4.8254 17.899 4.41523 17.7046C4.00506 17.5102 3.64434 17.2274 3.35969 16.877C3.07504 16.5265 2.87374 16.1174 2.77064 15.6799C2.66753 15.2424 2.66526 14.7876 2.76399 14.3491L4.25284 7.73306C4.61173 6.13926 5.49993 4.70966 6.77623 3.67151C8.05254 2.63335 9.6437 2.04623 11.2968 2.00347C12.9498 1.96071 14.57 2.46477 15.8996 3.43553C17.2292 4.40628 18.192 5.788 18.6349 7.36106L20.5592 14.1971C20.6864 14.6424 20.7066 15.1109 20.6184 15.5654C20.5301 16.0198 20.3358 16.4477 20.0508 16.8151Z"
        fill={style.hasOwnProperty("color") ? style.color : "#282828"}
      />
      <ellipse
        cx="19.9231"
        cy="6"
        rx="6.07692"
        ry="6"
        fill={style.hasOwnProperty("color") ? style.color : "#38A3A5"}
      />
    </svg>
  );
}

export default NewNotificationIcon;
