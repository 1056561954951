import { Input } from "semantic-ui-react";
import React, { useEffect, useRef, useState } from "react";

import "../../../assets/css/views/popups/actions/passwordrecoverpopup.css";

import { Button } from "../../../components/Button";

import ws from "../../../services/wiselib";

import { useTranslation } from "react-i18next";
import "./../../../translation/i18n";

import { toast } from "react-toastify";

export const PasswordRecoverPopup = () => {
   const { t } = useTranslation();
   const [email, setEmail] = useState("");
   const [isLoading, setIsLoading] = useState(false);
   const inputRef = useRef(null);

   useEffect(() => {
      inputRef.current.focus();
   }, []);

   const handleSubmit = (e) => {
      e?.preventDefault();
      if (email.length > 0) {
         setIsLoading(true);
         ws.forgotmypassword(email)
            .then(() =>
               toast.success(t("popup.action.passwordrecover.button.info")),
            )
            .catch((error) =>
               toast.error(t(`backend.ERROR.${error.response.data}`)),
            )
            .finally(() => setIsLoading(false));
      }
   };

   return (
      <form className="password-reset-popup" onSubmit={handleSubmit}>
         <div id="password-text" style={{ color: "black" }}>
            {t("popup.action.passwordrecover.title")}
         </div>

         <Input
            id="password-input"
            placeholder={t("popup.action.passwordrecover.input.placeholder")}
            onChange={(e, { value }) => {
               setEmail(value);
            }}
            ref={inputRef}
            loading={isLoading}
            disabled={isLoading}
         />

         <div id="password-button">
            <Button
               buttonStyle={
                  isLoading || email.length === 0
                     ? "btn--primary--disable"
                     : "btn--primary--solid"
               }
               buttonSize="btn--auto"
               onClick={handleSubmit}
               disabled={isLoading || email.length === 0}
            >
               {t("popup.action.passwordrecover.button.name")}
            </Button>
         </div>
      </form>
   );
};
