import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import React from "react";

import "../assets/css/components/usercard.css";
import { BiTrash, BiShareAlt } from "react-icons/bi";

const UserCard = ({ userFirstName, userLastName, userEmail, userPicture, onClick, alreadyShared }) => {
   return (
      <button className="user-card" onClick={onClick}>
         {userPicture === null ? (
            <AccountCircleIcon style={{ fontSize: 32 }} />
         ) : (
            <img
               id="user-profile-picture"
               src={`${userPicture}`}
               alt={<AccountCircleIcon style={{ fontSize: 32 }} />}
            />
         )}
         <div className="user-info">
            <div className="user-name" title={userFirstName + " " + userLastName}>
               {userFirstName} {userLastName}
            </div>
            <div className="user-email">{userEmail}</div>
         </div>
         {alreadyShared ? <BiTrash size="24" /> : <BiShareAlt size="24" />}
      </button>
   );
};

export default UserCard;
