import "../../assets/css/views/home/home.css";

import { About } from "./About";
import { Contact } from "./Contact";
import { StartPage } from "./StartPage/StartPage";

export const Home = () => {
   return (
      <div className="home">
         <StartPage />
         <About />
         <Contact />
      </div>
   );
};
