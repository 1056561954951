import { DataGrid, ptBR, esES, frFR } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";

function LogTable({
    rows,
    isLoading,
    type,
    setSelectedRows
}) {

    const link = "monitoring.systemuser.popup";
    const { t, i18n } = useTranslation();
    const language = i18n.language;

    let localeText;

    switch (language) {
        case "pt_br":
            localeText = ptBR.props.MuiDataGrid.localeText;
            break;
        case "pt_pt":
            localeText = ptBR.props.MuiDataGrid.localeText;
            break;
        case "es_es":
            localeText = esES.props.MuiDataGrid.localeText;
            break;
        case "fr_fr":
            localeText = frFR.props.MuiDataGrid.localeText;
            break;
        default:
            localeText = ptBR.props.MuiDataGrid.localeText;
            break;
    }

    const useStyles = makeStyles({
        root: {
            fontFamily: "Montserrat, sans-serif",
            fontSize: "0.7em",
            borderRadius: 15,
            padding: 0
        },
        row: {
            cursor: "pointer",
        },
        customCell: {
            fontSize: 11,
        },
    });

    const classes = useStyles();

    const columnLogs = [
        {
            field: "jobType",
            headerName: t(`${link}.table.header.action`),
            editable: false,
            flex: 0.2,
            width: 150,
        },
        {
            field: "jobStatus",
            headerName: t(`${link}.table.header.status`),
            editable: false,
            flex: 0.2,
            width: 150,
        },
        {
            field: "date",
            headerName: t(`${link}.table.header.date.init`),
            editable: false,
            flex: 0.2,
            width: 150,
        },
        {
            field: "finishTime",
            headerName: t(`${link}.table.header.date.finish`),
            editable: false,
            flex: 0.2,
            width: 150,
        },
        {
            field: "id",
            headerName: "ID",
            editable: false,
            flex: 0.15,
            with: 100,
        },
    ];

    const columnProjects = [
        {
            field: "name",
            headerName: t(`${link}.table.header.name`),
            editable: false,
            flex: 0.1,
            width: 150
        },
        {
            field: "date",
            headerName: t(`${link}.table.header.date.default`),
            editable: false,
            flex: 0.15,
            width: 150
        },
        {
            field: "isSharedModelsProject",
            headerName: t(`${link}.table.header.shared`),
            editable: false,
            flex: 0.1,
            width: 150,
        },
        {
            field: "id",
            headerName: "ID",
            editable: false,
            flex: 0.08,
            width: 100
        },
    ];

    const columnDatasets = [
        {
            field: "name",
            headerName: t(`${link}.table.header.name`),
            editable: false,
            flex: 0.15,
            width: 150
        },
        {
            field: "date",
            headerName: t(`${link}.table.header.date.default`),
            editable: false,
            flex: 0.15,
            width: 150
        },
        {
            field: "isSource",
            headerName: t(`${link}.table.header.issource`),
            editable: false,
            flex: 0.1,
            width: 150,
        },
        {
            field: "id",
            headerName: "ID",
            editable: false,
            flex: 0.08,
            width: 100
        },
    ];

    const columnModels = [
        {
            field: "algorithm",
            headerName: t(`${link}.table.header.type`),
            editable: false,
            flex: 0.12,
            width: 150
        },
        {
            field: "date",
            headerName: t(`${link}.table.header.date.default`),
            editable: false,
            flex: 0.15,
            width: 150
        },
        {
            field: "framework",
            headerName: "FRAMEWORK",
            editable: false,
            flex: 0.15,
            width: 150
        },
        {
            field: "sharedWith",
            headerName: t(`${link}.table.header.shared`),
            editable: false,
            flex: 0.15,
            width: 150
        },
        {
            field: "id",
            headerName: "ID",
            editable: false,
            flex: 0.08,
            width: 100
        },
    ];

    const columnPredictions = [
        {
            field: "isBatch",
            headerName: t(`${link}.table.header.type`),
            editable: false,
            flex: 0.12,
            width: 150
        },
        {
            field: "date",
            headerName: t(`${link}.table.header.date.default`),
            editable: false,
            flex: 0.15,
            width: 150
        },
        {
            field: "id",
            headerName: "ID",
            editable: false,
            flex: 0.08,
            width: 100
        },
    ]

    const [columns, setColumns] = useState([]);

    useEffect(() => {
        let auxColumn
        switch (type) {
            case "Log":
                auxColumn = columnLogs;
                break;
            case "Project":
                auxColumn = columnProjects;
                break;
            case "Dataset":
                auxColumn = columnDatasets;
                break;
            case "Model":
                auxColumn = columnModels;
                break;
            case "Prediction":
                auxColumn = columnPredictions;
                break;
            default:
                auxColumn = [];
                break;
        }

        if (window.innerWidth < 1300) {
            auxColumn.forEach(columns => {
                delete columns.flex;
            })
        }

        setColumns(auxColumn);
        // eslint-disable-next-line
    }, [type])

    return (
        <DataGrid
            localeText={localeText}
            rows={rows}
            columns={columns}
            pageSize={rows.length > 100 ? 100 : rows.length}
            checkboxSelection
            disableRowSelectionOnClick
            loading={isLoading}
            rowHeight={40}
            classes={{
                root: classes.root,
                cell: classes.customCell,
            }}
            onSelectionModelChange={(newSelection) => {
                setSelectedRows(newSelection)
            }}
        />
    )
}

export default LogTable;