export function DadosIcon({ style }) {
   if (style == null) {
      style = [];
   }
   return (
      <svg
         width={style.hasOwnProperty("size") ? style.size : 24}
         height={style.hasOwnProperty("size") ? style.size : 24}
         viewBox="0 0 24 24"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M0 5.5C0 2.743 2.243 0.5 5 0.5H19C21.757 0.5 24 2.743 24 5.5H0ZM11 7.5H0V12.5H11V7.5ZM24 7.5H13V12.5H24V7.5ZM11 14.5H0V15.5C0 18.257 2.243 20.5 5 20.5H11V14.5ZM13 14.5V20.5H19C21.757 20.5 24 18.257 24 15.5V14.5H13Z"
            fill={style.hasOwnProperty("color") ? style.color : "#282828"}
         />
      </svg>
   );
}

export default DadosIcon;
