import React from "react";

import "../../assets/css/views/home/contact.css";

import { useTranslation } from "react-i18next"
import "./../../translation/i18n";


export const Contact = () => {

  const {t} = useTranslation();

  return (
    <div className="container-contact">
      <div className="title">{t("home.contact.header")}</div>
      <div className="wise">
        <div
          className="logo"
          onClick={() => window.open("https://www.wises.com.br/", "_blank")}
        >
          <img src={require("../../assets/images/wise_geral_1.svg").default} alt=""/>
        </div>
        <div className="description">{t("home.contact.content.text_1")}</div>
        <div className="informations">
          <div className="left">
            <div><a href="https://www.wises.com.br/" target="_blank" rel="noreferrer">wises.com.br</a></div>
          </div>
        </div>
      </div>
      <div className="lapti">
        <div
          className="logo"
          onClick={() => window.open("http://lapti.ct.utfpr.edu.br/", "_blank")}
        >
          <img src={require("../../assets/images/marca_lapti.svg").default} alt=""/>
        </div>
        <div className="description">{t("home.contact.content.text_2")}</div>
        <div className="informations">
          <div className="left">
            <a href="http://lapti.ct.utfpr.edu.br/" target="_blank" rel="noreferrer">
              lapti.ct.utfpr.edu.br
            </a>
            <span>
              <p>Prof Christian Mendes</p>
              <p>ccsm@utfpr.edu.br</p>
            </span>
            <span>
              <p>Prof Leandro Almeida</p>
              <p>leandro@utfpr.edu.br</p>
            </span>
          </div>
          <div className="right">
            <p>{"(41) 3310-4757"}</p>
            <span className="address">
              <p>Av. Sete de Setembro, 3165</p>
              <p>Rebouças, Curitiba, PR</p>
              <p>2º andar do Bloco D</p>
              <p>Centro de Inovações Tecnológicas (CITEC)</p>
              <p>Universidade Tecnológica Federal do Paraná</p>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

<div
  className="logo"
  onClick={() => window.open("http://lapti.ct.utfpr.edu.br/", "_blank")}
>
  <img src={require("../../assets/images/lapti.svg").default} alt=""/>
</div>;
