function UploadIcon({ style }) {
  if (style == null) {
    style = [];
  }

  return (
    <svg
      width={style.hasOwnProperty("size") ? style.size : 24}
      height={style.hasOwnProperty("size") ? style.size : 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_77_23)">
        <path
          d="M7.76009 6.65L10.5411 3.868L10.5621 17.5C10.5621 17.8978 10.7201 18.2794 11.0014 18.5607C11.2827 18.842 11.6643 19 12.0621 19V19C12.4599 19 12.8414 18.842 13.1227 18.5607C13.4041 18.2794 13.5621 17.8978 13.5621 17.5L13.5411 3.885L16.3061 6.65C16.4445 6.79327 16.61 6.90754 16.793 6.98615C16.976 7.06477 17.1728 7.10615 17.372 7.10788C17.5712 7.10961 17.7687 7.07166 17.953 6.99623C18.1374 6.92081 18.3048 6.80943 18.4457 6.66859C18.5865 6.52775 18.6979 6.36028 18.7733 6.17593C18.8487 5.99159 18.8867 5.79407 18.885 5.5949C18.8832 5.39573 18.8418 5.1989 18.7632 5.01589C18.6846 4.83289 18.5704 4.66737 18.4271 4.529L15.2151 1.317C14.7972 0.899132 14.3011 0.567659 13.7552 0.34151C13.2092 0.11536 12.624 -0.0010376 12.0331 -0.0010376C11.4421 -0.0010376 10.857 0.11536 10.311 0.34151C9.76503 0.567659 9.26895 0.899132 8.85109 1.317L5.63909 4.529C5.36585 4.8119 5.21466 5.19081 5.21807 5.5841C5.22149 5.9774 5.37925 6.35362 5.65736 6.63173C5.93547 6.90984 6.31169 7.06759 6.70498 7.07101C7.09828 7.07443 7.47718 6.92324 7.76009 6.65V6.65Z"
          fill={style.hasOwnProperty("color") ? style.color : "#282828"}
        />
        <path
          d="M22.5 14.5C22.1022 14.5 21.7206 14.658 21.4393 14.9393C21.158 15.2206 21 15.6022 21 16V20.591C20.9997 20.6994 20.9566 20.8033 20.8799 20.8799C20.8033 20.9566 20.6994 20.9997 20.591 21H3.409C3.30061 20.9997 3.19673 20.9566 3.12009 20.8799C3.04344 20.8033 3.00026 20.6994 3 20.591V16C3 15.6022 2.84196 15.2206 2.56066 14.9393C2.27936 14.658 1.89782 14.5 1.5 14.5C1.10218 14.5 0.720644 14.658 0.43934 14.9393C0.158035 15.2206 0 15.6022 0 16L0 20.591C0.00105862 21.4948 0.36056 22.3613 0.999642 23.0004C1.63872 23.6394 2.5052 23.9989 3.409 24H20.591C21.4948 23.9989 22.3613 23.6394 23.0004 23.0004C23.6394 22.3613 23.9989 21.4948 24 20.591V16C24 15.6022 23.842 15.2206 23.5607 14.9393C23.2794 14.658 22.8978 14.5 22.5 14.5Z"
          fill={style.hasOwnProperty("color") ? style.color : "#282828"}
        />
      </g>
      <defs>
        <clipPath id="clip0_77_23">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default UploadIcon;
