function ScatterPlotIcon({ style }) {
  if (style == null) {
    style = [];
  }

  return (
    <svg
      width={style.hasOwnProperty("size") ? style.size : 24}
      height={style.hasOwnProperty("size") ? style.size : 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23 22H3C2.73478 22 2.48043 21.8946 2.29289 21.7071C2.10536 21.5196 2 21.2652 2 21V1C2 0.734784 1.89464 0.48043 1.70711 0.292893C1.51957 0.105357 1.26522 0 1 0C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1L0 21C0 21.7956 0.31607 22.5587 0.87868 23.1213C1.44129 23.6839 2.20435 24 3 24H23C23.2652 24 23.5196 23.8946 23.7071 23.7071C23.8946 23.5196 24 23.2652 24 23C24 22.7348 23.8946 22.4804 23.7071 22.2929C23.5196 22.1054 23.2652 22 23 22Z"
        fill={style.hasOwnProperty("color") ? style.color : "#282828"}
      />
      <circle
        cx="7.5"
        cy="16.5"
        r="1.5"
        fill={style.hasOwnProperty("color") ? style.color : "#282828"}
      />
      <circle
        cx="9.5"
        cy="9.5"
        r="2.5"
        fill={style.hasOwnProperty("color") ? style.color : "#282828"}
      />
      <circle
        cx="15.5"
        cy="15.5"
        r="3.5"
        fill={style.hasOwnProperty("color") ? style.color : "#282828"}
      />
      <circle
        cx="17.5"
        cy="7.5"
        r="2.5"
        fill={style.hasOwnProperty("color") ? style.color : "#282828"}
      />
    </svg>
  );
}

export default ScatterPlotIcon;
