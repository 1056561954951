import React from "react";

import "../assets/css/components/modetabs.css";
import { useSelector } from "react-redux";
import { selectUserEmail } from "../store/slices/user/userSlice";
import { useTranslation } from "react-i18next";

const ModeTabs = ({ setReadOnly, readOnly, owner }) => {
   const { t } = useTranslation();
   const userEmail = useSelector(selectUserEmail);
   const userIsOwner = owner === userEmail;
   return (
      <div className="mode-tabs">
         <button
            className={`visualize ${readOnly ? "activated" : ""}`}
            onClick={() => setReadOnly(true)}
         >
            <strong>{t("model.header.mode_tabs.tab_1")}</strong>
         </button>
         {/* Só mostra o botão se o usuário for dono do modelo */}
         {userIsOwner && (
            <button
               className={`create ${!readOnly ? "activated" : ""}`}
               onClick={() => setReadOnly(false)}
            >
               <strong>{t("model.header.mode_tabs.tab_2")}</strong>
            </button>
         )}
      </div>
   );
};

export default ModeTabs;
