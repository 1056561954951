import "../../assets/css/views/models/multilayerperceptron.css";

import FileIcon from "../../assets/icons/jsxIcons/FileIcon";
import MultilayerPercetronIcon from "../../assets/icons/jsxIcons/MultilayerPerceptronIcon";
import LoadingWheel from "../../components/LoadingWheel";
import InputSlider from "../../components/Slider";
import WandIcon from "../../assets/icons/jsxIcons/WandIcon";

import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { setNewNotification } from "../../store/slices/notification/notificationSlice";
import { setRunningTask } from "../../store/slices/tasks/tasksSlice";

import { Popup } from "../../components/Popup";
import { MetricsPopup } from "../popups/informations/MetricsPopup";
import Prediction from "../popups/predictions/Prediction";
import TargetModel from "../../components/TargetModel";
import Dropdown from "../../components/Dropdown";

import modelService from "../../services/ModelService";

import MultilayerPerceptronD3 from "../../graphicsComponents/modelsRepresentations/MultilayerPerceptronD3";
import { setModelsResult } from "../../store/slices/cache/cacheSlice";
import { setNeedUpdateProjectList } from "../../store/slices/projects/projectsSlice";
import ModeTabs from "../../components/ModeTabs";
import { useTranslation } from "react-i18next";
import "./../../translation/i18n";

import handleErrorResponse from "../../helpers/handleErrorResponse";
import CustomTooltipWrapper from "../../components/CustomTooltip";
import { MdOutlineFileOpen } from "react-icons/md";
import SaveStatus from "../../components/SaveStatus";

function MultilayerPercetron({
   model,
   setModel,
   setGeneratedPredictionsPopup,
   setIsCreated,
   isCreated,
   dataSetExists,
   setDataSetExists,
}) {
   const { t } = useTranslation();
   const [readOnly, setReadOnly] = useState(isCreated);
   const [percentualTreinamento, setPercentualTreinamento] = useState(75);
   const [epoch, setEpoch] = useState(200);
   const [framework, setFramework] = useState("sklearn");
   const [activation, setActivation] = useState("identity");
   const [learningRate, setLearningRate] = useState(0.001);
   const [taskIsLoading, setTaskIsLoading] = useState(false);
   const [progress, setProgress] = useState(0);
   const [metricsPopup, setMetricsPopup] = useState(false);
   const [predictionPopup, setPredictionPopup] = useState(false);
   const [hiddenLayers, setHiddenLayers] = useState([]);
   const [editNeurons, setEditNeurons] = useState(false);
   const frameworkOptions = [
      { label: "Sklearn", value: "sklearn" },
      { label: "Spark", value: "spark" },
   ];
   const activationOptions = [
      { label: t("model.multilayerperceptron.select.option_1"), value: "identity" },
      { label: t("model.multilayerperceptron.select.option_2"), value: "logisti" },
      { label: t("model.multilayerperceptron.select.option_3"), value: "tanh" },
      { label: t("model.multilayerperceptron.select.option_4"), value: "relu" },
   ];

   const taskList = useSelector((state) => state.tasks.taskList);
   const [taskId, setTaskId] = useState("");

   const dispatch = useDispatch();
   const history = useHistory();

   useEffect(() => {
      if (taskId) {
         let task = taskList.filter((task) => task.task === taskId)[0];
         if (task) {
            setProgress(task.progress);
         } else if (progress <= 100) {
            setTaskId("");
            setProgress(100);
            setTaskIsLoading(false);
            modelService
               .getmodel(taskId)
               .then((data) => {
                  setTaskId("");
                  dispatch(setModelsResult(data));
                  dispatch(setNeedUpdateProjectList(true));
                  dispatch(setNewNotification(true));
                  history.push(`/model/${data.id}`);
               })
               .catch((error) => {
                  handleErrorResponse(history, error, t);
               });
         }
      }
      // eslint-disable-next-line
   }, [taskList]);

   useEffect(() => {
      setIsCreated(!!model?.id);
      setReadOnly(!!model?.id);
   }, [model]);

   function createMLP() {
      if (!taskIsLoading) {
         setIsCreated(false);
         setTaskIsLoading(true);
         setProgress(0);
         setModel({
            ...model,
            id: "",
            name: "",
            saved: false,
            sharedWith: null,
            result: null,
            metrics: "",
            significantFields: null,
            hasPrediction: false,
         });
         modelService
            .multilayerperceptron({
               mlframework: framework,
               datasetid: model?.datasetId,
               name: model?.name,
               trainfraction: percentualTreinamento,
               target: model?.target,
               featuresused: model?.fields?.usedFields.toString(),
               epoch: epoch,
               hiddenLayers: hiddenLayers,
               activation: framework === "spark" ? null : activation,
               learningRate: framework === "spark" ? null : learningRate,
            })
            .then((id) => {
               dispatch(setRunningTask(true));
               setTaskId(id);
            })
            .catch((error) => {
               setTaskIsLoading(false);
               handleErrorResponse(history, error, t);
            });
      }
   }

   const isShared = model?.sharedWith && model?.sharedWith.length > 0;

   return (
      <div className="model-algorithm" onClick={() => setEditNeurons(false)}>
         <div className="main-algorithm">
            <div className="left">
               <header className="header-algorithm">
                  {/* Nome do algoritmo */}
                  <div className="name">
                     <MultilayerPercetronIcon style={{ size: 24, color: "#282828" }} />
                     <p>MULTILAYER PERCEPTRON</p>
                  </div>
                  <div className="name">
                     {isShared && !dataSetExists  ? <FileIcon /> : <MdOutlineFileOpen size={24} />}
                     {!isShared && dataSetExists ? (
                        <button
                           onClick={() => {
                              history.push(`/dataset/${model?.datasetId}`);
                           }}
                        >
                           <p id="model-dataset">{model?.datasetName}</p>
                        </button>
                     ) : (
                        <p id="model-dataset">{model?.datasetName}</p>
                     )}
                  </div>
                  {isCreated && <ModeTabs readOnly={readOnly} setReadOnly={setReadOnly} owner={model?.owner} />}
                  <TargetModel
                     model={model}
                     dataSetExists={dataSetExists}
                     setDataSetExists={setDataSetExists}
                     readOnly={readOnly}
                     nameAlgorithm="Multilayer Perceptron"
                  />
               </header>
               {/* Hiperparâmetros */}
               <div className="model-hyperparameters">
                  <div className="model-slider">
                     <CustomTooltipWrapper title={t("model.multilayerperceptron.slider_1.info")}>
                        <p>{t("model.multilayerperceptron.slider_1")}</p>
                     </CustomTooltipWrapper>
                     <InputSlider
                        currentValue={percentualTreinamento}
                        setCurrentValue={setPercentualTreinamento}
                        isPercentage={true}
                        min={0}
                        max={100}
                        id="training"
                     />
                  </div>
                  <div className="model-slider">
                     <CustomTooltipWrapper title={t("model.multilayerperceptron.slider_2.info")}>
                        <p>{t("model.multilayerperceptron.slider_2")}</p>
                     </CustomTooltipWrapper>
                     <InputSlider
                        currentValue={epoch}
                        setCurrentValue={setEpoch}
                        isPercentage={false}
                        min={10}
                        max={1000}
                        id="epoch"
                     />
                  </div>
                  {framework === "sklearn" && (
                     <div className="model-slider">
                        <CustomTooltipWrapper title={t("model.multilayerperceptron.slider_3.info")}>
                           <p>{t("model.multilayerperceptron.slider_3")}</p>
                        </CustomTooltipWrapper>
                        <InputSlider
                           currentValue={learningRate}
                           setCurrentValue={setLearningRate}
                           isPercentage={false}
                           step={0.001}
                           min={0.001}
                           max={0.5}
                           id="learningRate"
                        />
                     </div>
                  )}
                  <div className="hidderLayers">
                     <CustomTooltipWrapper title={t("model.multilayerperceptron.slider_4.info")}>
                        <p>{t("model.multilayerperceptron.neuron.header.name")}</p>
                     </CustomTooltipWrapper>
                     <div className="control" id="neurouns">
                        <button
                           style={{ fontSize: 40, cursor: "pointer", opacity: 0.2 }}
                           onClick={() => {
                              let string = JSON.stringify(hiddenLayers);
                              let array = JSON.parse(string);
                              array.pop();
                              setHiddenLayers(array);
                           }}
                        >
                           <p>-</p>
                        </button>
                        <div style={{ width: 42, background: "#F2F2F2", borderRadius: 6 }}>
                           <p>{hiddenLayers.length}</p>
                        </div>
                        <button
                           style={{ fontSize: 40, cursor: "pointer" }}
                           onClick={() => {
                              if (hiddenLayers.length < 8) {
                                 let string = JSON.stringify(hiddenLayers);
                                 let array = JSON.parse(string);
                                 array.push(1);
                                 setHiddenLayers(array);
                              }
                           }}
                        >
                           <p>+</p>
                        </button>
                     </div>
                     <button
                        style={{ cursor: "pointer", background: "transparent", border: "none" }}
                        onClick={() => {
                           if (hiddenLayers.length > 0 && editNeurons === false) {
                              setTimeout(() => {
                                 let element = document.getElementById("neurouns")?.getBoundingClientRect();
                                 setEditNeurons(false);
                                 const x = element.x + (window.innerWidth < 500 ? 0 : element.width + 30);
                                 const y = element.y + (window.innerWidth < 500 ? element.height + 55 : 0);
                                 setEditNeurons({
                                    x: x,
                                    y: y,
                                    left: x + (window.innerWidth < 500 ? 20 : -12),
                                    top: y + (window.innerWidth < 500 ? -12 : 30),
                                 });
                              }, 0);
                           } else {
                              setEditNeurons(false);
                           }
                        }}
                     >
                        <p style={{ color: "#22577A" }}>{t("model.multilayerperceptron.neuron.edit")}</p>
                     </button>
                  </div>
                  {framework === "sklearn" && (
                     <div className="model-dropdown">
                        <CustomTooltipWrapper title={t("model.multilayerperceptron.slider_5.info")}>
                           <p>{t("model.multilayerperceptron.select.header")}</p>
                        </CustomTooltipWrapper>
                        <Dropdown
                           defaultValue={0}
                           onChange={setActivation}
                           options={activationOptions}
                           disabled={readOnly}
                        />
                     </div>
                  )}
                  <div className="model-dropdown">
                     <p>{t("model.backend")}</p>
                     <Dropdown
                        defaultValue={0}
                        onChange={setFramework}
                        options={frameworkOptions}
                        disabled={readOnly}
                     />
                  </div>
               </div>
               {!readOnly && (
                  <div className="model-execute">
                     <button
                        onClick={createMLP}
                        className={dataSetExists ? "" : "btn--disabled--solid"}
                        disabled={model?.id || taskIsLoading}
                     >
                        <p>{t("model.execute")}</p>
                     </button>
                  </div>
               )}
            </div>
            <div className="model-result-2">
               {taskIsLoading && <LoadingWheel progress={progress} loadingSize="large" />}
               {model?.sharedWith === null && isCreated && !model?.saved && <SaveStatus />}
               {model?.result && <MultilayerPerceptronD3 datas={model?.result} framework={framework} />}
            </div>
         </div>

         {model?.id && (
            <div className="buttons-bottom-algorithm">
               {model?.isCategorical && (
                  <button
                     disabled={!model}
                     onClick={() => {
                        setMetricsPopup(!metricsPopup);
                     }}
                  >
                     <p>{t("model.footer.button_1")}</p>
                  </button>
               )}
               {model?.hasPrediction && (
                  <button
                     disabled={!model?.hasPrediction}
                     onClick={() => {
                        setGeneratedPredictionsPopup(true);
                     }}
                  >
                     <p>{t("model.footer.button_3")}</p>
                  </button>
               )}
               <button
                  disabled={!model}
                  onClick={() => {
                     setPredictionPopup(model ? !predictionPopup : false);
                  }}
                  id="fill"
               >
                  <WandIcon />
                  <p>{t("model.footer.button_4")}</p>
               </button>
            </div>
         )}

         {editNeurons && (
            <div className="editNeurons" style={{ left: editNeurons?.x, top: editNeurons?.y }}>
               <div id="editNeurounsRectangle" style={{ left: editNeurons?.left, top: editNeurons?.top }}></div>
               <header className="headerEditNeurouns">{t("model.multilayerperceptron.neuron.popup.header")}</header>
               <div
                  className="neurons"
                  onClick={(e) => {
                     e.stopPropagation();
                  }}
               >
                  {hiddenLayers.map((layer, index) => {
                     return (
                        <div className="containerLayer" key={index}>
                           <header className="headerContainerLayers">
                              {t("model.multilayerperceptron.neuron.popup.layer")} {index + 1}
                           </header>
                           <InputSlider
                              currentValue={layer}
                              handleValue={(newValue) => {
                                 let string = JSON.stringify(hiddenLayers);
                                 let array = JSON.parse(string);
                                 array[index] = newValue;
                                 setHiddenLayers(array);
                              }}
                              isPercentage={false}
                              min={1}
                              max={10}
                              id={`layer-${index + 1}`}
                           />
                        </div>
                     );
                  })}
               </div>
            </div>
         )}

         <Popup trigger={metricsPopup} setTrigger={setMetricsPopup}>
            <MetricsPopup
               data={{
                  metrics: model?.metrics,
                  framework: model?.framework,
                  type: model?.type,
               }}
            />
         </Popup>

         <Popup trigger={predictionPopup} setTrigger={setPredictionPopup}>
            <Prediction
               targetName={model?.target}
               fields={model?.fields}
               modelId={model?.id}
               algorithmType={model?.type}
            />
         </Popup>
      </div>
   );
}

export default MultilayerPercetron;
