import http from "../http-common";

function getproject(id) {
   return http
      .get(`/getproject/${localStorage.getItem("user_token")}/${id}`)
      .then((response) => {
         return response.data;
      });
}

function getprojectlist() {
   return http
      .get(`/getprojectlist/${localStorage.getItem("user_token")}`)
      .then((response) => {
         return response.data;
      });
}

function deleteproject(id) {
   const token = localStorage.getItem("user_token");
   return http
      .request({
         method: "delete",
         url: "/deleteproject",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}}`,
         },
         data: { token: token, id: id },
      })
      .then((response) => {
         return response;
      });
}

function deletemanyprojects(id) {
   const token = localStorage.getItem("user_token");
   return http
      .post("/deletemanyproject", {
         token: token,
         options: id,
      })
      .then((response) => {
         return response;
      });
}

function renameproject(id, option) {
   return http
      .patch("/renameproject", {
         token: localStorage.getItem("user_token"),
         id: id,
         option: option,
      })
      .then((response) => {
         return response.data;
      });
}

function changedescription(id, option) {
   return http
      .patch("/changedescription", {
         token: localStorage.getItem("user_token"),
         id: id,
         option: option,
      })
      .then((response) => {
         return response.data;
      });
}

const projectService = {
   getproject,
   getprojectlist,
   deleteproject,
   deletemanyprojects,
   renameproject,
   changedescription,
};

export default projectService;
