import { Loader } from "semantic-ui-react";
import "../../../assets/css/views/popups/actions/confirmationpopup.css";
import { Button } from "../../../components/Button";

import { useTranslation } from "react-i18next";
import "./../../../translation/i18n";

export const ConfirmationPopup = ({ title, description, confirmText, denyText, onConfirm, onDeny, isLoading }) => {
   const { t } = useTranslation();

   return (
      <div id="confirmation-popup">
         <div id="title-msg" style={{ display: "flex", justifyContent: "center" }}>
            {title}
         </div>
         <div id="description" style={{ display: "flex", justifyContent: "center" }}>
            {description}
         </div>
         {isLoading && <Loader active />}
         <div id="buttons">
            <Button
               type="button"
               buttonStyle="btn--primary--outline"
               buttonSize="btn--auto"
               disabled={isLoading}
               onClick={onConfirm}
            >
               {confirmText ? confirmText : t("popup.action.confirm.yes")}
            </Button>

            <Button type="button" buttonStyle="btn--primary--solid" buttonSize="btn--auto" onClick={onDeny}>
               {denyText ? denyText : t("popup.action.confirm.no")}
            </Button>
         </div>
      </div>
   );
};

export default ConfirmationPopup;
