import * as d3 from "d3";
import React, { useEffect, useRef } from "react";

function FPGrowthD3Example() {
    const d3Chart = useRef();

    const datas = {
        nodes: [
            { id: "milk" },
            { id: "bread" },
            { id: "butter" },
            { id: "diapers" },
            { id: "beer" },
            { id: "wine" },
            { id: "apple" },
            { id: "banana"},
            { id: "orange" }
        ],
        links: [
            { source: "milk", target: "bread", value: 0.5 },
            { source: "milk", target: "wine", value: 0.5 },
            { source: "bread", target: "wine", value: 0.5 },
            { source: "wine", target: "butter", value: 0.5 },
            { source: "bread", target: "butter", value: 0.4 },
            { source: "milk", target: "diapers", value: 0.3 },
            { source: "beer", target: "butter", value: 0.3 },
            { source: "banana", target: "orange", value: 0.3 },
            { source: "banana", target: "diapers", value: 0.3 },
            // ... outros links
        ]
    };

    useEffect(() => {
        const margin = { top: 0, right: 0, bottom: 0, left: 0 };
        const width = parseInt(d3.select(".model-representation").style("width"), 10) - margin.left - margin.right;
        const height = parseInt(d3.select(".model-representation").style("height"), 10) - margin.top - margin.bottom;

        const svg = d3
            .select(d3Chart.current)
            .attr("viewBox", `0 0 ${width} ${height}`)
            .attr("preserveAspectRatio", "xMidYMid meet")
            .append("g")
            .attr("transform", `translate(${margin.left},${margin.top})`);

        const simulation = d3
            .forceSimulation(datas.nodes)
            .force("link", d3.forceLink(datas.links).id(d => d.id).distance(100))
            .force("charge", d3.forceManyBody().strength(-10))
            .force("center", d3.forceCenter(width / 2, height / 2))
            .on("tick", ticked);

        const link = svg
            .append("g")
            .attr("stroke", "#999")
            .attr("stroke-opacity", 0.6)
            .selectAll("line")
            .data(datas.links)
            .enter().append("line")
            .attr("stroke-width", d => Math.sqrt(d.value));

        const node = svg
            .append("g")
            .attr("fill", "#22577A")
            .attr("stroke", "#fff")
            .attr("stroke-width", 1.5)
            .selectAll("circle")
            .data(datas.nodes)
            .enter().append("circle")
            .attr("r", 12);

        function ticked() {
            link
                .attr("x1", d => d.source.x)
                .attr("y1", d => d.source.y)
                .attr("x2", d => d.target.x)
                .attr("y2", d => d.target.y);

            node
                .attr("cx", d => d.x)
                .attr("cy", d => d.y);
        }

        // eslint-disable-next-line
    }, []);

    return <svg ref={d3Chart}></svg>;
}

export default FPGrowthD3Example;