export function CheckIcon({ style }) {
  if (style == null) {
    style = [];
  }

  return (
    <svg
      width={style.hasOwnProperty("size") ? style.size : 12}
      height={style.hasOwnProperty("size") ? style.size : 12}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M22.319 4.431L8.5 18.249C8.40709 18.3423 8.29668 18.4163 8.17509 18.4668C8.05351 18.5173 7.92315 18.5433 7.7915 18.5433C7.65985 18.5433 7.52949 18.5173 7.4079 18.4668C7.28632 18.4163 7.17591 18.3423 7.083 18.249L1.739 12.9C1.64609 12.8067 1.53568 12.7327 1.41409 12.6822C1.29251 12.6317 1.16215 12.6057 1.0305 12.6057C0.898845 12.6057 0.768487 12.6317 0.646905 12.6822C0.525322 12.7327 0.414909 12.8067 0.321999 12.9C0.228722 12.9929 0.15471 13.1033 0.104208 13.2249C0.0537066 13.3465 0.02771 13.4768 0.02771 13.6085C0.02771 13.7402 0.0537066 13.8705 0.104208 13.9921C0.15471 14.1137 0.228722 14.2241 0.321999 14.317L5.668 19.662C6.23195 20.2249 6.9962 20.541 7.793 20.541C8.5898 20.541 9.35405 20.2249 9.918 19.662L23.736 5.847C23.8291 5.75411 23.903 5.64376 23.9534 5.52227C24.0038 5.40078 24.0298 5.27053 24.0298 5.139C24.0298 5.00747 24.0038 4.87722 23.9534 4.75573C23.903 4.63424 23.8291 4.52389 23.736 4.431C23.6431 4.33772 23.5327 4.26371 23.4111 4.21321C23.2895 4.16271 23.1592 4.13671 23.0275 4.13671C22.8958 4.13671 22.7655 4.16271 22.6439 4.21321C22.5223 4.26371 22.4119 4.33772 22.319 4.431Z"
          fill={style.hasOwnProperty("color") ? style.color : "#282828"}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width={style.hasOwnProperty("size") ? style.size : 24}
            height={style.hasOwnProperty("size") ? style.size : 24}
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CheckIcon;
