import styles from "./../../../assets/css/monitoring/views/screens/AttempLimit.module.css";
import LoadingWheel from "../../../components/LoadingWheel.jsx";
import TableGrid from "./../../tables/TableGrid.jsx";
import ConfirmationPopup from "../../../views/popups/actions/ConfirmationPopup.jsx";
import { Popup } from "../../../components/Popup.jsx";
import TrashIcon from "./../../../assets/icons/jsxIcons/TrashIcon.jsx";
import { toast } from "react-toastify";

import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { t } from "i18next";
import handleErrorResponse from "../../../helpers/handleErrorResponse.js";
import monitoringService from "../../../services/MonitoringService.js";

function AttempLimit() {
   const link = "monitoring.attemplimit";

   const history = useHistory();
   const [isLoading, setIsLoading] = useState(true);
   const [attempLimits, setAttempLimits] = useState([]);
   const [confirmationTrigger, setConfirmationTrigger] = useState(false);
   const [ids, setIds] = useState([]);

   function updateAttemptLimits() {
      setIsLoading(true);
      monitoringService.getattemptlimits()
         .then(data => {
            setAttempLimits(data.map(attemp => {
               return {
                  ...attemp,
                  ipAdress: attemp.ipAdress.clientIp,
                  os: attemp.ipAdress.os,
                  browser: attemp.ipAdress.browser,
                  date: new Date(attemp.date * 1000).toLocaleString('pt-BR'),
                  time: `${attemp.time / 60} min`
               }
            }))
         })
         .catch(error => handleErrorResponse(history, error, t))
         .finally(() => setIsLoading(false));
   }

   useEffect(() => {
      updateAttemptLimits();
      // eslint-disable-next-line
   }, []);

   const columns = [
      {
         field: "ipAdress",
         headerName: t(`${link}.clientip`),
         editable: false,
         width: 250,
         flex: 0.1
      },
      {
         field: "os",
         headerName: t(`${link}.so`),
         editable: false,
         width: 150,
         flex: 0.1
      },
      {
         field: "browser",
         headerName: t(`${link}.browser`),
         editable: false,
         width: 200,
         flex: 0.1
      },
      {
         field: "date",
         headerName: t(`${link}.date`),
         editable: false,
         width: 200,
         flex: 0.1
      },
      {
         field: "time",
         headerName: t(`${link}.timeban`),
         editable: false,
         width: 200,
         flex: 0.1
      },
      {
         field: "attemptsCounter",
         headerName: t(`${link}.count`),
         editable: false,
         width: 200,
         flex: 0.1
      },
   ];


   const deleteAtempLimits = () => {
      setConfirmationTrigger(false);
      setIsLoading(true);
      monitoringService.deleteanythingbyid(ids, "AttemptLimit")
         .then(data => {
            updateAttemptLimits();
            toast.success(t(`backend.SUCCESS.${data}`));
         })
         .catch(error => handleErrorResponse(history, error, t))
         .finally(() => {
            setIsLoading(false);
            setIds([]);
         })
   }

   if (isLoading) {
      return (
         <main className={styles.isloading}>
            <LoadingWheel loadingSize="large" />
         </main>
      )
   } else {
      return (
         <main className={styles.containerattemplimit}>
            <div className={styles.buttons}>
               <button
                  onClick={() => setConfirmationTrigger(true)}
                  className={`${styles.button} ${!ids.length ? styles.buttondisabled : ""}`}
                  disabled={!ids.length}
               >
                  <TrashIcon />
                  <p>{t(`${link}.buttons.delete`)}</p>
               </button>
            </div>
            <div className={styles.table}>
               <TableGrid
                  columns={columns}
                  rows={attempLimits}
                  isLoading={isLoading}
                  setSelectedRows={setIds}
               />
            </div>
            <Popup trigger={confirmationTrigger} setTrigger={setConfirmationTrigger}>
               <ConfirmationPopup
                  title={t("monitoring.popup.areyousure")}
                  onConfirm={deleteAtempLimits}
                  onDeny={() => setConfirmationTrigger(false)}
               />
            </Popup>
         </main>
      )
   }
}

export default AttempLimit;
