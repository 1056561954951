import React from "react";
import PropTypes from "prop-types";
import { Dropdown } from "semantic-ui-react";
import { useTranslation } from "react-i18next"
import "./../translation/i18n";

const CheckboxLabeled = ({
  isSelectedActive,
  setIsSelectedActive,
  selectedIds,
  setSelectedIds,
  cards,
}) => {
  const {t} = useTranslation();
  const options = [
    {
      key: "select-all",
      text: t("components.checkboxlabeled"),
      onClick: (e) => {
        e.preventDefault();
        const cardsId = cards.map((card) => {
          if (!card?.shared) {
            if(typeof card?.id === "undefined"){
              return card.key
            }
            return card.id
          }
        });
        let filteredCards = cardsId.filter((card) => typeof card !== "undefined")
        setIsSelectedActive(true);
        setSelectedIds(filteredCards);
      },
    },
    // { key: "delete-all", text: "Excluir Todos", value: "delete-all" },
  ];

  return (
    <div className="checkbox-container" style={{ display: "flex", alignItems: "center"}}>
      <input
        type="checkbox"
        style={{ transform: "scale(1.5)", maxWidth: "5rem" }}
        checked={isSelectedActive}
        onChange={() => setIsSelectedActive(!isSelectedActive)}
      />
      <Dropdown
        className="icon"
        direction="left"
        floating
        options={options}
        trigger={<></>}
      />
    </div>
  );
};

CheckboxLabeled.propTypes = {
  isSelectedActive: PropTypes.bool.isRequired,
  setIsSelectedActive: PropTypes.func.isRequired,
  setSelectedIds: PropTypes.func.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.any),
  cards: PropTypes.arrayOf(PropTypes.any),
};

export default CheckboxLabeled;