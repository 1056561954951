import { createSlice } from "@reduxjs/toolkit";

const updateOrAddItemById = (array, newItem) => {
  const existingIndex = array.findIndex((item) => item.id === newItem.id);
  if (existingIndex !== -1) {
    // Se existir, substitui o item existente
    array[existingIndex] = newItem;
  } else {
    // Se não existir, adiciona o novo item
    array.push(newItem);
  }
};

const cacheSlice = createSlice({
  name: "cache",
  initialState: {
    dataSources: [],
    dataSets: [],
    modelsResult: [],
    dataSetsContent: [],
    dataSourcesContent: [],
    needUpdateDataset: false,
    needUpdateDataSource: false,
    needUpdateModelResult: false,
    needUpdateMyDatasources: false,
  },
  reducers: {
    setDataSources: (state, action) => {
      state.dataSources = action.payload;
    },
    setDataSets: (state, action) => {
      state.dataSets = action.payload;
    },
    setModelsResult: (state, action) => {
      updateOrAddItemById(state.modelsResult, action.payload);
    },
    setDataSetsContent: (state, action) => {
      updateOrAddItemById(state.dataSetsContent, action.payload);
    },
    setDataSourcesContent: (state, action) => {
      updateOrAddItemById(state.dataSourcesContent, action.payload);
    },
    setNeedUpdateDataset: (state, action) => {
      state.needUpdateDataset = action.payload;
    },
    setNeedUpdateDataSource: (state, action) => {
      state.needUpdateDataSource = action.payload;
    },
    setNeedUpdateModelResult: (state, action) => {
      state.needUpdateModelResult = action.payload;
    },
    setNeedUpdateMyDatasources: (state, action) => {
      state.needUpdateMyDatasources = action.payload;
    },
  },
});

export const {
  setDataSets,
  setDataSources,
  setModelsResult,
  setDataSetsContent,
  setDataSourcesContent,
  setNeedUpdateDataset,
  setNeedUpdateDataSource,
  setNeedUpdateModelResult,
  setNeedUpdateMyDatasources,
} = cacheSlice.actions;

export default cacheSlice.reducer;

export const selectDataSets = (state) => state.cache.dataSets;
export const selectDataSources = (state) => state.cache.dataSources;
export const selectModelsResult = (state) => state.cache.modelsResult;
export const selectDataSetsContent = (state) => state.cache.dataSetsContent;
export const selectDataSourcesContent = (state) =>
  state.cache.dataSourcesContent;
export const selectNeedUpdateDataset = (state) => state.cache.needUpdateDataset;
export const selectNeedUpdateDataSource = (state) =>
  state.cache.needUpdateDataSource;
export const selectNeedUpdateModelResult = (state) =>
  state.cache.needUpdateModelResult;
export const selectNeedUpdateMyDatasources = (state) =>
  state.cache.needUpdateMyDatasources;
