import React from 'react';

import "../../../assets/css/views/popups/informations/linearregressionimportancepopup.css"
import { useTranslation } from "react-i18next"
import "./../../../translation/i18n";

export const LinearRegressionImportancePopup = ({modelResult, modelName}) => {
    const {t} = useTranslation();
    const pesos = [...modelResult.Significances]
    
    // organizando em decrescente
    function compare( a, b ) {
        if ( a.Value > b.Value ){
          return -1;
        }
        if ( a.Value < b.Value ){
          return 1;
        }
        return 0;
      }
      
    pesos.sort(compare)
    
    const link = "popup.informations.importance"

    return (
        <div className="importancia-popup-linear">
            <div id='texto-popup'>{t(`${link}.title`) + " | " + modelName}</div>
            <div className='content-popup'>
                <div className='info-popup'>
                    {pesos.map((peso) => {
                        return (
                            <div className="names-popup">
                                <div id="feature-popup">{peso.Feature}</div>
                                <div id="values-popup">{peso.Value === 0 ? t(`${link}.no_significant`) : t(`${link}.significant`)}</div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>

    )
}