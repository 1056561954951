import * as d3 from "d3";
import React, { useEffect, useRef, useCallback } from "react";

import "../../assets/css/graphicsComponents/examples/kmeansd3example.css";

import example_data from "./kmeans-example.json";


export const KmeansD3Example = () => {

  /* PREVINE ZOOM NA PÁGINA */
  // https://stackoverflow.com/a/56546458
  // https://stackoverflow.com/a/55566585
  // https://stackoverflow.com/a/33083910
  // https://stackoverflow.com/q/27116221
  // Adicionar em modelos que possuam zoom próprio
  // (pra poder dar zoom com Ctrl + e Ctrl -)
  /* -------------- COMEÇO ---------------- */
  const handleUserZoom = useCallback((event) => {
    if (event.ctrlKey) {
      event.preventDefault();
    }
  }, []);

  useEffect(() => {
    document.getElementById("root").addEventListener("wheel", handleUserZoom);

    return () => {
      document
        .getElementById("root")
        .removeEventListener("wheel", handleUserZoom);
    };
  }, [handleUserZoom]);
  /* -------------- FIM ------------------- */

  // vetor com as paletas de cores indicadas no figma
  const colorScheme = [
    "rgba(73, 47, 117, 1)",
    "rgba(38, 55, 128, 1)",
    "rgba(2, 62, 138, 1)",
    "rgba(105, 61, 143, 1)",
    "rgba(53, 106, 171, 1)",
    "rgba(0, 150, 199, 1)",
    "rgba(149, 101, 178, 1)",
    "rgba(111, 152, 203, 1)",
    "rgba(72, 202, 228, 1)",
    "rgba(205, 167, 224, 1)",
    "rgba(189, 200, 234, 1)",
    "rgba(173, 232, 244, 1)",
  ];

  // inicializa um Ref
  const d3Chart = useRef();

  //Key Perfomance Indicator
  function KmeansKPI(d, i) {
    if (i > colorScheme.length) {
      return colorScheme[colorScheme.length - (i % colorScheme.length)];
    } else {
      return colorScheme[i];
    }
  }

  function desenharKmeans() {
    // adicionado pra remover um bug que, ao alterar entre
    // algoritmos muito rápido, fazia o frontend crashar porque
    // o resultado era null ou não possuia uma propriedade de
    // 'centers', que (eu acho) que são os centroides do kmeans
    if (example_data === null || !example_data.hasOwnProperty("centers")) {
      return;
    }

    // remove a linha e os eixos pra desenhar novamente, evitando que fique se sobrepondo
    d3.select("#kmeansd3-circle").remove();

    const margin = {top: 0, right: 0, bottom: 0, left: 0};
    const width = parseInt(d3.select(".model-representation").style("width"), 10) - margin.left - margin.right;
    const height = parseInt(d3.select(".model-representation").style("height"), 10) - margin.top - margin.bottom;

    const svg = d3
      .select(d3Chart.current)
      .attr("viewBox", `0 0 ${width} ${height}`) // Define a caixa de visualização
      .attr("preserveAspectRatio", "xMidYMid meet") // Mantém a proporção do gráfico
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // pra mover usando espaço
    // pra dar zoom segurando shift

    /* -------- Aqui começa o código individual ---------- */

    // propriedades da simulação de forças, feito pelo Gustavo I
    var forceProperties = {
      center: {
        x: 0.5,
        y: 0.5,
      },
      collide: {
        strenghth: 0.7,
        iterations: 1,
        radius: 10,
      },
      forceX: {
        enabled: false,
        strenghth: 0.1,
        x: 0.5,
      },
      forceY: {
        enabled: false,
        strenghth: 0.1,
        y: 0.5,
      },
    };

    //Cria simulação de forças
    var simulation = d3.forceSimulation();

    //Cria escala de tamanho
    var escala = d3
      .scaleLinear()
      .domain([
        0,
        Math.max.apply(
          null,
          example_data.centers.map((x) => x.count * 0.55)
        ),
      ])
      .range([0, width / 10]);

    const node = svg
      .append("g")
      .attr("id", "kmeansd3-circle")
      .attr("class", "nodes")
      //.attr("cursor", "grab")
      .selectAll("circle")
      .data(example_data.centers)
      .join("circle")
      .attr("cx", ({ x }) => x)
      .attr("cy", ({ y }) => y)
      .attr("r", (d, i) => {
        return escala(d.count);
      })
      .attr("fill", function (d, i) {
        return KmeansKPI(d, i);
      })

    simulation.nodes(example_data.centers);
    simulation
      .force("collide", d3.forceCollide())
      .force("center", d3.forceCenter());

    simulation
      .force("center")
      .x(width * forceProperties.center.x)
      .y(height * forceProperties.center.y);
    simulation
      .force("collide")
      .strength(0.7)
      .radius((d) => {
        return escala(d.count);
      })
      .iterations(3);

    // Reinicia a simulação para aplicar mudanças
    simulation.alpha(1).restart();
    simulation.on("tick", () => {
      node
        .attr("cx", function (d) {
          return d.x;
        })
        .attr("cy", function (d) {
          return d.y;
        });
    });

    return svg.node();
  }

  useEffect(() => {
    desenharKmeans();
  });

  return <svg ref={d3Chart}></svg>;
};
