import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    modified: false,
};

export const sidebarSlice = createSlice({
    name: "sidebar",
    initialState,
    reducers: {
        setSidebarModified: (state, action) => {
            state.modified = action.payload;
        },
    },
});

export const { setSidebarModified } = sidebarSlice.actions;

export default sidebarSlice.reducer;
export const selectSideBarModified = (state) => state.sidebar.modified;
