export function AlertIcon({ style }) {

    // padrao para mudar as propriedades 
    // {style.hasOwnProperty('size') ? style.size : 24}
    // {style.hasOwnProperty('color') ? style.color : "#282828"}

    if (style == null) {
        style = [];
    }
    return (
      <svg
        width={style.hasOwnProperty("size") ? style.size : 24}
        height={style.hasOwnProperty("size") ? style.size : 24}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
          <path
            d="M12 24C14.3734 24 16.6935 23.2962 18.6668 21.9776C20.6402 20.6591 22.1783 18.7849 23.0866 16.5922C23.9948 14.3995 24.2324 11.9867 23.7694 9.65892C23.3064 7.33115 22.1635 5.19295 20.4853 3.51472C18.8071 1.83649 16.6689 0.693605 14.3411 0.230582C12.0133 -0.232441 9.60051 0.00519943 7.4078 0.913451C5.21509 1.8217 3.34094 3.35977 2.02236 5.33316C0.703788 7.30655 0 9.62663 0 12C0.00344108 15.1815 1.26883 18.2318 3.51852 20.4815C5.76821 22.7312 8.81846 23.9966 12 24ZM11 6.00001C11 5.73479 11.1054 5.48043 11.2929 5.2929C11.4804 5.10536 11.7348 5.00001 12 5.00001C12.2652 5.00001 12.5196 5.10536 12.7071 5.2929C12.8946 5.48043 13 5.73479 13 6.00001V14C13 14.2652 12.8946 14.5196 12.7071 14.7071C12.5196 14.8946 12.2652 15 12 15C11.7348 15 11.4804 14.8946 11.2929 14.7071C11.1054 14.5196 11 14.2652 11 14V6.00001ZM12 18C12.1978 18 12.3911 18.0587 12.5556 18.1685C12.72 18.2784 12.8482 18.4346 12.9239 18.6173C12.9996 18.8 13.0194 19.0011 12.9808 19.1951C12.9422 19.3891 12.847 19.5673 12.7071 19.7071C12.5673 19.847 12.3891 19.9422 12.1951 19.9808C12.0011 20.0194 11.8 19.9996 11.6173 19.9239C11.4346 19.8482 11.2784 19.72 11.1685 19.5556C11.0586 19.3911 11 19.1978 11 19C11 18.7348 11.1054 18.4804 11.2929 18.2929C11.4804 18.1054 11.7348 18 12 18Z"
            fill={style.hasOwnProperty("color") ? style.color : "#282828"}
          />
      </svg>
    );
}

export default AlertIcon;
