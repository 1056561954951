import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
   selectDataSetsContent,
   selectDataSourcesContent,
   selectNeedUpdateDataSource,
   selectNeedUpdateDataset,
   setDataSetsContent,
   setDataSourcesContent,
   setNeedUpdateDataSource,
   setNeedUpdateDataset,
} from "../../store/slices/cache/cacheSlice";
import { DataGrid, ptBR, esES, frFR } from "@material-ui/data-grid";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import wiselib from "../../services/wiselib";
import {
   useHistory,
   useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import ColumnHistogram from "./ColumnHistogram";
import datasetService from "../../services/DatasetService";
import handleErrorResponse from "../../helpers/handleErrorResponse";
import { toast } from "react-toastify";

const useStyles = makeStyles({
   root: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1em",
   },
});

const DatasetStatistics = () => {
   const { t, i18n } = useTranslation();
   const language = i18n.language;
   const classes = useStyles();

   const dispatch = useDispatch();

   const history = useHistory();

   const location = useLocation();

   const isDataSourcePage = location.pathname.includes("datasource");

   const pageId = location.pathname.split("/").pop();

   const dataSourceContent = useSelector(selectDataSourcesContent);

   const dataSetContent = useSelector(selectDataSetsContent);

   const needUpdateDataset = useSelector(selectNeedUpdateDataset);

   const needUpdateDataSource = useSelector(selectNeedUpdateDataSource);

   const [isLoading, setIsLoading] = useState(false);

   const [rows, setRows] = useState([]);

   const [cellValueSelected, setCellValueSelected] = useState("");

   const cachedDataset = useMemo(
      () => dataSetContent.find((dataset) => dataset.id === pageId) || [],
      [dataSetContent, pageId]
   );

   const cachedDataSource = useMemo(
      () =>
         dataSourceContent.find((datasource) => datasource.id === pageId) || [],
      [dataSourceContent, pageId]
   );

   const fetchTableData = useCallback(async () => {
      setIsLoading(true);
      try {
         const endpoint = isDataSourcePage
            ? await datasetService.getdatasource(pageId)
            : await datasetService.getdataproxy(pageId);

         let clonedData = { ...endpoint };
         clonedData.id = pageId;

         isDataSourcePage
            ? dispatch(setDataSourcesContent(clonedData))
            : dispatch(setDataSetsContent(clonedData));
      } catch (error) {
         handleErrorResponse(history, error, t);
      } finally {
         setIsLoading(false);
      }
   }, [dispatch, history, isDataSourcePage, pageId, t]);

   const handleRows = useCallback(
      (data) => {
         if (!data.headerInfo) return [];

         let rows = data.headerInfo.map((row) => {
            let type = t(
               `dataset.content.table.fields.${row.Tipo.toLowerCase()}`
            );
            return {
               id: row.Id,
               tipo: type,
               ...row,
            };
         });

         return rows;
      },
      [t]
   );

   const columns = [
      {
         field: "Nome",
         headerName: t("dataset.content.table.name"),
         width: 150,
         editable: true,
      },
      {
         field: "tipo",
         headerName: t("dataset.content.table.type"),
         width: 150,
         editable: true,
         type: "singleSelect",
         valueOptions: [
            {
               label: t(`dataset.content.table.fields.categorical`),
               value: "Categorical",
            },
            {
               label: t(`dataset.content.table.fields.numeric`),
               value: "Numeric",
            },
            { label: t(`dataset.content.table.fields.text`), value: "Text" },
         ],
      },
      {
         field: "Quantidade",
         headerName: t("dataset.content.table.number"),
         type: "number",
         width: 150,
         editable: false,
      },
      {
         field: "Faltantes",
         headerName: t("dataset.content.table.missing"),
         type: "number",
         width: 150,
         editable: false,
      },
      {
         field: "Erros",
         headerName: t("dataset.content.table.errors"),
         type: "number",
         width: 150,
         editable: false,
      },
      {
         field: "Média",
         headerName: t("dataset.content.table.average"),
         type: "number",
         width: 150,
         editable: false,
      },
      {
         field: "Desvio Padrão",
         headerName: t("dataset.content.table.dp"),
         type: "number",
         width: 150,
         editable: false,
      },
      {
         field: "Coeficiente De Variação",
         headerName: t("dataset.content.table.coefficient"),
         type: "number",
         width: 150,
         editable: false,
      },
      {
         field: "Histogram",
         headerName: t("dataset.content.table.histogram.name"),
         width: 200,
         editable: false,
         renderCell: (params) => {
            const histogramData = createHistogram(
               isDataSourcePage
                  ? cachedDataSource.histogramList
                  : cachedDataset.histogramList,
               params.row.Nome
            );
            return histogramData;
         },
      },
   ];

   const createHistogram = (histogramList, columnName) => {
      const hist = histogramList?.find((data) => data.column === columnName);
      if (!hist) return null;

      return <ColumnHistogram data={hist} />;
   };

   const handleSuccessResponse = (response) => {
      toast.success(t(`backend.SUCCESS.${response}`));
      isDataSourcePage
         ? dispatch(setNeedUpdateDataSource(true))
         : dispatch(setNeedUpdateDataset(true));
   };

   const handleChangeType = (params) => {
      const rowId = params.id;
      const newValue = params.value;
      const field = params.field;
      const options = [rowId, newValue];

      let endpoint;
      const previousValue = rows.find((row) => row.id === rowId)[field];
      if (isDataSourcePage) {
         endpoint = wiselib.changesourcecolumntype(
            localStorage.getItem("user_token"),
            pageId,
            options
         );
      } else {
         endpoint = wiselib.changecolumntype(
            localStorage.getItem("user_token"),
            pageId,
            options
         );
      }

      endpoint
         .then((response) => {
            handleSuccessResponse(response);
         })
         .catch((error) => {
            setRows((prevRows) =>
               prevRows.map((row) =>
                  row.id === rowId ? { ...row, [field]: previousValue } : row
               )
            );
            handleErrorResponse(history, error, t);
         });
   };

   const handleRenameColumn = (params) => {
      const rowId = params.id;
      const newValue = params.value;
      const field = params.field;

      if (cellValueSelected !== newValue) {
         let endpoint;
         const previousValue = rows.find((row) => row.id === rowId)[field];
         const options = [previousValue, newValue];
         if (isDataSourcePage) {
            endpoint = wiselib.renamesourcecolumn(
               localStorage.getItem("user_token"),
               pageId,
               options
            );
         } else {
            endpoint = wiselib.renamecolumn(
               localStorage.getItem("user_token"),
               pageId,
               options
            );
         }

         endpoint
            .then((response) => {
               isDataSourcePage
                  ? dispatch(setNeedUpdateDataSource(true))
                  : dispatch(setNeedUpdateDataset(true));
               handleSuccessResponse(response);
            })
            .catch((error) => {
               setRows((prevRows) =>
                  prevRows.map((row) =>
                     row.id === rowId ? { ...row, [field]: previousValue } : row
                  )
               );
               handleErrorResponse(history, error, t);
            });
      }
   };

   const handleOnCellEditCommit = (params, event) => {
      if (params.field === "tipo") {
         handleChangeType(params);
      } else {
         handleRenameColumn(params);
      }
   };

   useEffect(() => {
      if (
         needUpdateDataset ||
         needUpdateDataSource ||
         (!cachedDataset && !isDataSourcePage) ||
         (!cachedDataSource && isDataSourcePage)
      ) {
         fetchTableData();
         dispatch(setNeedUpdateDataset(false));
         dispatch(setNeedUpdateDataSource(false));
      } else if (isDataSourcePage) {
         setRows(handleRows(cachedDataSource));
      } else {
         setRows(handleRows(cachedDataset));
      }
   }, [
      needUpdateDataset,
      needUpdateDataSource,
      cachedDataset,
      cachedDataSource,
      isDataSourcePage,
      fetchTableData,
      dispatch,
      handleRows,
   ]);

   let localeText;

   switch (language) {
      case "pt_br":
      case "pt_pt":
         localeText = ptBR.props.MuiDataGrid.localeText;
         break;
      case "es_es":
         localeText = esES.props.MuiDataGrid.localeText;
         break;
      case "fr_fr":
         localeText = frFR.props.MuiDataGrid.localeText;
         break;

      default:
         break;
   }

   return (
      <div
         className="statistic-data"
         style={{ maxWidth: "98%", height: "80%" }}
      >
         <DataGrid
            localeText={localeText}
            rows={rows}
            columns={columns}
            pageSize={10}
            onCellEditCommit={handleOnCellEditCommit}
            onCellDoubleClick={(params) => setCellValueSelected(params.value)}
            disableSelectionOnClick
            autoHeight
            classes={{
               root: classes.root,
            }}
            loading={isLoading}
         />
      </div>
   );
};

export default DatasetStatistics;
