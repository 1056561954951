import CollaborativeFilteringTableExample from "../../../../../graphicsComponents/examples/CollaborativeFilteringTableExample";
import { DecisionTreeD3Example } from "../../../../../graphicsComponents/examples/DecisionTreeD3Example";
import FPGrowthD3Example from "../../../../../graphicsComponents/examples/FPGrowthD3Example";
import IsolationForestExample from "../../../../../graphicsComponents/examples/IsolationForestExample";
import { KmeansD3Example } from "../../../../../graphicsComponents/examples/KmeansD3Example";
import { LinearRegressionD3Example } from "../../../../../graphicsComponents/examples/LinearRegressionD3Example";
import MultilayerPerceptronD3Example from "../../../../../graphicsComponents/examples/MultilayerPerceptronD3Example";
import { RandomForestD3Example } from "../../../../../graphicsComponents/examples/RandomForestD3Example";
import SVMExample from "../../../../../graphicsComponents/examples/SVMExample";

export const modelComponents = {
    "decision tree": <DecisionTreeD3Example />,
    "gradient boosting": <DecisionTreeD3Example />,
    "random forest": <RandomForestD3Example />,
    "linear regression": <LinearRegressionD3Example />,
    "support vector machine": <SVMExample />,
    "multilayer perceptron": <MultilayerPerceptronD3Example />,
    cluster: <KmeansD3Example />,
    als: <CollaborativeFilteringTableExample />,
    "isolation forest": <IsolationForestExample />,
    fpgrowth: <FPGrowthD3Example />,
};