import "../../../assets/css/views/popups/quickstartCases/quickstartpopup.css";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon } from "semantic-ui-react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { setCanInitTour, setTutorialDone } from "../../../store/slices/wizard/wizardSlice";
import wiselib from "../../../services/wiselib";
import handleErrorResponse from "../../../helpers/handleErrorResponse";

export const QuickstartPopup = ({ setTrigger }) => {
   const { t, i18n } = useTranslation();
   const language = i18n.language === "pt_br" || i18n.language === "pt_pt" ? "pt_br" : "en_us";
   const [currentPage, setCurrentPage] = useState(1);
   const [isLoading, setIsLoading] = useState(false);
   const history = useHistory();
   const dispatch = useDispatch();

   const steps = [
      {
         video: require(`../../../assets/videos/${language}/1CreatingProject.mp4`),
         titleKey: "popup.quickstartcases.createproject.title",
         descriptionKey: "popup.quickstartcases.createproject.description",
      },
      {
         video: require(`../../../assets/videos/${language}/2AddDataset.mp4`),
         titleKey: "popup.quickstartcases.adddataset.title",
         descriptionKey: "popup.quickstartcases.adddataset.description",
      },
      {
         video: require(`../../../assets/videos/${language}/3OperationsDataset.mp4`),
         titleKey: "popup.quickstartcases.editingdataset.title",
         descriptionKey: "popup.quickstartcases.editingdataset.description",
      },
      {
         video: require(`../../../assets/videos/${language}/4SelectAlgorithm.mp4`),
         titleKey: "popup.quickstartcases.focustarget.title",
         descriptionKey: "popup.quickstartcases.focustarget.description",
      },
      {
         video: require(`../../../assets/videos/${language}/5AlgorithmConfig.mp4`),
         titleKey: "popup.quickstartcases.algorithmconfig.title",
         descriptionKey: "popup.quickstartcases.algorithmconfig.description",
      },
      {
         video: require(`../../../assets/videos/${language}/6OperationsModel.mp4`),
         titleKey: "popup.quickstartcases.finished.title",
         descriptionKey: "popup.quickstartcases.finished.description",
      },
   ];

   const currentStep = steps[currentPage - 1];

   const handleNext = () => {
      if (currentPage === steps.length) {
         setTrigger(false);
      } else {
         setCurrentPage((prev) => prev + 1);
      }
   };

   const handlePrevious = () => {
      if (currentPage > 1) {
         setCurrentPage((prev) => prev - 1);
      }
   };

   const handleClickTutorial = () => {
      setIsLoading(true);
      wiselib
         .updatetutorialdone(false)
         .then(() => {
            history.push("/myprojects");
            dispatch(setTutorialDone(false));
            dispatch(setCanInitTour(false));
            setTrigger(false);
         })
         .catch((error) => {
            handleErrorResponse(history, error, t);
         })
         .finally(() => setIsLoading(false));
   };

   return (
      <div className="container-quickstartpopup">
         <header>
            <h3>{t(currentStep.titleKey)}</h3>
            <span>
               <Button color="violet" size="mini" compact icon onClick={handleClickTutorial} disabled={isLoading}>
                  {t("notificationquickstart.quickstart.wizard")} <Icon name="play" />
               </Button>
            </span>
         </header>
         <main>
            <article>
               <p>{t(currentStep.descriptionKey)}</p>
            </article>
            <video
               controls
               preload="metadata"
               poster={require("../../../assets/images/player.svg")}
               key={currentStep.video}
            >
               <source src={currentStep.video} type="video/mp4" />
            </video>
         </main>
         <footer>
            <span>
               {currentPage}/{steps.length}
            </span>
            <div>
               <button onClick={handlePrevious} disabled={currentPage === 1}>
                  {t("notificationquickstart.quickstart.previous")}
               </button>
               <button onClick={handleNext}>
                  {currentPage === steps.length
                     ? t("notificationquickstart.quickstart.conclude")
                     : t("notificationquickstart.quickstart.next")}
               </button>
            </div>
         </footer>
      </div>
   );
};
