import "../../../assets/css/views/popups/informations/treeimportancepopup.css";

import { PieChart } from "../../../graphicsComponents/others/PieChartD3";

import dp from "../../../helpers/data_parse";
import { useTranslation } from "react-i18next"
import "./../../../translation/i18n";

export const TreeImportancePopup = ({
  modelResult,
  fieldList,
  modelName,
  significances,
}) => {
  //const pesos = JSON.parse(significances);
  // organizando em decrescente
  const pesos = [...significances];
  function compare(a, b) {
    if (a.Value > b.Value) {
      return -1;
    }
    if (a.Value < b.Value) {
      return 1;
    }
    return 0;
  }
  pesos.sort(compare)
  const {t} = useTranslation();
  const link = "popup.informations.importance"
  return (
    <div className="importancia-popup-tree">
      <div id="texto-popup">{t(`${link}.title`) + " | " + modelName}</div>
      <div className="content-popup">
        <div className="pie-chart">
          <PieChart
            modelResult={modelResult}
            modelName={modelName}
            fieldList={fieldList}
            significances={significances}
          />
        </div>
        <div className="info-popup">
          {pesos.map((t, index) => {
            return (
              <div key={index} className="names-popup">
                <div id="feature-popup">{t.Feature}</div>
                <div id="values-popup">{dp.removeDecimals(t.Value * 100)}%</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
