function ProjectAddIcon({ style }) {
  if (style == null) {
    style = [];
  }

  return (
    <svg
      width={style.hasOwnProperty("size") ? style.size : 24}
      height={style.hasOwnProperty("size") ? style.size : 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 15C16 15.2652 15.8946 15.5196 15.7071 15.7071C15.5196 15.8946 15.2652 16 15 16H13V18C13 18.2652 12.8946 18.5196 12.7071 18.7071C12.5196 18.8946 12.2652 19 12 19C11.7348 19 11.4804 18.8946 11.2929 18.7071C11.1054 18.5196 11 18.2652 11 18V16H9C8.73478 16 8.48043 15.8946 8.29289 15.7071C8.10536 15.5196 8 15.2652 8 15C8 14.7348 8.10536 14.4804 8.29289 14.2929C8.48043 14.1054 8.73478 14 9 14H11V12C11 11.7348 11.1054 11.4804 11.2929 11.2929C11.4804 11.1054 11.7348 11 12 11C12.2652 11 12.5196 11.1054 12.7071 11.2929C12.8946 11.4804 13 11.7348 13 12V14H15C15.2652 14 15.5196 14.1054 15.7071 14.2929C15.8946 14.4804 16 14.7348 16 15ZM24 8V18C23.9984 19.3256 23.4711 20.5964 22.5338 21.5338C21.5964 22.4711 20.3256 22.9984 19 23H5C3.67441 22.9984 2.40356 22.4711 1.46622 21.5338C0.528882 20.5964 0.00158786 19.3256 0 18L0 6C0.00158786 4.67441 0.528882 3.40356 1.46622 2.46622C2.40356 1.52888 3.67441 1.00159 5 1H7.528C7.9934 1.00039 8.45239 1.10855 8.869 1.316L12.025 2.9C12.1644 2.96692 12.3173 3.00113 12.472 3H19C20.3256 3.00159 21.5964 3.52888 22.5338 4.46622C23.4711 5.40356 23.9984 6.67441 24 8ZM2 6V7H21.816C21.6099 6.41709 21.2288 5.91209 20.7247 5.55409C20.2206 5.19608 19.6183 5.00256 19 5H12.472C12.0066 4.99961 11.5476 4.89145 11.131 4.684L7.975 3.105C7.83596 3.03635 7.68306 3.00043 7.528 3H5C4.20435 3 3.44129 3.31607 2.87868 3.87868C2.31607 4.44129 2 5.20435 2 6ZM22 18V9H2V18C2 18.7956 2.31607 19.5587 2.87868 20.1213C3.44129 20.6839 4.20435 21 5 21H19C19.7956 21 20.5587 20.6839 21.1213 20.1213C21.6839 19.5587 22 18.7956 22 18Z"
        fill={style.hasOwnProperty("color") ? style.color : "#282828"}
      />
    </svg>
  );
}

export default ProjectAddIcon;
