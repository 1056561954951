import React, { useState } from "react";
import {
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   Button,
   Typography,
   Grid,
   CircularProgress,
} from "@material-ui/core";

import { MdTour } from "react-icons/md";
import wiselib from "../../../services/wiselib";
import { useDispatch, useSelector } from "react-redux";
import handleErrorResponse from "../../../helpers/handleErrorResponse";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
   selectCanInitTour,
   setCanInitTour,
   setTutorialDone,
} from "../../../store/slices/wizard/wizardSlice";

const DialogInitTour = () => {
   const canInitiateTour = useSelector(selectCanInitTour);
   const [open, setOpen] = useState(!canInitiateTour);
   const [isLoading, setIsLoading] = useState(false);
   const { t } = useTranslation();
   const dispatch = useDispatch();
   const history = useHistory();

   const handleOnClose = () => {
      setIsLoading(true);
      if (!isLoading) {
         wiselib
            .updatetutorialdone(true)
            .then(() => {
               setOpen(false);
               dispatch(setTutorialDone(true));
            })
            .catch((error) => {
               handleErrorResponse(history, error, t);
            })
            .finally(() => setIsLoading(false));
      }
   };

   const handleOnStartTour = () => {
      dispatch(setCanInitTour(true));
   };

   return (
      <Dialog
         open={open}
         onClose={handleOnClose}
         aria-labelledby="dialog-title"
      >
         <DialogTitle id="dialog-title">
            <Grid container alignItems="center">
               <MdTour style={{ marginRight: 8 }} />
               <Typography variant="h6">{t("wizard.welcome.title")}</Typography>
            </Grid>
         </DialogTitle>
         <DialogContent>
            <Typography variant="body1" gutterBottom>
               <strong>{t("wizard.welcome.description")}</strong>
            </Typography>
            <Typography variant="body2">{t("wizard.welcome.tip")}</Typography>
         </DialogContent>
         <DialogActions>
            <Button
               onClick={handleOnClose}
               color="secondary"
               variant="outlined"
               disabled={isLoading}
               aria-label={t("wizard.welcome.button_skip")}
            >
               {isLoading ? (
                  <CircularProgress size={24} />
               ) : (
                  t("wizard.welcome.button_skip")
               )}
            </Button>
            <Button
               onClick={handleOnStartTour}
               color="primary"
               variant="contained"
               disabled={isLoading}
               aria-label={t("wizard.welcome.button_start")}
            >
               {isLoading ? (
                  <CircularProgress size={24} />
               ) : (
                  t("wizard.welcome.button_start")
               )}
            </Button>
         </DialogActions>
      </Dialog>
   );
};

export default DialogInitTour;
