import Highcharts from "highcharts";
import highcharts3d from "highcharts/highcharts-3d";
highcharts3d(Highcharts); //init module

//Give the points a 3D feel by adding a radial gradient
Highcharts.setOptions({
   colors: Highcharts.getOptions().colors.map(function (color) {
      return {
         radialGradient: {
            cx: 0.4,
            cy: 0.3,
            r: 0.5,
         },
         stops: [
            [0, color],
            [1, Highcharts.color(color).brighten(-0.2).get("rgb")],
         ],
      };
   }),
});

let chart = null;

const randomColor = (() => {
   "use strict";

   const randomInt = (min, max) => {
      return Math.floor(Math.random() * (max - min + 1)) + min;
   };

   return () => {
      let h = randomInt(0, 360);
      let s = randomInt(42, 98);
      let l = randomInt(40, 90);
      return `hsl(${h},${s}%,${l}%)`;
   };
})();

function create(model, graphics, categorical, usedCategorical) {
   let seriess = [];
   let pca_data = graphics;
   let serie = [[[[]]]];
   let fildUsed = -1;

   if (categorical?.encoder) {
      for (let key in categorical.encoder) {
         let obj = categorical.encoder[key];
         if (obj.hasOwnProperty(usedCategorical)) {
            fildUsed = obj[usedCategorical];
         }
      }
   }

   for (let i = 0; i < pca_data?.length; i++) {
      if (!serie[pca_data[i].prediction]) {
         serie[pca_data[i].prediction] = [];
      }
      if (fildUsed != -1) {
         if (!serie[pca_data[i].prediction][pca_data[i].values[fildUsed]]) {
            serie[pca_data[i].prediction][pca_data[i].values[fildUsed]] = [];
         }
         serie[pca_data[i].prediction][pca_data[i].values[fildUsed]].push(
            pca_data[i].position
         );
      } else {
         if (!serie[pca_data[i].prediction][0]) {
            serie[pca_data[i].prediction][0] = [];
         }
         serie[pca_data[i].prediction][0].push(pca_data[i].position);
      }
   }

   for (let i = 0; i < serie.length; i++) {
      let color = randomColor();
      if (serie[i] == null) 
         continue;
      for (const element of serie[i]) {
         seriess.push({
            name: model.centers[i].name,
            colorByPoint: false,
            accessibility: {
               exposeAsGroupOnly: true,
            },
            color: color,
            data: element,
         });
      }
   }

   chart = new Highcharts.Chart({
      chart: {
         renderTo: "container",
         margin: 10,
         type: "scatter3d",
         animation: false,
         backgroundColor: "#FDFDFD",
         options3d: {
            enabled: true,
            alpha: 10,
            beta: 30,
            depth: 500,
            viewDistance: 5,
            fitToPlot: false,
         },
      },
      title: {
         text: "",
      },
      subtitle: {
         text: "",
      },
      tooltip: {
         formatter: function () {
            let coord = [];
            let values = [];

            coord.push(
               this.point.options.x,
               this.point.options.y,
               this.point.options.z
            );

            pca_data.forEach((element) => {
               if (
                  coord[0] === element.position[0] &&
                  coord[1] === element.position[1] &&
                  coord[2] === element.position[2]
               ) {
                  values = element.values;
               }
            });

            //Bem ineficiente, não conheço bem javascript para melhor esses loops
            values?.forEach((field, index) => {
               if (categorical?.encoder) {
                  for (let key in categorical.encoder) {
                     for (let keys in categorical) {
                        let obj = categorical.encoder[key];
                        let obj2 = categorical[keys];
                        Object.keys(obj).forEach((keyObject) => {
                           if (
                              keyObject === keys &&
                              obj[keyObject] === index &&
                              obj2[field] !== undefined
                           ) {
                              values[index] = obj2[field];
                           }
                        });
                     }
                  }
               }
            });

            model.Fields.forEach((field, index) => {
               model.Fields[index] = field.replace("indexed", "");
            });

            let returnString = "";
            values
               .map((value, index) => {
                  returnString += `${model.Fields[index]}: <b>${value}</b><br>`;
               })
               .join("");

            return returnString;
         },
      },
      plotOptions: {
         scatter: {
            width: 10,
            height: 10,
            depth: 10,
         },
      },
      yAxis: {
         min: model.pca.axis[1][1],
         max: model.pca.axis[1][0],
         title: null,
         visible: false,
      },
      xAxis: {
         min: model.pca.axis[0][1],
         max: model.pca.axis[0][0],
         visible: false,
      },
      zAxis: {
         min: model.pca.axis[2][1],
         max: model.pca.axis[2][0],
         showFirstLabel: false,
         visible: false,
      },
      legend: {
         enabled: false,
      },
      accessibility: {
         enabled: false,
      },
      legend: {
         enabled: true,
      },
      series: seriess,
   });
   // Add mouse and touch events for rotation
   (function (H) {
      function scroll(e) {
         // e = chart.pointer.normalize(e);
         // let direction = 1;
         // let chartMinX = chart.xAxis[0].userOptions.min;
         // let chartMaxX = chart.xAxis[0].userOptions.max;
         // let chartMinY = chart.yAxis[0].userOptions.min;
         // let chartMaxY = chart.yAxis[0].userOptions.max;
         // let chartMinZ = chart.zAxis[0].userOptions.min;
         // let chartMaxZ = chart.zAxis[0].userOptions.max;
         // let chartSizeX = chartMaxX - chartMinX;
         // let chartSizeY = chartMaxY - chartMinY;
         // let chartSizeZ = chartMaxZ - chartMinZ;
         // let chartMagnitude = Math.sqrt(
         //    chartSizeX * chartSizeX +
         //       chartSizeY * chartSizeY +
         //       chartSizeZ * chartSizeZ
         // );
         // let zoomSpeed = (1 / chartMagnitude) * 100;
         // direction = e.deltaY < 0 ? -zoomSpeed : zoomSpeed;
         // console.log (chartMagnitude);
         // console.log (direction);
         // let xAxis = [];
         // let yAxis = [];
         // let zAxis = [];
         // let x = e.clientX - chart.plotLeft - chart.plotWidth / 2;
         // let y = e.clientY - chart.plotTop - chart.plotHeight / 2;
         // let zoomSpeedX = (x / chart.plotWidth) * direction;
         // let zoomSpeedY = (y / chart.plotHeight) * direction;
         // let zoomSpeedZ =
         //    (Math.sqrt(x * x + y * y) /
         //       Math.sqrt(
         //          chart.plotWidth * chart.plotWidth +
         //             chart.plotHeight * chart.plotHeight
         //       )) *
         //    direction;
         // xAxis.push(
         //    chart.xAxis[0].userOptions.min +
         //       chart.xAxis[0].userOptions.min * zoomSpeedX
         // );
         // xAxis.push(
         //    chart.xAxis[0].userOptions.max +
         //       chart.xAxis[0].userOptions.min * zoomSpeedX
         // );
         // yAxis.push(
         //    chart.yAxis[0].userOptions.min +
         //       chart.yAxis[0].userOptions.min * zoomSpeedY
         // );
         // yAxis.push(
         //    chart.yAxis[0].userOptions.max +
         //       chart.yAxis[0].userOptions.max * zoomSpeedY
         // );
         // zAxis.push(
         //    chart.zAxis[0].userOptions.min +
         //       chart.xAxis[0].userOptions.min * zoomSpeedZ
         // );
         // zAxis.push(
         //    chart.zAxis[0].userOptions.max +
         //       chart.xAxis[0].userOptions.min * zoomSpeedZ
         // );
         // chart.update(
         //    {
         //       xAxis: {
         //          min: xAxis[0],
         //          max: xAxis[1],
         //       },
         //       yAxis: {
         //          min: yAxis[0],
         //          max: yAxis[1],
         //       },
         //       zAxis: {
         //          min: zAxis[0],
         //          max: zAxis[1],
         //       },
         //    },
         //    undefined,
         //    undefined,
         //    false
         // );
      }

      function dragStart(eStart) {
         eStart = chart.pointer.normalize(eStart);

         let posX = eStart.chartX,
            posY = eStart.chartY,
            alpha = chart.options.chart.options3d.alpha,
            beta = chart.options.chart.options3d.beta,
            sensitivity = 5, // lower is more sensitive
            handlers = [];

         function drag(e) {
            // Get e.chartX and e.chartY
            e = chart.pointer.normalize(e);

            chart.update(
               {
                  chart: {
                     options3d: {
                        alpha: alpha + (e.chartY - posY) / sensitivity,
                        beta: beta + (posX - e.chartX) / sensitivity,
                     },
                  },
               },
               undefined,
               undefined,
               false
            );
         }

         function unbindAll() {
            handlers.forEach(function (unbind) {
               if (unbind) {
                  unbind();
               }
            });
            handlers.length = 0;
         }

         handlers.push(H.addEvent(document, "mousemove", drag));
         handlers.push(H.addEvent(document, "touchmove", drag));

         handlers.push(H.addEvent(document, "mouseup", unbindAll));
         handlers.push(H.addEvent(document, "touchend", unbindAll));
      }
      H.addEvent(chart.container, "mousedown", dragStart);
      H.addEvent(chart.container, "touchstart", dragStart);
      //add event scroll
      H.addEvent(chart.container, "wheel", scroll);
   })(Highcharts);
}

function destroy() {
   if (chart) {
      chart.destroy();
   }
}

const KmeansHighcharts = {
   create,
   destroy,
};

export default KmeansHighcharts;
