function FileIcon({ style }) {
  if (style == null) {
    style = [];
  }

  return (
    <svg
      width={style.hasOwnProperty("size") ? style.size : 24}
      height={style.hasOwnProperty("size") ? style.size : 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_123_295)">
        <path
          d="M19.949 5.536L16.465 2.05C15.8163 1.39814 15.0449 0.881331 14.1952 0.529459C13.3456 0.177588 12.4346 -0.00236676 11.515 1.60491e-07H7C5.67441 0.00158802 4.40356 0.528882 3.46622 1.46622C2.52888 2.40356 2.00159 3.67441 2 5V19C2.00159 20.3256 2.52888 21.5964 3.46622 22.5338C4.40356 23.4711 5.67441 23.9984 7 24H17C18.3256 23.9984 19.5964 23.4711 20.5338 22.5338C21.4711 21.5964 21.9984 20.3256 22 19V10.485C22.0025 9.56542 21.8225 8.65449 21.4704 7.80497C21.1184 6.95546 20.6012 6.18424 19.949 5.536ZM18.535 6.95C18.8404 7.26415 19.103 7.61719 19.316 8H15C14.7348 8 14.4804 7.89464 14.2929 7.70711C14.1054 7.51957 14 7.26522 14 7V2.684C14.3829 2.89692 14.7363 3.15916 15.051 3.464L18.535 6.95ZM20 19C20 19.7956 19.6839 20.5587 19.1213 21.1213C18.5587 21.6839 17.7956 22 17 22H7C6.20435 22 5.44129 21.6839 4.87868 21.1213C4.31607 20.5587 4 19.7956 4 19V5C4 4.20435 4.31607 3.44129 4.87868 2.87868C5.44129 2.31607 6.20435 2 7 2H11.515C11.679 2 11.838 2.032 12 2.047V7C12 7.79565 12.3161 8.55871 12.8787 9.12132C13.4413 9.68393 14.2044 10 15 10H19.953C19.968 10.162 20 10.32 20 10.485V19Z"
          fill={style.hasOwnProperty("color") ? style.color : "#282828"}
        />
      </g>
      <defs>
        {/* <clipPath id="clip0_123_295">
                    <rect width={style.hasOwnProperty('size') ? style.size : 24} height={style.hasOwnProperty('size') ? style.size : 24} fill={style.hasOwnProperty('color') ? style.color : "#282828"} />
                </clipPath> */}
      </defs>
    </svg>
  );
}

export default FileIcon;
