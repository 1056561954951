function AssociationDiscoveryIcon({ style }) {
    return (
        <svg 
            width={style.hasOwnProperty("size") ? style.size : 24}
            height={style.hasOwnProperty("size") ? style.size : 24}
            viewBox="0 0 24 24" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="8.5" cy="3.5" r="2.5" fill={style.hasOwnProperty("color") ? style.color : "#282828"}  />
            <circle cx="19.5" cy="11.5" r="2.5" fill={style.hasOwnProperty("color") ? style.color : "#282828"}  />
            <circle cx="8.5" cy="19.5" r="2.5" fill={style.hasOwnProperty("color") ? style.color : "#282828"}  />
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M8.27311 3.05444C8.4407 2.96911 8.64199 2.98502 8.79409 3.09563L19.7941 11.0956C19.9235 11.1897 20 11.34 20 11.5C20 11.66 19.9235 11.8103 19.7941 11.9044L8.79409 19.9044C8.64199 20.015 8.4407 20.0309 8.27311 19.9456C8.10553 19.8602 8 19.6881 8 19.5V3.5C8 3.31194 8.10553 3.13978 8.27311 3.05444ZM9 4.48189V18.5181L18.6499 11.5L9 4.48189Z" 
                fill={style.hasOwnProperty("color") ? style.color : "#282828"} 
            />
        </svg>

    )
}

export default AssociationDiscoveryIcon;