import React from "react";
import { Bar } from "react-chartjs-2";

import "../../assets/css/graphicsComponents/others/barchart.css";

/**
 * Faz uso da biblioteca gráfica Chart.js, através
 * de componentes da biblioteca react-charts-js
 *
 * https://github.com/reactchartjs/react-chartjs-2
 * https://www.chartjs.org/docs/3.0.2/charts/bar.html
 *
 * Se necessário aprofundar o entendimento, no arquivo
 * src\data\table-test-data.json tem um exemplo de dados
 * de tabela de dataset e nele, os dados de histograma
 */

/** Tooltip externo */
const getOrCreateTooltip = (chart) => {
   let tooltipEl = chart.canvas.parentNode.querySelector("div");

   if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      /** Propriedades de style */
      tooltipEl.style.background = "rgba(0, 0, 0, 0.7)";
      tooltipEl.style.borderRadius = "3px";
      tooltipEl.style.color = "white";
      tooltipEl.style.opacity = 1;
      tooltipEl.style.pointerEvents = "none";
      tooltipEl.style.position = "fixed";
      tooltipEl.style.transform = "translate(-50%, 0)";
      tooltipEl.style.transition = "all .1s ease";

      const table = document.createElement("table");
      table.style.margin = "0px";

      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
   }

   return tooltipEl;
};

/** Função que trata o Tooltip */
const externalTooltipHandler = (context, event) => {
   /** Tooltip element */
   const { chart, tooltip } = context;
   const tooltipEl = getOrCreateTooltip(chart);
   const tableEl = document.querySelector("#table");

   /** Hide if no tooltip */
   if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
   }

   /** Configurar o texto do tooltip */
   if (tooltip.body) {
      /** pega os títulos do tooltip */
      const titleLines = tooltip.title || [];
      /** pega os dados do tooltip */
      const bodyLines = tooltip.body.map((b) => b.lines);
      /**
       * cria um elemento de tableHead
       *
       * é aqui que vai ficar o título do tooltip
       */
      const tableHead = document.createElement("thead");

      /**
       * Itera titleLines, mapeando cada valor pra uma string
       * chamada 'title'
       *
       * esses valores já existem em barChartData, aqui ele meramente
       * pega eles desse jeito estranho
       */
      titleLines.forEach((title) => {
         /**
          * Visualização em table
          *
          * tr = table row
          */
         const tr = document.createElement("tr");
         tr.style.borderWidth = 0;

         /** table header cell */
         const th = document.createElement("th");
         th.style.borderWidth = 0;
         /**
          * texto contendo informação do tooltip
          *
          * o que estiver dentro de createTextNode é
          * o que vai pro título do tooltip do histograma
          *
          * nesse caso, 'title' é o label do valor
          */
         const text = document.createTextNode(title);

         /** appends text to table header cell */
         th.appendChild(text);
         /** appends table header cell to table row*/
         tr.appendChild(th);
         /** appends table row to tableHead */
         tableHead.appendChild(tr);
      });

      /**
       * cria um elemento de tableBody
       *
       * é aqui que vai ficar a descrição do tooltip,
       * ou seja, os dados correspondentes ao título
       * da barrinha que o usuário selecionou
       */
      const tableBody = document.createElement("tbody");

      /**
       * Itera bodyLines, mapeando cada valor pra uma string
       * chamada 'body' e cada posição pra um inteiro i
       *
       * esses valores já existem em barChartData, aqui ele meramente
       * pega eles desse jeito estranho
       */
      bodyLines.forEach((body, i) => {
         const colors = tooltip.labelColors[i];

         /** Set style properties for the tooltip background */
         const span = document.createElement("span");
         span.style.background = colors.backgroundColor;
         span.style.borderColor = colors.borderColor;
         span.style.borderWidth = "2px";
         span.style.marginRight = "10px";
         span.style.height = "10px";
         span.style.width = "10px";
         span.style.display = "inline-block";

         /**
          * Visualização em table
          *
          * tr = table row
          */
         const tr = document.createElement("tr");
         tr.style.backgroundColor = "inherit";
         tr.style.borderWidth = 0;

         /**
          * Visualização em table
          *
          * td = table data cell
          */
         const td = document.createElement("td");
         td.style.borderWidth = 0;

         /**
          * texto contendo informação do tooltip
          *
          * o que estiver dentro de createTextNode é
          * o que vai pra DESCRIÇÃO do tooltip do histograma
          *
          * nesse caso, 'body' é o valor em si, que fica abaixo
          * do título
          */
         const text = document.createTextNode(body);

         /** appends <span> to table data cell */
         td.appendChild(span);
         /** appends text to table data cell */
         td.appendChild(text);
         /** appends table data cell to table row*/
         tr.appendChild(td);
         /** appends table row to tableBody */
         tableBody.appendChild(tr);
      });

      const tableRoot = tooltipEl.querySelector("table");

      /** Remove old children */
      while (tableRoot.firstChild) {
         tableRoot.firstChild.remove();
      }

      /** Add new children */
      tableRoot.appendChild(tableHead);
      tableRoot.appendChild(tableBody);
   }

   const { offsetTop: positionY } = chart.canvas;

   tooltipEl.style.left = 60 + "rem";
   tooltipEl.style.top =
      positionY + 160 + tooltip.caretY + tableEl.scrollTop / 5 + "px";

   // Define os estilos restantes para o tooltip
   tooltipEl.style.opacity = 1;
   tooltipEl.style.overflow = "visible";
   tooltipEl.style.zIndex = 10000; // Ajuste o valor conforme necessário
   tooltipEl.style.font = tooltip.options.bodyFont.string;
   tooltipEl.style.padding =
      tooltip.options.padding + "px " + tooltip.options.padding + "px";
};

// opções de configuração
const options = {
   animation: false,
   responsive: false,
   maintainAspectRatio: false,
   plugins: {
      legend: {
         display: false,
      },
      tooltip: {
         enabled: false,
         position: "nearest",
         external: externalTooltipHandler,
      },
   },
   layout: {},
   scales: {
      x: {
         display: false,
         label: {
            display: false,
         },
         grid: {
            display: false,
         },
      },

      y: {
         display: false,
         label: {
            display: false,
         },
         grid: {
            display: false,
         },
      },
   },
};

const BarChart = ({ barChartData }) => {
   // Se Histogram: null (ou seja, não houver info de histograma), retorna ''
   // A especificação do formato pra histograma está em ../../data/table-test-data.json

   const tag = document.getElementById("bar-histogram");

   return (
      <div className="BarChart">
         {barChartData === null || barChartData === undefined ? (
            ""
         ) : (
            <Bar
               id="new-barchart"
               data={barChartData}
               options={options}
               height={tag ? tag.clientWidth : 40}
               width={tag ? tag.clientHeight : 145}
            />
         )}
      </div>
   );
};

export default BarChart;
