import { configureStore } from "@reduxjs/toolkit";
import sidebarReducer from "./slices/sidebar/sidebarSlice";
import tokenReducer from "./slices/token/tokenSlice";
import cardReducer from "./slices/card/cardSlice";
import notificationReducer from "./slices/notification/notificationSlice";
import uploadingReducer from "./slices/uploadingFlag/uploadingSlice";
import tasksReducer from "./slices/tasks/tasksSlice";
import urlReducer from "./slices/url/urlSlice";
import selectReducer from "./slices/select/selectSlice";
import userReducer from "./slices/user/userSlice";
import userProjects from "./slices/projects/projectsSlice";
import cacheSlice from "./slices/cache/cacheSlice";
import mainAttributeSlice from "./slices/mainAttribute/mainAttributeSlice";
import monitoringSlice from "./slices/monitoring/monitoringSlice";
import wizardSlice from "./slices/wizard/wizardSlice";
export const store = configureStore({
  reducer: {
    sidebar: sidebarReducer,
    user_token: tokenReducer,
    card: cardReducer,
    notification: notificationReducer,
    uploading: uploadingReducer,
    tasks: tasksReducer,
    url: urlReducer,
    select: selectReducer,
    user: userReducer,
    projects: userProjects,
    cache: cacheSlice,
    mainAttribute: mainAttributeSlice,
    monitoring: monitoringSlice,
    wizard: wizardSlice,
  },
});
