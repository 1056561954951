import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isUploading: false,
  isUploadSuccess: false,
};

export const uploadingSlice = createSlice({
  name: "uploading",
  initialState,
  reducers: {
    setUploading: (state, action) => {
      state.isUploading = action.payload;
    },
    setIsUploadSuccess: (state, action) => {
      state.isUploadSuccess = action.payload;
    },
  },
});

export const { setUploading, setIsUploadSuccess } = uploadingSlice.actions;
export default uploadingSlice.reducer;
export const selectIsUploadSuccess = (state) =>
  state.uploading.isUploadSuccess;
export const selectIsUploading = (state) => state.uploading.isUploading;
