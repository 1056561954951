import React from "react";
import AlertIcon from "../assets/icons/jsxIcons/AlertIcon";
import InterrogationIcon from "../assets/icons/jsxIcons/InterrogationIcon";
import "../assets/css/components/savestatus.css";

import { useTranslation } from "react-i18next";
import "./../translation/i18n";
import CustomTooltipWrapper from "./CustomTooltip";

const SaveStatus = () => {
   const { t } = useTranslation();

   return (
      <CustomTooltipWrapper title={t("model.header.save.name_3.info")}>
         <div id="save-status">
            <span id="save-status-icon">
               <AlertIcon style={{ size: 22, color: "red" }} />
            </span>
            <span>{t("model.header.save.name_3")}</span>
            <span>
               <InterrogationIcon style={{ size: "1.5em" }} />
            </span>
         </div>
      </CustomTooltipWrapper>
   );
};

export default SaveStatus;
