import styles from "./../../../assets/css/monitoring/views/screens/SystemUsers.module.css";
import { useState, useEffect } from "react";
import { Popup } from "../../../components/Popup";
import InfoUser from "../popups/InfoUserPopUp";
import TableGrid from "../../tables/TableGrid";

import LoadingWheel from "../../../components/LoadingWheel";
import { useTranslation } from "react-i18next";
import monitoringService from "../../../services/MonitoringService";
import handleErrorResponse from "../../../helpers/handleErrorResponse";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import ConfirmationPopup from "../../../views/popups/actions/ConfirmationPopup";
import { toast } from "react-toastify";
import TrashIcon from "./../../../assets/icons/jsxIcons/TrashIcon.jsx";

function SystemUsers() {

    const history = useHistory();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [infoUserTrigger, setInfoUserTrigger] = useState(false);
    const [confirmationTrigger, setConfirmationTrigger] = useState(false);
    const [rows, setRows] = useState([]);
    const [usersId, setUsersId] = useState([]);
    const link = "monitoring.systemuser";

    useEffect(() => {
        updateSystemUsers();
        // eslint-disable-next-line
    }, [])

    function converterStorage(mb) {
        // 1 byte = 8 bits
        let bytes = mb * 1024 * 1024;  // Convertendo MB para bytes
        //let bits = bytes * 8;  // Convertendo bytes para bits

        // Convertendo para KB, MB, GB e TB
        let kb = bytes / 1024;
        let gb = bytes / (1024 ** 3);
        //let tb = bytes / (1024 ** 4);

        if (gb < 1024) {
            // if(kb < 1024){
            //     if(bytes < 1024){
            //         return `${bits.toFixed(2)} bits`;
            //     }
            //     return `${bytes.toFixed(2)} bytes`;
            // }
            return `${kb.toFixed(2)} KB`;
        }
        return `${gb.toFixed(2)} GB`;

    }

    const updateSystemUsers = () => {
        monitoringService.getsystemusers()
            .then(data => {
                setRows(data.map(user => {
                    return {
                        name: `${user.firstName} ${user.lastName}`,
                        date: new Date(user.lastTimeOnline * 1000).toLocaleString('pt-BR'),
                        id: user.userId,
                        storage: converterStorage(user.mongoDiskUsageMB),
                        email: user.email,
                        action: user.lastAction
                    }
                }));
            })
            .catch(error => handleErrorResponse(history, error, t))
            .finally(() => setIsLoading(false))
    }

    const columns = [
        {
            field: "email",
            headerName: t(`${link}.table.header.email`),
            editable: false,
            width: 270,
            flex: 0.1
        },
        {
            field: "name",
            headerName: t(`${link}.table.header.user`),
            editable: false,
            width: 200,
            flex: 0.1
        },
        {
            field: "storage",
            headerName: t(`${link}.table.header.storage`),
            editable: false,
            width: 280,
            flex: 0.1
        },
        {
            field: "action",
            headerName: t(`${link}.table.header.action`),
            editable: false,
            width: 180,
            flex: 0.1
        },
        {
            field: "date",
            headerName: t(`${link}.table.header.date`),
            editable: false,
            width: 200,
            flex: 0.1
        }
    ]

    const usersDelete = () => {
        setConfirmationTrigger(false);
        setIsLoading(true);
        monitoringService.deleteanythingbyid(usersId, "User")
            .then(data => {
                toast.success(t(`backend.SUCCESS.${data}`));
                updateSystemUsers();
            })
            .catch(error => {
                handleErrorResponse(history, error, t)
                setIsLoading(false)
            });
    }

    if (isLoading) {
        return (
            <main className={styles.isloading}>
                <LoadingWheel loadingSize="large" />
            </main>
        )
    } else {
        return (
            <main className={styles.containersystemusers}>
                <div className={styles.buttons}>
                    <button
                        onClick={() => setConfirmationTrigger(true)}
                        className={!usersId.length ? styles.buttondisabled : ""}
                        disabled={!usersId.length}
                    >
                        <TrashIcon />
                        <p>{t(`${link}.popup.buttons.deleteusers`)}</p>
                    </button>
                </div>
                <div className={styles.table}>
                    <TableGrid
                        rows={rows}
                        isLoading={isLoading}
                        columns={columns}
                        setTriggerPopup={setInfoUserTrigger}
                        onCellDoubleClick={(params) => setInfoUserTrigger(params.row.id)}
                        setSelectedRows={setUsersId}
                    />
                </div>
                <Popup trigger={infoUserTrigger} setTrigger={setInfoUserTrigger}>
                    <InfoUser
                        userId={infoUserTrigger}
                        update={() => {
                            setIsLoading(true);
                            updateSystemUsers()
                        }}
                        setTrigger={setInfoUserTrigger}
                    />
                </Popup>
                <Popup trigger={confirmationTrigger} setTrigger={setConfirmationTrigger}>
                    <ConfirmationPopup
                        title={t("monitoring.popup.areyousure")}
                        onConfirm={usersDelete}
                        onDeny={() => setConfirmationTrigger(false)}
                    />
                </Popup>
            </main>
        )
    }
}

export default SystemUsers;