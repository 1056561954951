import "./../../../../assets/css/views/popups/creations/NewDataset/databasedataset.css";

import DatabaseAddIcon from "./../../../../assets/icons/jsxIcons/DatabaseAddIcon.jsx";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import "./../../../../translation/i18n";

function DatabaseDataset({ setDatabase, database }) {
    const { t } = useTranslation();
    const [typeDatabase, setTypeDatabase] = useState("mysql");
    const [typeOne, setTypeOne] = useState(true);

    useEffect(() => {
        if (typeDatabase === "mysql") {
            setDatabase({
                type: "mysql",
                databaseHost: "",
                databasePort: "",
                databaseUserName: "",
                databaseUserPassword: "",
                databaseTargetSchema: "",
                connectionString: "",
                databaseTargetTable: "",
                databaseType: "",
            });
        } else {
            setTypeOne(true);
            setDatabase({
                type: "mongodb",
                connectionString: "",
                targetDatabase: "",
                targetCollection: "",
            });
        }
        // eslint-disable-next-line
    }, [typeDatabase, typeOne]);

    let link = "popup.creations.newdataset.database";

    return (
        <div className="containerDatabaseDataset">
            <div className="containerButton">
                <button
                    className={typeDatabase === "mysql" ? "buttonSelected" : ""}
                    onClick={() => {
                        setTypeDatabase("mysql");
                    }}
                >
                    <DatabaseAddIcon
                        style={
                            typeDatabase === "mysql"
                                ? { color: "#FDFDFD" }
                                : { color: "#282828" }
                        }
                    />
                    <p>MySQL</p>
                </button>
                <button
                    className={
                        typeDatabase === "mongodb" ? "buttonSelected" : ""
                    }
                    onClick={() => {
                        setTypeDatabase("mongodb");
                    }}
                >
                    <DatabaseAddIcon
                        style={
                            typeDatabase === "mongodb"
                                ? { color: "#FDFDFD" }
                                : { color: "#282828" }
                        }
                    />
                    <p>MongoDB</p>
                </button>
            </div>
            {typeDatabase === "mysql" ? (
                <div className="containerInputMySQL">
                    <header>
                        <p className={!typeOne ? "textInactive" : ""}>
                            {t(`${link}.header.title_1`)}
                        </p>
                        <div
                            className={
                                !typeOne
                                    ? "buttonSwitch buttonActive"
                                    : "buttonSwitch"
                            }
                            onClick={() => {
                                setTypeOne(!typeOne);
                            }}
                        >
                            <div id="ball"></div>
                        </div>
                        <p className={typeOne ? "textInactive" : ""}>
                            {t(`${link}.header.title_2`)}
                        </p>
                    </header>
                    {typeOne ? (
                        <div className="inputsMySQL">
                            <div>
                                <label>
                                    {t(`${link}.inputs.input_1.label`)}
                                </label>
                                <input
                                    type="text"
                                    placeholder="jdbc:sqlserver://<server>:<port>;encrypt=true;databaseName=AdventureWorks;user=<user>;password=<password>"
                                    value={database?.connectionString}
                                    onChange={(e) => {
                                        setDatabase({
                                            ...database,
                                            connectionString: e.target.value,
                                        });
                                    }}
                                />
                            </div>
                            <div>
                                <label>
                                    {t(`${link}.inputs.input_2.label`)}
                                </label>
                                <input
                                    type="text"
                                    placeholder={t(
                                        `${link}.inputs.input_2.placeholder`
                                    )}
                                    value={database?.databaseTargetTable}
                                    onChange={(e) => {
                                        setDatabase({
                                            ...database,
                                            databaseTargetTable: e.target.value,
                                        });
                                    }}
                                />
                            </div>
                            <div>
                                <label>
                                    {t(`${link}.inputs.input_3.label`)}
                                </label>
                                <input
                                    type="text"
                                    placeholder="oracle"
                                    value={database?.databaseType}
                                    onChange={(e) => {
                                        setDatabase({
                                            ...database,
                                            databaseType: e.target.value,
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="inputsMySQL">
                            <div>
                                <label>Host</label>
                                <input
                                    type="text"
                                    placeholder="0000000000"
                                    value={database?.databaseHost}
                                    onChange={(e) => {
                                        setDatabase({
                                            ...database,
                                            databaseHost: e.target.value,
                                        });
                                    }}
                                />
                            </div>
                            <div>
                                <label>
                                    {t(`${link}.inputs.input_4.label`)}
                                </label>
                                <input
                                    type="text"
                                    placeholder="0000000000"
                                    value={database?.databasePort}
                                    onChange={(e) => {
                                        setDatabase({
                                            ...database,
                                            databasePort: e.target.value,
                                        });
                                    }}
                                />
                            </div>
                            <div>
                                <label>
                                    {t(`${link}.inputs.input_2.label`)}
                                </label>
                                <input
                                    type="text"
                                    placeholder={t(
                                        `${link}.inputs.input_2.placeholder`
                                    )}
                                    value={database?.databaseTargetTable}
                                    onChange={(e) => {
                                        setDatabase({
                                            ...database,
                                            databaseTargetTable: e.target.value,
                                        });
                                    }}
                                />
                            </div>
                            <div>
                                <label>Schema</label>
                                <input
                                    type="text"
                                    placeholder="wiseml"
                                    value={database?.databaseTargetSchema}
                                    onChange={(e) => {
                                        setDatabase({
                                            ...database,
                                            databaseTargetSchema:
                                                e.target.value,
                                        });
                                    }}
                                />
                            </div>
                            <div>
                                <label>
                                    {t(`${link}.inputs.input_5.label`)}
                                </label>
                                <input
                                    type="text"
                                    placeholder="fulano"
                                    value={database?.databaseUserName}
                                    onChange={(e) => {
                                        setDatabase({
                                            ...database,
                                            databaseUserName: e.target.value,
                                        });
                                    }}
                                />
                            </div>
                            <div>
                                <label>
                                    {t(`${link}.inputs.input_6.label`)}
                                </label>
                                <input
                                    type="password"
                                    placeholder="**********"
                                    value={database?.databaseUserPassword}
                                    onChange={(e) => {
                                        setDatabase({
                                            ...database,
                                            databaseUserPassword:
                                                e.target.value,
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <div className="containerInput">
                    <div>
                        <label>{t(`${link}.inputs.input_1.label`)}</label>
                        <input
                            type="text"
                            placeholder="mongodb://mongodb0.example.com:27017"
                            value={database?.connectionString}
                            onChange={(e) => {
                                setDatabase({
                                    ...database,
                                    connectionString: e.target.value,
                                });
                            }}
                        />
                    </div>
                    <div>
                        <label>Database</label>
                        <input
                            type="text"
                            placeholder="wiseml"
                            value={database?.targetDatabase}
                            onChange={(e) => {
                                setDatabase({
                                    ...database,
                                    targetDatabase: e.target.value,
                                });
                            }}
                        />
                    </div>
                    <div>
                        <label>{t(`${link}.inputs.input_7.label`)}</label>
                        <input
                            type="text"
                            placeholder="dataset"
                            value={database?.targetCollection}
                            onChange={(e) => {
                                setDatabase({
                                    ...database,
                                    targetCollection: e.target.value,
                                });
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default DatabaseDataset;
