import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import UserCard from "../../../../components/UserCard";

import modelService from "../../../../services/ModelService";
import { Loader } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import "./../../../../translation/i18n";
import handleErrorResponse from "../../../../helpers/handleErrorResponse";
import { useSelector } from "react-redux";
import { selectUserEmail } from "../../../../store/slices/user/userSlice";

const UserCards = ({
   enableConfirmSharingPopup,
   enableConfirmRemovingPopup,
   modelId,
   cardsModifications,
   setCardsModifications,
   isLoading,
   setIsLoading,
}) => {
   const { t } = useTranslation();

   const [sharedWithUsersCards, setSharedWithUsersCards] = useState([]);
   const [suggestedUsersCards, setSuggestedUsersCards] = useState([]);

   const userEmail = useSelector(selectUserEmail);

   const history = useHistory();

   // Chama os endpoints quando o componente é montado.
   useEffect(() => {
      fetchCards();
   }, []);

   // Chama os endpoints novamente caso haja modificação(usuário removou compartilhamento ou compartilhou o modelo)
   useEffect(() => {
      cardsModifications && fetchCards();
   }, [cardsModifications]);

   const fetchCards = () => {
      updateSharedWithUsersCards();
      updateSuggestedUsersCards();
      setIsLoading(true);
   };

   function updateSharedWithUsersCards() {
      modelService
         .getsharedwith(modelId)
         .then((usersSharedInfo) => {
            usersSharedInfo = usersSharedInfo.filter((user) => user.email !== userEmail);
            if (usersSharedInfo.length > 0) {
               setSharedWithUsersCards(renderCards(usersSharedInfo, enableConfirmRemovingPopup, true));
            } else {
               setSharedWithUsersCards(<p style={{ opacity: "50%" }}>{t("popup.action.sharedmodel.cards.title_3")}</p>);
            }
         })
         .catch((error) => {
            handleErrorResponse(history, error, t);
         })
         .finally(() => {
            setCardsModifications(false);
            setIsLoading(false);
         });
   }

   function updateSuggestedUsersCards() {
      modelService
         .getsuggestedusers(modelId)
         .then((suggestedUsers) => {
            if (suggestedUsers.length > 0) {
               setSuggestedUsersCards(renderCards(suggestedUsers, enableConfirmSharingPopup, false));
            } else {
               setSuggestedUsersCards(<p style={{ opacity: "50%" }}>{t("popup.action.sharedmodel.cards.title_4")}</p>);
            }
         })
         .catch((error) => {
            handleErrorResponse(history, error, t);
         })
         .finally(() => setIsLoading(false));
   }

   function renderCards(usersList, cardsOnClick, alreadyShared) {
      return usersList.map((user) => {
         return (
            <UserCard
               userFirstName={user.firstName}
               userLastName={user.surName}
               userEmail={user.email}
               userPicture={user.profilePicture}
               onClick={() => cardsOnClick(user.email)}
               key={user.email}
               alreadyShared={alreadyShared}
            />
         );
      });
   }

   return (
      <div className="user-cards">
         <div className="users-list" id="shared-list">
            <span className="list-heading">{t("popup.action.sharedmodel.cards.title_1")}:</span>
            <div className="list-cards">{isLoading ? <Loader active inline="centered" /> : sharedWithUsersCards}</div>
         </div>
         <div className="users-list" id="suggestions-list">
            <span className="list-heading">{t("popup.action.sharedmodel.cards.title_2")}:</span>
            <div className="list-cards">{isLoading ? <Loader active inline="centered" /> : suggestedUsersCards}</div>
         </div>
      </div>
   );
};

export default UserCards;
