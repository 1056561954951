import {
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   TextField,
   Select,
   MenuItem as SelectMenuItem,
} from "@material-ui/core";
import { Button } from "../../Button";
import datasetService from "../../../services/DatasetService";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { setNeedUpdateDataset } from "../../../store/slices/cache/cacheSlice";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useState, useEffect, useRef, useCallback } from "react";
import { Loader } from "semantic-ui-react";

const FillDialog = ({ open, onClose, currentColumn }) => {
   const [value, setValue] = useState("");
   const [newValue, setNewValue] = useState("");
   const isDataSourcePage = useLocation().pathname.includes("datasource");
   const [method, setMethod] = useState(
      currentColumn.tipo === "Numeric" ? "avg" : "replace"
   );
   const datasetId = useLocation().pathname.split("/").pop();
   const dispatch = useDispatch();
   const { t } = useTranslation();
   const valueRef = useRef(null);
   const [isLoading, setIsLoading] = useState(false);

   useEffect(() => {
      if (open && valueRef.current) {
         setTimeout(() => {
            valueRef.current.focus();
         }, 0);
      }
   }, [open]);

   const handleFill = useCallback(() => {
      let endpoint;
      if (isDataSourcePage) {
         endpoint = datasetService.fillSourceColumn(
            datasetId,
            currentColumn.field,
            currentColumn.tipo,
            value,
            method,
            newValue
         );
      } else {
         endpoint = datasetService.fillColumn(
            datasetId,
            currentColumn.field,
            currentColumn.tipo,
            value,
            method,
            newValue
         );
      }
      setIsLoading(true);
      endpoint
         .then((response) => {
            dispatch(setNeedUpdateDataset(true));
            toast.success(t(`backend.SUCCESS.${response}`));
         })
         .catch((error) => {
            if (error.response.data === "_ERROR_111") {
               toast.error(
                  t(`backend.ERROR.${error.response.data}`) +
                     ` ${currentColumn.tipo}`
               );
            } else {
               toast.error(t(`backend.ERROR.${error.response.data}`));
            }
         })
         .finally(() => {
            setIsLoading(false);
            onClose();
         });
   }, [
      isDataSourcePage,
      datasetId,
      currentColumn.field,
      currentColumn.tipo,
      value,
      method,
      newValue,
      dispatch,
      t,
      onClose,
   ]);

   useEffect(() => {
      const handleKeyDown = (event) => {
         if (event.key === "Enter") {
            event.stopPropagation();
            handleFill();
         }
      };

      if (open) {
         document.addEventListener("keydown", handleKeyDown, true);
      } else {
         document.removeEventListener("keydown", handleKeyDown, true);
      }

      return () => {
         document.removeEventListener("keydown", handleKeyDown, true);
      };
   }, [open, handleFill]);

   const handleStopPropagation = (event) => {
      event.stopPropagation();
   };

   return (
      <Dialog open={open} onClose={onClose} onClick={handleStopPropagation}>
         <DialogTitle>
            {t("dataset.content.table.columnMenu.fill.title")}{" "}
            <strong>{currentColumn.field}</strong>
         </DialogTitle>
         <DialogContent
            onClick={handleStopPropagation}
            style={{
               display: "flex",
               flexDirection: "column",
               gap: "1em",
               padding: "1em",
            }}
         >
            <TextField
               label={t(
                  "dataset.content.table.columnMenu.fill.placeholder"
               )}
               value={value}
               onClick={handleStopPropagation}
               onChange={(e) => setValue(e.target.value)}
               inputRef={valueRef}
               autoFocus
            />
            <Select
               label="Método"
               value={method}
               onClick={handleStopPropagation}
               onChange={(e) => setMethod(e.target.value)}
               MenuProps={{
                  disablePortal: true,
                  onClick: handleStopPropagation,
               }}
            >
               <SelectMenuItem
                  disabled={currentColumn.tipo !== "Numeric"}
                  value="avg"
               >
                  {t("dataset.content.table.columnMenu.fill.option_1")}
               </SelectMenuItem>
               <SelectMenuItem
                  disabled={currentColumn.tipo !== "Numeric"}
                  value="median"
               >
                  {t("dataset.content.table.columnMenu.fill.option_2")}
               </SelectMenuItem>

               <SelectMenuItem value="replace">
                  {t("dataset.content.table.columnMenu.fill.option_3")}
               </SelectMenuItem>
               <SelectMenuItem value="random">
                  {t("dataset.content.table.columnMenu.fill.option_4")}
               </SelectMenuItem>
            </Select>
            {method === "replace" && (
               <TextField
                  label={t(
                     "dataset.content.table.columnMenu.fill.option_3"
                  )}
                  value={newValue}
                  onClick={handleStopPropagation}
                  onChange={(e) => setNewValue(e.target.value)}
               />
            )}
         </DialogContent>
         <DialogActions>
            <Button
               onClick={onClose}
               type="button"
               buttonStyle="btn--primary--outline"
               buttonSize="btn--auto"
            >
               {t("dataset.content.table.columnMenu.fill.button_1")}
            </Button>
            <Button
               onClick={handleFill}
               type="button"
               buttonStyle="btn--primary--solid"
               buttonSize="btn--auto"
               disabled={isLoading}
            >
               {t("dataset.content.table.columnMenu.fill.button_2")}
               {isLoading && <Loader active inline size="tiny" />}
            </Button>
         </DialogActions>
      </Dialog>
   );
};

export default FillDialog;
