const numberOfEmployeesValues = [
    "",
    "Up to 9",
    "10 to 49",
    "50 to 99",
    "More than 100",
];

const intendedUseValues = [
    "",
    "Work",
    "Study",
    "Personal project",
    "Research",
    "Test",
    "Other"
]

const howCameToKnowValues = [
    "",
    "E-mail",
    "Lecture",
    "Friends",
    "Other"
]

const levelOfMLexperienceValues = [
    "",
    "Never had contact",
    "Little experience/personal projects",
    "Professional/advanced experience"
]

function getSelectArray(field, t) {

    switch (field) {
        case "companyNumberOfEmployees":
            const numberOfEmployeesOptions = [
                "",
                t("home.startpage.card.content.select.select_employees.employees_1"),
                t("home.startpage.card.content.select.select_employees.employees_2"),
                t("home.startpage.card.content.select.select_employees.employees_3"),
                t("home.startpage.card.content.select.select_employees.employees_4"),
            ];
            return {
                options: numberOfEmployeesOptions,
                values: numberOfEmployeesValues
            }
        case "levelOfMLExperience":
            const levelOfMLexperienceOptions = [
                "",
                t("home.startpage.card.content.select.select_experience.experience_1"),
                t("home.startpage.card.content.select.select_experience.experience_2"),
                t("home.startpage.card.content.select.select_experience.experience_3"),
            ];
            return {
                options: levelOfMLexperienceOptions,
                values: levelOfMLexperienceValues
            };
        case "howCameToKnow":
            const howCameToKnowOptions = [
                "",
                "E-mail",
                t("home.startpage.card.content.select.select_know_about.know_about_1"),
                t("home.startpage.card.content.select.select_know_about.know_about_2"),
                t("home.startpage.card.content.select.select_know_about.know_about_3"),
            ];
            return {
                options: howCameToKnowOptions,
                values: howCameToKnowValues
            };
        case "intendedUse":
            const intendedUseOptions = [
                "",
                t("home.startpage.card.content.select.select_intention.intention_1"),
                t("home.startpage.card.content.select.select_intention.intention_2"),
                t("home.startpage.card.content.select.select_intention.intention_3"),
                t("home.startpage.card.content.select.select_intention.intention_4"),
                t("home.startpage.card.content.select.select_intention.intention_5"),
                t("home.startpage.card.content.select.select_intention.intention_6"),
            ];
            return {
                options: intendedUseOptions,
                values: intendedUseValues
            };
        default:
            return false;
    }
}

export default getSelectArray;