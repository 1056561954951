import styles from "./../../../assets/css/monitoring/views/screens/DashBoard.module.css";

import logoMongoDB from "./../../../assets/images/mongodb.png";
import logoJava from "./../../../assets/images/java.png";
import logoServidor from "./../../../assets/images/servidor.png";

import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { t } from "i18next";
import monitoringService from "../../../services/MonitoringService";
import handleErrorResponse from "./../../../helpers/handleErrorResponse";
import LoadingWheel from "./../../../components/LoadingWheel";

// cache:
import { useDispatch, useSelector } from "react-redux";
import { 
    setMemoriesJava, 
    setMemoriesMongoDB, 
    setMemoriesServer, 
    setLoggedUsers,
    selectMemoriesJava,
    selectMemoriesMongoDB,
    selectMemoriesServer,
    selectCpudiskServer,
    selectLoggedUsers,
    selectDashBoardUpdated,
    setDatashBoardUpdated,
    setCpudiskServer
} from "../../../store/slices/monitoring/monitoringSlice";

function DashBoard() {

    const link = "monitoring.dashboard";
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const memoriesJava = useSelector(selectMemoriesJava);
    const memoriesMongoDB = useSelector(selectMemoriesMongoDB);
    const memoriesServer = useSelector(selectMemoriesServer);
    const loggedUsers = useSelector(selectLoggedUsers);
    const dashBoardUpdated = useSelector(selectDashBoardUpdated);
    const cpuDisk = useSelector(selectCpudiskServer);

    const dispatch = useDispatch();

    const getService = (id = 0) => {
        if (!id) {
            history.push("/monitoring/mongodb");
        } else if(id === 1){
            history.push("/monitoring/java");
        } else {
            history.push("/monitoring/server");
        }
    }

    useEffect(() => {
        if(!dashBoardUpdated){
            monitoringService.getdashboard()
            .then(data => {
                if (data.loggedusers) {
                    dispatch(setLoggedUsers(data.loggedusers))
                    //setLoggedUsers(data.loggedusers);
                }
                if (data.mongodb.length) {
                    data.mongodb = data.mongodb.map(memorie => {
                        return {
                            ...memorie,
                            Time: new Date(memorie.Time * 1000).toLocaleString('pt-BR')
                        }
                    })
                    dispatch(setMemoriesMongoDB(data.mongodb));
                    //setMemoriesMongoDB(data.mongodb);
                }
                if (data.java.length) {
                    data.java = data.java.map(memorie => {
                        return {
                            ...memorie,
                            Time: new Date(memorie.Time * 1000).toLocaleString('pt-BR')
                        }
                    })
                    dispatch(setMemoriesJava(data.java));
                    //setMemoriesJava(data.java);
                }
                if (data.server) {
                    dispatch(setMemoriesServer(data.server.memories));
                    dispatch(setCpudiskServer(data.server.cpudisk));
                    //setMemoriesServer(data.server);
                }
                dispatch(setDatashBoardUpdated(true));
            })
            .catch(error => { handleErrorResponse(history, error, t) })
            .finally(() => { setIsLoading(false) });
        } else {
            setIsLoading(false);
        }
        // eslint-disable-next-line
    }, []);


    const getMemoryRes = (name) => {
        try {
            let value = 0;
            if (name === "mongodb") {
                value = (memoriesMongoDB[0].ResidentMemUsed / 1000).toFixed(2);
            } else if (name === "java") {
                value = (memoriesJava[0].ResidentMemUsed / 1000).toFixed(2);
            } else if (name === "server") {
                value = (memoriesServer[0].ResidentMemUsed / 1000).toFixed(2);
                let per = (memoriesServer[0].ResidentMemUsed / memoriesServer[0].ResidentMemTotal) * 100;
                per = per.toFixed(2);
                const result = (isNaN(per) || isNaN(value)) ? '?' : `${value}GB (${per}%)`;
                return `${t(`${link}.service.info.residentmemory`)}: ${result}`;
            }
            const result = isNaN(value) ? '?' : `${value}GB`;
            return `${t(`${link}.service.info.residentmemory`)}: ${result}`;
        } catch (error) {
            return `${t(`${link}.service.info.residentmemory`)}: ?`;
        }
    }

    const getMemoryVirtual = (name) => {
        try {
            let value = 0;
            if (name === "mongodb") {
                value = (memoriesMongoDB[0].VirtualMemUsed / 1000).toFixed(2);
            } else if (name === "java") {
                value = (memoriesJava[0].VirtualMemUsed / 1000).toFixed(2);
            } else {
                return `${t(`${link}.service.info.virtual`)}: ?`;
            }
            const result = isNaN(value) ? '?' : `${value}GB`;
            return `${t(`${link}.service.info.virtual`)}: ${result}`;
        } catch (error) {
            return `${t(`${link}.service.info.virtual`)}: ?`;
        }
    }

    const getMemorySwapServer = () => {
        try {
            let value = (memoriesServer[0].SwapMemUsed / 1000).toFixed(2);
            let per = (memoriesServer[0].SwapMemUsed / memoriesServer[0].SwapMemTotal) * 100;
            per = per.toFixed(2);
            const result = (isNaN(per) || isNaN(value)) ? '?' : `${value}GB (${per}%)`;
            return `${t(`${link}.service.info.swap`)}: ${result}`;
        } catch (error) {
            return `${t(`${link}.service.info.swap`)}: ?`;
        }
    }

    const getCPUDisk = () => {
        try {
            const cpu = cpuDisk[0].CpuUse;
            const diskPer = ((cpuDisk[0].DiskUse / cpuDisk[0].DiskTotal) * 100).toFixed(2);
            if(!(isNaN(cpu) || isNaN(diskPer))){
                return `${diskPer}% / ${cpu}%`;
            }
        } catch (error) {

        }
        return '?'
    }

    if (isLoading) {
        return (
            <main className={styles.isloading}>
                <LoadingWheel loadingSize="large" />
            </main>
        )
    } else {
        return (
            <main className={styles.dashboard}>
                <div className={styles.connectedusers}>
                    <header><p>{t(`${link}.connectedusers.title`)}</p></header>
                    <div className={styles.users}>
                        {loggedUsers.map(user => {
                            return (
                                <div key={user.firstName} className={styles.user}>
                                    <p>{`${user.firstName} ${user.lastName}`}</p>
                                    <div></div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className={styles.containerServices}>
                    <header className={styles.headertitle}><p>Serviços</p></header>
                    <div className={styles.services}>
                        <div className={styles.service}>
                            <header><p>MongoDB</p></header>
                            <div className={styles.serviceinfo}>
                                <div className={styles.info}>
                                    <div>
                                        <h2>{t(`${link}.service.info.status`)}</h2>
                                        <p>{memoriesMongoDB.length ? t(`${link}.service.info.connected`) : t(`${link}.service.info.disconnected`)}</p>
                                    </div>
                                    <div>
                                        <h2>{t(`${link}.service.info.memoryused`)}</h2>
                                        <p>{getMemoryRes("mongodb")}</p>
                                        <p>{getMemoryVirtual("mongodb")}</p>
                                    </div>
                                </div>
                                <div className={styles.buttons}>
                                    <img alt={logoMongoDB} src={logoMongoDB} />
                                    <button onClick={() => getService()}><p>{t(`${link}.service.button`)}</p></button>
                                </div>
                            </div>
                        </div>
                        <div className={styles.service}>
                            <header><p>Java</p></header>
                            <div className={styles.serviceinfo}>
                                <div className={styles.info}>
                                    <div>
                                        <h2>{t(`${link}.service.info.status`)}</h2>
                                        <p>{memoriesJava.length ? t(`${link}.service.info.connected`) : t(`${link}.service.info.disconnected`)}</p>
                                    </div>
                                    <div>
                                        <h2>{t(`${link}.service.info.memoryused`)}</h2>
                                        <p>{getMemoryRes("java")}</p>
                                        <p>{getMemoryVirtual("java")}</p>
                                    </div>
                                </div>
                                <div className={styles.buttons}>
                                    <img alt={logoJava} src={logoJava} />
                                    <button onClick={() => getService(1)}><p>{t(`${link}.service.button`)}</p></button>
                                </div>
                            </div>
                        </div>

                        <div className={styles.service}>
                            {/*TODO: internacionalizar*/}
                            <header><p>Servidor</p></header>
                            <div className={styles.serviceinfo}>
                                <div className={styles.info}>
                                    <div>
                                        <h2>{t(`${link}.service.info.diskusage`)}</h2>
                                        <p>{getCPUDisk()}</p>
                                    </div>
                                    <div>
                                        <h2>{t(`${link}.service.info.memoryused`)}</h2>
                                        <p>{getMemoryRes("server")}</p>
                                        <p>{getMemorySwapServer()}</p>
                                    </div>
                                </div>
                                <div className={styles.buttons}>
                                    <img alt={logoServidor} src={logoServidor} />
                                    <button onClick={() => getService(2)}><p>{t(`${link}.service.button`)}</p></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

export default DashBoard;