import "./../../../assets/css/views/popups/actions/implementcodepopup.css"

import { useState } from "react";
import externalApi from "./../../../services/ExternalApi";

import { useTranslation } from "react-i18next"
import "./../../../translation/i18n";

function ImplementCodePopup({token}){

    const {t} = useTranslation();

    // const [keys, setKeys] = useState([]);
    const [generatedCode, setGeneratedCode] = useState({
        language: "python",
        type: "individual",
        generated: false,
    }) 
    const [code, setCode] = useState("");

    function handleCode(){
        if(token){
            if(generatedCode.language === "java"){
                if(generatedCode.type === "lote"){
                    externalApi.getjavabatchpredictioncode(token)
                    .then(data => setCode(data || ""))
                } else {
                    externalApi.getjavapredictioncode(token)
                    .then(data => setCode(data || ""))
                }
            } else {
                if(generatedCode.type === "lote"){
                    externalApi.getpythonbatchpredictioncode(token)
                    .then(data => setCode(data || ""))
                } else {
                    externalApi.getpythonpredictioncode(token)
                    .then(data => setCode(data || ""))
                }
            }
        }
    }

    // useEffect(() => {
    //     let i = 0;
    //     let auxKeys = [];
    //     while(i < 5){
    //         auxKeys.push("Chave 1");
    //         i++;
    //     }
    //     setKeys(auxKeys);
    //     // eslint-disable-next-line
    // }, []);

    return (
        <div className="containerImplementCode">
            <div className="containerTitlePopup">
                <p>{t("popup.action.implementcode.title")}</p>
            </div>
            <p>{t("popup.action.implementcode.description")}</p>
            <div className="containerInputs">
                {/* <div>
                    <p>Chave API:</p>
                    <select id="key">
                        {keys && keys.map((data, index) => {
                            return (
                                <option value={data} key={index}>{data}</option>
                            )
                        })}
                    </select>
                </div> */}
                <div>
                    <p>{t("popup.action.implementcode.content.select_1.name")}:</p>
                    <select id="language" onChange={(event) => {
                        setGeneratedCode({
                            ...generatedCode,
                            generated: false,
                            language: event.target.value
                        })
                        setCode("");
                    }}>
                        <option value="python">Python</option>
                        <option value="java">Java</option>
                    </select>
                </div>
                <div>
                    <p>{t("popup.action.implementcode.content.select_2.name")}</p>
                    <select id="prediction" onChange={(event) => {
                        setGeneratedCode({
                            ...generatedCode,
                            generated: false,
                            type: event.target.value,
                        })
                        setCode("");
                    }}>
                        <option value="individual">{t("popup.action.implementcode.content.select_2.option_1")}</option>
                        <option value="lote">{t("popup.action.implementcode.content.select_2.option_2")}</option>
                    </select>
                </div>
            </div>
            <div className="containerCode">
                {generatedCode.generated && (
                    (code && code.map((line, index) => {
                        return <pre key={index}>{line}</pre>
                })))}
            </div>
            <button id="buttonGeneratedCode" onClick={() => {
                handleCode();
                setGeneratedCode({
                    ...generatedCode,
                    generated: true,
                })
            }}>{t("popup.action.implementcode.button")}</button>
        </div>
    )
}

export default ImplementCodePopup;