import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Loader } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

import styles from "../../assets/css/views/screens/dataset.module.css";
import "./../../translation/i18n";

import { Popup } from "../../components/Popup";
import { Button } from "../../components/Button";
import { DeletePopup } from "../popups/actions/DeletePopup.jsx";
import ConfirmationPopup from "../popups/actions/ConfirmationPopup.jsx";
import LinkProject from "../popups/actions/LinkProject";

import TrashIcon from "../../assets/icons/jsxIcons/TrashIcon.jsx";
import DatasetData from "../../components/Dataset/DatasetData.jsx";

import datasetService from "../../services/DatasetService";
import handleErrorResponse from "../../helpers/handleErrorResponse.js";

import { selectIsUploading, setUploading } from "../../store/slices/uploadingFlag/uploadingSlice.js";
import { decrementTaskQnt, incrementTaskQnt, setRunningTask } from "../../store/slices/tasks/tasksSlice.js";
import {
   selectDataSourcesContent,
   selectNeedUpdateDataSource,
   setDataSourcesContent,
   setNeedUpdateDataSource,
} from "../../store/slices/cache/cacheSlice.js";

const useFetchData = (pageId) => {
   const dispatch = useDispatch();
   const history = useHistory();
   const { t } = useTranslation();

   const cachedDataSource = useSelector(selectDataSourcesContent).find((dataSource) => dataSource.id === pageId);
   const needUpdateDataSource = useSelector(selectNeedUpdateDataSource);

   const [datasetName, setDatasetName] = useState("");
   const [projectsNames, setProjectsNames] = useState([]);
   const [isWisemlDataset, setIsWisemlDataset] = useState(false);
   const [isLoading, setIsLoading] = useState(true);

   const fetchData = useCallback(async () => {
      try {
         let data;
         if (!cachedDataSource || needUpdateDataSource) {
            data = await datasetService.getdatasource(pageId);
            const clonedDataSource = { ...data, id: pageId };
            dispatch(setDataSourcesContent(clonedDataSource));
         } else {
            data = cachedDataSource;
         }
         setDatasetName(data.name);
         setProjectsNames(data.projectNamesList);
         setIsWisemlDataset(data.userId === null);
      } catch (error) {
         handleErrorResponse(history, error, t);
      } finally {
         setIsLoading(false);
         dispatch(setNeedUpdateDataSource(false));
      }
   }, [cachedDataSource, needUpdateDataSource, pageId, dispatch, history, t]);

   useEffect(() => {
      fetchData();
   }, [fetchData]);

   return { datasetName, projectsNames, isWisemlDataset, isLoading };
};

const EditButton = ({ isWisemlDataset, userIsAdm, setDeleteDatasourcePopup }) => {
   if ((isWisemlDataset && userIsAdm) || !isWisemlDataset) {
      return (
         <span className={styles.trashButton}>
            <button className={styles.trashIcon} onClick={() => setDeleteDatasourcePopup(true)}>
               <TrashIcon style={{ fontSize: 24 }} />
            </button>
         </span>
      );
   }
   return null;
};

export const Datasource = ({ match }) => {
   const {
      params: { pageId },
   } = match;

   const { t } = useTranslation();
   const history = useHistory();
   const dispatch = useDispatch();

   const isUploading = useSelector(selectIsUploading);
   const userIsAdm = useSelector((state) => state.user.isAdmin);

   const [linkPopup, setLinkPopup] = useState(false);
   const [confirmationPopup, setConfirmationPopup] = useState(false);
   const [deleteDatasourcePopup, setDeleteDatasourcePopup] = useState(false);
   const [activeTab, setActiveTab] = useState("dados");

   const { datasetName, projectsNames, isWisemlDataset, isLoading } = useFetchData(pageId);

   const handleButtonClick = () => {
      if (isWisemlDataset) {
         setConfirmationPopup({
            title: t("dataset.header.confirm"),
            onConfirm: () => {
               dispatch(incrementTaskQnt());
               dispatch(setUploading(true));
               datasetService
                  .copydatasource(pageId)
                  .then(() => {
                     toast.success(t("popup.action.copy_in_progress.message"));
                     dispatch(setRunningTask(true));
                  })
                  .catch((error) => {
                     handleErrorResponse(history, error, t);
                  })
                  .finally(() => {
                     dispatch(decrementTaskQnt());
                     dispatch(setUploading(false));
                     history.push("/mydatasets");
                  });
               setConfirmationPopup(false);
            },
            onDeny: () => {
               setConfirmationPopup(false);
            },
         });
      } else {
         setLinkPopup(true);
      }
   };

   if (isLoading) {
      return <Loader active />;
   }

   return (
      <div className={styles.dataset}>
         <header className={styles.header}>
            <div className={styles.infosDataSource}>
               <span className={styles.datasetName}>{datasetName} </span>
               {!isWisemlDataset ? (
                  <small>
                     {t("dataset.header.text_1") + ": "}
                     {projectsNames.length
                        ? projectsNames.map((projName, index) => (
                             <span key={projName}>
                                {projName}
                                {index !== projectsNames.length - 1 && " | "}
                             </span>
                          ))
                        : t("dataset.header.text_default")}
                  </small>
               ) : (
                  <small>{t("dataset.header.text_wiseml")}</small>
               )}
            </div>
            <div className={styles.datasourceButtons}>
               {isUploading && <Loader active />}
               <EditButton
                  isWisemlDataset={isWisemlDataset}
                  userIsAdm={userIsAdm}
                  setDeleteDatasourcePopup={setDeleteDatasourcePopup}
               />

               <Button
                  type="button"
                  buttonStyle="btn--primary--solid"
                  buttonSize="btn--auto"
                  disabled={isUploading}
                  onClick={handleButtonClick}
               >
                  {isWisemlDataset ? t("dataset.header.button_4") : t("dataset.header.button_1")}
               </Button>
            </div>
         </header>
         <DatasetData setActiveTab={setActiveTab} activeTab={activeTab} pageId={pageId} />
         <Popup trigger={linkPopup} setTrigger={setLinkPopup} enableCloseIcon={true}>
            <LinkProject dataID={pageId} projects={projectsNames} setTrigger={setLinkPopup} />
         </Popup>
         <Popup trigger={confirmationPopup} setTrigger={setConfirmationPopup}>
            <ConfirmationPopup
               title={confirmationPopup?.title}
               onConfirm={confirmationPopup?.onConfirm}
               onDeny={confirmationPopup?.onDeny}
            />
         </Popup>
         <Popup trigger={deleteDatasourcePopup} setTrigger={setDeleteDatasourcePopup}>
            <DeletePopup
               cardtype={"datasource"}
               modelResultId={pageId}
               setTrigger={setDeleteDatasourcePopup}
               needBack={true}
            />
         </Popup>
      </div>
   );
};
