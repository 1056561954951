import SVMIcon from "../../assets/icons/jsxIcons/SVMIcon";
import FileIcon from "../../assets/icons/jsxIcons/FileIcon";
import modelService from "../../services/ModelService";

import InputSlider from "../../components/Slider";

import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { MetricsPopup } from "../popups/informations/MetricsPopup";
import Prediction from "../popups/predictions/Prediction";
import Dropdown from "../../components/Dropdown";

import SVMHighcharts from "../../graphicsComponents/modelsRepresentations/SVMHighchart";
import { Popup } from "../../components/Popup";

import { useDispatch, useSelector } from "react-redux";
import { setRunningTask } from "../../store/slices/tasks/tasksSlice";
import { setNewNotification } from "../../store/slices/notification/notificationSlice";
import LoadingWheel from "../../components/LoadingWheel";
import { setNeedUpdateProjectList } from "../../store/slices/projects/projectsSlice";
import { setModelsResult } from "../../store/slices/cache/cacheSlice";
import { selectMainAttribute } from "../../store/slices/mainAttribute/mainAttributeSlice";
import ModeTabs from "../../components/ModeTabs";
import TargetModel from "../../components/TargetModel";

import { useTranslation } from "react-i18next";
import "./../../translation/i18n";

import handleErrorResponse from "../../helpers/handleErrorResponse";
import CustomTooltipWrapper from "../../components/CustomTooltip";
import { MdOutlineFileOpen } from "react-icons/md";

function SupportVectorMachine({
   model,
   setModel,
   setGeneratedPredictionsPopup,
   setIsCreated,
   isCreated,
   dataSetExists,
   setDataSetExists,
}) {
   const mainAttribute = useSelector(selectMainAttribute);
   const [readOnly, setReadOnly] = useState(isCreated);

   const { parameters } = model;
   const { t } = useTranslation();
   /** Percentual de treinamento do algoritmo */
   const [percentualTreinamento, setPercentualTreinamento] = useState(75);
   /** Parâmetero de regularização do algoritmo */
   const [regularization, setRegularization] = useState(10);
   /** Número de interações */
   const [numIterations, setNumIterations] = useState(1000);
   /** Tipo de Kernel */
   const [kernel, setKernel] = useState("linear");
   /** Framework a ser utilizado */
   const [framework, setFramework] = useState("sklearn");

   const [metricsPopup, setMetricsPopup] = useState(false);

   const [predictionPopup, setPredictionPopup] = useState(false);

   const [taskIsLoading, setTaskIsLoading] = useState(false);

   const [progress, setProgress] = useState(0);

   const kernels = {
      sklearn: [
         { label: "Linear", value: "linear" },
         { label: "Rbf", value: "rbf" },
         { label: "Poly", value: "poly" },
         { label: "Sigmoid", value: "sigmoid" },
      ],
      spark: [{ label: "Linear", value: "linear" }],
   };
   const frameworkOptions = [
      { label: "Sklearn", value: "sklearn" },
      { label: "Spark", value: "spark" },
   ];

   const dispatch = useDispatch();
   const history = useHistory();

   const taskList = useSelector((state) => state.tasks.taskList);
   const [taskId, setTaskId] = useState("");

   useEffect(() => {
      if (taskId) {
         let task = taskList.filter((task) => task.task === taskId)[0];
         if (task) {
            setProgress(task.progress);
         } else if (progress <= 100) {
            setTaskId("");
            setProgress(100);
            setTaskIsLoading(false);
            modelService
               .getmodel(taskId)
               .then((data) => {
                  setTaskId("");
                  dispatch(setModelsResult(data));
                  dispatch(setNeedUpdateProjectList(true));
                  dispatch(setNewNotification(true));
                  history.push(`/model/${data.id}`);
               })
               .catch((error) => {
                  handleErrorResponse(history, error, t);
               });
         }
      }
      // eslint-disable-next-line
   }, [taskList]);

   useEffect(() => {
      setIsCreated(!!model?.id);
      setReadOnly(!!model?.id);
      if (model?.result) {
         setTaskIsLoading(true);
         SVMHighcharts.createGraphic(model?.result);
         setTaskIsLoading(false);
      }
   }, [model, setIsCreated]);

   function createSVM() {
      if (!taskIsLoading) {
         setIsCreated(false);
         setTaskIsLoading(true);
         setProgress(0);
         setModel({
            ...model,
            id: "",
            name: "",
            saved: false,
            sharedWith: null,
            result: null,
            metrics: "",
            significantFields: null,
            hasPrediction: false,
         });
         modelService
            .supportvectormachine({
               mlframework: framework,
               kernel: kernel,
               datasetid: model?.datasetId,
               name: model?.name,
               trainfraction: percentualTreinamento,
               target: mainAttribute,
               featuresused: model?.fields?.usedFields.toString(),
               regparam: regularization,
               numiterations: numIterations,
            })
            .then((id) => {
               dispatch(setRunningTask(true));
               setTaskId(id);
            })
            .catch((error) => {
               setTaskIsLoading(false);
               handleErrorResponse(history, error, t);
            });
      }
   }

   const isShared = model?.sharedWith && model?.sharedWith.length > 0;

   return (
      <div className="model-algorithm">
         <div className="main-algorithm">
            <div className="left">
               <header className="header-algorithm">
                  {/* Nome do algoritmo */}
                  <div className="name">
                     <SVMIcon style={{ size: 24, color: "#282828" }} />
                     <p id="algorithm-name">SVM</p>
                  </div>
                  <div className="name">
                     {isShared || !dataSetExists ? <FileIcon /> : <MdOutlineFileOpen size={24} />}
                     {!isShared && dataSetExists ? (
                        <button
                           onClick={() => {
                              history.push(`/dataset/${model?.datasetId}`);
                           }}
                        >
                           <p id="model-dataset">{model?.datasetName}</p>
                        </button>
                     ) : (
                        <p id="model-dataset">{model?.datasetName}</p>
                     )}
                  </div>
                  {isCreated && <ModeTabs readOnly={readOnly} setReadOnly={setReadOnly} owner={model?.owner} />}
                  <TargetModel
                     readOnly={readOnly}
                     model={model}
                     dataSetExists={dataSetExists}
                     setDataSetExists={setDataSetExists}
                     nameAlgorithm={"SVM"}
                  />
               </header>
               {/* Hiperparâmetros */}
               <div className="model-hyperparameters">
                  <div className="model-slider">
                     <CustomTooltipWrapper title={t("model.supportvectormachine.slider_1.info")}>
                        <p id="training-input">{t("model.supportvectormachine.slider_1")}</p>
                     </CustomTooltipWrapper>
                     <InputSlider
                        currentValue={readOnly ? parameters?.TrainFraction : percentualTreinamento}
                        setCurrentValue={setPercentualTreinamento}
                        defaultValue={75}
                        isPercentage={true}
                        disabled={readOnly}
                        min={0}
                        max={100}
                        id="training"
                     />
                  </div>
                  <div className="model-slider">
                     <CustomTooltipWrapper title={t("model.supportvectormachine.slider_2.info")}>
                        <p id="regularization-input">{t("model.supportvectormachine.slider_2")}</p>
                     </CustomTooltipWrapper>
                     <InputSlider
                        currentValue={readOnly ? parameters?.RegParam : regularization}
                        setCurrentValue={setRegularization}
                        defaultValue={10}
                        disabled={readOnly}
                        isPercentage={false}
                        min={1}
                        max={1000}
                        id="regularizarion"
                     />
                  </div>
                  <div className="model-slider">
                     <CustomTooltipWrapper title={t("model.supportvectormachine.slider_3.info")}>
                        <p id="numiterations-input">{t("model.supportvectormachine.slider_3")}</p>
                     </CustomTooltipWrapper>
                     <InputSlider
                        currentValue={readOnly ? parameters?.NumIterations : numIterations}
                        setCurrentValue={setNumIterations}
                        defaultValue={1000}
                        disabled={readOnly}
                        isPercentage={false}
                        min={10}
                        max={10000}
                        id="numiterations"
                     />
                  </div>
                  <div className="model-dropdown">
                     <p>{t("model.backend")}</p>
                     <Dropdown
                        defaultValue={0}
                        onChange={setFramework}
                        options={frameworkOptions}
                        disabled={readOnly}
                     />
                  </div>
                  <div className="model-dropdown">
                     <p>Kernel</p>
                     <Dropdown defaultValue={0} onChange={setKernel} options={kernels[framework]} disabled={readOnly} />
                  </div>
               </div>
               {!readOnly && (
                  <div className="model-execute">
                     <button
                        onClick={createSVM}
                        className={dataSetExists ? "" : "btn--disabled--solid"}
                        disabled={taskIsLoading || !dataSetExists}
                     >
                        {t("model.execute")}
                     </button>
                  </div>
               )}
            </div>
            <div className="model-result-2" id="container">
               {taskIsLoading && <LoadingWheel progress={progress} loadingSize="large" />}
            </div>
         </div>

         {model?.id && (
            <div className="buttons-bottom-algorithm">
               {model?.isCategorical && (
                  <button
                     disabled={!model}
                     onClick={() => {
                        setMetricsPopup(!metricsPopup);
                     }}
                  >
                     <p>{t("model.footer.button_1")}</p>
                  </button>
               )}
               {model?.hasPrediction && (
                  <button
                     disabled={!model?.hasPrediction}
                     onClick={() => {
                        setGeneratedPredictionsPopup(true);
                     }}
                  >
                     <p>{t("model.footer.button_3")}</p>
                  </button>
               )}
               <button
                  disabled={!model}
                  onClick={() => {
                     setPredictionPopup(model ? !predictionPopup : false);
                  }}
                  id="fill"
               >
                  <p>{t("model.footer.button_4")}</p>
               </button>
            </div>
         )}

         <Popup trigger={metricsPopup} setTrigger={setMetricsPopup}>
            <MetricsPopup
               data={{
                  metrics: model?.metrics,
                  framework: model?.framework,
                  type: model?.type,
               }}
            />
         </Popup>

         <Popup trigger={predictionPopup} setTrigger={setPredictionPopup}>
            <Prediction
               targetName={model?.target}
               fields={model?.fields}
               modelId={model?.id}
               algorithmType={model?.type}
            />
         </Popup>
      </div>
   );
}

export default SupportVectorMachine;
