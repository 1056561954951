import React from "react";

import "../assets/css/components/dropdown.css";

// Pedro : não entendi o comentario dos ícones

const Dropdown = ({defaultValue, onChange, options, disabled}) => {
  return (
    <div className="dropdown">
      {renderDropdownSelect()}
    </div>
  );

  function renderDropdownSelect() {

    return (
      <select 
        defaultValue={defaultValue}
        disabled={disabled}
        onChange={(changeEvent) => {
          onChange(changeEvent.target.value)
        }}
      >
        {renderDropdownOptions()}
      </select>
    )
  }

  function renderDropdownOptions() {
    return options.map(({value, label}) => {
      return (
        <option 
          className="dropdown-option"
          value={value}
          key={label}
        >
          {label}
        </option>
      )
    })
  }
};

export default Dropdown;
