import React from "react";
import { Dropdown } from "semantic-ui-react";

import "../assets/css/components/SelectTarget.css";



function SelectTarget({
  placeholder,
  currentValue,
  options,
  setCurrentValue,
  defaultValue,
  barSize,
}) {
  if (defaultValue == null){
    defaultValue = "";
  }
  // função que atualiza o valor de currentValue
  const handleSelectedTarget = (event, selected) => {
    setCurrentValue(selected.value);
  };

  // preenche o vetor com os dados passados por parâmetro
  let targetOptions = [];
  if(options != null){
    
    for (let i = 0; i < options.length; i++) {
      targetOptions.push({ key: i, value: i, text: options[i] });
    }
  }
  const sizes = ["default", "small"];
  const checkSize = sizes.includes(barSize) ? barSize : sizes[0] // ODOT: arrumar <-
  // TODO
  // placeholder: ao simplesmente abrir as opções, mesmo se tentar clicar fora, ele seleciona um target
  // text: ao selecionar as opções, o texto na barrinha não altera e fica sempre "Atributo Principal"
  return (
    <span className={`${checkSize}`}>
      <Dropdown
        className=""
        // trigger={currentValue === "" ? <div className="error-text">{placeholder}</div> : ""}
        // trigger={currentValue === "" ? placeholder : ""}
        placeholder={placeholder}
        closeOnBlur
        closeOnChange={true}
        fluid
        search
        selection
        defaultValue={defaultValue}
        options={targetOptions}
        onChange={handleSelectedTarget}
      />
    </span>
  );
}

export default SelectTarget;
