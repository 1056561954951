import React, { useEffect, useRef, useState } from "react";
import Tour from "reactour";
import CloseTourDialog from "./components/CloseTourDialog";
import { useTranslation } from "react-i18next";
import StyledParagraph from "./components/StyledParagraph";
import {
   Delete,
   Edit,
   FilterList,
   Info,
   Sort,
   TableChart,
} from "@material-ui/icons";
import { GiClick } from "react-icons/gi";

const DatasetTour = ({ setActiveTab, setIsDatasetTab }) => {
   const [isTourOpen, setIsTourOpen] = useState(true);
   const [currentStep, setCurrentStep] = useState(0);
   const [disableInteraction, setDisableInteraction] = useState(true);
   const tourRef = useRef();
   const [isDialogOpen, setIsDialogOpen] = useState(false);
   const { t } = useTranslation();

   const steps = [
      {
         selector: "#root > span > div:nth-child(3)",
         content: (
            <div>
               <h4>{t("wizard.datasetTour.step_1.title")}</h4>
               <StyledParagraph icon={Info}>
                  {t("wizard.datasetTour.step_1.description")}
               </StyledParagraph>
            </div>
         ),
         style: {
            borderRadius: 10,
         },
      },
      {
         selector:
            "#root > span > div:nth-child(3) > header > div:nth-child(2)",
         content: (
            <div>
               <h4>{t("wizard.datasetTour.step_2.title")}</h4>
               <StyledParagraph icon={Info}>
                  {t("wizard.datasetTour.step_2.description")}
               </StyledParagraph>
            </div>
         ),
         style: {
            borderRadius: 10,
         },
      },
      {
         selector:
            "#root > span > div:nth-child(3) > nav > button:nth-child(1)",
         content: (
            <div>
               <StyledParagraph icon={Info}>
                  {t("wizard.datasetTour.step_3.description")}
               </StyledParagraph>
            </div>
         ),
         style: {
            borderRadius: 10,
         },
      },
      {
         selector: "#root > span > div:nth-child(3) > div",
         content: (
            <div>
               <h4>{t("wizard.datasetTour.step_4.title")}</h4>

               <StyledParagraph icon={Edit}>
                  {t("wizard.datasetTour.step_4.description_1")}
               </StyledParagraph>

               <StyledParagraph icon={Delete}>
                  {t("wizard.datasetTour.step_4.description_2")}
               </StyledParagraph>
               <StyledParagraph icon={TableChart}>
                  {t("wizard.datasetTour.step_4.description_3")}
               </StyledParagraph>
               <StyledParagraph icon={FilterList}>
                  {t("wizard.datasetTour.step_4.description_4")}
               </StyledParagraph>
               <StyledParagraph icon={Sort}>
                  {t("wizard.datasetTour.step_4.description_5")}
               </StyledParagraph>
               <StyledParagraph icon={Info}>
                  <strong>
                     {t("wizard.datasetTour.step_4.description_6")}
                  </strong>
               </StyledParagraph>
            </div>
         ),
         style: {
            borderRadius: 10,
         },
      },
      {
         selector:
            "#root > span > div:nth-child(3) > nav > button:nth-child(2)",
         content: (
            <div>
               <StyledParagraph icon={Info}>
                  {t("wizard.datasetTour.step_5.description")}
               </StyledParagraph>
            </div>
         ),
         style: {
            borderRadius: 10,
         },
      },
      {
         selector: "#root > span > div:nth-child(3) > div > div",
         content: (
            <div>
               <h4>{t("wizard.datasetTour.step_6.title")}</h4>
               <StyledParagraph icon={Info}>
                  {t("wizard.datasetTour.step_6.description_1")}
               </StyledParagraph>
               <StyledParagraph icon={Edit}>
                  {t("wizard.datasetTour.step_6.description_2")}
               </StyledParagraph>
               <StyledParagraph icon={Edit}>
                  {t("wizard.datasetTour.step_6.description_3")}
               </StyledParagraph>
            </div>
         ),
         style: {
            borderRadius: 10,
         },
      },
      {
         selector:
            "#root > span > div:nth-child(3) > header > div:nth-child(2) > button:nth-child(2)",
         content: (
            <div>
               <h4>{t("wizard.datasetTour.step_7.title")}</h4>
               <StyledParagraph icon={Info}>
                  {t("wizard.datasetTour.step_7.description")}
               </StyledParagraph>
            </div>
         ),
         style: {
            borderRadius: 10,
         },
      },
      {
         selector:
            "#root > span > div.dataset_dataset__Sa3uE > nav.modelcards_navModels__ioyZe > button",
         content: (
            <div>
               <h4>{t("wizard.datasetTour.step_8.title")}</h4>
               <StyledParagraph icon={GiClick}>
                  {t("wizard.datasetTour.step_8.description")}
               </StyledParagraph>
            </div>
         ),
         style: {
            borderRadius: 10,
         },
      },
   ];

   useEffect(() => {
      if (currentStep < 4) {
         setIsDatasetTab(true);
         setActiveTab("dados");
      } else if (currentStep < 6) {
         setIsDatasetTab(true);
         setActiveTab("estatisticas");
      } else {
         setIsDatasetTab(false);
      }

      if (currentStep === 7 || currentStep === 3) {
         setDisableInteraction(false);
      }
   }, [currentStep, setActiveTab, setIsDatasetTab]);

   useEffect(() => {
      if (currentStep === steps.length - 1) {
         const createModelButton = document.querySelector(
            "#root > span > div.dataset_dataset__Sa3uE > nav.modelcards_navModels__ioyZe > button"
         );

         if (createModelButton) {
            createModelButton.addEventListener("click", () => {
               setIsTourOpen(false);
            });
         }

         return () => {
            if (createModelButton) {
               createModelButton.removeEventListener("click", () => {
                  setIsTourOpen(false);
               });
            }
         };
      }
   }, [currentStep, steps.length]);

   return (
      <>
         <Tour
            ref={tourRef}
            steps={steps}
            isOpen={isTourOpen}
            onRequestClose={() => setIsDialogOpen(true)}
            getCurrentStep={(step) => setCurrentStep(step)}
            goToStep={currentStep}
            disableDotsNavigation
            disableInteraction={disableInteraction}
            disableFocusLock={isDialogOpen}
            rounded={10}
         />
         <CloseTourDialog
            open={isDialogOpen}
            onCloseDialog={() => setIsDialogOpen(false)}
            onCloseTour={() => setIsTourOpen(false)}
         />
      </>
   );
};

export default DatasetTour;
