import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    popUp: false,
    new: false,
};

export const notificationSlice = createSlice({
    name: "notification",
    initialState,
    reducers: {
        setNotificationPopUp: (state, action) => {
            state.popUp = action.payload;
        },
        setNewNotification: (state, action) => {
            state.new = action.payload;
        },
    },
});

export const { setNewNotification, setNotificationPopUp } = notificationSlice.actions;

export default notificationSlice.reducer;
