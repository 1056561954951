import React, { useEffect, useState } from "react";

import "../../../assets/css/views/popups/actions/linkprojectpopup.css";
import ds from "../../../services/DatasetService";
import { Button } from "../../../components/Button";
import CheckIcon from "../../../assets/icons/jsxIcons/CheckIcon";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { setRunningTask } from "../../../store/slices/tasks/tasksSlice";
import { setNeedUpdateMyDatasources } from "../../../store/slices/cache/cacheSlice";
import { setNeedUpdateProjectList } from "../../../store/slices/projects/projectsSlice";
import { Loader } from "semantic-ui-react";

import { useTranslation } from "react-i18next"
import "./../../../translation/i18n";
import handleErrorResponse from "../../../helpers/handleErrorResponse";
import { toast } from "react-toastify";

const LinkProject = ({ projects, setTrigger, dataID, setProjectsChanged }) => {

  const {t} = useTranslation();

  const dispatch = useDispatch();

  const [optionsList, setOptionsList] = useState([]);

  const projectList = useSelector((state) => state.projects.projectList);

  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    let projectListAux = projectList.slice();
    projectListAux = projectListAux.filter(
      (project) => !project.shared && !projects.includes(project.name)
    );
    projectListAux.forEach((project, index) => {
      projectListAux[index] = {
        ...project,
        selected: false,
      };
    });
    setOptionsList(projectListAux);
    // eslint-disable-next-line
  }, []);

  //Funcão que vincula um datasource a projeto(s)
  const handleLinkDataSource = () => {
    if (optionsList.length > 0) {
      const idSelected = optionsList
        .filter((option) => option.selected)
        .map((option) => option.id);
      if (idSelected.length > 0) {
        setIsLoading(true);
        ds.linkonedatasource(dataID, idSelected)
          .then(() => {
            setTrigger(false);
            toast.success(t("popup.action.linkdatasource.success"));
            dispatch(setRunningTask(true));
            dispatch(setNeedUpdateMyDatasources(true));
          })
          .catch((error) => {
            handleErrorResponse(history, error, t);
          })
          .finally(() => {
            dispatch(setNeedUpdateMyDatasources(true));
            dispatch(setNeedUpdateProjectList(true));
            setIsLoading(false);
          });
      } else {
        toast.error(t("popup.action.linkproject.select"));
      }
    }
  };

  return (
    <div id="linkPopup">
      {t("popup.action.linkproject.title")}:
      <div id="options">
        {optionsList.length > 0 ? (
          optionsList.map((opt, index) => {
            const handleCheckboxClick = () => {
              const updatedOptionsList = [...optionsList]; // Cria uma cópia do array optionsList
              updatedOptionsList[index].selected = !opt.selected;

              setOptionsList(updatedOptionsList); // Atualiza o estado com a nova cópia do array
            };

            return (
              <div id="option" key={opt}>
                <div id="checkbox" onClick={handleCheckboxClick}>
                  {opt.selected && <CheckIcon />}
                </div>
                <div className="div-name">{opt.name}</div>
              </div>
            );
          })
        ) : (
          <>
            <div>{t("popup.action.linkproject.no_project")}</div>
            <div></div>
          </>
        )}
      </div>
      {isLoading && <Loader active />}
      <div id="buttons">
        <span id="btn">
          <Button
            type="button"
            buttonStyle="btn--primary--transparent"
            buttonSize="btn--auto"
            onClick={() => setTrigger(false)}
          >
            {t("popup.action.linkproject.button.cancel")}
          </Button>
        </span>
        {optionsList.length > 0 ? (
          <span id="btn">
            <Button
              type="button"
              buttonStyle="btn--primary--solid"
              buttonSize="btn--auto"
              disabled={isLoading}
              onClick={handleLinkDataSource}
            >
              {t("popup.action.linkproject.button.save")}
            </Button>
          </span>
        ) : (
          <Link to={"/myprojects"}>
            <span id="btn">
              <Button
                type="button"
                buttonStyle="btn--primary--solid"
                buttonSize="btn--auto"
              >
                {t("popup.action.linkproject.button.projects")}
              </Button>
            </span>
          </Link>
        )}
      </div>
    </div>
  );
};

export default LinkProject;
