import * as d3 from "d3";
import React, { useEffect, useRef } from "react";

import "../../assets/css/graphicsComponents/modelsRepresentations/linearregressiond3.css";


export const LinearRegressionD3 = ({
  result,
  secondaryTarget,
  setSecondaryTarget,
}) => {

  // inicializa um Ref
  const d3Chart = useRef();
  // inicializa o secondaryTarget pra garantir que nunca vai ser null
  if (secondaryTarget == null) {
    setSecondaryTarget(result.Fields[0]);
  }

  // função que desenha o line chart de fato - feito em função pra poder ser chamado tanto no use effect
  // onMount quanto no side effect que é ativado quando o secondary target for alterado
  function desenharLineChart() {
    // garantindo que não vai dar erro no retorno do backend
    if (result === undefined) {
      return;
    }
    if (
      result === null ||
      !result.hasOwnProperty("Weights") ||
      result.Weights === undefined
    ) {
      return;
    }

    let m = 0;

    // caso nenhum target tenha sido escolhido ainda, escolher o primeiro do vetor
    if (secondaryTarget == null) {
      m = result.Weights[0];
    } else {
      // eslint-disable-next-line
      m = result.Weights[secondaryTarget];
    }

    // remove a linha e os eixos pra desenhar novamente, evitando que fique se sobrepondo
    d3.select("#linechart-line").remove();
    d3.select("#linechart-axis-x").remove();
    d3.select("#linechart-axis-y").remove();
    d3.select("#big-line").remove();
    d3.select("#big-line2").remove();
    d3.selectAll("text").remove();

    const margin = {top: 40, right: 20, bottom: 40, left: 40};
    const width = parseInt(d3.select(".model-result-2").style("width"), 10) - margin.left - margin.right;
    const height = parseInt(d3.select(".model-result-2").style("height"), 10) - margin.top - margin.bottom;

    const svg = d3
      .select(d3Chart.current)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // Eixo X
    // escala, domínio e intervalo
    var x = d3
      .scaleLinear()
      .domain([
        result.Weights[secondaryTarget].MinValue,
        result.Weights[secondaryTarget].MaxValue,
      ])
      .range([0, width]);

    // Grudar X
    svg
      .append("g")
      .attr("id", "linechart-axis-x")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x));

    // Eixo Y
    // escala, domínio e intervalo
    var yLowDomain = result.Target.MinValue;
    var yHighDomain = result.Target.MaxValue;

    var intercept = result.Intercept;
    var confidenceInterval = result.ConfidenceInterval;

    var secondaryTargetCoefficient = result.Weights[secondaryTarget].Value;
    var secondaryTargetDomainIncrement =
      secondaryTargetCoefficient * result.Weights[secondaryTarget].MaxValue;

    if (intercept - confidenceInterval < yLowDomain) {
      yLowDomain = intercept - confidenceInterval;
      if (
        intercept - confidenceInterval + secondaryTargetDomainIncrement <
        intercept - confidenceInterval
      )
        yLowDomain = yLowDomain + secondaryTargetDomainIncrement;
    }

    if (intercept + confidenceInterval > yHighDomain) {
      yHighDomain = intercept + confidenceInterval;
      if (
        intercept + confidenceInterval <
        intercept + confidenceInterval + secondaryTargetDomainIncrement
      )
        yHighDomain = yHighDomain + secondaryTargetDomainIncrement;
    }

    var y = d3
      .scaleLinear()
      .domain([yLowDomain, yHighDomain])
      .range([height, 0]);

    // Grudar Y
    svg.append("g").attr("id", "linechart-axis-y").call(d3.axisLeft(y));

    var regressionPoints = [
      { x: result.Weights[secondaryTarget].MinValue, y: result.Intercept },
      {
        x: result.Weights[secondaryTarget].MaxValue,
        y:
          result.Intercept +
          result.Weights[secondaryTarget].Value *
            result.Weights[secondaryTarget].MaxValue,
      },
    ];

    var lowerInterval = [
      {
        x: result.Weights[secondaryTarget].MinValue,
        y: result.Intercept - result.ConfidenceInterval,
      },
      {
        x: result.Weights[secondaryTarget].MaxValue,
        y:
          result.Intercept -
          result.ConfidenceInterval +
          result.Weights[secondaryTarget].Value *
            result.Weights[secondaryTarget].MaxValue,
      },
    ];

    var higherInterval = [
      {
        x: result.Weights[secondaryTarget].MinValue,
        y: result.Intercept + result.ConfidenceInterval,
      },
      {
        x: result.Weights[secondaryTarget].MaxValue,
        y:
          result.Intercept +
          result.ConfidenceInterval +
          result.Weights[secondaryTarget].Value *
            result.Weights[secondaryTarget].MaxValue,
      },
    ];

    // y = mx + b
    // m = coef angular
    // b = y0
    // NOVO : talvez o b seja o bias, e não o y0, conferir dps
    // x = xmax

    // Desenhar Linha
    svg
      .append("path")
      .attr("id", "linechart-line")
      .datum(regressionPoints)
      .attr("fill", "none")
      .attr("stroke", "black")
      .attr("stroke-width", 3)
      .attr(
        "d",
        d3
          .line()
          .x(function (d) {
            return x(d.x);
          })
          .y(function (d) {
            return y(d.y);
          })
      );
    svg
      .append("path")
      .attr("id", "big-line")
      .datum(lowerInterval)
      .attr("fill", "none")
      .attr("stroke", "blue")
      .attr("opacity", 0.5)
      .attr("stroke-width", 2)

      .attr(
        "d",
        d3
          .line()
          .x(function (d) {
            return x(d.x);
          })
          .y(function (d) {
            return y(d.y);
          })
      );

    svg
      .append("path")
      .attr("id", "big-line2")
      .datum(higherInterval)
      .attr("fill", "none")
      .attr("stroke", "blue")
      .attr("opacity", 0.5)
      .attr("stroke-width", 2)

      .attr(
        "d",
        d3
          .line()
          .x(function (d) {
            return x(d.x);
          })
          .y(function (d) {
            return y(d.y);
          })
      );

    // texto de teste: nome do target selecionado
    svg
      .append("text")
      .attr("x", width / 2)
      .attr("y", margin.top / 16)
      .attr("text-anchor", "middle")
      .attr("font-size", "16px")
      .attr("fill", "black")
      .text(result.Fields[secondaryTarget]);

    svg
      .append("text")
      .attr("x", width / 2)
      .attr("y", (5 * margin.top) / 8)
      .attr("text-anchor", "middle")
      .attr("font-size", "16px")
      .attr("fill", "black");
  }

  useEffect(() => {
    desenharLineChart();
  });

  useEffect(() => {
    desenharLineChart();
    // eslint-disable-next-line 
  }, [secondaryTarget]);

  return <svg ref={d3Chart}></svg>
};
