function RandomForestIcon({ style }) {
  if (style == null) {
    style = [];
  }
  return (
    <svg
      width={style.hasOwnProperty('size') ? style.size : 18}
      height={style.hasOwnProperty('size') ? style.size : 18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="8.50886"
        cy="2.05431"
        rx="1.47321"
        ry="1.46739"
        fill={style.hasOwnProperty('color') ? style.color : "#282828"}
      />
      <ellipse
        cx="11.4556"
        cy="6.74998"
        rx="1.47321"
        ry="1.46739"
        fill={style.hasOwnProperty('color') ? style.color : "#282828"}
      />
      <ellipse
        cx="8.50886"
        cy="12.0326"
        rx="1.47321"
        ry="1.46739"
        fill={style.hasOwnProperty('color') ? style.color : "#282828"}
      />
      <ellipse
        cx="5.56257"
        cy="6.74998"
        rx="1.47321"
        ry="1.46739"
        fill={style.hasOwnProperty('color') ? style.color : "#282828"}
      />
      <ellipse
        cx="2.61628"
        cy="12.0326"
        rx="1.47321"
        ry="1.46739"
        fill={style.hasOwnProperty('color') ? style.color : "#282828"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.80368 1.76093L11.439 6.30747L10.9287 6.60095L8.29335 2.05441L8.80368 1.76093Z"
        fill={style.hasOwnProperty('color') ? style.color : "#282828"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.85739 7.04352L8.49276 11.5901L7.98242 11.8835L5.34706 7.337L5.85739 7.04352Z"
        fill={style.hasOwnProperty('color') ? style.color : "#282828"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.85723 6.30739L8.49259 1.76084L9.00293 2.05432L6.36756 6.60087L5.85723 6.30739Z"
        fill={style.hasOwnProperty('color') ? style.color : "#282828"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.91094 11.59L5.5463 7.04344L6.05664 7.33691L3.42127 11.8835L2.91094 11.59Z"
        fill={style.hasOwnProperty('color') ? style.color : "#282828"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.98223 15.6987L12.6176 11.1522L13.1279 11.4457L10.4926 15.9922L9.98223 15.6987Z"
        fill={style.hasOwnProperty('color') ? style.color : "#282828"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9287 11.1522L15.564 15.6987L15.0537 15.9922L12.4183 11.4456L12.9287 11.1522Z"
        fill={style.hasOwnProperty('color') ? style.color : "#282828"}
      />
      <ellipse
        cx="9.68757"
        cy="16.1413"
        rx="1.47321"
        ry="1.46739"
        fill={style.hasOwnProperty('color') ? style.color : "#282828"}
      />
      <ellipse
        cx="12.6339"
        cy="10.8587"
        rx="1.47322"
        ry="1.46739"
        fill={style.hasOwnProperty('color') ? style.color : "#282828"}
      />
      <ellipse
        cx="15.5806"
        cy="16.1413"
        rx="1.47321"
        ry="1.46739"
        fill={style.hasOwnProperty('color') ? style.color : "#282828"}
      />
    </svg>
  );
}

export default RandomForestIcon;
