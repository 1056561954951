import React from "react";

function DatasetIMGIcon({ style }) {
    if (style == null) {
        style = [];
    }

    return (
        <svg
            width={style.hasOwnProperty("size") ? style.size : 24}
            height={style.hasOwnProperty("size") ? style.size : 24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_3863_4908)">
                <path d="M19.5 0H4.5C3.30693 0.00132343 2.16311 0.475854 1.31948 1.31948C0.475854 2.16311 0.00132343 3.30693 0 4.5L0 19.5C0.00132343 20.6931 0.475854 21.8369 1.31948 22.6805C2.16311 23.5241 3.30693 23.9987 4.5 24H19.5C20.6931 23.9987 21.8369 23.5241 22.6805 22.6805C23.5241 21.8369 23.9987 20.6931 24 19.5V4.5C23.9987 3.30693 23.5241 2.16311 22.6805 1.31948C21.8369 0.475854 20.6931 0.00132343 19.5 0V0ZM4.5 3H19.5C19.8978 3 20.2794 3.15804 20.5607 3.43934C20.842 3.72064 21 4.10218 21 4.5V19.5C21.0003 19.697 20.9615 19.8921 20.886 20.074C20.8105 20.2559 20.6997 20.4211 20.56 20.56L11.828 11.828C11.0779 11.0781 10.0607 10.6569 9 10.6569C7.93934 10.6569 6.92211 11.0781 6.172 11.828L3 15V4.5C3 4.10218 3.15804 3.72064 3.43934 3.43934C3.72064 3.15804 4.10218 3 4.5 3Z" 
                fill={style.hasOwnProperty("color") ? style.color : "#282828"}/>
                <path d="M15.4999 9.99969C16.8806 9.99969 17.9999 8.88041 17.9999 7.49969C17.9999 6.11898 16.8806 4.99969 15.4999 4.99969C14.1192 4.99969 12.9999 6.11898 12.9999 7.49969C12.9999 8.88041 14.1192 9.99969 15.4999 9.99969Z" 
                fill={style.hasOwnProperty("color") ? style.color : "#282828"}/>
            </g>
            <defs>
                <clipPath id="clip0_3863_4908">
                    <rect
                        width={style.hasOwnProperty("size") ? style.size : 24}
                        height={style.hasOwnProperty("size") ? style.size : 24}
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}

export default DatasetIMGIcon;
