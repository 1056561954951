function TableIcon({ style }) {
  if (style == null) {
    style = [];
  }

  return (
    <svg
      width={style.hasOwnProperty("size") ? style.size : 24}
      height={style.hasOwnProperty("size") ? style.size : 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M19 0H5C3.67441 0.00158786 2.40356 0.528882 1.46622 1.46622C0.528882 2.40356 0.00158786 3.67441 0 5L0 19C0.00158786 20.3256 0.528882 21.5964 1.46622 22.5338C2.40356 23.4711 3.67441 23.9984 5 24H19C20.3256 23.9984 21.5964 23.4711 22.5338 22.5338C23.4711 21.5964 23.9984 20.3256 24 19V5C23.9984 3.67441 23.4711 2.40356 22.5338 1.46622C21.5964 0.528882 20.3256 0.00158786 19 0V0ZM16 11H13V8H16V11ZM18 8H22V11H18V8ZM13 6V2H16V6H13ZM11 6H8V2H11V6ZM11 8V11H8V8H11ZM6 11H2V8H6V11ZM2 13H6V16H2V13ZM8 13H11V16H8V13ZM11 18V22H8V18H11ZM13 18H16V22H13V18ZM13 16V13H16V16H13ZM18 13H22V16H18V13ZM22 5V6H18V2H19C19.7956 2 20.5587 2.31607 21.1213 2.87868C21.6839 3.44129 22 4.20435 22 5ZM5 2H6V6H2V5C2 4.20435 2.31607 3.44129 2.87868 2.87868C3.44129 2.31607 4.20435 2 5 2ZM2 19V18H6V22H5C4.20435 22 3.44129 21.6839 2.87868 21.1213C2.31607 20.5587 2 19.7956 2 19ZM19 22H18V18H22V19C22 19.7956 21.6839 20.5587 21.1213 21.1213C20.5587 21.6839 19.7956 22 19 22Z"
          fill={style.hasOwnProperty("color") ? style.color : "#282828"}
        />
      </g>
      {/* <defs>
        <clipPath id="clip0">
          <rect
            width={style.hasOwnProperty("size") ? style.size : 24}
            height={style.hasOwnProperty("size") ? style.size : 24}
            fill="white"
          />
        </clipPath>
      </defs> */}
    </svg>
  );
}

export default TableIcon;
