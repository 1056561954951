import "./../../assets/css/views/screens/mydatasets.css";
import dp from "../../helpers/data_parse";
import SearchBar from "./../../components/SearchBar.jsx";
import { Card } from "../../components/Card";
import { useCallback, useEffect, useState } from "react";
import {
   Link,
   Route,
   Switch,
   useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import { useSelector, useDispatch } from "react-redux";
import { Popup } from "../../components/Popup";
import NewDatasetPopup from "../popups/creations/NewDataset/NewDatasetPopup";
import datasetService from "../../services/DatasetService";
import CheckboxLabeled from "../../components/CheckboxLabeled";
import TrashIcon from "../../assets/icons/jsxIcons/TrashIcon.jsx";
import { DeletePopup } from "../popups/actions/DeletePopup.jsx";
import {
   selectDataSources,
   selectNeedUpdateMyDatasources,
   setDataSources,
   setNeedUpdateMyDatasources,
} from "../../store/slices/cache/cacheSlice.js";

import { useTranslation } from "react-i18next";
import "./../../translation/i18n";
import handleErrorResponse from "../../helpers/handleErrorResponse.js";
import { Loader } from "semantic-ui-react";

function MyDatasets() {
   const { t } = useTranslation();

   // dataSources armazenado no redux(cached data)
   const dataSources = useSelector(selectDataSources);

   const needUpdateMyDataSources = useSelector(selectNeedUpdateMyDatasources);

   const [cardsDatasets, setCardsDatasets] = useState([]);
   // State que armazena as opcões de ordenação
   const [optionSort, setOptionSort] = useState("Mais recente");
   // State que armazena o termo procurado na search bar
   const [searchedTerm, setSearchedTerm] = useState("");
   // Termo com 1 segundo de delay
   const [debouncedTerm, setDebouncedTerm] = useState("");
   // Trigger que ativa o popup de novo dataset
   const [newDatasetPopup, setNewDatasetPopup] = useState(false);
   // State com os ids dos dataSources selecionados
   const [selectedDatasets, setSelectedDatasets] = useState([]);
   //flag que define se select all está ativo
   const [isSelectedActive, setIsSelectedActive] = useState(false);
   // Trigger para abrir o pop-up de deletar dataSources
   const [deleteDatasetPopup, setDeleteDatasetPopup] = useState(false);

   const [isLoading, setIsLoading] = useState(false);

   const history = useHistory();

   const dispatch = useDispatch();

   const getsourcelist = useCallback(() => {
      setIsLoading(true);
      datasetService
         .getsourcelist()
         .then((data) => dispatch(setDataSources(data)))
         .catch((error) => {
            handleErrorResponse(history, error, t);
         })
         .finally(() => setIsLoading(false));
   }, [dispatch, history, t]);

   const handleDatasetsSelection = useCallback(
      (e, datasetId) => {
         e?.preventDefault();
         // Verifico se o projeto já está selecionado
         const isSelected = selectedDatasets.includes(datasetId);

         if (isSelected) {
            // Se estiver selecionado, removo da lista de projetos selecionados
            setSelectedDatasets(
               selectedDatasets.filter((id) => id !== datasetId)
            );
         } else {
            // Se não estiver selecionado, adiciono à lista de projetos selecionados
            setSelectedDatasets([...selectedDatasets, datasetId]);
         }
      },
      [selectedDatasets]
   );

   // Primeiro é chamado searchSort, com o retorno é filtrado e depois renderizado com map
   useEffect(() => {
      if (dataSources?.length > 0) {
         setCardsDatasets(
            dp
               .searchSort(optionSort, dataSources)
               .filter((value) =>
                  value.name
                     .toLowerCase()
                     .trim()
                     .includes(searchedTerm.toLowerCase().trim())
               )
               .map((data) => (
                  <Link
                     key={data.id}
                     to={!isSelectedActive && `/datasource/${data.id}`}
                     onClick={(e) => {
                        if (e.ctrlKey || e.metaKey || isSelectedActive) {
                           handleDatasetsSelection(e, data.id);
                           !isSelectedActive && setIsSelectedActive(true);
                        }
                     }}
                  >
                     <Card
                        type={"datasource"}
                        date={data.date}
                        name={data.name}
                        projects={data.projectNamesList}
                        elementId={data.id}
                        dataType={data.DataType ? data.DataType : "document"}
                     />
                  </Link>
               ))
         );
      } else {
         setCardsDatasets(
            <span className="noDatasets">{t("mydatasets.no_dataset")}</span>
         );
      }
   }, [
      dataSources,
      handleDatasetsSelection,
      isSelectedActive,
      optionSort,
      searchedTerm,
      selectedDatasets,
      t,
   ]);

   useEffect(() => {
      if (needUpdateMyDataSources) {
         getsourcelist();
         dispatch(setNeedUpdateMyDatasources(false));
      }
   }, [dataSources, dispatch, getsourcelist, needUpdateMyDataSources]);

   // Primeiro é chamado searchSort, com o retorno é filtrado e depois renderizado com map
   useEffect(() => {
      if (dataSources?.length > 0) {
         setCardsDatasets(
            dp
               .searchSort(optionSort, dataSources)
               .filter((value) =>
                  value.name
                     .toLowerCase()
                     .trim()
                     .includes(searchedTerm.toLowerCase().trim())
               )
               .map((data) => (
                  <Link
                     key={data.id}
                     to={!isSelectedActive && `/datasource/${data.id}`}
                     onClick={(e) => {
                        if (e.ctrlKey || e.metaKey || isSelectedActive) {
                           handleDatasetsSelection(e, data.id);
                           !isSelectedActive && setIsSelectedActive(true);
                        }
                     }}
                  >
                     <Card
                        type={"datasource"}
                        date={data.date}
                        name={data.name}
                        projects={data.projectNamesList}
                        elementId={data.id}
                        dataType={data.DataType ? data.DataType : "document"}
                     />
                  </Link>
               ))
         );
      } else {
         setCardsDatasets(
            <span className="noDatasets">{t("mydatasets.no_dataset")}</span>
         );
      }
   }, [
      dataSources,
      optionSort,
      searchedTerm,
      isSelectedActive,
      selectedDatasets,
      handleDatasetsSelection,
      t,
   ]);

   // Uma forma de evitar que o searchTerm alterne a cada letra digitada
   useEffect(() => {
      const timer = setTimeout(() => {
         setSearchedTerm(debouncedTerm);
      }, 1000);
      return () => clearTimeout(timer);
   }, [debouncedTerm]);

   const renderCards = () => {
      if (isSelectedActive) {
         return cardsDatasets.map((dataset) => (
            <div
               key={dataset.key}
               className={`project-card background ${
                  selectedDatasets.includes(dataset.key) ? "selected" : ""
               }`}
            >
               <input
                  type="checkbox"
                  className="checkbox"
                  checked={selectedDatasets.includes(dataset.key)}
                  onChange={() =>
                     handleDatasetsSelection(undefined, dataset.key)
                  }
               />
               {dataset}
            </div>
         ));
      } else {
         return cardsDatasets;
      }
   };

   return (
      <Switch>
         <Route path="/mydatasets">
            <div className="containerMyDatasets">
               <div className="title">
                  <p>{t("mydatasets.header.title")}</p>
               </div>
               <header className="headerMyDatasets">
                  <SearchBar
                     onChange={(e) => {
                        setDebouncedTerm(e.target.value);
                     }}
                     onChangeOption={setOptionSort}
                     enableSort={true}
                  />
                  <button onClick={() => setNewDatasetPopup(true)}>
                     {t("mydatasets.header.button")}
                  </button>
               </header>
               <div className="checkbox-labeled">
                  <div className="container-checkbox">
                     {isSelectedActive && selectedDatasets.length > 0 && (
                        <button
                           className="trash-icon"
                           onClick={() => setDeleteDatasetPopup(true)}
                        >
                           <TrashIcon />
                        </button>
                     )}
                     {cardsDatasets.length > 0 && (
                        <CheckboxLabeled
                           isSelectedActive={isSelectedActive}
                           setIsSelectedActive={setIsSelectedActive}
                           setSelectedIds={setSelectedDatasets}
                           cards={dataSources}
                        />
                     )}
                  </div>
               </div>
               <div className="contentMyDatasets">
                  {cardsDatasets.length ? (
                     renderCards()
                  ) : (
                     <p className="noDatasets">
                        {isLoading ? (
                           <Loader active />
                        ) : (
                           t("mydatasets.no_dataset")
                        )}
                     </p>
                  )}
               </div>
               <Popup trigger={newDatasetPopup} setTrigger={setNewDatasetPopup}>
                  <NewDatasetPopup
                     setPopupTrigger={setNewDatasetPopup}
                     isProjectPage={false}
                  />
               </Popup>
               <Popup
                  trigger={deleteDatasetPopup}
                  setTrigger={setDeleteDatasetPopup}
               >
                  <DeletePopup
                     cardtype={"datasource"}
                     modelResultId={selectedDatasets}
                     setSelectedArray={setSelectedDatasets}
                     setTrigger={setDeleteDatasetPopup}
                     setIsSelectedActive={setIsSelectedActive}
                  />
               </Popup>
            </div>
         </Route>
      </Switch>
   );
}

export default MyDatasets;
