import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  projectList: [],
  needUpdateProjectList: false,
};

export const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    setProjectList: (state, action) => {
      state.projectList = action.payload;
    },
    setNeedUpdateProjectList: (state, action) => {
      state.needUpdateProjectList = action.payload;
    },
  },
});

export const { setProjectList, setNeedUpdateProjectList } =
  projectsSlice.actions;

export const selectProjectList = (state) => state.projects.projectList;
export const selectNeedUpdateProjectList = (state) =>
  state.projects.needUpdateProjectList;

export default projectsSlice.reducer;
