import * as d3 from "d3";
import React, { useState, useEffect, useRef } from "react";

import "../../assets/css/graphicsComponents/others/piechartd3.css";

import dp from "../../helpers/data_parse";

// adotar a ideia do Eduardo pras cores (?)

export const PieChart = ({ fieldList, significances }) => {
  const [data, setData] = useState([significances]);
  const pieChart = useRef();

  const colorScheme = [
    // 'rgba(57, 40, 104, 1)',
    // 'rgba(30, 22, 99, 1)',
    // 'rgba(3, 4, 94, 1)',
    "rgba(73, 47, 117, 1)",
    "rgba(38, 55, 128, 1)",
    "rgba(2, 62, 138, 1)",
    // 'rgba(89, 54, 130, 1)',
    // 'rgba(45, 87, 156, 1)',
    // 'rgba(0, 119, 182, 1)',
    "rgba(105, 61, 143, 1)",
    "rgba(53, 106, 171, 1)",
    "rgba(0, 150, 199, 1)",
    // 'rgba(120, 67, 155, 1)',
    // 'rgba(60, 124, 186, 1)',
    // 'rgba(0, 180, 216, 1)',
    "rgba(149, 101, 178, 1)",
    "rgba(111, 152, 203, 1)",
    "rgba(72, 202, 228, 1)",
    "rgba(177, 134, 201, 1)",
    "rgba(161, 179, 220, 1)",
    "rgba(144, 224, 239, 1)",
    "rgba(205, 167, 224, 1)",
    "rgba(189, 200, 234, 1)",
    "rgba(173, 232, 244, 1)",
    "rgba(233, 200, 247, 1)",
    "rgba(218, 220, 248, 1)",
    "rgba(202, 240, 248, 1)",
  ];

  // função pra colorir os nós
  function PieChartKPI(d) {
    if (fieldList !== null && fieldList !== undefined) {
      for (let i = 0; i < fieldList.length; i++) {
        if (i > colorScheme.length) {
          return colorScheme[colorScheme.length - (i % colorScheme.length)];
        } else if (fieldList[i] === d.data.Feature) {
          return colorScheme[i];
        }
      }
    }
    return "#eaff00";
  }

  function desenharPieChart() {
    // pegando posições de cada data
    const piedata = d3.pie().value((d) => d.Value)(significances);

    // definindo arcos
    const arc = d3.arc().innerRadius(0).outerRadius(200);

    // definindo tamanho e posição do svg
    const svg = d3
      .select(pieChart.current)
      .attr("width", 500)
      .attr("height", 500)
      .append("g")
      .attr("transform", "translate(" + 250 + "," + 250 + ")");

    // desenhando pie
    svg
      .append("g")
      .selectAll("path")
      .data(piedata)

      .join("path")
      .attr("d", arc)

      .attr("fill", function (d) {
        return PieChartKPI(d);
      })
      .attr("stroke", "white")
      .on("mouseover", (e, d) => {
        tooldiv
          .style("visibility", "visible")
          .text(
            `${d.data.Feature} : ${dp.removeDecimals(d.data.Value * 100)}%`
          );
      })
      .on("mousemove", (e, d) => {
        tooldiv.style("top", e.pageY - 200 + "px");
        tooldiv.style("left", e.pageX - 500 + "px");
      })
      .on("mouseout", () => {
        tooldiv.style("visibility", "hidden");
      });

    // tooltip
    const tooldiv = d3
      .select("#chartArea")
      .append("div")
      .style("visibility", "hidden")
      .style("position", "absolute")
      .style("background-color", "#F2F2F2")
      .style("border", "solid")
      .style("border-Radius", "5px");
  }

  useEffect((significances) => {
    setData(significances);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    desenharPieChart();
    // eslint-disable-next-line
  }, [data]);

  return (
    <div id="chartArea">
      <svg ref={pieChart}></svg>
    </div>
  );
};
