/* eslint-disable jsx-a11y/alt-text */
import React from "react";

import { useTranslation } from "react-i18next"
import "./../../translation/i18n";

import "../../assets/css/views/home/about.css";


export const About = () => {

  const {t} = useTranslation();

  return (
    <div className="about">
      <div className="title">
        <h1>{t("home.about.header")}</h1>
      </div>
      <div className="description">
        <div id="text-1">{t("home.about.content.div_1")}{" "}</div>
        <div id="text-2">{t("home.about.content.div_2")}{" "}</div>
        <div id="text-3">{t("home.about.content.div_3")}</div>
      </div>
      <div className="image">
        <img src={require("../../assets/images/tree.svg").default} />
      </div>
    </div>
  );

};
