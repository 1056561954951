function ArrowRightIcon({ style }) {
  if (style == null) {
    style = [];
  }

  return (
    <svg
      width={style.hasOwnProperty("size") ? style.size : 24}
      height={style.hasOwnProperty("size") ? style.size : 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4 9.879L10.811 5.293C10.6206 5.12084 10.3713 5.02849 10.1147 5.03508C9.85805 5.04167 9.61379 5.14669 9.43247 5.3284C9.25115 5.5101 9.14665 5.75458 9.14061 6.01121C9.13456 6.26783 9.22744 6.51696 9.4 6.707L13.986 11.293C14.1735 11.4805 14.2788 11.7348 14.2788 12C14.2788 12.2652 14.1735 12.5195 13.986 12.707L9.4 17.293C9.21249 17.4806 9.10721 17.7351 9.1073 18.0004C9.10739 18.2656 9.21286 18.52 9.4005 18.7075C9.58814 18.895 9.84259 19.0003 10.1079 19.0002C10.3731 19.0001 10.6275 18.8946 10.815 18.707L15.4 14.121C15.9624 13.5584 16.2784 12.7955 16.2784 12C16.2784 11.2045 15.9624 10.4416 15.4 9.879Z"
        fill={style.hasOwnProperty("color") ? style.color : "#282828"}
      />
    </svg>
  );
}

export default ArrowRightIcon;
