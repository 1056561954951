import React from "react";

const AlgorithmButton = ({ name, typeSelected, icon: Icon, setShowTarget, setNameAlgorithm, nameAlgorithm }) => {
   return (
      <button
         onClick={() => {
            setNameAlgorithm(name);
            setShowTarget(typeSelected !== "unsupervised");
         }}
         className={nameAlgorithm === name ? "selected" : ""}
      >
         <Icon style={{ size: 18, color: typeSelected === name ? "#FDFDFD" : "#282828" }} />
         <p>{name}</p>
      </button>
   );
};

export default AlgorithmButton;
