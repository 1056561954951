function DatabaseAddIcon({ style }) {
  if (style == null) {
    style = [];
  }

  return (
    <svg
      width={style.hasOwnProperty("size") ? style.size : 24}
      height={style.hasOwnProperty("size") ? style.size : 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.6842 13.8947H20.2105V17.6842H24V20.2105H20.2105V24H17.6842V20.2105H13.8947V17.6842H17.6842V13.8947ZM10.1053 0C15.6884 0 20.2105 2.26105 20.2105 5.05263C20.2105 7.84421 15.6884 10.1053 10.1053 10.1053C4.5221 10.1053 0 7.84421 0 5.05263C0 2.26105 4.5221 0 10.1053 0ZM0 7.57895C0 10.3705 4.5221 12.6316 10.1053 12.6316C15.6884 12.6316 20.2105 10.3705 20.2105 7.57895V11.4695L18.9474 11.3684C15.6758 11.3684 12.8842 13.44 11.8232 16.3453L10.1053 16.4211C4.5221 16.4211 0 14.16 0 11.3684V7.57895ZM0 13.8947C0 16.6863 4.5221 18.9474 10.1053 18.9474H11.3684C11.3684 20.2737 11.7095 21.5242 12.3158 22.6105L10.1053 22.7368C4.5221 22.7368 0 20.4758 0 17.6842V13.8947Z"
        fill={style.hasOwnProperty("color") ? style.color : "#282828"}
      />
    </svg>
  );
}

export default DatabaseAddIcon;
