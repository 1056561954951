import * as d3 from "d3";
import React, { useCallback, useEffect, useRef } from "react";

import "../../assets/css/graphicsComponents/modelsRepresentations/randomforestd3.css";

export const RandomForestD3 = ({
  result,
  fieldList,
  selectedTree,
  setSelectedTree,
  setCaminhoAtual,
}) => {
  if (selectedTree == null || selectedTree === undefined) {
    setSelectedTree(0);
  }

  const handleUserZoom = useCallback((event) => {
    if (event.ctrlKey) {
      event.preventDefault();
    }
  }, []);

  useEffect(() => {
    // listener de comando de mouse
    document.getElementById("root").addEventListener("wheel", handleUserZoom);

    return () => {
      document
        .getElementById("root")
        .removeEventListener("wheel", handleUserZoom);
    };
  }, [handleUserZoom]);

  // vetor com as paletas de cores indicadas no figma
  const colorScheme = [
    // 'rgba(57, 40, 104, 1)',
    // 'rgba(30, 22, 99, 1)',
    // 'rgba(3, 4, 94, 1)',
    "rgba(73, 47, 117, 1)",
    "rgba(38, 55, 128, 1)",
    "rgba(2, 62, 138, 1)",
    // 'rgba(89, 54, 130, 1)',
    // 'rgba(45, 87, 156, 1)',
    // 'rgba(0, 119, 182, 1)',
    "rgba(105, 61, 143, 1)",
    "rgba(53, 106, 171, 1)",
    "rgba(0, 150, 199, 1)",
    // 'rgba(120, 67, 155, 1)',
    // 'rgba(60, 124, 186, 1)',
    // 'rgba(0, 180, 216, 1)',
    "rgba(149, 101, 178, 1)",
    "rgba(111, 152, 203, 1)",
    "rgba(72, 202, 228, 1)",
    "rgba(177, 134, 201, 1)",
    "rgba(161, 179, 220, 1)",
    "rgba(144, 224, 239, 1)",
    "rgba(205, 167, 224, 1)",
    "rgba(189, 200, 234, 1)",
    "rgba(173, 232, 244, 1)",
    "rgba(233, 200, 247, 1)",
    "rgba(218, 220, 248, 1)",
    "rgba(202, 240, 248, 1)",
  ];

  // inicializa um Ref
  const d3Chart = useRef();

  let caminhoTree = [];

  let predictTrueColor = "rgb(87,204,153)";
  let predictFalseColor = "rgb(214, 34, 70)";

  // Key Performance Indicator (indicador de performance)
  // comumente usado para atribuir cores referentes aos valores
  function DecisionTreeKPI(d) {
    if (d.data.Feature === undefined) {
      if (d.data.Predict === "1.0") {
        return predictTrueColor;
      } else {
        return predictFalseColor;
      }
    } else if (fieldList != null && fieldList !== undefined) {
      for (let i = 0; i < fieldList.length; i++) {
        if (i > colorScheme.length) {
          return colorScheme[colorScheme.length - (i % colorScheme.length)];
        } else if (fieldList[i] === d.data.Feature) {
          return colorScheme[i];
        }
      }
    }
    return "#404040";
  }

  // função que desenha o line chart de fato - feito em função pra poder ser chamado tanto no use effect
  // onMount quanto no side effect que é ativado quando o secondary target for alterado
  function desenharRandomForest() {
    // garantindo que não vai dar erro no retorno do backend
    let isGrabbing = false;
    let isZooming = false;
    let lastTransform = null;

    if (
      result === null ||
      !result.hasOwnProperty("trees") ||
      result.trees === undefined
    ) {
      return;
    }
    if (result.trees === undefined) {
      return;
    }
    if (fieldList == null) {
      return;
    }

    d3.select("#randomforest-graph").remove();
    d3.select("#randomforest-links").remove();
    d3.select("#randomforest-nodes").remove();
    d3.zoom().scaleExtent([0.5, 5]);

    const margin = {top: -55, right: 10, bottom: 90, left: -30};
    const width = parseInt(d3.select(".model-result-1").style("width"), 10) - margin.left - margin.right;
    const height = parseInt(d3.select(".model-result-1").style("height"), 10) - margin.top - margin.bottom;
    const svg = d3
      .select(d3Chart.current)
      .attr("viewBox", `0 0 ${width} ${height}`) // Define a caixa de visualização
      .attr("preserveAspectRatio", "xMidYMid meet") // Mantém a proporção do gráfico
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Apenas cria um grupo do gráfico (contem grupos nodes e links, e desloca a posição)
    var graph = svg
      .append("g")
      .attr("id", "randomforest-graph")
      .attr("transform", "translate(50,50)");

    // d3.select("#randomforestd3-result")
    //   .on("mousedown", function () {
    //     // Botão do mouse esquerdo pressionado
    //     isGrabbing = true;
    //     d3.select("body").style("cursor", "grab");
    //   })
    //   .on("mouseup", function () {
    //     // Botão do mouse esquerdo liberado
    //     isGrabbing = false;
    //     d3.select("body").style("cursor", "default");
    //   })
    //   .on("wheel", function () {
    //     isZooming = true;
    //     isGrabbing = true
    //     d3.select("body").style("cursor", "default");
    //   });

    // Gera hierarquia
    var hierarchy = d3.hierarchy(result.trees[selectedTree]);

    // Cria a estrutura da Árvore
    // ver quantas têm
    // dividir? nesse número
    // -> identar resultado contando
    var tree = d3.tree().size([width - 40, height - 0]);

    // Popula a Árvore com os dados
    var myTree = tree(hierarchy);

    // Obtém a largura e altura máximas da árvore gerada
    // var treeWidth =
    //   d3.max(myTree.descendants(), (d) => d.x) + margin.left + margin.right;
    // var treeHeight =
    //   d3.max(myTree.descendants(), (d) => d.y) + margin.top + margin.bottom;

    // // Calcula o número total de nós na árvore
    // var totalNodes = myTree.descendants().length;

    // // // Calcula o raio médio desejado dos nós
    //  var averageNodeRadius;

    // if (totalNodes > 150) {
    //   averageNodeRadius = Math.min(
    //     (width - margin.left - margin.right) / (totalNodes / 5), // Dividido por 5 para garantir espaço para todos os nós
    //     (height - margin.top - margin.bottom) / (totalNodes / 5)
    //   );
    // } else {
    //   averageNodeRadius = Math.min(
    //     (width - margin.left - margin.right) / (totalNodes / 3), // Dividido por 2 para garantir espaço para todos os nós
    //     (height - margin.top - margin.bottom) / (totalNodes / 3)
    //   );
    // }

    // // Calcula a escala necessária para caber dentro do espaço definido
    // var scaleX = width / treeWidth;
    // var scaleY = height / treeHeight;
    // var scale = Math.min(scaleX, scaleY);

    // svg.attr(
    //   "transform",
    //   "translate(" + margin.left + "," + margin.top + ") scale(" + scale + ")"
    // );

    // tree.size([
    //   (width - margin.left - margin.right) / scale,
    //   (height - margin.top - margin.bottom) / scale,
    // ]);

    // Popula a Árvore com os dados novamente usando a nova configuração de tamanho
    myTree = tree(hierarchy);

    // Faz uma seleção para os nós e associa dados
    var nodes = graph.selectAll(".node").data(myTree.descendants());

    // get link selection and join data
    var links = graph.selectAll(".link").data(myTree.links());

    // adicionar ligações
    links
      .enter()
      .append("path")
      .attr("id", "randomforest-links")
      .transition()
      .duration(300)
      .attr("class", "link")
      .attr("fill", "none")
      .attr("stroke", "#aaa")
      .attr("stroke-width", 2)
      .attr(
        "d",
        d3
          .linkVertical()
          .x((d) => d.x)
          .y((d) => d.y)
      );

    // evento de hover do mouse
    nodes
      .enter()
      .append("g")
      .attr("id", "randomforest-nodes")
      .on("mouseover", function (d) {
        var current = d3.select(this);
        current
          .append("g")
          //.append('text')
          .classed("info", true)
          .attr("x", -40)
          .attr("y", 45)
          .text((d) => {
            let nodeAux = d;
            while (nodeAux != null) {
              let folha = {};
              if (nodeAux.data.hasOwnProperty("Predict"))
                folha = { Feature: "Predict", Threshold: nodeAux.data.Predict };
              else
                folha = {
                  Feature: nodeAux.data.Feature,
                  Threshold: nodeAux.data.Threshold,
                  Side: nodeAux.data.Side,
                  Operation: nodeAux.data.Operation,
                };
              caminhoTree.unshift(folha);
              nodeAux = nodeAux.parent;
            }
            setCaminhoAtual(caminhoTree);

            if (d.data.Feature !== undefined) {
              return d.data.Feature + " / " + d.data.Threshold;
            } else {
              return d.data.Predict;
            }
          });
      })
      .on("mouseout", function () {
        caminhoTree = [];
      })
      .attr("class", "node")
      .attr("transform", (d) => `translate(${d.x},${d.y})`)
      .append("circle")
      .attr("r", 15)
      .attr("fill", function (d) {
        return DecisionTreeKPI(d);
      });

    // d3.select("#randomforestd3-result").call(
    //   d3
    //     .zoom()
    //     .extent([
    //       [0, 0],
    //       [width, height],
    //     ])
    //     .scaleExtent([scale, 3]) // Ajuste os limites de escala conforme necessário
    //     .on("zoom", zoomed)
    // );

    function zoomed({ sourceEvent, transform }) {
      if (lastTransform !== null) {
        if (transform.k - lastTransform.k !== 0 && isZooming) {
          svg.attr("transform", transform);

          let direction = transform.k - lastTransform.k < 0 ? "down" : "up";

          if (direction === "down") {
            d3.select("body").style("cursor", "vertical-text");
          } else {
            d3.select("body").style("cursor", "crosshair");
          }
        } else if (
          (transform.x - lastTransform.x !== 0 ||
            transform.y - lastTransform.y !== 0) &&
          isGrabbing
        ) {
          svg.attr("transform", transform);
        }
      } else {
        svg.attr("transform", transform);
      }
      lastTransform = transform;
    }
  }

  useEffect(() => {
    desenharRandomForest();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    desenharRandomForest();
    // eslint-disable-next-line
  }, [selectedTree, fieldList, result]);

  return (
      <svg ref={d3Chart} id="randomforestd3-result"/>
  );
};
