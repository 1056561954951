import Highcharts from 'highcharts'
import highcharts3d from "highcharts/highcharts-3d";
highcharts3d(Highcharts); //init module

//Give the points a 3D feel by adding a radial gradient
Highcharts.setOptions({
    colors: Highcharts.getOptions().colors.map(function (color) {
        return {
            radialGradient: {
                cx: 0.4,
                cy: 0.3,
                r: 0.5
            },
            stops: [
                [0, color],
                [1, Highcharts.color(color).brighten(-0.2).get('rgb')]
            ]
        };
    })
});

function createGraphic(data){
    let series = [];
    let result = data;
    const color = ["#22577A", "#EB5757", "yellow", "green"];
    for (let i = 0; i < result?.series.length; i++) {
        let serie = result.series[i];
        series.push({
            name: serie.name.toString(),
            colorByPoint: false,
            accessibility: {
                exposeAsGroupOnly: true
            },
            color : color[series.length],
            data: serie.data
            });
        }
        if(result?.axis.length < 3){
            let len = result.axis.length;
            for(let i = len; i < 3; i++){
                result.axis.push([0, 0]);
            }
        }
    const chart = new Highcharts.Chart({
        chart: {
            renderTo: 'container',
            margin: 10,
            type: 'scatter3d',
            animation: false,
            backgroundColor: "#FDFDFD",
            options3d: {
                enabled: true,
                alpha: 10,
                beta: 30,
                depth: 500,
                viewDistance: 5,
                fitToPlot: false,
            }
        },
        title: {
            text: ''
        },
        plotOptions: {
            scatter: {
                width: 10,
                height: 10,
                depth: 100,
            },
        },
        yAxis: {
            min: result.axis[1][0],
            max: result.axis[1][1],
            title: null,
            gridLineWidth: 0,
            visible: false,
        },
        xAxis: {
            min: result.axis[0][0],
            max: result.axis[0][1],
            gridLineWidth: 0,
            visible: false,
        },
        zAxis: {
            min: result.axis[2][0],
            max: result.axis[2][1],
            gridLineWidth: 0,
            visible: false,
            showFirstLabel: false,
        },
        tooltip: {
            enabled: false,
        },

        legend: {
            enabled: true,
        },
        accessibility: {
            enabled: false
        },
        series: series
    });

    // Add mouse and touch events for rotation
    (function (H) {
        function dragStart(eStart) {
            eStart = chart.pointer.normalize(eStart);

            const posX = eStart.chartX,
                posY = eStart.chartY,
                alpha = chart.options.chart.options3d.alpha,
                beta = chart.options.chart.options3d.beta,
                sensitivity = 5,  // lower is more sensitive
                handlers = [];

            function drag(e) {
                // Get e.chartX and e.chartY
                e = chart.pointer.normalize(e);

                chart.update({
                    chart: {
                        options3d: {
                            alpha: alpha + (e.chartY - posY) / sensitivity,
                            beta: beta + (posX - e.chartX) / sensitivity
                        }
                    }
                }, undefined, undefined, false);
            }

            function unbindAll() {
                handlers.forEach(function (unbind) {
                    if (unbind) {
                        unbind();
                    }
                });
                handlers.length = 0;
            }

            handlers.push(H.addEvent(document, 'mousemove', drag));
            handlers.push(H.addEvent(document, 'touchmove', drag));


            handlers.push(H.addEvent(document, 'mouseup', unbindAll));
            handlers.push(H.addEvent(document, 'touchend', unbindAll));
        }
        H.addEvent(chart.container, 'mousedown', dragStart);
        H.addEvent(chart.container, 'touchstart', dragStart);
    }(Highcharts));
}

const SVMHighcharts = {
    createGraphic,
}

export default SVMHighcharts;
