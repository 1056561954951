import React from "react";
import ArrowDownFilledIcon from "../../../../../assets/icons/jsxIcons/ArrowDownFilledIcon";
import ArrowRightFilledIcon from "../../../../../assets/icons/jsxIcons/ArrowRightFilledIcon";
import AlgorithmButton from "./AlgorithmButton";
import { useTranslation } from "react-i18next";

const Section = ({
   sectionName,
   algorithms,
   displayId,
   typeSelected,
   toggleSelection,
   link,
   setShowTarget,
   nameAlgorithm,
   setNameAlgorithm,
}) => {
   const { t } = useTranslation();
   return (
      <section>
         <button className="section-button" onClick={toggleSelection}>
            <p>{t(`${link}.algorithms.header.${sectionName}`)}</p>
            {typeSelected === sectionName ? (
               <ArrowDownFilledIcon style={{ size: "18px", color: "#282828" }} />
            ) : (
               <ArrowRightFilledIcon style={{ size: "18px", color: "#282828" }} />
            )}
         </button>
         <div style={{ display: typeSelected === sectionName ? "flex" : "none" }} id={displayId}>
            {algorithms.map(({ name, icon: Icon }) => (
               <AlgorithmButton
                  key={name}
                  name={name}
                  typeSelected={typeSelected}
                  icon={Icon}
                  setShowTarget={setShowTarget}
                  nameAlgorithm={nameAlgorithm}
                  setNameAlgorithm={setNameAlgorithm}
               />
            ))}
         </div>
      </section>
   );
};
export default Section;
