import "./../../../../assets/css/views/popups/creations/NewDataset/webadressdataset.css";

function WebAdressDataset(){
    return (
        <div className="containerWebAdressDataset">
            <div className="containerInput">
                <label>Endereço da web</label>
                <input type="text" placeholder="Endereço da web"/>
            </div>
        </div>
    )
}

export default WebAdressDataset;