import "./../../../assets/css/views/popups/predictions/prediction.css"
import FocusIcon from "../creations/ChooseAlgorithmPopUp/assets/icons/jsxIcons/FocusIcon";
import WandIcon from "../../../assets/icons/jsxIcons/WandIcon";
import UploadIcon from "../../../assets/icons/jsxIcons/UploadIcon";
import LoadingWheel from "../../../components/LoadingWheel";
import { DataGrid } from "@material-ui/data-grid";

import predictionService from "../../../services/PredictionService";

import { useTranslation } from "react-i18next"
import "./../../../translation/i18n";
import { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { setRunningTask } from "./../../../store/slices/tasks/tasksSlice";
import handleErrorResponse from "../../../helpers/handleErrorResponse";

function Prediction({
    targetName,
    fields,
    modelId,
    algorithmType
}){

    const {t} = useTranslation();
    const link = "popup.predictions";
    const [predictionSingle, setPredictionSingle] = useState(true);
    const [prediction, setPrediction] = useState(null);
    const [isLoading, setIsLoading] = useState(false); 
    const [file, setFile] = useState(null);
    const [taskId, setTaskId] = useState("");
    const [progress, setProgress] = useState(0);
    const [table, setTable] = useState(null);

    const taskList = useSelector((state) => state.tasks.taskList);
    const history = useHistory();
    const dispatch = useDispatch();


    useEffect(() => {
        if (taskId) {
            let task = taskList.filter((task) => task.task === taskId)[0];
            if (task) {
                setProgress(task.progress);
            } else if (progress <= 100) {
                predictionService.getprediction(taskId)
                .then(data => {
                    toast.success(t(`${link}.predictions.success.makebatchprediction`));
                    data.header = Object.keys(data?.result[0]);
                    data.header = data.header?.map(column => {return {field: column, editable: false}});
                    data.result = data?.result?.map((pre, index) => {return {...pre, id: index}})
                    setTable(data);
                }).catch(error => {
                    handleErrorResponse(history, error, t);
                })
                .finally(() => {
                    setIsLoading(false);
                    setProgress(100);
                })

            }
        }
        // eslint-disable-next-line
    }, [taskList]);

    const filterChar = (value) => {
        const index = value.length - 1;
        if(index >= 0){
            const char = value[index];
            if((char.charCodeAt() < 48 || char.charCodeAt() > 57) && char.charCodeAt() !== 46){
                value = value.slice(0, index);
            } 
        }
        return value;
    }

    function fieldsList(){
        return fields?.usedFields?.map((name) => {
            let object = fields?.categoricalFields?.find(element => element?.Feature === name);
            if(!object){
                return (
                    <div key={name} className="field">
                        <p>{name}</p>
                        <input 
                            onChange={(e) => {e.target.value = filterChar(e.target.value)}} 
                            id="field-input" 
                            type="text" 
                            placeholder="0.0"
                        />
                    </div>
                )
            }
            object = object?.Values;
            return (
                <div key={name} className="field">
                    <p>{name}</p>
                    <select
                        id="field-select" 
                    >
                        {object?.map((field) => {
                            return <option key={field}>{field}</option>
                        })}
                    </select>
                </div>
            )
        })
    }

    const makePrediction = () => {
        if(!isLoading){
            let fields = document.querySelectorAll(".field")
            let fields_values = {};
            fields.forEach((field) => {
                field = field.children;
                fields_values = {
                    ...fields_values,
                    [field[0].textContent]: field[1]?.value === "" ? 0.0 : parseFloat(field[1]?.value)
                }
            })
            setIsLoading(true);
            predictionService.makesingleprediction(modelId, fields_values)
            .then((data) => {
                setPrediction(data?.Prediction);
                toast.success(t(`${link}.predictions.success.makeprediction`));
            })
            .catch((error) => {
                handleErrorResponse(history, error, t);
            })
            .finally(() => {setIsLoading(false)});
        }
    }

    const makeBatchPrediction = () => {
        try {
            // suporta apenas arquivo csv, por enquanto
            if(!file.type.includes("text/csv")){
                toast.error(t(`${link}.predictions.error.type`));
                return;
            } 
            // Não deixa o usuário importar um arquivo csv maior que 20MB
            const kb = 1024;
            if(file.size / (kb * kb ) > 20){
                toast.error(t(`${link}.predictions.error.size`));
                return; 
            }
        } catch (error) {
            toast.error(t(`${link}.predictions.error.download`));
            return;
        }

        if(!isLoading){
            setIsLoading(true);
            predictionService.makebatchprediction(modelId, file)
            .then(data => {
                if(data){
                    toast.info(t(`${link}.predictions.info.makebacthprediction`));
                    setTaskId(data);
                    setProgress(0);
                    dispatch(setRunningTask(true));
                }
            })
            .catch(error => {
                handleErrorResponse(history, error, t);
                setIsLoading(false);
            });
        }
    }

    const downloadPrediction = () => {
        if(table?.id){
            setIsLoading(true);
            predictionService.predictiondownload(table.id)
            .then(() => {
                toast.success(t(`${link}.predictions.success.download`));
            }).catch((error) => {
                handleErrorResponse(history, error, t);
                toast.error(t(`${link}.predictions.error.download`));
            }).finally(() => {
                setIsLoading(false);
            });
        } else {
            toast.error(t(`${link}.predictions.error.download`));
        }
    }

    const canMakeBatchPrediction = () => {
        const name = algorithmType.toLowerCase();
        // Fala implementar predição em lote no backend
        if(name === "svm" || name === "multilayerperceptron"){
            return false;
        }
        return true;
    }

    return (
        <div className="prediction-popup">
            <header>
                <div className="title"><p>{t(`${link}.predictions.title`)}</p></div>
                <ul>
                    <li>
                        <button 
                            onClick={() => {setPredictionSingle(true)}}
                            style={ predictionSingle ? { padding: "15px 5px"} : {}}
                        >
                            <p style={ predictionSingle ? {color: "#22577A", fontWeight: 600} : {}}>
                                {t(`${link}.predictions.button.single`)}
                            </p>
                        </button>
                    </li>
                    {canMakeBatchPrediction() && (
                        <li>
                            <button 
                                onClick={() => {setPredictionSingle(false)}}
                                style={ !predictionSingle ? { padding: "15px 5px"} : {}}
                            >
                                <p style={ !predictionSingle ? {color: "#22577A", fontWeight: 600} : {}}>
                                    {t(`${link}.predictions.button.batch`)}
                                </p>
                            </button>
                        </li>
                    )}
                </ul>
            </header>
            {isLoading ? (
                <main style={{justifyContent: "center"}}>
                    <LoadingWheel loadingSize="large" progress={progress ? progress : 0}/>
                </main>
            ) : predictionSingle ? (
                <main>
                    <article><p>{t(`${link}.predictions.description`)}</p></article>
                    <div className="single-prediction">
                        <div className="target">
                            <FocusIcon style={{color: "#808080"}}/>
                            <p>{targetName}</p>
                        </div>
                        {fieldsList()}
                    </div>
                    <button onClick={makePrediction}><WandIcon/></button>
                    <div className="answer">
                        <p id="attribute">{targetName}</p>
                        {prediction && <div className="field"><p>{prediction}</p></div>}
                    </div>
                </main>
            ) : !table ? (
                <main style={{justifyContent: "center"}}>
                    <div className="batch-prediction">
                        <input
                            type="file"
                            id="upload"
                            style={{ display: "none" }}
                            accept=".csv"
                            disabled={false}
                            onChange={(e) => {setFile(e?.target?.files ? e.target.files[0] : null)}}
                        />
                            <label htmlFor="upload">
                                <UploadIcon style={{ color: "#FFFFFF"}}/>
                                <p>{t(`${link}.predictions.button.choose`)}</p>
                            </label>
                    </div>
                    <div className="file">
                        {file && (
                            <>
                                <p>{file?.name}</p>
                                <button onClick={makeBatchPrediction}><WandIcon/></button>
                            </>
                        )}
                    </div>
                </main>
            ) : (
                <main className="table">
                    <DataGrid
                        rows={table?.result}
                        columns={table?.header}
                        pageSize={table?.result?.length}
                        disableSelectionOnClick
                        isRowSelectable={false}
                        autoHeight
                        classes={{
                            root: "table",
                        }}
                        loading={isLoading}
                    />
                    <button 
                        id="download"
                        onClick={downloadPrediction}
                    >
                        <p>{t(`${link}.predictions.button.download`)}</p>
                    </button>
                </main>
            )}
        </div>
    )
}

export default Prediction;