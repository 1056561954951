function SVMIcon({ style }) {
    if(style == null){
        style = [];
    }
    return (
        <svg 
            width={style.hasOwnProperty("size") ? style.size : 18} 
            height={style.hasOwnProperty("size") ? style.size : 18}
            viewBox="0 0 18 18" 
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M7.19974 10.08V11.52L17.2797 11.52C17.6774 11.52 17.9997 11.8424 17.9997 12.24C17.9997 12.6376 17.6774 12.96 17.2797 12.96L6.77796 12.96L1.94884 17.7891C1.66767 18.0703 1.21179 18.0703 0.930609 17.7891C0.649432 17.5079 0.649432 17.0521 0.930609 16.7709L5.75975 11.9418V10.08H7.19974Z" fill={style.hasOwnProperty("color") ? style.color : "#FDFDFD"}  />
            <path d="M6.47974 0C6.0821 0 5.75975 0.322355 5.75975 0.72V7.92H7.19974V0.72C7.19974 0.322355 6.87739 0 6.47974 0Z" fill={style.hasOwnProperty("color") ? style.color : "#FDFDFD"}  />
            <path d="M15.4797 1.44C16.0762 1.44 16.5597 1.92353 16.5597 2.52C16.5597 3.11647 16.0762 3.6 15.4797 3.6C14.8833 3.6 14.3997 3.11647 14.3997 2.52C14.3997 1.92353 14.8833 1.44 15.4797 1.44Z" fill={style.hasOwnProperty("color") ? style.color : "#FDFDFD"}  />
            <path d="M16.9197 9.36C17.1186 9.36 17.2797 9.19882 17.2797 9C17.2797 8.80118 17.1186 8.64 16.9197 8.64H1.79974C1.60092 8.64 1.43974 8.80118 1.43974 9C1.43974 9.19882 1.60092 9.36 1.79974 9.36H16.9197Z" fill={style.hasOwnProperty("color") ? style.color : "#FDFDFD"}  />
            <path d="M12.5997 4.32C13.1962 4.32 13.6797 4.80353 13.6797 5.4C13.6797 5.99647 13.1962 6.48 12.5997 6.48C12.0033 6.48 11.5197 5.99647 11.5197 5.4C11.5197 4.80353 12.0033 4.32 12.5997 4.32Z" fill={style.hasOwnProperty("color") ? style.color : "#FDFDFD"}  />
            <path d="M3.23974 4.32C3.83621 4.32 4.31974 4.80353 4.31974 5.4C4.31974 5.99647 3.83621 6.48 3.23974 6.48C2.64328 6.48 2.15974 5.99647 2.15974 5.4C2.15974 4.80353 2.64328 4.32 3.23974 4.32Z" fill={style.hasOwnProperty("color") ? style.color : "#FDFDFD"}  />
            <path d="M15.8397 15.48C15.8397 14.8835 15.3562 14.4 14.7597 14.4C14.1633 14.4 13.6797 14.8835 13.6797 15.48C13.6797 16.0765 14.1633 16.56 14.7597 16.56C15.3562 16.56 15.8397 16.0765 15.8397 15.48Z" fill={style.hasOwnProperty("color") ? style.color : "#FDFDFD"}  />
            <path d="M3.23974 10.8C3.83621 10.8 4.31974 11.2835 4.31974 11.88C4.31974 12.4765 3.83621 12.96 3.23974 12.96C2.64328 12.96 2.15974 12.4765 2.15974 11.88C2.15974 11.2835 2.64328 10.8 3.23974 10.8Z" fill={style.hasOwnProperty("color") ? style.color : "#FDFDFD"}  />
            <path d="M10.7997 15.48C10.7997 14.8835 10.3162 14.4 9.71974 14.4C9.12328 14.4 8.63974 14.8835 8.63974 15.48C8.63974 16.0765 9.12328 16.56 9.71974 16.56C10.3162 16.56 10.7997 16.0765 10.7997 15.48Z" fill={style.hasOwnProperty("color") ? style.color : "#FDFDFD"}  />
            <path d="M10.7997 2.52C10.7997 1.92353 10.3162 1.44 9.71974 1.44C9.12328 1.44 8.63974 1.92353 8.63974 2.52C8.63974 3.11647 9.12328 3.6 9.71974 3.6C10.3162 3.6 10.7997 3.11647 10.7997 2.52Z" fill={style.hasOwnProperty("color") ? style.color : "#FDFDFD"}  />
        </svg>

    )
}

export default SVMIcon;