import styles from "./../../../assets/css/monitoring/views/screens/Java.module.css";
import logoSpring from "./../../../assets/images/spring-logo.png";

import { useState, useEffect } from "react";

import { Popup } from "./../../../components/Popup";

import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, Filler, Title, Tooltip, Legend, PointElement, LineElement } from "chart.js"
import { t } from "i18next";

import LoadingWheel from "../../../components/LoadingWheel";
import logoJava from "./../../../assets/images/java.png";
import logoSpark from "./../../../assets/images/spark.png";
import monitoringService from "../../../services/MonitoringService.js";
import handleErrorResponse from "./../../../helpers/handleErrorResponse";
import { useHistory } from "react-router-dom/cjs/react-router-dom.js";

// cache:
import { useDispatch, useSelector } from "react-redux";
import {
    selectMemoriesJava,
    selectSpringLogs,
    selectSpringLogsUpdated,
    setSpringLogs,
    setSpringLogsUpdated
} from "../../../store/slices/monitoring/monitoringSlice";

function Java() {

    const [isLoading, setIsLoading] = useState(true);
    const [graphic, setGraphic] = useState(false);
    const history = useHistory();
    const link = "monitoring.dashboard.service.java";
    const dispatch = useDispatch();
    const memoriesJava = useSelector(selectMemoriesJava);
    const springLogs = useSelector(selectSpringLogs);
    const springLogsUpdated = useSelector(selectSpringLogsUpdated);
    const [triggerPopup, setTriggerPopup] = useState(false);

    function initiGraphic() {
        if (memoriesJava) {
            try {
                ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Filler, Title, Tooltip, Legend);
                setGraphic({
                    data: {
                        labels: memoriesJava.map(memorie => { return memorie.Time }).reverse(),
                        datasets: [{
                            label: t(`${link}.graphic.buttons.virtual`),
                            data: memoriesJava.map(memorie => { return memorie.VirtualMemUsed }).reverse(),
                            backgroundColor: 'rgba(255, 0, 0, 0.2)',
                            borderColor: 'rgba(255, 0, 0, 0.6)',
                            fill: true,
                            pointRadius: 0,
                            lineTension: 0.2
                        }, {
                            label: t(`${link}.graphic.buttons.resident`),
                            data: memoriesJava.map(memorie => { return memorie.ResidentMemUsed }).reverse(),
                            backgroundColor: 'rgba(0, 0, 255, 0.2)',
                            borderColor: 'rgba(0, 0, 255, 0.6)',
                            fill: true,
                            pointRadius: 0,
                            lineTension: 0.2
                        }]
                    },
                    options: {
                        maintainAspectRatio: false,
                        responsive: true,
                        plugins: {
                            title: {
                                display: true,
                                text: t(`${link}.graphic.title`)
                            },
                            legend: {
                                position: "top"
                            },
                        },
                        scales: {
                            x: {
                                type: 'category',
                                labels: memoriesJava.map(memorie => memorie.Time).reverse(),
                                ticks: {
                                    font: {
                                        size: 8
                                    }
                                },
                                grid: {
                                    display: true,
                                    drawBorder: true,
                                }
                            },
                        }
                    }
                })
            } catch (error) {

            }
        }
    }

    useEffect(() => {
        if (!springLogsUpdated) {
            monitoringService.getspringbootlog()
                .then(data => {
                    dispatch(setSpringLogs(data.result));
                    dispatch(setSpringLogsUpdated(true));
                })
                .catch(error => {
                    handleErrorResponse(history, error, t)
                }).finally(() => { setIsLoading(false) });
        } else {
            setIsLoading(false);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        initiGraphic();
        // eslint-disable-next-line
    }, [springLogsUpdated]);

    const getSpringLogs = () => {
        if (springLogs.length) {
            return springLogs.map(log => {
                return (
                    <button key={log.title} onClick={() => { setTriggerPopup(log.logs) }}>
                        <p>{log.title}</p>
                    </button>
                )
            })
        } else {
            //TODO: internacionalizar
            return (
                <article><span>Não há logs do spring</span></article>
            )
        }
    }

    const getSparkLogs = () => {
        //TODO: internacionalizar
        return (
            <article><span>Não há logs do spark</span></article>
        )
    }

    if (isLoading) {
        return (
            <main className={styles.isloading}>
                <LoadingWheel loadingSize="large" />
            </main>
        )
    } else {
        return (
            <main className={styles.containerjava}>
                <section className={styles.containergraphic}>
                    <div className={styles.graphic}>
                        {graphic && <Line data={graphic.data} options={graphic.options} />}
                    </div>
                    <div className={styles.logo}>
                        <img alt={logoJava} src={logoJava} />
                    </div>
                </section>
                <section className={styles.containerlogs}>
                    <div className={styles.tablelogsspring}>
                        <header>
                            <img src={logoSpring} alt="spring-boot" />
                            <h2>{t(`${link}.spring_boot.header.title`)}</h2>
                        </header>
                        <div className={styles.logs}>{getSpringLogs()}</div>
                    </div>
                    <div className={styles.tablelogspark}>
                        <header>
                            <img src={logoSpark} alt="spark-apache" />
                            <h2>Spark</h2>
                        </header>
                        <div className={styles.logs}>{getSparkLogs()}</div>
                    </div>
                </section>
                {triggerPopup &&
                    <Popup trigger={triggerPopup} setTrigger={setTriggerPopup}>
                        <article className={styles.logspringpopup}>
                            {triggerPopup?.map(log => {
                                return <p key={log}>{log}</p>
                            })}
                        </article>
                    </Popup>
                }
            </main>
        )
    }

}

export default Java;