import ApiKeyPopup from "../views/popups/actions/ApiKeyPopup";
import NewKeyApiPopup from "../views/popups/creations/NewKeyApiPopup";
import SuccessfullyCreatedKeyApiPopup from "../views/popups/actions/SuccessfullyCreatedKeyApiPopup";
import ImplementCodePopup from "../views/popups/actions/ImplementCodePopup";
import EditApiKeyPopup from "../views/popups/actions/EditApiKeyPopup";
import { Popup } from "./Popup";

import { useState, useEffect } from "react"

function ApiPopup({
    trigger, 
    setTrigger, 
    token,
    modelID,
}){

    /** Pop-up de chave api */
    const [apiKeyPopup, setApiKeyPopup] = useState(false);
    /** Pop-up de nova chave api */
    const [newKeyApiPopup, setNewKeyApiPopup] = useState(false);
    /**Popup de chave de api criada com sucesso */
    const [successfullyCreatedKeyPopup, setSuccessfullyCreatedKeyPopup] = useState(false);
    /**Pop-up de implementação do codígo */
    const [implementCodePopup, setImplementCodePopup] = useState(false);
    /**Pop-up de edição da chave api */
    const [editApiKeyPopup, setEditApiKeyPopup] = useState(false);
    /**Chave api para o usuário salvar */
    const [apiKey, setApiKey] = useState("");
    /**Lista de chaves api */
    const [listKeys, setListKeys] = useState([]);

    useEffect(() => {
        if(trigger){
            setApiKeyPopup(true);
            setTrigger(false)
        }
        // eslint-disable-next-line
    }, [trigger]);

    return (
        <>
            <Popup 
                trigger={apiKeyPopup}
                setTrigger={setApiKeyPopup}
            >
                <ApiKeyPopup 
                    setTrigger={setApiKeyPopup}
                    setNewKeyApiPopup={setNewKeyApiPopup}
                    setImplementCodePopup={setImplementCodePopup}
                    modelID={modelID}
                    token={token}
                    apiKey={apiKey}
                    setApiKey={setApiKey}
                    setEditApiKeyPopup={setEditApiKeyPopup}
                    listKeys={listKeys}
                    setListKeys={setListKeys}
                />
            </Popup>

            <Popup
                trigger={newKeyApiPopup}
                setTrigger={setNewKeyApiPopup}
            >
                <NewKeyApiPopup 
                    setApiPopup={setApiKeyPopup}
                    setTrigger={setNewKeyApiPopup}
                    setSuccessfullyCreatedKeyPopup={setSuccessfullyCreatedKeyPopup}
                    modelID={modelID}
                    token={token}
                    apiKey={apiKey}
                    setApiKey={setApiKey}
                    listKeys={listKeys}
                />
            </Popup>

            <Popup
                trigger={successfullyCreatedKeyPopup}
                setTrigger={setSuccessfullyCreatedKeyPopup}
            >
                <SuccessfullyCreatedKeyApiPopup 
                    setTrigger={setSuccessfullyCreatedKeyPopup}
                    setImplementCodePopup={setImplementCodePopup}
                    apiKey={apiKey ? apiKey : ""}
                />
            </Popup>

            <Popup
                trigger={implementCodePopup}
                setTrigger={setImplementCodePopup}
            >
                <ImplementCodePopup 
                    token={token}
                />
            </Popup>

            <Popup
                trigger={editApiKeyPopup}
                setTrigger={setEditApiKeyPopup}
            >   
                <EditApiKeyPopup 
                    token={token}
                    apiKey={apiKey}
                    setTrigger={setEditApiKeyPopup}
                    setApiKeyPopup={setTrigger}
                    modelID={modelID}
                    listKeys={listKeys}
                />
            </Popup>
        </>
    )
}

export default ApiPopup;