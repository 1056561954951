import * as d3 from "d3"; 
import React, { useState, useEffect, useRef } from "react";

import "../../assets/css/graphicsComponents/examples/linearregressiond3example.css";


import example_data from "./linear-regression-example.json";


export const LinearRegressionD3Example = () => {
  const [secondaryTarget, setSecondaryTarget] = useState(null);

  let fieldList = [
    "Pregnancies",
    "Glucose",
    "BloodPressure",
    "SkinThickness",
    "Insulin",
    "BMI",
    "DiabetesPedigreeFunction",
    "Atributo X",
    "Outcome",
  ];
  // inicializa um Ref
  const d3Chart = useRef();

  // inicializa o secondaryTarget pra garantir que nunca vai ser null
  if (secondaryTarget == null) {
    setSecondaryTarget(7);
  }

  // função que desenha o line chart de fato - feito em função pra poder ser chamado tanto no use effect
  // onMount quanto no side effect que é ativado quando o secondary target for alterado
  function desenharLineChart() {
    // garantindo que não vai dar erro no retorno do backend
    if (example_data === undefined) {
      return;
    }
    if (
      example_data === null ||
      !example_data.hasOwnProperty("weights") ||
      example_data.weights === undefined
    ) {
      return;
    }

    // vetor de coeficientes angulares
    let CoeficientesAngulares = [];
    // eslint-disable-next-line
    example_data.weights.map((value, index) => {
      // field = nome, coeficiente = valor
      const coef = { field: fieldList[index], coefficient: value };

      CoeficientesAngulares.push(coef);
    });

    // inicializar o coeficiente angular
    // let m = 0;

    // // caso nenhum target tenha sido escolhido ainda, escolher o primeiro do vetor
    // if (secondaryTarget == null) {
    //   m = example_data.weights[0];
    // } else {
    //   m = example_data.weights[secondaryTarget];
    // }

    let regressionPoints = [
      { x: 0, y: 28 },
      { x: 81, y: 50 },
    ];

    let lowerInterval = [
      { x: 0, y: 18 },
      { x: 81, y: 40 },
    ];

    let higherInterval = [
      { x: 0, y: 38 },
      { x: 81, y: 60 },
    ];

    // remove a linha e os eixos pra desenhar novamente, evitando que fique se sobrepondo
    d3.select("#linechart-line").remove();
    d3.select("#linechart-axis-x").remove();
    d3.select("#linechart-axis-y").remove();
    d3.selectAll("text").remove();

    // const margin = { top: 35, right: -120, bottom: -10, left: 50 };
    // const width =
    //   parseInt(d3.select("#lineard3-exemple-result").style("width"), 10) -
    //   margin.left -
    //   margin.right;
    // const height =
    //   parseInt(d3.select("#lineard3-exemple-result").style("height"), 10) -
    //   margin.top -
    //   margin.bottom;
    const margin = {top: 40, right: 0, bottom: 20, left: 30};
    const width = parseInt(d3.select(".model-representation").style("width"), 10) - margin.left - margin.right;
    const height = parseInt(d3.select(".model-representation").style("height"), 10) - margin.top - margin.bottom;

    const svg = d3
      .select(d3Chart.current)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
    // const svg = d3
    //   .select(d3Chart.current)
    //   .attr("viewBox", `0 0 ${width} ${height}`) // Define a caixa de visualização
    //   .attr("preserveAspectRatio", "xMidYMid meet") // Mantém a proporção do gráfico
    //   .append("g")
    //   .attr("transform", `translate(${margin.left},${margin.top})`);

    // Eixo X
    // escala, domínio e intervalo
    var x = d3.scaleLinear().domain([0, 80]).range([0, width]);

    // Grudar X
    svg
      .append("g")
      .attr("id", "linechart-axis-x")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x));

    // Eixo Y
    // escala, domínio e intervalo
    var y = d3.scaleLinear().domain([0, 100]).range([height * 0.9, 0]);

    // Grudar Y
    svg.append("g").attr("id", "linechart-axis-y").call(d3.axisLeft(y));

    // Desenhar Linha
    svg
      .append("path")
      .attr("id", "linechart-line")
      .datum(regressionPoints)
      .attr("fill", "none")
      .attr("stroke", "black")
      .attr("stroke-width", 3)
      .attr(
        "d",
        d3
          .line()
          .x(function (d) {
            return x(d.x);
          })
          .y(function (d) {
            return y(d.y);
          })
      );

    svg
      .append("path")
      .attr("id", "big-line")
      .datum(lowerInterval)
      .attr("fill", "none")
      .attr("stroke", "blue")
      .attr("opacity", 0.2)
      .attr("stroke-width", 2)
      .attr(
        "d",
        d3
          .line()
          .x(function (d) {
            return x(d.x);
          })
          .y(function (d) {
            return y(d.y);
          })
      );

    svg
      .append("path")
      .attr("id", "big-line2")
      .datum(higherInterval)
      .attr("fill", "none")
      .attr("stroke", "blue")
      .attr("opacity", 0.2)
      .attr("stroke-width", 2)
      .attr(
        "d",
        d3
          .line()
          .x(function (d) {
            return x(d.x);
          })
          .y(function (d) {
            return y(d.y);
          })
      );

    // texto de teste: nome do target selecionado
    svg
      .append("text")
      .attr("x", 125)
      .attr("y", margin.top / 8)
      .attr("text-anchor", "middle")
      .attr("font-size", "16px")
      .attr("fill", "black")
      .text(fieldList[secondaryTarget]);

    // texto de teste: coeficiente do target selecionado
    svg
      .append("text")
      .attr("x", width / 2)
      .attr("y", margin.top)
      .attr("text-anchor", "middle")
      .attr("font-size", "12px")
      .attr("fill", "black")
      .text
      // example_data.weights[secondaryTarget]
      ();

    // texto de teste: "porcentagem de relevância" do target selecionado
    svg
      .append("text")
      .attr("x", width / 2)
      .attr("y", (5 * margin.top) / 8)
      .attr("text-anchor", "middle")
      .attr("font-size", "16px")
      .attr("fill", "black")
      .text(function () {
        // let percentage = (
        //   (example_data.weights[secondaryTarget] /
        //     d3.max(example_data.weights)) *
        //   100
        // ).toFixed(2);
      });
  }

  useEffect(() => {
    desenharLineChart();
  });

  useEffect(() => {
    desenharLineChart();
    // eslint-disable-next-line
  }, [secondaryTarget]);

  //Eduardo: Codigo morto
  return (
      <svg ref={d3Chart} id="lineard3-svg" />
  );
};
