import React, { useState, useEffect } from "react";
import Tour from "reactour";

const NewProjectTour = () => {
   const [isTourOpen, setIsTourOpen] = useState(false);
   const [currentStep, setCurrentStep] = useState(0);

   const steps = [
      {
         selector: "#root > span > div.popup > div",
         content:
            "Preencha o nome do projeto e clique no botão para criar um novo projeto.",
         style: {
            borderRadius: 10,
         },
      },
   ];

   useEffect(() => {
      const handleIconClick = () => {
         setIsTourOpen(false);
      };

      const iconElement = document.querySelector(
         "#root > span > div.popup > div > div > form > div.newprojectpopup_containerName__1QMwh > input[type=text]"
      );
      if (iconElement) {
         iconElement.addEventListener("click", handleIconClick);
      }

      return () => {
         if (iconElement) {
            iconElement.removeEventListener("click", handleIconClick);
         }
      };
   }, []);

   return (
      <Tour
         steps={steps}
         isOpen={isTourOpen}
         onRequestClose={() => setIsTourOpen(false)}
         getCurrentStep={(step) => setCurrentStep(step)}
         goToStep={currentStep}
         showButtons={false}
         showNavigation={false}
         showNumber={false}
         showNavigationNumber={false}
         rounded={10}
      />
   );
};

export default NewProjectTour;
