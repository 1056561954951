import "./../../../assets/css/views/popups/actions/successfullycreatedkeyapipopup.css";
import CopyIcon from "./../../../assets/icons/jsxIcons/CopyFileIcon";

import { useTranslation } from "react-i18next"
import "./../../../translation/i18n";

function SuccessfullyCreatedKeyApiPopup({
    setTrigger, 
    setImplementCodePopup,
    apiKey,
}){
    const {t} = useTranslation();
    return (
        <div className="containerSuccessfullyCreatedKeyApi">
            <div className="containerTitlePopup">
                <p>{t("popup.action.successfullycreatedkeyapi.title")}</p>
            </div>
            <div className="containerKey">
                <p id="text">{apiKey.key}</p>
                <button onClick={() => {
                    const textKey = document.getElementById("text").textContent;
                    if(textKey){
                        navigator.clipboard.writeText(textKey);
                    }
                }}><CopyIcon /></button>
            </div>
            <div className="containerContent">
                <p>{t("popup.action.successfullycreatedkeyapi.content.info_1")}</p>
                <p>{t("popup.action.successfullycreatedkeyapi.content.info_2")}</p>
            </div>
            <div className="containerButton">
                <button id="buttonClose" onClick={() => setTrigger(false)}>{t("popup.action.successfullycreatedkeyapi.buttons.close")}</button>
                <button id="buttonImplement" onClick={() => {
                    setTrigger(false);
                    setImplementCodePopup(true);
                }}>{t("popup.action.successfullycreatedkeyapi.buttons.implement")}</button>
            </div>
        </div>
    )
}

export default SuccessfullyCreatedKeyApiPopup;