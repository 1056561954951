import * as d3 from "d3";
import React, { useEffect, useRef } from "react";

import example_data from "./decision-tree-example.json";

export const DecisionTreeD3Example = () => {
   // inicializa um Ref
   const d3Chart = useRef();

   // vetor com as paletas de cores indicadas no figma
   const colorScheme = [
      "rgba(73, 47, 117, 1)",
      "rgba(38, 55, 128, 1)",
      "rgba(2, 62, 138, 1)",
      "rgba(105, 61, 143, 1)",
      "rgba(53, 106, 171, 1)",
      "rgba(0, 150, 199, 1)",
      "rgba(149, 101, 178, 1)",
      "rgba(111, 152, 203, 1)",
      "rgba(72, 202, 228, 1)",
   ];

   // Key Performance Indicator (indicador de performance)
   // comumente usado para atribuir cores referentes aos valores
   function DecisionTreeKPI(d) {
      //Marodim: Talvez fosse bom colocar algum nome por extenso pra esse parametro, acho
      //que pode deixar melhor de entender o que precisa ser passado

      let predictTrueColor = "rgb(87,204,153)";
      let predictFalseColor = "rgb(214, 34, 70)";

      if (d.data.Feature === undefined) {
         if (d.data.Predict === "1.0") {
            return predictTrueColor;
         } else {
            return predictFalseColor;
         }
      } else {
         let min = 0;
         let max = colorScheme.length;
         let randomValue = Math.floor(Math.random() * (max - min)) + min;
         return colorScheme[randomValue];
      }
   }

   // função que desenha o line chart de fato - feito em função pra poder ser chamado tanto no use effect
   // onMount quanto no side effect que é ativado quando o secondary target for alterado
   function desenharDecisionTree() {
      if (example_data == null || example_data === undefined) {
         return;
      }

      d3.select("#decisiontree-graph").remove();
      d3.select("#decisiontree-links").remove();
      d3.select("#decisiontree-nodes").remove();

      const margin = { top: 0, right: -45, bottom: -80, left: 0 };
      const width = parseInt(d3.select(".model-representation").style("width"), 10) - margin.left - margin.right;
      const height = parseInt(d3.select(".model-representation").style("height"), 10) - margin.top - margin.bottom;
      const svg = d3
         .select(d3Chart.current)
         .attr("viewBox", `0 0 ${width} ${height}`) // Define a caixa de visualização
         .attr("preserveAspectRatio", "xMidYMid meet") // Mantém a proporção do gráfico
         .append("g")
         .attr("transform", `translate(${margin.left},${margin.top})`);

      // // Apenas cria um grupo do gráfico (contem grupos nodes e links, e desloca a posição)
      let graph = svg.append("g").attr("id", "decisiontree-graph").attr("transform", "translate(50,50)");

      // // Gera hierarquia
      let hierarchy = d3.hierarchy(example_data);

      let tree = d3.tree().size([width - 100, height - 120]);

      // // Popula a Árvore com os dados
      let myTree = tree(hierarchy);

      // // Faz uma seleção para os nós e associa dados
      let nodes = graph.selectAll(".node").data(myTree.descendants());

      // // get link selection and join data
      let links = graph.selectAll(".link").data(myTree.links());

      // adicionar ligações
      links
         .enter()
         .append("path")
         .attr("id", "decisiontree-links")
         // 0 pra não sumir
         .transition()
         .duration(0)
         .attr("class", "link")
         .attr("fill", "none")
         .attr("stroke", "#aaa")
         .attr("stroke-width", 2)
         .attr(
            "d",
            d3
               .linkVertical()
               .x((d) => d.x)
               .y((d) => d.y),
         );

      // // evento de hover do mouse
      nodes
         .enter()
         .append("g")
         .attr("id", "decisiontree-nodes")
         .on("mouseover", function (d) {
            let current = d3.select(this);

            current
               .append("g")
               .append("text")
               .classed("info", true)
               .attr("x", -40)
               .attr("y", 45)
               .text((d) => {
                  if (d.data.Feature !== undefined) {
                     return d.data.Feature + " / " + d.data.Threshold;
                  } else {
                     return d.data.Predict;
                  }
               });
         })
         .on("mouseout", function () {
            d3.select(this).select("text.info").remove();
         })
         .attr("class", "node")
         .attr("transform", (d) => `translate(${d.x},${d.y})`)
         .append("circle")
         .attr("r", 15)
         .attr("fill", function (d) {
            return DecisionTreeKPI(d);
         });
   }

   useEffect(() => {
      desenharDecisionTree();
   }, []);

   return <svg ref={d3Chart} id="decisiond3-representation" />;
};
