function MenuDotsVerticalIcon({ style }) {
  if (style == null) {
    style = [];
  }

  return (
    <svg
      width={style.hasOwnProperty("size") ? style.size : 24}
      height={style.hasOwnProperty("size") ? style.size : 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 5C13.3807 5 14.5 3.88071 14.5 2.5C14.5 1.11929 13.3807 0 12 0C10.6193 0 9.5 1.11929 9.5 2.5C9.5 3.88071 10.6193 5 12 5Z"
        fill={style.hasOwnProperty("color") ? style.color : "#282828"}
      />
      <path
        d="M12 14.5C13.3807 14.5 14.5 13.3807 14.5 12C14.5 10.6193 13.3807 9.5 12 9.5C10.6193 9.5 9.5 10.6193 9.5 12C9.5 13.3807 10.6193 14.5 12 14.5Z"
        fill={style.hasOwnProperty("color") ? style.color : "#282828"}
      />
      <path
        d="M12 24C13.3807 24 14.5 22.8807 14.5 21.5C14.5 20.1193 13.3807 19 12 19C10.6193 19 9.5 20.1193 9.5 21.5C9.5 22.8807 10.6193 24 12 24Z"
        fill={style.hasOwnProperty("color") ? style.color : "#282828"}
      />
    </svg>
  );
}

export default MenuDotsVerticalIcon;
