import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

function MultilayerPerceptronD3Example() {
  const d3Chart = useRef(null);

  const network = {
    nodes: [
      { id: "1-1", nr: 1, layer: 1 },
      { id: "1-2", nr: 2, layer: 1 },
      { id: "2-1", nr: 1, layer: 2 },
      { id: "2-2", nr: 2, layer: 2 },
      { id: "2-3", nr: 3, layer: 2 },
      { id: "3-1", nr: 1, layer: 3 },
      { id: "3-2", nr: 2, layer: 3 },
      { id: "3-3", nr: 3, layer: 3 },
      { id: "3-4", nr: 4, layer: 3 },
      { id: "4-1", nr: 1, layer: 4 },
      { id: "4-2", nr: 2, layer: 4 },
      { id: "4-3", nr: 3, layer: 4 },
      { id: "5-1", nr: 1, layer: 5 },
      { id: "5-2", nr: 2, layer: 5 },
    ],
    edges: [
      { s: "1-1", t: "2-1", w: -1 },
      { s: "1-1", t: "2-2", w: 0 },
      { s: "1-1", t: "2-3", w: 1 },
      { s: "1-2", t: "2-1", w: 1 },
      { s: "1-2", t: "2-2", w: 0 },
      { s: "1-2", t: "2-3", w: -1 },
      { s: "2-1", t: "3-1", w: 1 },
      { s: "2-1", t: "3-2", w: 0 },
      { s: "2-1", t: "3-3", w: -1 },
      { s: "2-1", t: "3-4", w: 0 },
      { s: "2-2", t: "3-1", w: 1 },
      { s: "2-2", t: "3-2", w: 0 },
      { s: "2-2", t: "3-3", w: -1 },
      { s: "2-2", t: "3-4", w: 0 },
      { s: "2-3", t: "3-1", w: 1 },
      { s: "2-3", t: "3-2", w: 0 },
      { s: "2-3", t: "3-3", w: -1 },
      { s: "2-3", t: "3-4", w: 0 },
      { s: "3-1", t: "4-1", w: 1 },
      { s: "3-1", t: "4-2", w: 0 },
      { s: "3-1", t: "4-3", w: -1 },
      { s: "3-2", t: "4-1", w: 1 },
      { s: "3-2", t: "4-2", w: 0 },
      { s: "3-2", t: "4-3", w: -1 },
      { s: "3-3", t: "4-1", w: 1 },
      { s: "3-3", t: "4-2", w: 0 },
      { s: "3-3", t: "4-3", w: -1 },
      { s: "4-1", t: "5-1", w: 1 },
      { s: "4-1", t: "5-2", w: 0 },
      { s: "4-2", t: "5-1", w: 1 },
      { s: "4-2", t: "5-2", w: 0 },
      { s: "4-3", t: "5-1", w: 1 },
      { s: "4-3", t: "5-2", w: 0 },
    ],
  };

  function layout(width, height) {
    let lsz = network.nodes.reduce((a, i) => {
      a[i.layer] = a[i.layer] ? a[i.layer] + 1 : 1;
      return a;
    }, {});
    let horz = d3
      .scaleLinear()
      .domain([0, Math.max(...network.nodes.map((n) => n.layer))])
      .range([0, width - 10]);

    const pos = (id) => {
      const node = network.nodes.find((e) => e.id === id);
      return {
        x: horz(node.layer),
        y: d3
          .scaleLinear()
          .domain([0, lsz[node.layer] + 1])
          .range([0, height])(node.nr),
      };
    };

    return pos;
  }

  useEffect(() => {
    const width = parseInt(d3.select(".model-representation").style("width"), 10);
    const height = parseInt(d3.select(".model-representation").style("height"), 10);

    const svg = d3
      .select(d3Chart.current)
      .attr("width", width)
      .attr("height", height)
      .attr("style", "width: 100%; height: auto;");

    const pos = layout(width, height);
    const thick = d3
      .scaleLinear()
      .domain([Math.min(...network.edges.map((e) => e.w)), 0, Math.max(...network.edges.map((e) => e.w)), ]).range([2, 2, 2]);
    const color = d3
      .scaleLinear()
      .domain([
        Math.min(...network.edges.map((e) => e.w)),
        0,
        Math.max(...network.edges.map((e) => e.w)),
      ])
      .range(["#CECECE", "#CECECE"]);

    // Determine the bounds of the graph
    const allX = network.nodes.map((node) => pos(node.id).x);
    const allY = network.nodes.map((node) => pos(node.id).y);
    const minX = Math.min(...allX);
    const maxX = Math.max(...allX);
    const minY = Math.min(...allY);
    const maxY = Math.max(...allY);

    // Calculate translation to center the graph
    const translateX = (width - (maxX - minX)) / 2 - minX;
    const translateY = (height - (maxY - minY)) / 2 - minY;

    const graphGroup = svg
      .append("g")
      .attr("transform", `translate(${translateX},${translateY})`);

    graphGroup
      .selectAll(".line")
      .data(network.edges)
      .enter()
      .append("line")
      .attr("x1", (d) => pos(d.s).x)
      .attr("y1", (d) => pos(d.s).y)
      .attr("x2", (d) => pos(d.t).x)
      .attr("y2", (d) => pos(d.t).y)
      .attr("stroke-width", (d) => thick(d.w))
      .attr("stroke", (d) => color(d.w))
      .attr("stroke-opacity", 0.6);

    graphGroup
      .selectAll(".node")
      .data(network.nodes)
      .enter()
      .append("circle")
      .attr("id", (d) => `n-${d.layer}-${d.id}`)
      .attr("cx", (d) => pos(d.id).x)
      .attr("cy", (d) => pos(d.id).y)
      .attr("r", 15)
      .attr("stroke", "#282828")
      .attr("stroke-width", 1)
      .attr("fill", d => { return (d.layer === 1 || d.layer === 5) ? "#D9D9D9" : "#22577A"});
  }, []);

  return <svg ref={d3Chart}></svg>;
}

export default MultilayerPerceptronD3Example;