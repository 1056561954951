function SearchIcon({ style }) {
  if (style == null) {
    style = [];
  }

  return (
    <svg
      width={style.hasOwnProperty("size") ? style.size : 24}
      height={style.hasOwnProperty("size") ? style.size : 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M23.561 21.439L18.9 16.776C20.4967 14.6422 21.2258 11.9828 20.9406 9.33305C20.6554 6.6833 19.3769 4.23998 17.3627 2.49492C15.3484 0.749864 12.7478 -0.167362 10.0845 -0.0721184C7.42112 0.0231247 4.89272 1.12376 3.00824 3.00824C1.12376 4.89272 0.0231247 7.42112 -0.0721184 10.0845C-0.167362 12.7478 0.749864 15.3484 2.49492 17.3627C4.23998 19.3769 6.6833 20.6554 9.33305 20.9406C11.9828 21.2258 14.6422 20.4967 16.776 18.9L21.439 23.563C21.7204 23.8444 22.102 24.0025 22.5 24.0025C22.898 24.0025 23.2796 23.8444 23.561 23.563C23.8424 23.2816 24.0005 22.9 24.0005 22.502C24.0005 22.104 23.8424 21.7224 23.561 21.441V21.439ZM10.5 18C9.01664 18 7.56659 17.5601 6.33322 16.736C5.09986 15.9119 4.13856 14.7406 3.5709 13.3701C3.00325 11.9997 2.85472 10.4917 3.14411 9.03682C3.4335 7.58197 4.14781 6.24559 5.1967 5.1967C6.24559 4.14781 7.58197 3.4335 9.03682 3.14411C10.4917 2.85472 11.9997 3.00325 13.3701 3.5709C14.7406 4.13856 15.9119 5.09986 16.736 6.33322C17.5601 7.56659 18 9.01664 18 10.5C17.9979 12.4885 17.207 14.3949 15.801 15.801C14.3949 17.207 12.4885 17.9979 10.5 18Z"
          fill={style.hasOwnProperty("color") ? style.color : "#282828"}
        />
      </g>
    </svg>
  );
}

export default SearchIcon;
