import * as d3 from "d3";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

function ColumnHistogram({ data }) {
   const { t } = useTranslation();

   const d3Chart = useRef();

   // Declare the chart dimensions and margins.
   const width = 600;
   const height = 200;
   const marginTop = 30;
   const marginRight = 0;
   const marginBottom = 30;
   const marginLeft = 0;

   let datas = data.data.map((histogram, index) => {
      return {
         x: index,
         y: histogram,
         value: histogram,
         field: data.labels[index],
      };
   });

   useEffect(() => {
      // Declare the x (horizontal position) scale.
      const x = d3
         .scaleBand()
         .domain(
            d3.groupSort(
               datas,
               ([d]) => -d.y,
               (d) => d.x
            )
         )
         .range([marginLeft, width - marginRight])
         .padding(0.1);

      // Declare the y (vertical position) scale.
      const y = d3
         .scaleLinear()
         .domain([0, d3.max(datas, (d) => d.y)])
         .range([height - marginBottom, marginTop]);

      const svg =
         //d3.create("svg")
         d3
            .select(d3Chart.current)
            .attr("width", width)
            .attr("height", height)
            .attr("viewBox", [0, 0, width, height])
            .attr("style", "max-width: 100%; height: auto;");

      svg.append("g")
         .attr("fill", "#BDBDBD")
         .selectAll()
         .data(datas)
         .join("rect")
         .attr("x", (d) => x(d.x))
         .attr("y", (d) => y(d.y))
         .attr("height", (d) => y(0) - y(d.y))
         .attr("width", x.bandwidth())
         .attr("rx", 10) // raio horizontal da borda
         .attr("ry", 10) // raio vertical da borda
         .append("title")
         .text(
            (d) =>
               `${t("dataset.content.table.histogram.value")}: ${d.field}\n${t(
                  "dataset.content.table.histogram.frequency"
               )}: ${d.value}`
         );

      // eslint-disable-next-line
   }, []);

   return <svg ref={d3Chart}></svg>;
}

export default ColumnHistogram;
