import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectProjects: false,
  selectDataS: false,
};

export const selectSlice = createSlice({
  name: "select",
  initialState,
  reducers: {
    setSelectProjects: (state, action) => {
      state.selectProjects = action.payload;
    },
    setSelectDataS: (state, action) => {
      state.selectDataS = action.payload;
    },
  },
});

export const { setSelectProjects, setSelectDataS } = selectSlice.actions;

export default selectSlice.reducer;
export const selectProjects = (state) => state.select.selectProjects;
export const selectDataS = (state) => state.select.selectDataS;
