function ArrowDownIcon({ style }) {
  if (style == null) {
    style = [];
  }

  return (
    <svg
      width={style.hasOwnProperty("size") ? style.size : 24}
      height={style.hasOwnProperty("size") ? style.size : 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.707 8.207C18.5195 8.01953 18.2652 7.91422 18 7.91422C17.7349 7.91422 17.4806 8.01953 17.293 8.207L12.707 12.793C12.5169 12.9755 12.2636 13.0774 12 13.0774C11.7365 13.0774 11.4832 12.9755 11.293 12.793L6.70704 8.207C6.51844 8.02484 6.26584 7.92405 6.00364 7.92633C5.74144 7.92861 5.49063 8.03377 5.30522 8.21918C5.11981 8.40459 5.01465 8.6554 5.01237 8.9176C5.01009 9.1798 5.11088 9.4324 5.29304 9.621L9.87804 14.207C10.1566 14.4856 10.4874 14.7067 10.8514 14.8575C11.2154 15.0082 11.6055 15.0859 11.9995 15.0859C12.3935 15.0859 12.7837 15.0082 13.1477 14.8575C13.5117 14.7067 13.8425 14.4856 14.121 14.207L18.707 9.621C18.8945 9.43347 18.9998 9.17917 18.9998 8.914C18.9998 8.64884 18.8945 8.39453 18.707 8.207Z"
        fill={style.hasOwnProperty("color") ? style.color : "#282828"}
      />
    </svg>
  );
}

export default ArrowDownIcon;
