import "./../../../../assets/css/views/popups/creations/NewDataset/availabledataset.css";

import { Card } from "../../../../components/Card";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect, useState, useRef } from "react";

import datasetService from "../../../../services/DatasetService.js";
import DoneIcon from "./../../../popups/creations/ChooseAlgorithmPopUp/assets/icons/jsxIcons/DoneIcon.jsx";
import SearchIcon from "../../../../assets/icons/jsxIcons/SearchIcon";
import { useTranslation } from "react-i18next";
import "./../../../../translation/i18n";
import handleErrorResponse from "../../../../helpers/handleErrorResponse.js";
import { useDispatch } from "react-redux";
import { setDataSources } from "../../../../store/slices/cache/cacheSlice.js";

function AvailableDataset({ setIDs }) {
   const { t } = useTranslation();
   //state utilizado para renderizar o card de dataSource para o usuário.
   const [cards, setCards] = useState([]);
   //state utilizado para selecionar ou não todos os dataSources.
   const [selectedAll, setSelectedAll] = useState(false);

   //referencia de todos os cards, que é inicializado na resposta do endpoint.
   const backupCards = useRef([]);
   //variável local utilizada para impedir que a função handleCards seja chamada desnecessariamente.
   let eventSearch = true;

   const IDsDataSourceRef = useRef([]);

   const history = useHistory();

   const dispatch = useDispatch();

   useEffect(() => {
      /*
            Primeiro, eu chamo o endpoint getuser para obter o id do usuário e pegar os todos os
            datasources do usuário. Assim, eu consigo inicializar o backupCards (para eu não precisar 
            chamar os endpoints novamente quando eu quiser pegar a lista de dataSource) e card (que é 
            renderizado para o usuário).
        */
      datasetService
         .getsourcelist()
         .then((data) => {
            dispatch(setDataSources(data));
            let arrayDataSource = [];
            for (const element of data) {
               arrayDataSource.push({
                  date: element.date,
                  name: element.name,
                  projects: element.projectNamesList,
                  id: element.id,
                  selected: false,
               });
            }
            setCards(arrayDataSource);
            backupCards.current = arrayDataSource;
         })
         .catch((error) => {
            handleErrorResponse(history, error, t);
         });
   }, [dispatch, history, t]);

   useEffect(() => {
      /*
            Toda vez eu pressionar "Selecionar tudo" ou pesquisar um dataSource 
            na interface, eu vou atualizar a seleção de todos os cards.
        */
      let auxCards = [];
      auxCards = cards;
      IDsDataSourceRef.current = [];
      for (let i = 0; i < auxCards.length; i++) {
         auxCards[i] = {
            ...auxCards[i],
            selected: selectedAll,
         };
      }
      let string = JSON.stringify(auxCards);
      setCards(JSON.parse(string));
      if (selectedAll) {
         for (const element of auxCards) {
            IDsDataSourceRef.current.push(element.id);
         }
      }
      setIDs(IDsDataSourceRef.current);
      // eslint-disable-next-line
   }, [selectedAll]);

   function handleCards(name) {
      /*
            Faz um filtro na lista de dataSource (backup) e atualiza o card para ser renderizado. Mas, 
            se não tiver nada no input eu reseto o card e volta para configuração inicial.
        */
      name = name.trim().toLowerCase();
      if (name === "") {
         setCards(backupCards.current);
      } else if (backupCards?.current) {
         let auxCards = [];
         auxCards = backupCards.current.filter((value) =>
            value.name.toLowerCase().includes(name)
         );
         setCards(auxCards.length ? auxCards : backupCards.current);
      }
   }

   let link = "popup.creations.newdataset.available";

   return (
      <div className="containerAvailableDataset">
         <header className="containerHeader">
            <div className="containerSelectedAll">
               <div
                  onClick={() => {
                     setSelectedAll(!selectedAll);
                  }}
               >
                  {selectedAll && (
                     <DoneIcon
                        style={{ width: 15, height: 15, color: "#BDBDBD" }}
                     />
                  )}
               </div>
               <p>{t(`${link}.header.checkbox`)}</p>
            </div>
            <div className="containerSearch">
               <div className="containerInput">
                  <SearchIcon style={{ size: 20, color: "#BDBDBD" }} />
                  <input
                     type="text"
                     placeholder={t(`${link}.header.placeholder`)}
                     onChange={(e) => {
                        if (eventSearch) {
                           eventSearch = !eventSearch;
                           const timeout = setTimeout(() => {
                              handleCards(e.target.value);
                              eventSearch = !eventSearch;
                           }, 1500);
                           return () => {
                              clearTimeout(timeout);
                           };
                        }
                     }}
                  />
               </div>
            </div>
         </header>
         <div className="containerContent">
            {cards.length ? (
               cards?.map((data, index) => {
                  return (
                     <div
                        className={
                           data.selected
                              ? "containerCard selected"
                              : "containerCard"
                        }
                        key={data.id}
                        onClick={(e) => {
                           //verifica se o Ctrl está precionado, se ele estiver eu vou selecionar o card
                           if (e.ctrlKey) {
                              data.selected = !data.selected;
                              if (data.selected) {
                                 //adicionar o elemento no array
                                 IDsDataSourceRef.current.push(data.id);
                              } else {
                                 //removendo o elemento do array
                                 IDsDataSourceRef.current.splice(index, 1);
                              }
                              setIDs(IDsDataSourceRef.current);
                              e.preventDefault();
                              let string = JSON.stringify(cards);
                              setCards(JSON.parse(string));
                           }
                        }}
                     >
                        <div
                           className="containerBox"
                           onClick={(e) => {
                              data.selected = !data.selected;
                              if (data.selected) {
                                 //adicionar o elemento no array
                                 IDsDataSourceRef.current.push(data.id);
                              } else {
                                 //removendo o elemento do array
                                 IDsDataSourceRef.current.splice(index, 1);
                              }
                              setIDs(IDsDataSourceRef.current);
                              e.preventDefault();
                              let string = JSON.stringify(cards);
                              setCards(JSON.parse(string));
                           }}
                        >
                           {data.selected && (
                              <DoneIcon
                                 style={{
                                    width: 15,
                                    height: 15,
                                    color: "#BDBDBD",
                                 }}
                              />
                           )}
                        </div>
                        <Link to={`/datasource/${data.id}`} key={data.id}>
                           <Card
                              type="datasource"
                              date={data.date}
                              name={data.name}
                              projects={data.projects}
                              elementId={data.id}
                              dataType={data.dataType}
                           />
                        </Link>
                     </div>
                  );
               })
            ) : (
               <span id="no-data">{t("project.info.no_data")}</span>
            )}
         </div>
      </div>
   );
}

export default AvailableDataset;
