function DownloadIcon({ style }) {
  if (style == null) {
    style = [];
  }

  return (
    <svg
      width={style.hasOwnProperty("size") ? style.size : 24}
      height={style.hasOwnProperty("size") ? style.size : 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M23 16C22.7348 16 22.4804 16.1054 22.2929 16.2929C22.1054 16.4804 22 16.7348 22 17V21C22 21.2652 21.8946 21.5196 21.7071 21.7071C21.5196 21.8946 21.2652 22 21 22H3C2.73478 22 2.48043 21.8946 2.29289 21.7071C2.10536 21.5196 2 21.2652 2 21V17C2 16.7348 1.89464 16.4804 1.70711 16.2929C1.51957 16.1054 1.26522 16 1 16C0.734784 16 0.48043 16.1054 0.292893 16.2929C0.105357 16.4804 0 16.7348 0 17L0 21C0 21.7956 0.31607 22.5587 0.87868 23.1213C1.44129 23.6839 2.20435 24 3 24H21C21.7956 24 22.5587 23.6839 23.1213 23.1213C23.6839 22.5587 24 21.7956 24 21V17C24 16.7348 23.8946 16.4804 23.7071 16.2929C23.5196 16.1054 23.2652 16 23 16Z"
          fill={style.hasOwnProperty("color") ? style.color : "#282828"}
        />
        <path
          d="M5.29308 13.538L9.87908 18.125C10.4426 18.6857 11.2052 19.0005 12.0001 19.0005C12.795 19.0005 13.5576 18.6857 14.1211 18.125L18.7071 13.539C18.8026 13.4468 18.8788 13.3364 18.9312 13.2144C18.9836 13.0924 19.0112 12.9612 19.0123 12.8284C19.0135 12.6956 18.9882 12.5639 18.9379 12.441C18.8876 12.3181 18.8134 12.2065 18.7195 12.1126C18.6256 12.0187 18.5139 11.9445 18.391 11.8942C18.2681 11.8439 18.1365 11.8186 18.0037 11.8197C17.8709 11.8209 17.7397 11.8485 17.6177 11.9009C17.4957 11.9533 17.3853 12.0295 17.2931 12.125L13.0001 16.416V1C13.0001 0.734784 12.8947 0.48043 12.7072 0.292893C12.5197 0.105357 12.2653 0 12.0001 0C11.7349 0 11.4805 0.105357 11.293 0.292893C11.1054 0.48043 11.0001 0.734784 11.0001 1V16.417L6.70708 12.124C6.61483 12.0285 6.50449 11.9523 6.38249 11.8999C6.26048 11.8475 6.12926 11.8199 5.99648 11.8187C5.8637 11.8176 5.73202 11.8429 5.60913 11.8932C5.48623 11.9435 5.37458 12.0177 5.28069 12.1116C5.18679 12.2055 5.11254 12.3172 5.06226 12.44C5.01198 12.5629 4.98668 12.6946 4.98783 12.8274C4.98898 12.9602 5.01657 13.0914 5.06898 13.2134C5.12139 13.3354 5.19757 13.4458 5.29308 13.538Z"
          fill={style.hasOwnProperty("color") ? style.color : "#282828"}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width={style.hasOwnProperty("size") ? style.size : 24}
            height={style.hasOwnProperty("size") ? style.size : 24}
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default DownloadIcon;
