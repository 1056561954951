function ProjectIcon({ style }) {
  if (style == null) {
    style = [];
  }

  return (
    <svg
      width={style.hasOwnProperty("size") ? style.size : 24}
      height={style.hasOwnProperty("size") ? style.size : 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 3H12.472C12.3173 3.00102 12.1645 2.96682 12.025 2.9L8.869 1.316C8.45239 1.10855 7.9934 1.00039 7.528 1H5C3.67441 1.00159 2.40356 1.52888 1.46622 2.46622C0.528882 3.40356 0.00158786 4.67441 0 6L0 18C0.00158786 19.3256 0.528882 20.5964 1.46622 21.5338C2.40356 22.4711 3.67441 22.9984 5 23H19C20.3256 22.9984 21.5964 22.4711 22.5338 21.5338C23.4711 20.5964 23.9984 19.3256 24 18V8C23.9984 6.67441 23.4711 5.40356 22.5338 4.46622C21.5964 3.52888 20.3256 3.00159 19 3ZM5 3H7.528C7.68266 2.99898 7.83552 3.03318 7.975 3.1L11.131 4.679C11.5472 4.88819 12.0062 4.99806 12.472 5H19C19.598 5.00098 20.1821 5.18067 20.6773 5.51601C21.1725 5.85135 21.5561 6.32704 21.779 6.882L2 6.994V6C2 5.20435 2.31607 4.44129 2.87868 3.87868C3.44129 3.31607 4.20435 3 5 3ZM19 21H5C4.20435 21 3.44129 20.6839 2.87868 20.1213C2.31607 19.5587 2 18.7956 2 18V8.994L22 8.881V18C22 18.7956 21.6839 19.5587 21.1213 20.1213C20.5587 20.6839 19.7956 21 19 21Z"
        fill={style.hasOwnProperty("color") ? style.color : "#282828"}
      />
    </svg>
  );
}

export default ProjectIcon;
