function HistoryLoadingIcon({ style }) {
  if (style == null) {
    style = [];
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{
        margin: "auto",
        background: "none repeat scroll 0% 0%",
        display: "block",
        "shapeRendering": "auto",
      }}
      width={style.hasOwnProperty("size") ? style.size : 40}
      height={style.hasOwnProperty("size") ? style.size : 40}
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke={style.hasOwnProperty("color") ? style.color : "#7f7f7f"}
        strokeWidth="10"
        r="16"
        strokeDasharray="75.39822368615503 27.132741228718345"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1s"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
        ></animateTransform>
      </circle>
    </svg>
  );
}

export default HistoryLoadingIcon;
