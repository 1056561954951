function DatasetAddIcon({ style }) {
  if (style == null) {
    style = [];
  }

  return (
    <svg
      width={style.hasOwnProperty("size") ? style.size : 24}
      height={style.hasOwnProperty("size") ? style.size : 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M16 16C16 16.2652 15.8946 16.5196 15.7071 16.7071C15.5196 16.8946 15.2652 17 15 17H13V19C13 19.2652 12.8946 19.5196 12.7071 19.7071C12.5196 19.8946 12.2652 20 12 20C11.7348 20 11.4804 19.8946 11.2929 19.7071C11.1054 19.5196 11 19.2652 11 19V17H9C8.73478 17 8.48043 16.8946 8.29289 16.7071C8.10536 16.5196 8 16.2652 8 16C8 15.7348 8.10536 15.4804 8.29289 15.2929C8.48043 15.1054 8.73478 15 9 15H11V13C11 12.7348 11.1054 12.4804 11.2929 12.2929C11.4804 12.1054 11.7348 12 12 12C12.2652 12 12.5196 12.1054 12.7071 12.2929C12.8946 12.4804 13 12.7348 13 13V15H15C15.2652 15 15.5196 15.1054 15.7071 15.2929C15.8946 15.4804 16 15.7348 16 16ZM22 10.485V19C21.9984 20.3256 21.4711 21.5964 20.5338 22.5338C19.5964 23.4711 18.3256 23.9984 17 24H7C5.67441 23.9984 4.40356 23.4711 3.46622 22.5338C2.52888 21.5964 2.00159 20.3256 2 19V5C2.00159 3.67441 2.52888 2.40356 3.46622 1.46622C4.40356 0.528882 5.67441 0.00158802 7 1.60491e-07H11.515C12.4346 -0.00236676 13.3456 0.177588 14.1952 0.529459C15.0449 0.881331 15.8163 1.39814 16.465 2.05L19.949 5.536C20.6012 6.18424 21.1184 6.95546 21.4704 7.80497C21.8225 8.65449 22.0025 9.56542 22 10.485ZM15.051 3.464C14.7363 3.15916 14.3829 2.89692 14 2.684V7C14 7.26522 14.1054 7.51957 14.2929 7.70711C14.4804 7.89464 14.7348 8 15 8H19.316C19.103 7.61719 18.8404 7.26415 18.535 6.95L15.051 3.464ZM20 10.485C20 10.32 19.968 10.162 19.953 10H15C14.2044 10 13.4413 9.68393 12.8787 9.12132C12.3161 8.55871 12 7.79565 12 7V2.047C11.838 2.032 11.679 2 11.515 2H7C6.20435 2 5.44129 2.31607 4.87868 2.87868C4.31607 3.44129 4 4.20435 4 5V19C4 19.7956 4.31607 20.5587 4.87868 21.1213C5.44129 21.6839 6.20435 22 7 22H17C17.7956 22 18.5587 21.6839 19.1213 21.1213C19.6839 20.5587 20 19.7956 20 19V10.485Z"
          fill={style.hasOwnProperty("color") ? style.color : "#282828"}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width={style.hasOwnProperty("size") ? style.size : 24}
            height={style.hasOwnProperty("size") ? style.size : 24}
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default DatasetAddIcon;
