import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { setNewNotification } from "../store/slices/notification/notificationSlice";
import NotificationIcon from "../assets/icons/jsxIcons/NotificationIcon";
import InterrogationIcon from "../assets/icons/jsxIcons/InterrogationIcon";
import NewNotificationIcon from "../assets/icons/jsxIcons/NewNotificationIcon";
import { NotificationPopup } from "../views/popups/informations/NotificationPopup";
import { QuickstartPopup } from "../views/popups/quickstartCases/QuickstartPopup";
import { Popup } from "./Popup";
import "../views/app.css";

const NotificationQuickStart = () => {
   let token = localStorage.getItem("user_token");
   const location = useLocation();
   const dispatch = useDispatch();
   const url = location.pathname;

   const [displayNotification, setDisplayNotification] = useState(true);
   const [notificationPopup, setNotificationPopup] = useState(false);
   const [quickstartPopup, setQuickstartPopup] = useState(false);
   const newNotification = useSelector((state) => state.notification.new);

   useEffect(() => {
      if (url === "/" || url.includes("model") || url.includes("monitoring")) {
         setDisplayNotification(false);
      } else {
         setDisplayNotification(true);
      }
   }, [url]);

   return (
      <>
         {token && displayNotification && (
            <div className="top-btn">
               <button
                  className="notification-btn"
                  onClick={() => {
                     setNotificationPopup(true);
                     dispatch(setNewNotification(false));
                  }}
               >
                  {newNotification ? <NewNotificationIcon /> : <NotificationIcon />}
               </button>
               <button
                  className="quickstart-btn"
                  onClick={() => {
                     setQuickstartPopup(true);
                  }}
               >
                  <InterrogationIcon />
               </button>
            </div>
         )}
         <Popup trigger={notificationPopup} setTrigger={setNotificationPopup}>
            <NotificationPopup trigger={notificationPopup} setTrigger={setNotificationPopup} />
         </Popup>

         <Popup trigger={quickstartPopup} setTrigger={setQuickstartPopup}>
            <QuickstartPopup trigger={quickstartPopup} setTrigger={setQuickstartPopup} />
         </Popup>
      </>
   );
};

export default NotificationQuickStart;
