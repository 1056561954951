import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import "../../../assets/css/views/popups/predictions/generatedpredictionspopup.css";
import TrashIcon from "../../../assets/icons/jsxIcons/TrashIcon";

import DataParse from "../../../helpers/DataParse";
import DownloadIcon from "../../../assets/icons/jsxIcons/DownloadIcon";
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify";
import "./../../../translation/i18n";
import predictionService from "../../../services/PredictionService.js";
import LoadingWheel from "../../../components/LoadingWheel";
import handleErrorResponse from "../../../helpers/handleErrorResponse.js";

function GeneratedPredictionsPopup({ modelResultId, modelName, projectName }) {
  const { t } = useTranslation();
  const [listPrediction, setListPrediction] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [needUpdate, setNeedUpdate] = useState(false);
  const history = useHistory();


  useEffect(() => {
    if (modelResultId || needUpdate) {
      setIsLoading(true);
      setNeedUpdate(false);
      predictionService.getpredictionlistbymodelid(modelResultId)
        .then(data => {
          data = JSON.parse(data.result);
          data = data.map(element => {
            let keys = Object.keys(element.single);
            let values = Object.values(element.single);
            return {
              ...element,
              single: keys.map((key, index) => {
                return {
                  name: key,
                  value: values[index]
                }
              })
            }
          })
          setListPrediction(data);
        }).catch(error => {
          handleErrorResponse(history, error, t)
        }).finally(() => {
          setIsLoading(false);
        })
    }
    // eslint-disable-next-line
  }, [needUpdate]);

  const downloadPrediction = (id) => {
    if (id) {
      predictionService.predictiondownload(id)
        .then(() => {
          toast.success(t("popup.predictions.predictions.success.download"));
        }).catch((error) => {
          handleErrorResponse(history, error, t);
          toast.error(t("popup.predictions.predictions.error.download"));
        });
    } else {
      toast.error(t("popup.predictions.predictions.error.download"));
    }
  }

  const link = "popup.predictions.generatedpredictions";

  const getPredictions = () => {

    const singlePrediction = (single, date, id) => {
      return (
        <div className="card-prediction">
          <header>
            <p>{`${t(`${link}.card.single`)} - ${date}`}</p>
            <button onClick={() => deletePrediction(id)}><TrashIcon style={{ size: 16 }} /></button>
          </header>
          <div className="card-result">
            {single.map(element => {
              return <p style={element.name.toLowerCase() === "prediction" ? { fontWeight: 500 } : {}} key={element.name}>{`${element.name}: ${element.value}`}</p>
            })}
          </div>
        </div>
      )
    }

    const batchPrediciton = (id, date) => {
      return (
        <div className="card-prediction">
          <header>
            <p>{`${t(`${link}.card.batch`)} - ${date}`}</p>
            <button onClick={() => deletePrediction(id)}><TrashIcon style={{ size: 16 }} /></button>
          </header>
          <button onClick={() => downloadPrediction(id)}>
            <DownloadIcon style={{ color: "#22577A" }} />
            <p>{t(`${link}.download`)}</p>
          </button>
        </div>
      )
    }

    if (listPrediction.length) {
      return listPrediction.map(prediction => {
        const date = DataParse.parseDate(prediction?.date).day;
        if (prediction?.single?.length) {
          return singlePrediction(prediction.single, date, prediction.id)
        } else {
          return batchPrediciton(prediction.id, date);
        }
      })
    } else {
      return <p>{t(`${link}.default`)}</p>
    }
  }

  const deletePrediction = (id) => {
    setIsLoading(true);
    predictionService.deleteprediction(id)
      .then(data => {
        toast.success(t(`backend.SUCCESS.${data}`));
        const index = listPrediction.findIndex(prediction => {
          if(prediction.id === id){
            return prediction;
          }
        });
        listPrediction.splice(index, 1);
      }).catch(error => {
        handleErrorResponse(history, error, t);
      }).finally(() => {
        setIsLoading(false);
      })
  }

  const deleteAllPrediction = () => {
    setIsLoading(true);
    predictionService.deleteallmodelprediction(modelResultId)
      .then(data => {
        toast.success(t(`backend.SUCCESS.${data}`));
        setListPrediction([])
      }).catch(error => {
        handleErrorResponse(history, error, t);
      }).finally(() => {
        setIsLoading(false);
      })
  }

  if (isLoading) {
    return (
      <div className="generated-predictions" style={{justifyContent: "center"}}>
        <LoadingWheel loadingSize="large"/>
      </div>
    )
  } else {
    return (
      <div className="generated-predictions">
        <header>
          <p>{t(`${link}.title`)}</p>
        </header>
        <div className="sections">
          <div className="generated-predictions-info">
            <div className="infos">
              <div>
                <p>{t(`${link}.info.project`)}</p>
                <span>{projectName}</span>
              </div>
              <div>
                <p>{t(`${link}.info.model`)}</p>
                <span>{modelName}</span>
              </div>
              <div>
                <p>{t(`${link}.info.count`)}</p>
                <span>{listPrediction.length}</span>
              </div>
            </div>
            <button onClick={() => deleteAllPrediction()}>
              <TrashIcon style={{ size: 19, color: "#FFFFFF" }} />
              <p>{t("popup.predictions.predictions.button.delete")}</p>
            </button>
          </div>
          <div className="generated-predictions-predictions">
            {getPredictions()}
          </div>
        </div>
      </div>
    )
  }
}

export default GeneratedPredictionsPopup;
