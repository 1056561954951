import React, { useEffect, useState } from "react";
import Tour from "reactour";
import CloseTourDialog from "./components/CloseTourDialog";
import StyledParagraph from "./components/StyledParagraph";
import { useTranslation } from "react-i18next";
import { Warning } from "@material-ui/icons";

const NewDatasetTour = () => {
   const [isTourOpen, setIsTourOpen] = useState(true);
   const [currentStep, setCurrentStep] = useState(0);
   const [isDialogOpen, setIsDialogOpen] = useState(false);
   const { t } = useTranslation();

   const steps = [
      {
         selector: "#newDataset > div > nav > button:nth-child(1)",
         content: (
            <div>
               <h4>{t("wizard.newDatasetTour.step_1.title")}</h4>
               <StyledParagraph>
                  {t("wizard.newDatasetTour.step_1.description_1")}
               </StyledParagraph>

               <small>{t("wizard.newDatasetTour.step_1.description_2")}</small>
            </div>
         ),
         style: {
            borderRadius: 10,
         },
      },
      {
         selector: "#newDataset > div > nav > button:nth-child(2)",
         content: (
            <div>
               <h4>{t("wizard.newDatasetTour.step_2.title")}</h4>
               <StyledParagraph>
                  {t("wizard.newDatasetTour.step_2.description_1")}
               </StyledParagraph>

               <StyledParagraph icon={Warning} small={true}>
                  {t("wizard.newDatasetTour.step_2.description_2")}
               </StyledParagraph>
            </div>
         ),
         style: {
            borderRadius: 10,
         },
      },
      {
         selector: "#newDataset > div > nav > button:nth-child(3)",
         content: (
            <div>
               <h4>{t("wizard.newDatasetTour.step_3.title")}</h4>
               <StyledParagraph>
                  {t("wizard.newDatasetTour.step_3.description")}
               </StyledParagraph>
            </div>
         ),
         style: {
            borderRadius: 10,
         },
      },
      {
         selector: "#newDataset > div > nav",
         content: (
            <div>
               <h4>{t("wizard.newDatasetTour.step_4.title")}</h4>
               <StyledParagraph>
                  {t("wizard.newDatasetTour.step_4.description")}
               </StyledParagraph>
            </div>
         ),
         style: {
            borderRadius: 10,
         },
      },
   ];

   useEffect(() => {
      const handleIconClick = () => {
         setIsTourOpen(false);
      };

      const iconSelectors = [
         "#newDataset > div > nav > button:nth-child(1)",
         "#newDataset > div > nav > button:nth-child(2)",
         "#newDataset > div > nav > button:nth-child(3)",
      ];

      const iconElements = iconSelectors.map((selector) =>
         document.querySelector(selector)
      );

      iconElements.forEach((iconElement) => {
         if (iconElement) {
            iconElement.addEventListener("click", handleIconClick);
         }
      });

      return () => {
         iconElements.forEach((iconElement) => {
            if (iconElement) {
               iconElement.removeEventListener("click", handleIconClick);
            }
         });
      };
   }, []);

   return (
      <>
         <Tour
            steps={steps}
            isOpen={isTourOpen}
            onRequestClose={() => setIsDialogOpen(true)}
            getCurrentStep={(step) => setCurrentStep(step)}
            goToStep={currentStep}
            rounded={10}
            className="tour-wrapper"
            disableFocusLock={isDialogOpen}
         />
         <CloseTourDialog
            open={isDialogOpen}
            onCloseDialog={() => setIsDialogOpen(false)}
            onCloseTour={() => setIsTourOpen(false)}
         />
      </>
   );
};

export default NewDatasetTour;
