import AnomaliesIcon from "../assets/icons/jsxIcons/AnomaliesIcon";
import AssociationDiscoveryIcon from "../assets/icons/jsxIcons/AssociationDiscoveryIcon";
import DecisionTreeIcon from "../assets/icons/jsxIcons/DecisionTreeIcon";
import KmeansIcon from "../assets/icons/jsxIcons/KmeansIcon";
import LinearRegressionIcon from "../assets/icons/jsxIcons/LinearRegressionIcon";
import MultilayerPerceptronIcon from "../assets/icons/jsxIcons/MultilayerPerceptronIcon";
import RandomForestIcon from "../assets/icons/jsxIcons/RandomForestIcon";
import SVMIcon from "../assets/icons/jsxIcons/SVMIcon";
import TableIcon from "../assets/icons/jsxIcons/TableIcon";
import XGBoostIcon from "../views/popups/creations/ChooseAlgorithmPopUp/assets/icons/jsxIcons/XGBoostIcon";

export function getAlgorithmIcon(algorithm) {
   let iconStyle = { size: 62, color: "#38A3A5" };
   let icon;



   switch (algorithm.toLowerCase()) {
      case "decisiontree":
         icon = <DecisionTreeIcon style={iconStyle} />;
         break;
      case "linearregression":
         icon = <LinearRegressionIcon style={iconStyle} />;
         break;
      case "randomforest":
         icon = <RandomForestIcon style={{ size: 62, color: "#22577A" }} />;
         break;
      case "kmeans":
         icon = <KmeansIcon style={iconStyle} />;
         break;
      case "filtrocolaborativo":
         icon = <TableIcon style={{ size: 62, color: "#22577A" }} />;
         break;
      case "gradientboosting":
         icon = <XGBoostIcon style={iconStyle} />;
         break;
      case "multilayerperceptron":
         icon = <MultilayerPerceptronIcon style={iconStyle} />;
         break;
      case "svm":
         icon = <SVMIcon style={iconStyle} />;
         break;
      case "isolationforest":
         icon = <AnomaliesIcon style={iconStyle} />;
         break;
      case "fpgrowth":
         icon = <AssociationDiscoveryIcon style={iconStyle} />;
         break;
      default:
         icon = null;
   }

   return icon;
}
