import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    tutorialDone: true,
    canInitTour: false
}

export const wizardSlice = createSlice({
    name: "wizard",
    initialState,
    reducers: {
        setTutorialDone: (state, action) => {
            state.tutorialDone = action.payload;
        },
        setCanInitTour: (state, action) => {
            state.canInitTour = action.payload;
        }
    }
})

export const { setTutorialDone, setCanInitTour } = wizardSlice.actions;
export default wizardSlice.reducer;
export const selectTutorialDone = state => state.wizard.tutorialDone;
export const selectCanInitTour = state => state.wizard.canInitTour;