import React, { useState, useEffect } from "react";
import Tour from "reactour";
import DialogInitTour from "./components/DialogInitTour";
import CloseTourDialog from "./components/CloseTourDialog";
import { useSelector } from "react-redux";
import {
   selectCanInitTour,
   selectTutorialDone,
} from "../../store/slices/wizard/wizardSlice";
import { useTranslation } from "react-i18next";
import { Info } from "@material-ui/icons";
import StyledParagraph from "./components/StyledParagraph";

const MyProjectTour = () => {
   const [isTourOpen, setIsTourOpen] = useState(false);
   const [currentStep, setCurrentStep] = useState(0);
   const [isDialogOpen, setIsDialogOpen] = useState(false);
   const tutorialDone = useSelector(selectTutorialDone);
   const canInitiateTour = useSelector(selectCanInitTour);

   const { t } = useTranslation();

   const steps = [
      {
         selector: "#root > span > div.myprojects",
         content: (
            <div>
               <h4>{t("wizard.myProjectTour.step_1.title")}</h4>
               <StyledParagraph icon={Info}>
                  {t("wizard.myProjectTour.step_1.description")}
               </StyledParagraph>
            </div>
         ),
         style: {
            borderRadius: 10,
         },
      },
      {
         selector: "#icon-new-project",
         content: (
            <div>
               <h4>{t("wizard.myProjectTour.step_2.title")}</h4>
               <StyledParagraph icon={Info}>
                  {t("wizard.myProjectTour.step_2.description")}
               </StyledParagraph>
            </div>
         ),
         style: {
            borderRadius: 10,
         },
      },
   ];

   useEffect(() => {
      const timer = setTimeout(() => {
         setIsTourOpen(true);
      }, 1000);

      const handleIconClick = () => {
         setIsTourOpen(false);
      };

      const iconElement = document.querySelector("#icon-new-project");
      if (iconElement) {
         iconElement.addEventListener("click", handleIconClick);
      }

      return () => {
         clearTimeout(timer);
         if (iconElement) {
            iconElement.removeEventListener("click", handleIconClick);
         }
      };
   }, []);

   if (!canInitiateTour && !tutorialDone) {
      return <DialogInitTour />;
   }

   return (
      <>
         <Tour
            steps={steps}
            isOpen={isTourOpen && canInitiateTour}
            onRequestClose={() => setIsDialogOpen(true)}
            getCurrentStep={(step) => setCurrentStep(step)}
            goToStep={currentStep}
            // showButtons={false}
            // showNavigation={false}
            // showNumber={false}
            // showNavigationNumber={false}
            disableInteraction={currentStep === 0}
            rounded={10}
            className="tour-wrapper"
            disableFocusLock={isDialogOpen}
         />
         <CloseTourDialog
            open={isDialogOpen}
            onCloseDialog={() => setIsDialogOpen(false)}
            onCloseTour={() => setIsTourOpen(false)}
         />
      </>
   );
};

export default MyProjectTour;
