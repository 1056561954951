import '../assets/css/components/textinput.css'

const TextInput = ({ disabled, placeholder, onChange, size }) => {


    return (
        <div className="text-input-component">
            <input className="text-input"
                type="text"
                autocomplete="off"
                disabled={disabled}
                placeholder={placeholder}
                onChange={(event) => {
                    onChange(event.target.value);
                }}
            />
        </div>
    )
}

export default TextInput;
