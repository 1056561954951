import http from "../http-common";

function getmodel(id) {
   return http
      .get(`/getmodel/${localStorage.getItem("user_token")}/${id}`)
      .then((response) => {
         return response.data;
      });
}

function savemodel(id) {
   return http
      .patch("/savemodel", {
         token: localStorage.getItem("user_token"),
         id: id,
      })
      .then((response) => {
         return response;
      });
}

function supportvectormachine({
   mlframework,
   kernel,
   datasetid,
   name,
   trainfraction,
   target,
   featuresused,
   regparam,
   numiterations,
}) {
   return http
      .post("/supportvectormachine", {
         mlframework: mlframework,
         token: localStorage.getItem("user_token"),
         datasetid: datasetid,
         name: name,
         trainfraction: trainfraction,
         target: target,
         kernel: kernel,
         featuresused: featuresused,
         numiterations: numiterations,
         regparam: regparam,
      })
      .then((response) => {
         return response?.data;
      });
}

function isolationforest({
   datasetid,
   name,
   featuresused,
   numberofanomalies,
   randomstate,
   maxfeatures,
   bootstrap,
   warmstart,
}) {
   return http
      .post("/isolationforest", {
         token: localStorage.getItem("user_token"),
         datasetid: datasetid,
         name: name,
         featuresused: featuresused,
         numberofanomalies: numberofanomalies,
         randomstate: randomstate,
         maxfeatures: maxfeatures,
         bootstrap: bootstrap,
         warmstart: warmstart,
      })
      .then((response) => {
         return response.data;
      });
}

function fpgrowth({ datasetid, name, minSupport, minConfidence }) {
   return http
      .post("/fpgrowth", {
         token: localStorage.getItem("user_token"),
         datasetid: datasetid,
         name: name,
         minSupport: minSupport,
         minConfidence: minConfidence,
      })
      .then((response) => {
         return response?.data;
      });
}

function multilayerperceptron({
   mlframework,
   datasetid,
   name,
   trainfraction,
   target,
   featuresused,
   epoch,
   hiddenLayers,
   activation,
   learningRate,
}) {
   return http
      .post("/multilayerperceptron", {
         token: localStorage.getItem("user_token"),
         mlframework: mlframework,
         datasetid: datasetid,
         name: name,
         trainfraction: trainfraction,
         target: target,
         featuresused: featuresused,
         epoch: epoch,
         hiddenLayers: hiddenLayers,
         activation: activation,
         learningRate: learningRate,
      })
      .then((response) => {
         return response?.data;
      });
}

function deletemodel(id) {
   const token = localStorage.getItem("user_token");
   return http
      .request({
         method: "delete",
         url: "/deletemodel",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}}`,
         },
         data: { token: token, options: id },
      })
      .then((response) => response.data);
}

function deletemanymodels(ids) {
   return http.post("/deletemany");
}

function renamemodel(id, option) {
   return http
      .patch("/renamemodel", {
         token: localStorage.getItem("user_token"),
         id: id,
         option: option,
      })
      .then((response) => {
         return response?.data;
      });
}

async function getyoloparams(id) {
   const response = await http.get(
      `/getyoloparams/${localStorage.getItem("user_token")}/${id}`
   );
   return response.data;
}

function getsharedwith(modelId) {
   return http
      .get(`/getsharedwith/${localStorage.getItem("user_token")}/${modelId}`)
      .then((response) => response.data);
}

function getsuggestedusers(modelId) {
   return http
      .get(
         `/getsuggestedusers/${localStorage.getItem("user_token")}/${modelId}`
      )
      .then((response) => response.data);
}

function removeusersharedwith(modelId, email) {
   return http
      .patch(
         `/removeusersharedwith/${localStorage.getItem(
            "user_token"
         )}/${modelId}/${email}`
      )
      .then((response) => response.data);
}

/**
 * Retrieves all models metrics from a dataset.
 *
 * @param {string} datasetId - The ID of the dataset.
 * @returns {Promise} A promise that resolves to the data returned by the server.
 */
function getAllModelsMetricsFromDataset(datasetId) {
   return http
      .get(
         `/getAllModelsMetricsFromDataset/${localStorage.getItem(
            "user_token"
         )}/${datasetId}`
      )
      .then((response) => response.data);
}

const modelService = {
   getmodel,
   getyoloparams,
   savemodel,
   supportvectormachine,
   isolationforest,
   fpgrowth,
   multilayerperceptron,
   deletemodel,
   deletemanymodels,
   renamemodel,
   getsharedwith,
   getsuggestedusers,
   removeusersharedwith,
   getAllModelsMetricsFromDataset
};

export default modelService;
