import React, { useEffect, useState } from "react";
import Tour from "reactour";
import CloseTourDialog from "./components/CloseTourDialog";
import { Info } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { GiClick } from "react-icons/gi";
import StyledParagraph from "./components/StyledParagraph";

const ProjectTour = ({ datasets }) => {
   const [isTourOpen, setIsTourOpen] = useState(true);
   const [currentStep, setCurrentStep] = useState(0);
   const [isDialogOpen, setIsDialogOpen] = useState(false);
   const { t } = useTranslation();

   const steps = [
      {
         selector: "#root > span > div.container-project",
         content: (
            <div>
               <h4>{t("wizard.projectTour.step_1.title")}</h4>
               <StyledParagraph icon={Info}>
                  {t("wizard.projectTour.step_1.description")}
               </StyledParagraph>
            </div>
         ),
         style: {
            borderRadius: 10,
         },
      },
      {
         selector: "#icon > svg",
         content: (
            <div>
               <h4>{t("wizard.projectTour.step_2.title")}</h4>
               <StyledParagraph icon={Info}>
                  {t("wizard.projectTour.step_2.description_1")}
               </StyledParagraph>
               <StyledParagraph icon={GiClick}>
                  {t("wizard.projectTour.step_2.description_2")}
               </StyledParagraph>
            </div>
         ),
         style: {
            borderRadius: 10,
         },
      },
   ];

   if (datasets.length > 0) {
      steps.push({
         selector:
            "#root > span > div.container-project > main > section:nth-child(1) > div.dataSetsContent > a",
         content: (
            <div>
               <h4>{t("wizard.projectTour.step_3.title")}</h4>
               <StyledParagraph icon={Info}>
                  {t("wizard.projectTour.step_3.description_1")}
               </StyledParagraph>
               <StyledParagraph icon={GiClick}>
                  {t("wizard.projectTour.step_3.description_2")}
               </StyledParagraph>
            </div>
         ),
         style: {
            borderRadius: 10,
         },
      });
   }

   useEffect(() => {
      const handleIconClick = () => {
         setIsTourOpen(false);
      };

      const iconElement = document.querySelector("#icon > svg");
      if (iconElement) {
         iconElement.addEventListener("click", handleIconClick);
      }

      return () => {
         if (iconElement) {
            iconElement.removeEventListener("click", handleIconClick);
         }
      };
   }, []);

   useEffect(() => {
      if (datasets.length > 0) {
         setTimeout(() => {
            setIsTourOpen(true);
            setCurrentStep(2);
         }, 500);
      }
   }, [datasets]);

   return (
      <>
         <Tour
            steps={steps}
            isOpen={isTourOpen}
            onRequestClose={() => setIsDialogOpen(true)}
            getCurrentStep={(step) => setCurrentStep(step)}
            goToStep={currentStep}
            rounded={10}
            className="tour-wrapper"
            disableFocusLock={isDialogOpen}
         />
         <CloseTourDialog
            open={isDialogOpen}
            onCloseDialog={() => setIsDialogOpen(false)}
            onCloseTour={() => setIsTourOpen(false)}
         />
      </>
   );
};

export default ProjectTour;
