import {
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
} from "@material-ui/core";

import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { setNeedUpdateDataset } from "../../../store/slices/cache/cacheSlice";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import ws from "../../../services/wiselib";

import { Button } from "../../Button";
import { Loader } from "semantic-ui-react";

const DeleteDialog = ({ open, onClose, currentColumn }) => {
   const datasetId = useLocation().pathname.split("/").pop();
   const isDataSourcePage = useLocation().pathname.includes("datasource");
   const token = localStorage.getItem("user_token");
   const dispatch = useDispatch();
   const { t } = useTranslation();
   const [isLoading, setIsLoading] = useState(false);

   const handleDelete = useCallback(() => {
      const field = currentColumn.field;
      let endpoint;
      if (isDataSourcePage) {
         endpoint = ws.removesourcecolumns(token, datasetId, [field]);
      } else {
         endpoint = ws.removecolumns(token, datasetId, [field]);
      }

      setIsLoading(true);

      endpoint
         .then((response) => {
            dispatch(setNeedUpdateDataset(true));
            toast.success(t(`backend.SUCCESS.${response}`));
         })
         .catch((error) => {
            toast.error(t(`backend.ERROR.${error.response.data}`));
         })
         .finally(() => {
            setIsLoading(false);
            onClose();
         });
   }, [
      currentColumn,
      datasetId,
      dispatch,
      isDataSourcePage,
      onClose,
      t,
      token,
   ]);

   useEffect(() => {
      const handleKeyDown = (event) => {
         if (event.key === "Enter") {
            event.stopPropagation();
            handleDelete();
         }
      };

      if (open) {
         document.addEventListener("keydown", handleKeyDown, true);
      } else {
         document.removeEventListener("keydown", handleKeyDown, true);
      }

      return () => {
         document.removeEventListener("keydown", handleKeyDown, true);
      };
   }, [handleDelete, open]);

   const handleStopPropagation = (event) => {
      event.stopPropagation();
   };

   return (
      <Dialog open={open} onClose={onClose} onClick={handleStopPropagation}>
         <DialogTitle>
            {t("dataset.content.table.columnMenu.delete.title")}{" "}
            <strong>{currentColumn.field}?</strong>
         </DialogTitle>
         <DialogContent
            onClick={handleStopPropagation}
            style={{
               display: "flex",
               flexDirection: "column",
               gap: "1em",
               padding: "1em",
            }}
         />
         <DialogActions>
            <Button
               type="button"
               buttonStyle="btn--primary--outline"
               buttonSize="btn--auto"
               onClick={onClose}
            >
               {t("dataset.content.table.columnMenu.delete.button_1")}
            </Button>
            <Button
               type="button"
               buttonStyle="btn--primary--solid"
               buttonSize="btn--auto"
               onClick={handleDelete}
               disabled={isLoading}
            >
               {t("dataset.content.table.columnMenu.delete.button_2")}
               {isLoading && <Loader active inline size="tiny" />}
            </Button>
         </DialogActions>
      </Dialog>
   );
};

export default DeleteDialog;
