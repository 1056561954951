import "../../../assets/css/views/popups/actions/logoutpopup.css";

import { Button } from "../../../components/Button";

import ws from "../../../services/wiselib";

import { useTranslation } from "react-i18next";
import "./../../../translation/i18n";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import handleErrorResponse from "../../../helpers/handleErrorResponse";

export const LogoutPopup = ({ setTrigger }) => {
   const { t } = useTranslation();
   const history = useHistory();

   return (
      <div className="logout-task">
         <div id="description">{t("popup.action.logout.title")}</div>
         <div className="buttons">
            <span id="btn">
               <Button
                  type="button"
                  buttonStyle="btn--primary--transparent"
                  buttonSize="btn--auto"
                  onClick={() => {
                     setTrigger(false);
                     // cancelar delete
                  }}
               >
                  {t("popup.action.logout.button.cancel")}
               </Button>
            </span>

            <span id="btn">
               <Button
                  type="button"
                  buttonStyle="btn--primary--solid"
                  buttonSize="btn--auto"
                  onClick={() => {
                     setTrigger(false);
                     let token = localStorage.getItem("user_token");
                     ws.logout(token)
                        .then((result) => {
                           localStorage.setItem("user_token", null);
                           if (result.status === 200) {
                              window.location.pathname = "/";
                           }
                        })
                        .catch((error) => {
                           handleErrorResponse(history, error, t);
                        });
                  }}
               >
                  {t("popup.action.logout.button.desconect")}
               </Button>
            </span>
         </div>
      </div>
   );
};
