function ArrowDownFilledIcon({ style }) {
  if (style == null) {
    style = [];
  }

  return (
    <svg
      width={style.hasOwnProperty("size") ? style.size : 24}
      height={style.hasOwnProperty("size") ? style.size : 24}
      style={{cursor: 'pointer'}}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.41397 9H17.586C17.7837 9.00004 17.977 9.05871 18.1414 9.1686C18.3058 9.27848 18.434 9.43465 18.5096 9.61735C18.5853 9.80005 18.6051 10.0011 18.5666 10.195C18.528 10.389 18.4328 10.5671 18.293 10.707L12.707 16.293C12.5194 16.4805 12.2651 16.5858 12 16.5858C11.7348 16.5858 11.4805 16.4805 11.293 16.293L5.70697 10.707C5.56716 10.5671 5.47196 10.389 5.43339 10.195C5.39482 10.0011 5.41462 9.80005 5.49029 9.61735C5.56596 9.43465 5.6941 9.27848 5.85851 9.1686C6.02292 9.05871 6.21622 9.00004 6.41397 9Z"
        fill={style.hasOwnProperty("color") ? style.color : "#282828"}
      />
    </svg>
  );
}

export default ArrowDownFilledIcon;
