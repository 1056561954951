import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";

import "../assets/css/components/targetModel.css";
import FocusIcon from "../views/popups/creations/ChooseAlgorithmPopUp/assets/icons/jsxIcons/FocusIcon";
import { selectDataSetsContent, setDataSetsContent } from "../store/slices/cache/cacheSlice";
import { selectMainAttribute, setMainAttribute } from "../store/slices/mainAttribute/mainAttributeSlice";
import datasetService from "../services/DatasetService";
import handleErrorResponse from "../helpers/handleErrorResponse";

const TargetModel = ({ model, nameAlgorithm, readOnly, dataSetExists, setDataSetExists }) => {
   const { datasetId, target } = model;
   const { t } = useTranslation();
   const dispatch = useDispatch();
   const history = useHistory();

   const cachedDataset = useSelector(selectDataSetsContent).find((dataset) => dataset.id === datasetId);
   const mainAttribute = useSelector(selectMainAttribute);

   const [tableData, setTableData] = useState();
   const [attributes, setAttributes] = useState([]);

   const fetchTableData = useCallback(() => {
      datasetService
         .getdataproxy(datasetId)
         .then((dataset) => {
            const clonedDataset = { ...dataset };
            dispatch(setDataSetsContent(clonedDataset));
            setTableData(dataset.headerInfo);
            setDataSetExists(true);
         })
         .catch((error) => {
            handleErrorResponse(history, error, t);
         });
   }, [datasetId, dispatch, history, t, setDataSetExists]);

   useEffect(() => {
      if (!dataSetExists) {
         return;
      }
      if (cachedDataset) {
         setTableData(cachedDataset.headerInfo);
      } else {
         fetchTableData();
      }
   }, [cachedDataset, dataSetExists, fetchTableData]);

   useEffect(() => {
      if (tableData) {
         let auxAttributes = tableData;
         if (nameAlgorithm === "Linear Regression") {
            auxAttributes = tableData.filter((attr) => attr.Tipo === "Numeric");
         } else if (["SVM", "Multilayer Perceptron"].includes(nameAlgorithm)) {
            auxAttributes = tableData.filter((attr) => attr.Tipo === "Categorical");
         } else if (nameAlgorithm === "Yolo") {
            auxAttributes = [{ Nome: "Detecção de Imagem" }];
         }

         if (!auxAttributes.some((attr) => attr.Nome === target)) {
            dispatch(setMainAttribute(auxAttributes[0]?.Nome || ""));
         }

         setAttributes(auxAttributes);
      }
   }, [tableData, nameAlgorithm, target, dispatch]);

   const handleOnChange = (event) => {
      const selectedAttribute = tableData?.find((attr) => attr.Nome === event.target.value);
      if (selectedAttribute) {
         dispatch(setMainAttribute(selectedAttribute.Nome));
      }
   };

   const renderMainAttributeOptions = () => {
      if (attributes.length > 0) {
         return attributes.map((attr) => (
            <option value={attr.Nome} key={attr.Id}>
               {attr.Nome}
            </option>
         ));
      }
      return (
         <option value={target} key="disabled" disabled>
            {target}
         </option>
      );
   };

   return (
      <div className="target-model">
         <div className="target-model-svg">
            <FocusIcon />
         </div>
         <select
            name="mainAttribute"
            id="mainAttribute"
            onChange={handleOnChange}
            placeholder={readOnly ? target : mainAttribute}
            value={readOnly ? target : mainAttribute}
            disabled={readOnly}
         >
            {renderMainAttributeOptions()}
         </select>
      </div>
   );
};

export default TargetModel;
