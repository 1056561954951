import "./../assets/css/components/buttonswitch.css";

function ButtonSwitch({
    trigger,
    setTrigger,
}){
    return (
        <div 
            className={trigger ? "buttonActive containerButtonSwitch" : "buttonInactive containerButtonSwitch"} 
            onClick={() => setTrigger(!trigger)}
        >
            <div></div>
        </div>
    )
}

export default ButtonSwitch;