import React, { useState, useEffect } from "react";
import { setNewNotification } from "../../store/slices/notification/notificationSlice";

import "../../assets/css/views/models/collaborativefiltering.css";

import FileIcon from "../../assets/icons/jsxIcons/FileIcon";
import TableIcon from "../../assets/icons/jsxIcons/TableIcon";

import { Popup } from "../../components/Popup";
import InputSlider from "../../components/Slider";
import LoadingWheel from "../../components/LoadingWheel";

import ws from "../../services/wiselib";
import modelService from "../../services/ModelService";

import { CollaborativeFilteringTable } from "../../graphicsComponents/modelsRepresentations/CollaborativeFilteringTable";

import { MetricsPopup } from "../popups/informations/MetricsPopup";
import { useDispatch, useSelector } from "react-redux";
import { setRunningTask } from "../../store/slices/tasks/tasksSlice";
import { selectUrl } from "../../store/slices/url/urlSlice";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { setNeedUpdateProjectList } from "../../store/slices/projects/projectsSlice";
import { setModelsResult } from "../../store/slices/cache/cacheSlice";
import ModeTabs from "../../components/ModeTabs";
import { useTranslation } from "react-i18next";
import "./../../translation/i18n";

import handleErrorResponse from "../../helpers/handleErrorResponse";
import CustomTooltipWrapper from "../../components/CustomTooltip";
import { MdOutlineFileOpen } from "react-icons/md";
import SaveStatus from "../../components/SaveStatus";

export const CollaborativeFiltering = ({
   pageId,
   model,
   setModel,
   setIsCreated,
   isCreated,
   setGeneratedPredictionsPopup,
   attributesALS,
   dataSetExists,
}) => {
   const [readOnly, setReadOnly] = useState(isCreated);

   const { t } = useTranslation();

   /* Número de iterações do algoritmo */
   const [numIterations, setNumIterations] = useState(5);
   /* Número de recomendações do algoritmo */
   const [numRecomendations, setNumRecomendations] = useState(5);
   /* Bool que define se a task ainda está carregando */
   const [taskIsLoading, setTaskIsLoading] = useState(false);
   /* Progresso atual do modelo */
   const [progress, setProgress] = useState(0);
   /** Pop-up de metricas */
   const [metricsPopup, setMetricsPopup] = useState(false);

   const [collaborativeContent, setCollaborativeContent] = useState([]);

   const { parameters } = model;

   const dispatch = useDispatch();
   const history = useHistory();

   const url = useSelector(selectUrl);

   const taskList = useSelector((state) => state.tasks.taskList);
   const [taskId, setTaskId] = useState("");

   useEffect(() => {
      if (taskId) {
         let task = taskList.filter((task) => task.task === taskId)[0];
         if (task) {
            setProgress(task.progress);
         } else if (progress <= 100) {
            setTaskId("");
            setProgress(100);
            setTaskIsLoading(false);
            modelService
               .getmodel(taskId)
               .then((data) => {
                  setTaskId("");
                  dispatch(setModelsResult(data));
                  dispatch(setNeedUpdateProjectList(true));
                  dispatch(setNewNotification(true));
                  history.push(`/model/${data.id}`);
               })
               .catch((error) => {
                  handleErrorResponse(history, error, t);
               });
         }
      }
      // eslint-disable-next-line
   }, [taskList]);

   useEffect(() => {
      if (model?.id) {
         setIsCreated(true);
         setReadOnly(true);
         if (url.includes("/model/create")) {
            history.push(`/model/${model?.id}`);
         }
         setCollaborativeContent(
            <CollaborativeFilteringTable modelResult={model?.result} fieldsUsed={model?.fields?.usedFields} />,
         );
      } else {
         setIsCreated(false);
      }
      // eslint-disable-next-line
   }, [model]);
   useEffect(() => {
      if (model?.id) {
         setIsCreated(true);
         setReadOnly(true);
         if (url.includes("/model/create")) {
            history.push(`/model/${model?.id}`);
         }
         setCollaborativeContent(
            <CollaborativeFilteringTable modelResult={model?.result} fieldsUsed={model?.fields?.usedFields} />,
         );
      } else {
         setIsCreated(false);
      }
      // eslint-disable-next-line
   }, [model]);

   const executeALS = () => {
      setCollaborativeContent([]);
      if (!taskIsLoading) {
         setIsCreated(false);
         setTaskIsLoading(true);
         setProgress(0);
         setModel({
            ...model,
            id: "",
            name: "",
            saved: false,
            sharedWith: null,
            result: null,
            metrics: "",
            significantFields: null,
            hasPrediction: false,
         });
         ws.filtrocolaborativo(
            model?.name,
            model?.datasetId,
            numIterations,
            numRecomendations,
            attributesALS?.userColumn,
            attributesALS?.productColumn,
            attributesALS?.ratingsColumn,
         )
            .then((id) => {
               dispatch(setRunningTask(true));
               setTaskId(id);
            })
            .catch((error) => {
               setTaskIsLoading(false);
               handleErrorResponse(history, error, t);
            });
      }
   };

   const isShared = model?.sharedWith && model?.sharedWith.length > 0;

   return (
      <div className="model-algorithm">
         <div className="main-algorithm">
            <div className="left">
               <header className="header-algorithm">
                  <div className="name">
                     <TableIcon />
                     <p id="algorithm-name">ALS</p>
                  </div>
                  <div className="name">
                     {isShared || !dataSetExists ? (
                        <FileIcon />
                     ) : (
                        <MdOutlineFileOpen size={24} />
                     )}
                     {!isShared && dataSetExists ? (
                        <button
                           onClick={() => {
                              history.push(`/dataset/${model?.datasetId}`);
                           }}
                        >
                           <p id="model-dataset">{model?.datasetName}</p>
                        </button>
                     ) : (
                        <p id="model-dataset">{model?.datasetName}</p>
                     )}
                  </div>
                  {isCreated && <ModeTabs readOnly={readOnly} setReadOnly={setReadOnly} owner={model?.owner} />}
               </header>
               <div className="model-hyperparameters">
                  <div className="model-slider">
                     <CustomTooltipWrapper title={t("model.als.slider_1.info")}>
                        <p id="iteration-input">{t("model.als.slider_1")}</p>
                     </CustomTooltipWrapper>
                     <InputSlider
                        min={1}
                        max={10}
                        defaultValue={5}
                        currentValue={readOnly ? parameters?.NumIterations : numIterations}
                        disabled={readOnly}
                        setCurrentValue={setNumIterations}
                        id="interation"
                     />
                  </div>
                  <div className="model-slider">
                     <CustomTooltipWrapper title={t("model.als.slider_2.info")}>
                        <p id="recomendation-input">{t("model.als.slider_2")}</p>
                     </CustomTooltipWrapper>
                     <InputSlider
                        min={1}
                        max={8}
                        defaultValue={5}
                        currentValue={readOnly ? parameters?.NumRecomendations : numRecomendations}
                        disabled={readOnly}
                        setCurrentValue={setNumRecomendations}
                        id="recomendation"
                     />
                  </div>
               </div>
               {!readOnly && (
                  <div className="model-execute">
                     <button
                        className={dataSetExists ? "" : "btn--disabled--solid"}
                        disabled={taskIsLoading || !dataSetExists}
                        onClick={executeALS}
                     >
                        {t("model.execute")}
                     </button>
                  </div>
               )}
            </div>

            <div className="model-result-2">
               {taskIsLoading && <LoadingWheel progress={progress} loadingSize="large" />}
               {model?.sharedWith === null && isCreated && !model?.saved && <SaveStatus />}
               {collaborativeContent}
            </div>
         </div>
         {model?.id && (
            <div className="buttons-bottom-algorithm">
               {model?.isCategoricla && (
                  <button
                     onClick={() => {
                        setMetricsPopup(true);
                     }}
                  >
                     <p>{t("model.footer.button_1")}</p>
                  </button>
               )}
               {model?.hasPrediction && (
                  <button
                     onClick={() => {
                        setGeneratedPredictionsPopup(true);
                     }}
                  >
                     <p>{t("model.footer.button_3")}</p>
                  </button>
               )}
            </div>
         )}

         <Popup trigger={metricsPopup} setTrigger={setMetricsPopup}>
            <MetricsPopup
               data={{
                  metrics: model?.metrics,
                  framework: model?.framework,
                  type: model?.type,
               }}
            />
         </Popup>
      </div>
   );
};
